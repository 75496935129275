import React, { useState } from "react";
import styled from "styled-components";
import {
  AttributeEditorProps,
  arrayFromString,
  scanInvalidOptions
} from "../../quotaHelpers";
import AttributeEditButtonGroup from "./AttributeEditButtonGroup";
import AttributeEditorOuter, {
  StyledUploadGroup,
  StyledSeparator,
  StyledTextarea
} from "./AttributeEditStyles";
import Header from "./AttributeEditHeader";
import useAttributeEdit from "../../hooks/quota/useAttributeEdit";
import { commaSeparatedString } from "../../generalHelpers";
import {
  MAX_ATTRIBUTE_OPTIONS,
  MAX_ATTRIBUTE_OPTIONS_ERROR_MESSAGE,
  MAX_NUMBER_OF_ZIP_CODES_OR_RESPONDENT_IDS
} from "../lineitem/Constants";
import { ZIP_CODE_ATTRIBUTE_ID } from "../../constants/attributeIds";

const StyledOuter = styled(AttributeEditorOuter)`
  position: relative;
`;

const Blurb = styled.div`
  margin-bottom: 1rem;
`;

type State = {
  saveDisabled: boolean;
  text: string;
  invalidOptions: string[];
  tooManyOptions: boolean;
};

const AttributeStringEditor = ({
  draft,
  attribute,
  onCancel,
  onSave,
  options,
  initialSelectedOptions = [],
  initialOperatorType,
  initialQuotaGroupInput,
  editMode,
  invalidOptions = [],
  requiredCompletes
}: AttributeEditorProps) => {
  const { id: attributeId } = attribute;
  const maxAttributeOptions =
    attributeId !== ZIP_CODE_ATTRIBUTE_ID
      ? MAX_ATTRIBUTE_OPTIONS
      : MAX_NUMBER_OF_ZIP_CODES_OR_RESPONDENT_IDS;
  const text = initialSelectedOptions ? initialSelectedOptions.join(", ") : "";
  const invalidOps = scanInvalidOptions(text, invalidOptions, attribute.format);
  const tooManyOptions = arrayFromString(text).length > maxAttributeOptions;
  const saveDisabled = text === "" || invalidOps.length >= 1 || tooManyOptions;

  const [state, setState] = useState<State>({
    text,
    saveDisabled,
    invalidOptions: invalidOps,
    tooManyOptions
  });
  const fileReader = new FileReader();

  const {
    state: attributeEditState,
    toggleEditMode,
    toggleFilterType
  } = useAttributeEdit({
    attribute,
    draft,
    options,
    initialSelectedOptions,
    initialQuotaGroupInput,
    initialEditMode: editMode,
    initialOperatorType,
    requiredCompletes
  });

  const onTextareaChange = (e: React.FormEvent<HTMLInputElement>) => {
    const text = e.currentTarget.value;
    const invalidOpts = scanInvalidOptions(
      text,
      invalidOptions,
      attribute.format
    );
    const tooManyOptions = arrayFromString(text).length > maxAttributeOptions;
    const saveDisabled =
      text === "" || invalidOpts.length >= 1 || tooManyOptions;

    setState({
      tooManyOptions,
      saveDisabled,
      text,
      invalidOptions: invalidOpts
    });
  };

  const onFileInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;
    if (files && files.length) fileReader.readAsText(files[0]);
  };

  const onReaderLoad = () => {
    const t = fileReader.result;
    const text = t === null ? "" : t.toString();
    const invalidOpts = scanInvalidOptions(
      text,
      invalidOptions,
      attribute.format
    );
    const tooManyOptions = arrayFromString(text).length > maxAttributeOptions;
    const saveDisabled =
      text === "" || invalidOpts.length >= 1 || tooManyOptions;

    setState({
      tooManyOptions,
      saveDisabled,
      text,
      invalidOptions: invalidOpts
    });
  };

  const onFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    if (e.dataTransfer.items) {
      e.preventDefault();
      const files = Array.from(e.dataTransfer.items)
        .filter(i => i.kind === "file")
        .map(i => i.getAsFile());
      if (files && files.length && files[0]) {
        const file = files[0];
        if (file !== null) fileReader.readAsText(file);
      }
    }
  };

  const saveClick = () => {
    onSave({
      asAllocation: false,
      quotaFilterInput: {
        attributeId: attribute.id,
        operator: attributeEditState.filterType,
        options: arrayFromString(state.text)
      },
      quotaGroupInput: {
        name: "",
        quotaCells: []
      }
    });
    onCancel();
  };

  const disabledMessage = !state.text.length
    ? "Text field is empty."
    : state.tooManyOptions
    ? MAX_ATTRIBUTE_OPTIONS_ERROR_MESSAGE(maxAttributeOptions)
    : "Invalid value(s).";

  fileReader.onload = onReaderLoad;

  const invalidOptionString = commaSeparatedString(state.invalidOptions, false);

  const errorMessage = "Invalid entries: " + invalidOptionString;

  return (
    <StyledOuter onDrop={onFileDrop}>
      <Header
        allocationAllowed={attribute.isAllowedInQuotas}
        filterAllowed={attribute.isAllowedInFilters}
        editMode={attributeEditState.editMode}
        toggleEditMode={toggleEditMode}
        filterType={attributeEditState.filterType}
        toggleFilterType={toggleFilterType}
        allowSearch={false}
        allowSelectAll={false}
      />

      <Blurb>Values must be valid and comma or new line delimited.</Blurb>

      <StyledTextarea value={state.text} onChange={onTextareaChange} />

      <StyledUploadGroup onFileInputChange={onFileInputChange} />

      <StyledSeparator />

      <AttributeEditButtonGroup
        saveDisabled={state.saveDisabled}
        onSave={saveClick}
        onCancel={onCancel}
        tooltipOverlay={disabledMessage}
        saveButtonName="Save"
        showError={state.invalidOptions.length > 0}
        errorMsg={errorMessage}
        toggleErrorHighlight={() => {}}
      />
    </StyledOuter>
  );
};

export default AttributeStringEditor;
