import React from "react";
import styled from "styled-components";
import PopoverStyle from "../PopoverStyle";
import OptionList from "./OptionList";
import { onlyGroupAttributeId, getOptionMapping } from "../../quotaHelpers";
import AllocationGroupContainer from "../../containers/lineitem/AllocationGroupContainer";
import { QuotaCellInput, QuotaGroupInput, AttributeMap } from "../../types";
import { StyledCheckbox } from "./AttributeEditStyles";
import AttributeEditButtonGroup from "./AttributeEditButtonGroup";

type CustomProps = {
  maxHeight: string;
} & React.HTMLProps<HTMLDivElement>;

const CustomDiv = ({ maxHeight, ...rest }: CustomProps) => {
  return <div {...rest} />;
};

export const StyledOptionList = styled(CustomDiv)`
  height: 100%;
  max-height: ${props => props.maxHeight};
  overflow-y: scroll;
`;

export const Row = styled.label`
  color: #696e7b;
  font: normal 400 1.2rem/1.7rem ${props => props.theme.fontFamily};
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  margin-bottom: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const Title = styled.h1`
  margin: 0 0 0.8rem;
  color: #696e7b;
  font: normal 400 1.3rem/1.8rem ${props => props.theme.fontFamily};
`;

const StyledOptionContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

type Props = {
  quotaCell: QuotaCellInput;
  quotaGroup: QuotaGroupInput;
  updateTempQuotaGroupInput: (g: QuotaGroupInput) => void;
  attributes: AttributeMap;
  togglePopover: () => void;
  preSelectedOption: string;
  calculatedNestingListHeight: string;
  toggleRangeGroupingChange?: () => void;
};

const AllocationGroupPopover = ({
  quotaGroup,
  quotaCell,
  updateTempQuotaGroupInput,
  attributes,
  togglePopover,
  preSelectedOption,
  calculatedNestingListHeight,
  toggleRangeGroupingChange
}: Props) => {
  return (
    <AllocationGroupContainer
      quotaGroup={quotaGroup}
      quotaCell={quotaCell}
      updateTempQuotaGroupInput={updateTempQuotaGroupInput}
      togglePopover={togglePopover}
      toggleRangeGroupingChange={toggleRangeGroupingChange}
    >
      {({ rowMap, toggleRow, updateGroupings }) => {
        const onlyAttrId = onlyGroupAttributeId(quotaGroup);
        const attribute = attributes[onlyAttrId];
        const attributeType = attribute.type;
        const rowKeys = Array.from(rowMap.keys());
        const checkedKeys = rowKeys.filter(key => rowMap.get(key));
        const updateDisabled = checkedKeys.length < 1;
        const optionMap = getOptionMapping(attribute.options);
        return (
          <PopoverStyle>
            <Title>Group with</Title>

            <StyledOptionList maxHeight={calculatedNestingListHeight}>
              {rowKeys.map((row, i) => {
                if (row.length === 1 && row[0] === preSelectedOption) {
                  return null;
                }
                return (
                  <Row key={i}>
                    <StyledCheckbox
                      checked={rowMap.get(row)}
                      onChange={() => toggleRow(row)}
                    />
                    <StyledOptionContainer>
                      <OptionList
                        options={row}
                        optionMap={optionMap}
                        attributeType={attributeType}
                      />
                    </StyledOptionContainer>
                  </Row>
                );
              })}
            </StyledOptionList>

            <AttributeEditButtonGroup
              saveDisabled={updateDisabled}
              onSave={updateGroupings}
              onCancel={togglePopover}
              tooltipOverlay="Select at-least 1 option"
              saveButtonName="Update"
              showError={false}
              toggleErrorHighlight={() => {}}
            />
          </PopoverStyle>
        );
      }}
    </AllocationGroupContainer>
  );
};

export default AllocationGroupPopover;
