import React from "react";
import styled from "styled-components";
import WidthWrap from "../WidthWrap";
import Subheader from "../Subheader";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.neutralLight["7"]};
  min-height: 100%;
  height: 100%;
  font-size: 13px;
  color: ${({ theme }) => theme.neutralDark["4"]};
`;

const DashboardStyler = ({ children }) => (
  <Subheader>
    <WidthWrap>
      <Wrapper>{children}</Wrapper>
    </WidthWrap>
  </Subheader>
);

export default DashboardStyler;
