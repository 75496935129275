import React from "react";
import styled from "styled-components";
import { Wrapper } from "./Wrapper";
import { Datepicker } from "samplify-design";
import ClearFilter from "./ClearFilter";

const Separator = styled.span`
  margin: 0 1em;
`;

const StyledDatePicker = styled(Datepicker)`
  margin-right: 1em;
`;

const DateRange = ({ filterDate, setFilterDate }) => {
  const startDate =
    filterDate && filterDate.startDate ? filterDate.startDate : "";
  const endDate = filterDate && filterDate.endDate ? filterDate.endDate : "";
  const setStartDate = startDate => setFilterDate({ startDate, endDate });
  const setEndDate = endDate => setFilterDate({ startDate, endDate });
  const clearFilter = () => setFilterDate(null);
  const today = new Date();
  return (
    <Wrapper>
      <Datepicker
        clearIcon={null}
        maxDate={today}
        minDetail={"year"}
        onChange={setStartDate}
        value={startDate}
      />
      <Separator>-</Separator>
      <StyledDatePicker
        clearIcon={null}
        maxDate={today}
        minDetail={"year"}
        onChange={setEndDate}
        value={endDate}
      />
      <ClearFilter clearFilter={clearFilter} />
    </Wrapper>
  );
};

export default DateRange;
