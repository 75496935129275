import React from "react";
import { login } from "../networkHelpers";

/**
 * LoginCallback should be rendered at the /callback route.
 * It plucks a Keycloak code from the query string and calls fetch to get a session ID.
 */
class LoginCallback extends React.Component {
  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    if (code) login(code);
  }

  render() {
    return null;
  }
}

export default LoginCallback;
