import styled from "styled-components";

export const AccountMenuCommonStyles = `
  background: #ffffff;
  border: 0.1rem solid #e7eaed;
  border-radius: 0.4rem;
  box-sizing: border-box;
  filter: drop-shadow(0px 5px 10px rgba(19, 21, 46, 0.16));
  width: 30rem;
  z-index: 100 !important;
`;

export const StyledMenuHeader = styled.div`
  display: flex;
  background: #e7eaed;
  justify-content: center;
  padding: 1.4rem 1.6rem;
`;

export const StyledMenuHeaderLabel = styled.span`
  color: #4e525c;
  font: normal 600 1.4rem/2.2rem ${props => props.theme.fontFamily};
  text-overflow: ellipsis;
  overflow: hidden;
`;
