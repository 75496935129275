import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import register from "./registerServiceWorker";
import "./react-virtualized-styles.css";
import "samplify-design/bundle.css";
import "./styles/globalStyle.css";
import "./index.css";
import * as Sentry from "@sentry/browser";
import * as FullStory from "@fullstory/browser";

register();

try {
  if (process.env.NODE_ENV === "production") {
    // FullStory initialization ...
    FullStory.init({ orgId: "HCGKS" });

    // Sentry initialization ...
    const sentry_dsn =
      window.__SERVER_VARS__.attributes.sentryDSN ||
      process.env.REACT_APP_SENTRY_DSN;
    Sentry.init({ dsn: sentry_dsn });
  }
} catch (e) {
  console.error(e);
}

ReactDOM.render(<App />, document.getElementById("root"));
