import React, { useContext, Fragment } from "react";
import styled from "styled-components";
import ModalPropsContext from "../../containers/ModalPropsContext";
import ConfirmationModal from "./ConfirmationModal";
import { CMIX } from "../../constants/titles";
import svgInfo from "../../art/iconInfoBlue.svg";

const StyledHeader = styled.div`
  color: #696e7b;
  display: flex;
  align-items: center;
`;
const StyledInfoIcon = styled.img`
  width: 3.2rem;
  height: auto;
`;
const StyledTitle = styled.div`
  margin-left: 0.9rem;
  font: normal 600 1.6rem/2.4rem ${props => props.theme.fontFamily};
`;

const StyledBlurb = styled.p`
  color: #696e7b;
  font: normal 400 1.4rem/2.1rem ${props => props.theme.fontFamily};
`;

type Props = {
  clearModal: () => void;
};

type ModalProps = {
  duplicateProject: () => void;
};

type ModalPropsContextArg = {
  modalProps: ModalProps;
};

const ConfirmDuplicateProjectInternalSurveys = ({ clearModal }: Props) => {
  const context: ModalPropsContextArg = useContext(ModalPropsContext);
  const { duplicateProject } = context.modalProps;

  const confirmModal = () => {
    duplicateProject();
    clearModal();
  };

  return (
    <ConfirmationModal
      confirmation={
        <Fragment>
          <StyledHeader>
            <StyledInfoIcon src={svgInfo} />
            <StyledTitle>Survey(s) Will Not Be Duplicated</StyledTitle>
          </StyledHeader>

          <StyledBlurb>
            Your project contains survey(s) associations from {CMIX} or an
            external link, which cannot be duplicated. Duplicating this project
            will not duplicate the associated survey(s).
          </StyledBlurb>
        </Fragment>
      }
      onCancel={clearModal}
      onConfirm={confirmModal}
      confirmLabel={"Accept & Duplicate"}
    />
  );
};

export default ConfirmDuplicateProjectInternalSurveys;
