export const HEADER = "header",
  APPS = "apps",
  DASHBOARD = "dashboard",
  INVOICES = "invoices",
  HELP = "help",
  ACCOUNT = "account",
  CHANGE_PWD = "changePwd",
  LOGOUT = "logout",
  SWITCH_COMPANY = "switchCompany";

export default HEADER;
