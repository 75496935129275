import React, { ReactNode, Fragment } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import uploadIcon from "../../../art/upload.svg";
import alert from "../../../art/alert.svg";

interface DropzoneStyleType {
  isDragActive: boolean;
}

const StyledDropzone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #3ea8dd;
  height: 170px;
  margin: 10px 0;
  color: #9599a4;
  background: ${({ isDragActive }: DropzoneStyleType) =>
    isDragActive ? "#DBE1EE" : "#FFF"};
`;

const StyledDropzoneContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledDropzoneText = styled.div`
  margin: 10px 0;
`;

const StyledError = styled.div`
  margin: 20px 0;
  background: #fef0ee;
  border-color: #f76e5c;
  color: #292a33;
  font-size: 14px;
  & .axiom-notification-status {
    font-size: 17px;
  }
`;

const StyledErrorWrapper = styled.div`
  display: inline-flex;
`;

interface PropTypes {
  onDrop: (acceptedFiles: File[]) => void;
  accept: string | string[];
  uploadBtn: ReactNode;
}

const Dropzone = ({ onDrop, accept, uploadBtn }: PropTypes) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    rejectedFiles
  } = useDropzone({
    onDrop,
    accept
  });
  const dragText = isDragActive
    ? "Release to drop the files here"
    : "Drag & drop your XLS file here, or";

  const maybeError = rejectedFiles.length > 0 && (
    <StyledErrorWrapper>
      <StyledError className="axiom-notification-error">
        <span className="axiom-notification-status">
          <img src={alert} alt="alert-icon" width="12" />
        </span>
        <div className="axiom-notification-text">
          <h6>The file has the wrong file format. It must be XLS.</h6>
        </div>
        {/* <span className="axiom-notification-close">
          <i className="axiom-icon-icon icon-x"></i>
        </span> */}
      </StyledError>
    </StyledErrorWrapper>
  );

  return (
    <Fragment>
      {maybeError}
      <StyledDropzone {...getRootProps()} isDragActive={isDragActive}>
        <input className="dropzone-input" {...getInputProps()} />
        <StyledDropzoneContent>
          <img src={uploadIcon} alt="Upload" />
          <StyledDropzoneText>{dragText}</StyledDropzoneText>
          {uploadBtn}
        </StyledDropzoneContent>
      </StyledDropzone>
    </Fragment>
  );
};

export default Dropzone;
