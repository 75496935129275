import React from "react";

const OpenEnd = ({ includeOpen, update }) => (
  <React.Fragment>
    <h1>Open End</h1>
    <label>
      <input
        type="checkbox"
        value="includeOpen"
        checked={includeOpen}
        onChange={update("includeOpen")}
      />
      Include
    </label>
  </React.Fragment>
);

export default OpenEnd;
