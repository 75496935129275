import React from "react";
import { AppearanceTypes } from "react-toast-notifications";
import styled from "styled-components";
import ErrorIcon from "../art/iconError.svg";
import WarningIcon from "../art/iconWarning.svg";
import InfoIcon from "../art/iconInfoBlue.svg";

const IconWrapper = styled.div`
  margin-right: 1rem;
`;

const Message = styled.p`
  font-size: 1.2rem;
  margin: 1rem 0 0 0;
`;

const StyledToastChild = styled.div`
  display: flex;
`;

const StyledIcon = styled.img`
  height: 1.4rem;
  transform: translateY(2px);
`;

const Title = styled.p`
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0;
`;

const getIcon = (icon: AppearanceTypes) => {
  if (icon === "error") return ErrorIcon;
  if (icon === "warning") return WarningIcon;
  if (icon === "info") return InfoIcon;
  return "";
};

type Props = {
  title: string;
  message: string;
  icon?: AppearanceTypes;
};

const ToastChild = ({ title, icon, message }: Props) => {
  const maybeIcon = icon && (
    <IconWrapper>
      <StyledIcon src={getIcon(icon)} alt="" />
    </IconWrapper>
  );

  // If we get raw JSON as error message.
  const displayedMessage = message.replace(/[,:]/g, "$& ");

  return (
    <StyledToastChild>
      {maybeIcon}
      <div>
        <Title>{title}</Title>
        <Message>{displayedMessage}</Message>
      </div>
    </StyledToastChild>
  );
};

export default ToastChild;
