import uniq from "lodash/uniq";
import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";
import hash from "object-hash";
import {
  Attribute,
  AttributeType,
  QuotaPlanInput,
  QuotaNodeInput,
  QuotaGroupInput,
  QuotaCellInput,
  QuotaPlan,
  AttributeOptionMap,
  AttributeMap,
  FeasibilityQuotaGroup,
  FeasibilityQuotaCell,
  LineItemState,
  QuotaFilterInput,
  OperatorType,
  QuotaGroup,
  Report,
  QuotaCell,
  Stat,
  AttributeCategory,
  QuotaFilter,
  QuotaNode
} from "./types";

export const EDIT_MODE_FILTER = "filter";
export const EDIT_MODE_ALLOCATION = "allocation";
export type EditMode = "filter" | "allocation";

export const FILTER_TYPE_INCLUDE = "INCLUDE";
export const FILTER_TYPE_EXCLUDE = "EXCLUDE";

export const ATTR_HIGHLIGHT_COLORS = [
  "#DDF0F9",
  "#FFF0E5",
  "#DCF2EC",
  "#DFC8E1",
  "#F9CBDF",
  "#FDE8E6",
  "#C7CDE8",
  "#E9DCCA",
  "#E4B6AE",
  "#FCF3C7"
];

export const COMPLETES_COLOR = "#3EA8DD";
export const INCOMPLETES_COLOR = "#F0C419";
export const SCREENOUTS_COLOR = "#FB903F";
export const OVERQUOTAS_COLOR = "#B27CA7";
export const COMPLETES_REFUSED_COLOR = "#A7BECF";

export type AllocVal = number | "";

export type AllocationValue = {
  count: AllocVal;
  perc: AllocVal;
};

export const parseAllocations = (
  map: Map<number | string, AllocationValue>
) => {
  const result = new Map(map);
  const keys = Array.from(result.keys());
  for (let key of keys) {
    const allocationVal = result.get(key);
    if (allocationVal) {
      let numCount = allocationVal.count,
        numPerc = allocationVal.perc;
      if (typeof numCount !== "number") {
        numCount = parseFloat(numCount) || 0;
      }
      if (typeof numPerc !== "number") {
        numPerc = parseFloat(numPerc) || 0;
      }
      result.set(key, { count: numCount, perc: numPerc });
    }
  }
  return result;
};

export type AttributeSaveInput = {
  asAllocation: boolean;
  quotaFilterInput: QuotaFilterInput;
  quotaGroupInput: QuotaGroupInput;
};

export type AttributeEditorModalProps = {
  draft: boolean;
  editMode: EditMode;
  attribute: Attribute;
  onCancel: () => void;
  onSave: (input: AttributeSaveInput) => void;
  initialSelectedOptions: string[];
  initialOperatorType: OperatorType;
  initialQuotaGroupInput: QuotaGroupInput;
  requiredCompletes: number;
  lineItemState: LineItemState;
  attributeMap: AttributeMap;
  invalidOptions: string[];
};

type OptionTextIdMap = {
  [id: string]: string;
};

export type AttributeEditorProps = {
  options: AttributeOptionMap;
  calculatedListHeight: string;
  calculatedNestingListHeight: string;
} & AttributeEditorModalProps;

export type AttributeSelection = {
  [id: string]: boolean;
};

export type CellLike = {
  quotaNodes: Array<QuotaNodeInput>;
};

type GroupLike = {
  quotaCells: Array<CellLike>;
};

type OptionTextArgs = {
  optionString: string;
  optionMap: AttributeOptionMap;
  attributeType: AttributeType;
};

export const groupNodes = (quotaGroup: GroupLike): Array<QuotaNodeInput> => {
  const quotaNodes = new Array<QuotaNodeInput>();

  const nonNullCells = quotaGroup.quotaCells || [];
  nonNullCells.forEach(cell => {
    const nonNullNodes = cell.quotaNodes || [];
    quotaNodes.push(...nonNullNodes);
  });

  return quotaNodes;
};

export const planCells = (quotaPlan: QuotaPlanInput): Array<QuotaCellInput> => {
  const quotaCells = new Array<QuotaCellInput>();

  const nonNullGroups = quotaPlan.quotaGroups || [];
  nonNullGroups.forEach(g => {
    quotaCells.push(...g.quotaCells);
  });

  return quotaCells;
};

export const planNodes = (quotaPlan: QuotaPlanInput): Array<QuotaNodeInput> => {
  const quotaNodes = new Array<QuotaNodeInput>();

  planCells(quotaPlan).forEach(cell => {
    const nonNullNodes = cell.quotaNodes || [];
    quotaNodes.push(...nonNullNodes);
  });

  return quotaNodes;
};

export const planNodeAttributeIds = (quotaPlan: QuotaPlanInput) =>
  uniq(planNodes(quotaPlan).map(node => node.attributeId));

export const planAttributeIds = (quotaPlan: QuotaPlanInput): Array<string> => {
  const attributeIds = new Array<string>();

  quotaPlan.filters.forEach(f => attributeIds.push(f.attributeId));

  quotaPlan.quotaGroups.forEach(g =>
    g.quotaCells.forEach(c =>
      c.quotaNodes.forEach(n => attributeIds.push(n.attributeId))
    )
  );

  return attributeIds;
};

export const updateInterlock = (
  quotaPlan: QuotaPlanInput,
  selections: AttributeSelection,
  requiredCompletes: number
): QuotaPlanInput => {
  const allNonInterlockedGroups = clearInterlocks(quotaPlan.quotaGroups || []);

  const interlocked = new Array<QuotaGroupInput>();
  const loose = new Array<QuotaGroupInput>();
  allNonInterlockedGroups.forEach(g => {
    if (selections[g.quotaCells[0].quotaNodes[0].attributeId]) {
      interlocked.push(g);
    } else {
      loose.push(g);
    }
  });

  const newInterlockedGroup = interlockGroups(interlocked, requiredCompletes);

  return {
    filters: quotaPlan.filters || [],
    quotaGroups: [newInterlockedGroup, ...loose]
  };
};

export const clearInterlocks = (
  groups: Array<QuotaGroupInput>
): Array<QuotaGroupInput> => {
  const nonInterlockedGroupMap = new Map<string, QuotaGroupInput>();

  const interlocked = new Array<QuotaGroupInput>();
  groups.forEach(g => {
    const ids = groupAttributeIds(g);
    // add the loose groups directly to map
    if (ids.length === 1) {
      nonInterlockedGroupMap.set(ids[0], g);
    } else {
      interlocked.push(g);
    }
  });

  interlocked.forEach(g => {
    g.quotaCells.forEach(c => {
      c.quotaNodes.forEach(n => {
        const maybeExistingGroup = nonInterlockedGroupMap.get(n.attributeId);

        if (maybeExistingGroup) {
          let isNewCell = true;
          maybeExistingGroup.quotaCells.forEach(cell => {
            // check if current interlockedNode `n` is same as
            // node `cell.quotaNodes[0]` (existing in map)
            // to avoid creating a new group and just update perc of existing cell
            if (isEqual(n, cell.quotaNodes[0])) {
              isNewCell = false;
              nonInterlockedGroupMap.set(n.attributeId, {
                name: "",
                quotaCells: [
                  ...maybeExistingGroup.quotaCells.filter(
                    c => !isEqual(c, cell)
                  ),
                  { count: cell.count + c.count, quotaNodes: [n], perc: 0 }
                ]
              });
              return;
            }
          });
          if (isNewCell) {
            nonInterlockedGroupMap.set(n.attributeId, {
              name: "",
              quotaCells: [
                ...maybeExistingGroup.quotaCells,
                {
                  count: c.count,
                  quotaNodes: [n],
                  perc: 0
                }
              ]
            });
          }
          return;
        }

        nonInterlockedGroupMap.set(n.attributeId, {
          name: "",
          quotaCells: [{ count: c.count, quotaNodes: [n], perc: 0 }]
        });
      });
    });
  });

  return Array.from(nonInterlockedGroupMap.values());
};

export const interlockGroups = (
  groups: Array<QuotaGroupInput>,
  requiredCompletes: number
): QuotaGroupInput => {
  if (groups.length === 0) {
    return { name: "", quotaCells: [] };
  }

  if (groups.length === 1) {
    return groups[0];
  }

  let interlockedGroup = groups[0];
  groups.forEach((g, i) => {
    if (i !== 0) {
      interlockedGroup = interlockTwoGroups(
        interlockedGroup,
        g,
        requiredCompletes
      );
    }
  });

  return interlockedGroup;
};

export const interlockTwoGroups = (
  group1: QuotaGroupInput,
  group2: QuotaGroupInput,
  requiredCompletes: number
): QuotaGroupInput => {
  const interlockedCells = new Array<QuotaCellInput>();

  group1.quotaCells.forEach(group1Cell => {
    group2.quotaCells.forEach(group2Cell => {
      interlockedCells.push({
        count: Math.round(
          (group1Cell.count * group2Cell.count) / requiredCompletes
        ),
        quotaNodes: [...group1Cell.quotaNodes, ...group2Cell.quotaNodes],
        perc: 0
      });
    });
  });

  return {
    name: "",
    quotaCells: distributeRemaining(interlockedCells, requiredCompletes)
  };
};

export const distributeRemaining = (
  quotaCells: Array<QuotaCellInput>,
  requiredCompletes: number
): Array<QuotaCellInput> => {
  let counts = quotaCells.map(c => c.count);
  const cellsTotalCount = counts.reduce((a, b) => a + b, 0);
  const remainder = requiredCompletes - cellsTotalCount;

  let rectifyCnt = +1;
  if (remainder < 0) {
    rectifyCnt = -1;
  }
  for (let i = 0; i < Math.abs(remainder); i++) {
    let idx = i % counts.length;
    while (idx !== counts.length - 1 && counts[idx] + rectifyCnt < 0) {
      idx++;
      idx = idx % counts.length;
    }
    counts[idx] = counts[idx] + rectifyCnt;
  }

  return quotaCells.map((c, i) => ({
    ...c,
    count: counts[i]
  }));
};

const nodeComparison = (a: QuotaNodeInput, b: QuotaNodeInput): number => {
  if (a.attributeId > b.attributeId) return 1;
  if (a.attributeId < b.attributeId) return -1;
  if (a.options.sort()[0] > b.options.sort()[0]) return 1;
  if (a.options.sort()[0] < b.options.sort()[0]) return -1;
  return 0;
};

export const cellsEqual = (a: CellLike, b: CellLike): boolean => {
  if (Array.isArray(a.quotaNodes)) {
    const copyA = a.quotaNodes.slice();
    const copyB = b.quotaNodes.slice();
    const groupA = copyA.sort(nodeComparison);
    const groupB = copyB.sort(nodeComparison);
    return isEqual(groupA, groupB);
  } else {
    return isEqual(a, b);
  }
};

export const groupsEqual = (a: GroupLike, b: GroupLike): boolean => {
  const copyA = Object.assign({}, a);
  const copyB = Object.assign({}, b);
  const groupA = groupNodes(copyA).sort(nodeComparison); // groupNodes returns an array of nodes for quota group or valueCount
  const groupB = groupNodes(copyB).sort(nodeComparison);
  return isEqual(groupA, groupB);
};

export const groupFromFilter = (
  filter: QuotaNodeInput,
  requiredCompletes: number,
  name: string
): QuotaGroupInput => {
  const nonNullOptions = filter.options || [];
  const quotaCells = nonNullOptions.map<QuotaCellInput>(o => ({
    quotaNodes: [
      {
        attributeId: filter.attributeId,
        options: [o]
      }
    ],
    count: 0,
    perc: 0,
    __typename: "QuotaCell"
  }));

  return {
    name: name,
    quotaCells: flattenAllocation(quotaCells, requiredCompletes),
    __typename: "QuotaGroupInput"
  };
};

const distributeEvenly = (
  quotaCells: Array<QuotaCellInput>,
  requiredCompletes: number
): Array<QuotaCellInput> => {
  const divisor = quotaCells.length;
  const remainder = requiredCompletes % divisor;
  const minCountPerCell = (requiredCompletes - remainder) / divisor;

  let counts = new Array<number>(quotaCells.length).fill(minCountPerCell);

  for (let i = 0; i < remainder; i++) {
    counts[i] = counts[i] + 1;
  }

  return quotaCells.map((c, i) => ({
    ...c,
    count: counts[i],
    perc: getPerc(counts[i], requiredCompletes)
  }));
};

const distributeSequentially = (
  quotaCells: Array<QuotaCellInput>,
  totalCount: number
): Array<QuotaCellInput> => {
  const counts = new Array<number>(quotaCells.length).fill(1, 0, totalCount);

  return quotaCells.map<QuotaCellInput>((c, i) => ({
    ...c,
    count: counts[i],
    perc: getPerc(counts[i], totalCount)
  }));
};

export const flattenAllocation = (
  quotaCells: Array<QuotaCellInput>,
  requiredCompletes: number
): Array<QuotaCellInput> => {
  if (quotaCells.length === 0) {
    return [];
  }

  if (quotaCells.length > requiredCompletes) {
    return distributeSequentially(quotaCells, requiredCompletes);
  }

  return distributeEvenly(quotaCells, requiredCompletes);
};

export const getPerc = (count: number, total: number): number => {
  return parseFloat(((count / total) * 100).toFixed(2));
};

export const getCount = (perc: number, total: number): number => {
  return Math.round((perc / 100) * total);
};

const nodeOptions = (node: QuotaNodeInput): string[] =>
  new Array<string>(...(node.options || []));

const cellOptions = (cell: QuotaCellInput): string[] => {
  const opts = new Array<string>();

  const nonNullNodes = cell.quotaNodes || [];
  nonNullNodes.forEach(node => opts.push(...nodeOptions(node)));

  return opts;
};

export const groupOptions = (group: QuotaGroupInput): string[] => {
  const opts = new Array<string>();

  const nonNullCells = group.quotaCells || [];
  nonNullCells.forEach(cell => opts.push(...cellOptions(cell)));

  return opts;
};

export const groupAttributeIds = (group: QuotaGroupInput): string[] => {
  const attrIDSet = new Set<string>();

  (group.quotaCells || []).forEach(c =>
    (c.quotaNodes || []).forEach(n => attrIDSet.add(n.attributeId))
  );

  return Array.from(attrIDSet);
};

export const onlyGroupAttributeId = (group: QuotaGroupInput) => {
  const attrIds = groupAttributeIds(group);
  if (attrIds.length !== 1)
    throw new Error(
      "onlyGroupAttributeId expected to receive a quota group with only one attribute id."
    );
  return attrIds[0];
};

/**
 * filterFromGroup returns a quota filter from a given quota group.
 * If the given group has more than 1 attribute Id, filterFromGroup will throw.
 */
export const filterFromGroup = (
  group: QuotaGroupInput,
  operator: OperatorType
): QuotaFilterInput => ({
  attributeId: onlyGroupAttributeId(group),
  options: groupOptions(group),
  operator: operator
});

export const nodesEqual = (a: QuotaNodeInput, b: QuotaNodeInput) =>
  a.attributeId === b.attributeId && isEqual(a.options, b.options);

export const uniqueNodes = (quotaNodes: Array<QuotaNodeInput>) =>
  uniqWith(quotaNodes, nodesEqual);

export const inputFromPlan = (plan: QuotaPlan): QuotaPlanInput => ({
  filters: plan.filters.map(f => inputFromFilter(f)),
  quotaGroups: plan.quotaGroups.map(g => inputFromGroup(g)),
  __typename: "QuotaPlan"
});

export const inputFromFilter = (filter: QuotaFilter): QuotaFilterInput => ({
  attributeId: filter.attributeId,
  options: filter.options,
  operator: filter.operator,
  __typename: "Node"
});

export const inputFromGroup = (group: QuotaGroup): QuotaGroupInput => ({
  name: group.name,
  quotaCells: group.quotaCells.map(c => inputFromCell(c)),
  __typename: "QuotaGroup"
});

export const inputFromCell = (cell: QuotaCell): QuotaCellInput => ({
  count: cell.count,
  quotaNodes: cell.quotaNodes.map(n => inputFromNode(n)),
  perc: cell.perc,
  status: cell.status,
  quotaCellID: cell.quotaCellID,
  __typename: "QuotaCell"
});

export const inputFromNode = (node: QuotaNode): QuotaNodeInput => ({
  attributeId: node.attributeId,
  options: node.options,
  __typename: "Node"
});

const BlankStat: Stat = {
  floatValue: 0.0,
  intValue: 0,
  percentageValue: 0,
  percentageValueRounded: 0,
  status: "Error",
  statusMessage: ""
};

export const BlankReport: Report = {
  attempts: BlankStat,
  completes: BlankStat,
  screenouts: BlankStat,
  overquotas: BlankStat,
  incompletes: BlankStat,
  completesRefused: BlankStat,
  conversion: BlankStat,
  incidence: BlankStat,
  progress: BlankStat,
  remainingCompletes: BlankStat,
  actualMedianLOI: BlankStat
};

export const cellReport = (
  cellInput: QuotaCellInput,
  cells: Array<QuotaCell>
): Report => {
  const expectedCell = cells.find(c => cellsEqual(inputFromCell(c), cellInput));
  return expectedCell ? expectedCell.quotaCellReport : BlankReport;
};

export const isQuotaPlanNull = (quota: QuotaPlan) => {
  if (quota.filters.length === 0 && quota.quotaGroups.length === 0) return true;
  return false;
};

export const getAttributeFormPlanFilter = (
  attributeId: string,
  quotaPlan: QuotaPlan,
  allAttrMap: AttributeMap
): Attribute => {
  const id = quotaPlan.filters.filter(n => n.attributeId === attributeId)[0]
    .attributeId;
  return allAttrMap[id];
};

export const getAttributesFormPlan = (
  quotaPlan: QuotaPlan,
  allAttrMap: AttributeMap
): AttributeMap => {
  const attributes = {} as AttributeMap;

  (quotaPlan.filters || []).forEach(
    f => (attributes[f.attributeId] = allAttrMap[f.attributeId])
  );

  (quotaPlan.quotaGroups || []).forEach(g =>
    (g.quotaCells || []).forEach(c =>
      (c.quotaNodes || []).forEach(
        n => (attributes[n.attributeId] = allAttrMap[n.attributeId])
      )
    )
  );

  return attributes;
};

export const getOptionMapping = (options: string): AttributeOptionMap => {
  if (!options) return {};
  if (typeof options === "object") return options;
  return JSON.parse(options);
};

export const getOptionText = ({
  optionString,
  optionMap,
  attributeType
}: OptionTextArgs) => {
  if (!optionMap) {
    return optionString;
  }

  if (!optionMap[optionString]) {
    return optionString;
  }

  switch (attributeType) {
    case "LIST":
      return optionMap[optionString].text;
    default:
      return optionString;
  }
};

export const sortOptionList = (
  attributeType: AttributeType,
  attributeOptions: string,
  selectedOptions: string[]
): string[] => {
  const optionMap = getOptionMapping(attributeOptions);
  const selectedOptionsText: string[] = selectedOptions.map(o =>
    getOptionText({ optionString: o, optionMap, attributeType })
  );
  const sortedOptionsText = selectedOptionsText.sort();
  return sortedOptionsText;
};

export const attrSorter = (a: Attribute, b: Attribute) => {
  const attrNameA = a.name.toUpperCase();
  const attrNameB = b.name.toUpperCase();
  if (attrNameA < attrNameB) return -1;
  if (attrNameA > attrNameB) return 1;
  return 0;
};

export const makeNodeSorter = (attributeMap: AttributeMap) => (
  a: QuotaNodeInput,
  b: QuotaNodeInput
) => {
  const attrA = attributeMap[a.attributeId];
  const attrB = attributeMap[b.attributeId];
  return attrSorter(attrA, attrB);
};

export const buildGroupLikeFromQuotaGroup = (group: QuotaGroup): GroupLike => {
  const x = {
    quotaCells: group.quotaCells.map(c => ({
      quotaNodes: c.quotaNodes.map(n => ({
        attributeId: n.attributeId,
        options: n.options
      }))
    }))
  };
  return x;
};

export const buildGroupLikeFromFeasibilityGroup = (
  group: FeasibilityQuotaGroup
): GroupLike => {
  const x = {
    quotaCells: group.quotaCells.map(c => ({
      quotaNodes: c.quotaNodes.map(n => ({
        attributeId: n.attributeId,
        options: n.options
      }))
    }))
  };
  return x;
};

export const buildCellLikeFromFeasibilityCell = (
  cell: FeasibilityQuotaCell
): CellLike => {
  return {
    quotaNodes: cell.quotaNodes.map(n => ({
      attributeId: n.attributeId,
      options: n.options
    }))
  };
};

export const buildCellLikeFromQuotaGroupCell = (
  cell: QuotaCellInput
): CellLike => {
  return {
    quotaNodes: cell.quotaNodes.map(n => ({
      attributeId: n.attributeId,
      options: n.options
    }))
  };
};

export const sortOptionMapKeys = (
  optionMap: AttributeOptionMap,
  list: string[]
): string[] => {
  let optionTextMap: OptionTextIdMap = {};
  for (let i = 0; i < list.length; i++) {
    const k = list[i];
    optionTextMap[optionMap[k].text.toUpperCase()] = optionMap[k].id;
  }
  const sortedOptionTexts = Object.keys(optionTextMap).sort();
  const sortedOptionKeys = sortedOptionTexts.map(s => {
    return optionTextMap[s];
  });
  return sortedOptionKeys;
};

export const getGroupedOptionMap = (
  quotaCells: QuotaCellInput[]
): Map<string, string[]> => {
  const groupedOptionMap = new Map<string, string[]>();

  quotaCells.forEach(c => {
    c.quotaNodes.forEach(n => {
      if (n.options.length >= 2) {
        n.options.forEach(o => {
          groupedOptionMap.set(o, n.options);
        });
      }
    });
  });

  return groupedOptionMap;
};

export const groupFromOptionsAndGroup = (
  attributeId: string,
  selectedOptions: string[],
  quotaGroupInput: QuotaGroupInput,
  requiredCompletes: number
): QuotaGroupInput => {
  const selectedOptionsSet = new Set(selectedOptions);

  const quotaOptions = new Array<string>();
  const quotaCellsWithSelectedOptions = new Array<QuotaCellInput>();

  quotaGroupInput.quotaCells.forEach(c =>
    c.quotaNodes.forEach(n => {
      quotaOptions.push(...n.options);
      if (n.options.some(o => selectedOptionsSet.has(o))) {
        quotaCellsWithSelectedOptions.push(c);
      }
    })
  );
  const quotaOptionSet = new Set(quotaOptions);

  const newFilterOptions = selectedOptions.filter(o => !quotaOptionSet.has(o));
  const groupFromExistingFilter: QuotaGroupInput =
    newFilterOptions.length > 0
      ? groupFromFilter(
          {
            attributeId: attributeId,
            options: newFilterOptions
          },
          requiredCompletes,
          quotaGroupInput.name
        )
      : {
          name: "",
          quotaCells: []
        };

  return {
    name: quotaGroupInput.name,
    quotaCells: [
      ...quotaCellsWithSelectedOptions,
      ...groupFromExistingFilter.quotaCells
    ]
  };
};

export const groupWithOnlyAttribute = (
  attributeId: string,
  quotaGroup: QuotaGroupInput
): QuotaGroupInput => {
  const quotaMap = new Map<string, QuotaCellInput>();

  quotaGroup.quotaCells.forEach(c => {
    c.quotaNodes.forEach(n => {
      if (n.attributeId === attributeId) {
        const key = hash(n.options);
        const node = [{ attributeId: attributeId, options: n.options }];

        const cell: QuotaCellInput = quotaMap.get(key) || {
          quotaNodes: node,
          count: 0,
          perc: 0
        };

        quotaMap.set(key, {
          quotaNodes: node,
          count: cell.count + c.count,
          perc: cell.perc + c.perc
        });
      }
    });
  });

  return {
    name: "",
    quotaCells: Array.from(quotaMap.values())
  };
};

//groupsWithoutAttribute will return all the groups without the one that has `attributeId`
export const groupsWithoutAttribute = (
  attributeId: string,
  quotaGroups: Array<QuotaGroupInput>
): Array<QuotaGroupInput> =>
  quotaGroups.filter(
    g =>
      g.quotaCells.length >= 1 &&
      g.quotaCells[0].quotaNodes[0].attributeId !== attributeId
  );

export const getPieChartData = (
  attempts: Stat,
  completes: Stat,
  incompletes: Stat,
  screenouts: Stat,
  overquotas: Stat,
  completesRefused: Stat
) => {
  return attempts.intValue > 0
    ? [
        {
          title: "Completes",
          value: completes.intValue,
          color: COMPLETES_COLOR
        },
        {
          title: "Incompletes",
          value: incompletes.intValue,
          color: INCOMPLETES_COLOR
        },
        {
          title: "Screenouts",
          value: screenouts.intValue,
          color: SCREENOUTS_COLOR
        },
        {
          title: "Over Quota",
          value: overquotas.intValue,
          color: OVERQUOTAS_COLOR
        },
        {
          title: "Completes Refused",
          value: completesRefused.intValue,
          color: COMPLETES_REFUSED_COLOR
        }
      ]
    : null;
};

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getCategoryName = (category: AttributeCategory) => {
  return category.subCategory && category.subCategory.text.length > 0
    ? category.subCategory.text
    : category.mainCategory.text;
};

type FilterAndInvalidOps = {
  invalidOpts: string[];
  filter: QuotaFilterInput;
};

export const getInvalidFilterDataFromJSON = (
  msg: string
): FilterAndInvalidOps | null => {
  try {
    const result = JSON.parse(msg.substring(msg.indexOf("{")));
    const attrIds = Object.keys(result);
    if (attrIds.length < 1) {
      console.log("error message is blank");
      return null;
    }

    const message = result[attrIds[0]];
    if (
      message === undefined ||
      message.InvalidOpts === undefined ||
      message.Filter === undefined ||
      message.Filter.AttributeID === undefined ||
      message.Filter.Operator === undefined ||
      message.Filter.Options === undefined
    ) {
      console.log("error message is not as expected");
      return null;
    }

    return {
      filter: {
        attributeId: message.Filter.AttributeID,
        operator: message.Filter.Operator,
        options: message.Filter.Options
      },
      invalidOpts: message.InvalidOpts
    };
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const arrayFromString = (s: string) => {
  return uniq(
    s
      .trim()
      .replace(/\n/g, ",")
      .split(",")
      .filter(s => s !== undefined && s !== null && s !== "")
      .map(s => s.trim())
  );
};

export const scanInvalidOptions = (
  s: string,
  invalidOptions: string[],
  format: Attribute["format"]
): string[] => {
  const currTextsArr = arrayFromString(s);
  const currTextsSet = new Set(currTextsArr);

  const invalidOps = invalidOptions.filter(i => currTextsSet.has(i));

  const invalidFormatOps = currTextsArr.filter(c => c.match(format) === null);

  return Array.from(new Set([...invalidOps, ...invalidFormatOps]));
};

export const isAttributeInQuotaPlan = (
  attribute: Attribute,
  quotaPlanInput: QuotaPlanInput
): boolean => {
  const { filters, quotaGroups } = quotaPlanInput;
  return (
    !!filters.find(f => f.attributeId === attribute.id) ||
    !!quotaGroups.find(
      qg =>
        !!qg.quotaCells.find(
          qc => !!qc.quotaNodes.find(qn => qn.attributeId === attribute.id)
        )
    )
  );
};

export const emptyTooltipOverlayStyle = { display: "none" };

export const allocExceededText =
  "Number of allocations need to be less than 100,000.";
export const needMoreAllocText = "Allocation needs two or more options.";
export const finishAllocText = "Finish allocating your completes to save.";
export const finishFilterText = "Filter needs one or more options.";
export const selectOneFilterText = "Select at least one filter option to save.";
export const completesErrorText =
  "Make sure the number of completes allocated is greater than the number of completes already delivered.";

export const convertToTemplateQP = (
  quotaPlan: QuotaPlan,
  requiredCompletes: number
) => {
  const { filters, quotaGroups } = quotaPlan;
  const qGroups = quotaGroups.map(group => {
    const cells = group.quotaCells.map(cell => {
      return {
        perc: getPerc(cell.count, requiredCompletes),
        count: 0,
        quotaNodes: cell.quotaNodes,
        __typename: "QuotaCell"
      };
    });
    return {
      name: group.name,
      quotaCells: cells,
      __typename: "QuotaGroup"
    };
  });
  const newQuotaPlan: QuotaPlanInput = {
    filters,
    quotaGroups: qGroups,
    __typename: "QuotaPlan"
  };

  return newQuotaPlan;
};

export const getInitialAllocationEditModeMap = (
  groups: Array<QuotaGroup>
): Map<string, boolean> => {
  const map = new Map<string, boolean>();

  groups.forEach(g => {
    const key = hash(g);
    map.set(key, false);
  });

  return map;
};

export const hasAnyAllocEditOpen = (map: Map<string, boolean>): boolean => {
  let hasEditOpen = false;

  map.forEach(b => {
    if (b) {
      hasEditOpen = true;
      return;
    }
  });

  return hasEditOpen;
};

export const filterRetiredAttributes = (
  quotaPlan: QuotaPlanInput,
  attributes: AttributeMap
): Attribute[] => {
  const { filters, quotaGroups } = quotaPlan;
  const quotaGroupsAttributeIds = quotaGroups
    .map(qg => groupAttributeIds(qg))
    .flat(1);
  const filterAttributes = filters.map(f => attributes[f.attributeId]);
  const groupAttributes = quotaGroupsAttributeIds.map(v => attributes[v]);
  return [...filterAttributes, ...groupAttributes].filter(
    attr => attr.state === "DEPRECATED"
  );
};
