import React from "react";
import styled from "styled-components";
import { Checkbox, Upload, TextInput } from "samplify-design";

export const StyledCheckbox = styled(Checkbox)`
  font-size: 1.2em;
  margin-right: 0.8rem;
  > svg rect.sd-check-rect {
    fill: ${props => (props.checked ? "#9084de" : "#ffffff")};
    stroke: #dddddd;
  }
  > svg polyline {
    stroke: #ffffff;
  }
  cursor: pointer;
`;

export const StyledSeparator = styled.div`
  margin: 1rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.neutralLight["4"]};
`;

export const StyledTextarea = styled(TextInput.withComponent("textarea"))`
  height: 20rem;
  width: -webkit-fill-available;
  margin-bottom: 1.9rem;
  resize: none;
`;

const UploadOuter = styled.div``;
export const StyledOrSpan = styled.span`
  display: inline-block;
  font-weight: 600;
  margin-right: 1em;
`;
export const StyledUpload = styled(Upload)`
  font: normal 400 1.2rem/2rem ${props => props.theme.fontFamily};
  color: #ffffff;
  height: 3rem;
  padding-top: 0.5rem;
`;
type StyledUploadGroupProps = {
  onFileInputChange: (e: React.FormEvent<HTMLInputElement>) => void;
};
export const StyledUploadGroup = ({
  onFileInputChange
}: StyledUploadGroupProps) => {
  return (
    <UploadOuter>
      <StyledOrSpan>Or</StyledOrSpan>
      <StyledUpload
        accept="text/csv, text/plain"
        onChange={onFileInputChange}
        swatch="neutralDark"
      />
    </UploadOuter>
  );
};

type CustomProps = {
  noOfOptions: number;
} & React.HTMLProps<HTMLButtonElement>;
const CustomButton = ({ noOfOptions, ...rest }: CustomProps) => {
  return <button {...rest} />;
};
export const StyledGroupButton = styled(CustomButton)`
  font: normal 400 1.2rem/1.7rem ${props => props.theme.fontFamily};
  display: flex;
  align-items: center;
  width: fit-content;
  height: 2.4rem;
  border: none;
  background-color: ${props => (props.noOfOptions > 1 ? "#9084de" : "#e7e8eb")};
  color: ${props => (props.noOfOptions > 1 ? "#ffffff" : "#696e7b")};
  border-radius: 0.4rem;
  > span {
    border-color: ${props => (props.noOfOptions > 1 ? "#ffffff" : "#696e7b")};
  }
  cursor: pointer;
  &:focus {
    outline: none;
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => props.theme.primary["2"]};
    border-radius: 0.4rem;
  }
`;
export const StyledIcon = styled.span`
  border: solid;
  display: inline-block;
  padding: 0.2rem;
  margin: 0 0 0.2rem 0.4rem;
  border-width: 0 0.2rem 0.2rem 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
`;
type StyledInterlockButtonProps = {
  togglePopover: () => void;
  buttonText: string;
  interlockedCount: number;
};
export const StyledInterlockButton = ({
  togglePopover,
  buttonText,
  interlockedCount
}: StyledInterlockButtonProps) => {
  return (
    <StyledGroupButton onClick={togglePopover} noOfOptions={interlockedCount}>
      <span>{buttonText}</span>
      <StyledIcon />
    </StyledGroupButton>
  );
};

export type CustomGridProps = {
  showAllocationView: boolean;
} & React.HTMLProps<HTMLDivElement>;

const customGridView = ({ showAllocationView, ...rest }: CustomGridProps) => {
  return <div {...rest} />;
};

export const StyledGrid = styled(customGridView)`
  display: ${props => (props.showAllocationView ? "grid" : "flex")};
  grid-column-gap: 0.5rem;
  grid-template-columns: 30rem auto;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const AttributeEditorOuter = styled.div`
  margin: 0;
  width: 54rem;
  font-size: 1.2rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.neutralLight["7"]};
  display: inline-flex;
  flex-direction: column;
  padding: 1.6rem;
  color: #696e7b;
`;

export default AttributeEditorOuter;
