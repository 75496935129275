import { ApolloQueryResult } from "apollo-client";
import {
  LineItem,
  Attributes,
  LineItemSettingsInput,
  LineItemState,
  LineItemEvent,
  LineItemReport,
  LineItemActionKind,
  Country,
  SourcesForCountry,
  Schedule,
  SurveyLocale
} from "./types";
import { ActionsMap } from "./generalHelpers";
import moment from "moment";
import {
  FIELD_SCHEDULE_API_ACCEPTED_DATE_FORMAT,
  FIELD_SCHEDULE_API_TIME_FORMAT,
  FIELD_SCHEDULE_UI_TIME_FORMAT
} from "./constants/dateFormats";
import { getUTCDateInSpecFormat } from "./generalHelpers";

export type LineItemSettingsValidationError = {
  ID: string;
  Title: string;
  Code: string;
  Message: string;
};

export const isMutable = (state: LineItemState): boolean =>
  !(isLaunched(state) || isClosed(state) || isCompleted(state));

export const isDraft = (state: LineItemState): boolean =>
  state === LineItemState.PROVISIONED ||
  state === LineItemState.REJECTED ||
  state === LineItemState.REJECTED_PAUSED;

export const isCompleted = (state: LineItemState): boolean =>
  state === LineItemState.COMPLETED;

export const isCancelled = (state: LineItemState): boolean =>
  state === LineItemState.CANCELLED;

export const isRejected = (state: LineItemState): boolean =>
  state === LineItemState.REJECTED || state === LineItemState.REJECTED_PAUSED;

export const isClosed = (state: LineItemState): boolean =>
  state === LineItemState.CLOSED ||
  state === LineItemState.CANCELLED ||
  state === LineItemState.INVOICED;

export const isLaunched = (state: LineItemState): boolean =>
  isLive(state) ||
  state === LineItemState.CLOSED ||
  state === LineItemState.INVOICED ||
  state === LineItemState.CANCELLED;

export const isLaunchable = (state: LineItemState): boolean =>
  state === LineItemState.PROVISIONED ||
  state === LineItemState.REJECTED ||
  state === LineItemState.REJECTED_PAUSED ||
  state === LineItemState.PAUSED;

export const isLive = (state: LineItemState): boolean =>
  state === LineItemState.LAUNCHED ||
  state === LineItemState.QA_APPROVED ||
  state === LineItemState.AWAITING_APPROVAL ||
  isPaused(state);

export const isPaused = (state: LineItemState): boolean =>
  state === LineItemState.PAUSED ||
  state === LineItemState.AWAITING_APPROVAL_PAUSED ||
  state === LineItemState.AWAITING_CLIENT_APPROVAL;

export const isQueued = (state: LineItemState): boolean =>
  state === LineItemState.AWAITING_APPROVAL ||
  state === LineItemState.AWAITING_AUTO_APPROVAL ||
  state === LineItemState.AWAITING_APPROVAL_PAUSED ||
  state === LineItemState.QA_APPROVED;

// to check if lineitem (any field) is editable
const isEditable = (state: LineItemState): boolean =>
  state === LineItemState.PROVISIONED ||
  state === LineItemState.REJECTED ||
  state === LineItemState.REJECTED_PAUSED;

// to check if lineitem is editable when after live
const isPostLiveEditable = (state: LineItemState): boolean =>
  state === LineItemState.AWAITING_APPROVAL ||
  state === LineItemState.AWAITING_APPROVAL_PAUSED ||
  state === LineItemState.LAUNCHED ||
  state === LineItemState.PAUSED;

export enum LineItemField {
  title = "title",
  settings = "settings",
  surveyId = "surveyId",
  lengthOfInterview = "lengthOfInterview",
  countryISOCode = "countryISOCode",
  languageISOCode = "languageISOCode",
  sourceId = "sourceId",
  indicativeIncidence = "indicativeIncidence",
  daysInField = "daysInField",
  requiredCompletes = "requiredCompletes",
  deliveryType = "deliveryType",
  quotaCounts = "quotaCounts"
}
const isFieldPostLiveEditable = (field: LineItemField): boolean =>
  field === LineItemField.title ||
  field === LineItemField.daysInField ||
  field === LineItemField.deliveryType ||
  field === LineItemField.requiredCompletes ||
  field === LineItemField.quotaCounts;

export const isFieldEditable = (
  field: LineItemField,
  state: LineItemState
): boolean => {
  if (isEditable(state)) return true;
  if (isPostLiveEditable(state) && isFieldPostLiveEditable(field)) return true;
  return false;
};

export type LineItemSettings = {
  title: string;
  survey: SurveyLocale | null;
  lengthOfInterview: string;
  countryISOCode: string;
  languageISOCode: string;
  sourceId: string | null;
  indicativeIncidence: string;
  daysInField: string | null;
  requiredCompletes: string;
  deliveryType: string;
  fieldSchedule: Schedule | null;
};

export type FieldScheduleValidation = {
  startTime: boolean;
  endTime: boolean;
};

export type LineItemSettingsValidation = {
  title: boolean;
  surveyId: boolean;
  lengthOfInterview: boolean;
  countryISOCode: boolean;
  languageISOCode: boolean;
  sourceId: boolean;
  indicativeIncidence: boolean;
  daysInField: boolean;
  requiredCompletes: boolean;
  deliveryType: boolean;
  fieldSchedule: FieldScheduleValidation;
};

export enum LineItemStateEnum {
  LIVE = "LIVE",
  PAUSE = "PAUSE",
  CLOSED = "CLOSED",
  DRAFT = "DRAFT",
  REJECTED = "REJECTED",
  QUEUED = "QUEUED"
}

export const stateTypeOf = (state: LineItemState): LineItemStateEnum => {
  switch (state) {
    case LineItemState.AWAITING_APPROVAL:
    case LineItemState.AWAITING_AUTO_APPROVAL:
    case LineItemState.QA_APPROVED:
      return LineItemStateEnum.QUEUED;
    case LineItemState.LAUNCHED:
      return LineItemStateEnum.LIVE;
    case LineItemState.AWAITING_APPROVAL_PAUSED:
    case LineItemState.AWAITING_CLIENT_APPROVAL:
    case LineItemState.PAUSED:
      return LineItemStateEnum.PAUSE;
    case LineItemState.CLOSED:
    case LineItemState.CANCELLED:
    case LineItemState.INVOICED:
      return LineItemStateEnum.CLOSED;
    case LineItemState.REJECTED:
      return LineItemStateEnum.REJECTED;
    case LineItemState.PROVISIONED:
    default:
      return LineItemStateEnum.DRAFT;
  }
};

export const stateLabelOf = (state: LineItemState): string => {
  switch (state) {
    case LineItemState.AWAITING_APPROVAL:
    case LineItemState.AWAITING_AUTO_APPROVAL:
    case LineItemState.QA_APPROVED:
      return "Queued";
    case LineItemState.AWAITING_APPROVAL_PAUSED:
    case LineItemState.AWAITING_CLIENT_APPROVAL:
    case LineItemState.PAUSED:
      return "Paused";
    case LineItemState.LAUNCHED:
      return "Live";
    case LineItemState.CLOSED:
    case LineItemState.CANCELLED:
      return "Closed";
    case LineItemState.INVOICED:
      return "Closed & Invoiced";
    case LineItemState.COMPLETED:
      return "Completed";
    case LineItemState.REJECTED:
      return "Declined";
    case LineItemState.PROVISIONED:
    default:
      return "Draft";
  }
};

export const navStateLabelMap = {
  PROVISIONED: "Draft",
  AWAITING_APPROVAL: "Queued",
  AWAITING_AUTO_APPROVAL: "Queued",
  AWAITING_APPROVAL_PAUSED: "Paused",
  AWAITING_CLIENT_APPROVAL: "Paused",
  QA_APPROVED: "Queued",
  REJECTED: "Draft",
  REJECTED_PAUSED: "Draft",
  LAUNCHED: "Live",
  PAUSED: "Paused",
  CLOSED: "Closed",
  CANCELLED: "Closed",
  INVOICED: "Closed",
  COMPLETED: "Completed"
};

export const latestRepricing = (events: [LineItemEvent]) => {
  if (!events.length) return null;
  const result = events.filter(
    e => e.eventType === "LineItem:RepriceTriggered"
  );
  if (result.length === 0) return null;
  result.sort((a, b) => {
    const d1 = new Date(a.createdAt);
    const d2 = new Date(b.createdAt);
    return d1 > d2 ? -1 : d1 < d2 ? 1 : 0;
  });
  return result[0];
};

export const isBad = (
  report: LineItemReport | null,
  indicativeIncidence: number
) => false;
// !!report &&
// report.stats.attempts.intValue > 0 &&
// report.stats.conversion.percentageValueRounded < indicativeIncidence;

export const healthColor = (bad: boolean) => (bad ? "#F5513C" : "#52C1A3");

export const disabledSourceTooltipText = (
  country: Country | undefined,
  language: string | null,
  sourceId: string,
  sourcesForCountry: SourcesForCountry | null
) => {
  const text = "Supplier not available for";
  const visibilityCounter = sourcesForCountry
    ? Object.keys(sourcesForCountry).reduce(
        (visibility, key) =>
          visibility +
          sourcesForCountry[key].reduce(
            (v, o) =>
              v + Number(o.ID === sourceId && o.visibility === "VISIBLE"),
            0
          ),
        0
      )
    : 0;

  let languageName = "";
  if (country) {
    const l = country.supportedLanguages.find(l => l.ID === language);
    languageName = l ? l.name : "";
  }
  const countryName = country ? country.name : "";

  return visibilityCounter
    ? `${text} ${languageName}.`
    : `${text} ${countryName}.`;
};

export const findLineItemSettingsValidationMessage = (
  errorMessages: LineItemSettingsValidationError[],
  fieldName: string,
  replacedName = ""
) => {
  const errMsg = errorMessages.find(m => m.ID === fieldName);
  const message = errMsg ? errMsg.Message : "";
  return replacedName ? message.replace(fieldName, replacedName) : message;
};

export const sortLineItems = (lineItems: Array<LineItem>): Array<LineItem> => {
  const cancelled = new Array<LineItem>();
  const notCancelled = new Array<LineItem>();
  lineItems.forEach(l => {
    l.state === "CANCELLED" ? cancelled.push(l) : notCancelled.push(l);
  });

  return [...notCancelled, ...cancelled];
};

export const setNewAttributes = (
  projectAttributes: Array<Attributes>,
  setProjectAttributes: (a: Attributes[]) => void,
  fetchAttributes: () => Promise<
    ApolloQueryResult<{
      attributes: Attributes;
    }>
  >,
  countryISOCode: string,
  languageISOCode: string
) => {
  const sameCountryOrLang = projectAttributes.some(
    al =>
      al.countryISOCode === countryISOCode &&
      al.languageISOCode === languageISOCode
  );

  if (!sameCountryOrLang) {
    fetchAttributes().then(({ data }) => {
      if (data && data.attributes) {
        const newProjectAttributes = [...projectAttributes, data.attributes];
        setProjectAttributes(newProjectAttributes);
      }
    });
  }
};

export const lineItemSettingsToInput = (
  settings: LineItemSettings
): LineItemSettingsInput => {
  return {
    title: settings.title,
    survey: settings.survey,
    lengthOfInterview: parseInt(settings.lengthOfInterview, 10),
    countryISOCode: settings.countryISOCode,
    languageISOCode: settings.languageISOCode,
    source: settings.sourceId ? settings.sourceId.toString() : "",
    indicativeIncidence: parseInt(settings.indicativeIncidence, 10),
    requiredCompletes: parseInt(settings.requiredCompletes, 10),
    daysInField: settings.daysInField
      ? parseInt(settings.daysInField, 10)
      : null,
    fieldSchedule: settings.fieldSchedule,
    deliveryType: settings.deliveryType
  };
};

export const getSelectOptionStyle = (
  isDisabled: boolean,
  isFocused: boolean,
  isSelected: boolean
) => {
  return {
    backgroundColor: isSelected
      ? "var(--primary-color-2)"
      : isFocused
      ? "var(--primary-color-5)"
      : "var(--light-color-6)",
    color: isSelected
      ? "var(--light-color-6)"
      : isDisabled
      ? "var(--light-color-3)"
      : "var(--dark-color-2)",
    cursor: isDisabled ? "not-allowed" : "default",
    padding: 8
  };
};

export enum ScheduleType {
  NOW = "now",
  SCHEDULE = "schedule"
}

export const getScheduleType = (
  daysInField: number | null,
  fieldSchedule: Schedule | null
): ScheduleType => (fieldSchedule ? ScheduleType.SCHEDULE : ScheduleType.NOW);

export const launchSchedulingSelectorOptions = [
  { label: "Start Now", value: ScheduleType.NOW },
  { label: "Schedule", value: ScheduleType.SCHEDULE }
];

export const createFieldScheduleObject = (
  startDateValue: string,
  startTimeValue: string,
  endDateValue: string,
  endTimeValue: string
): Schedule => {
  const startTimeString = `${startDateValue} ${startTimeValue}`;
  const endTimeString = `${endDateValue} ${endTimeValue}`;

  const startTime = getUTCDateInSpecFormat(
    startTimeString,
    FIELD_SCHEDULE_API_ACCEPTED_DATE_FORMAT
  );
  const endTime = getUTCDateInSpecFormat(
    endTimeString,
    FIELD_SCHEDULE_API_ACCEPTED_DATE_FORMAT
  );

  return {
    startTime,
    endTime,
    __typename: "Schedule"
  };
};

export const shouldShowControls = (actionsMap: ActionsMap) =>
  actionsMap[LineItemActionKind.LAUNCH].visible ||
  actionsMap[LineItemActionKind.PAUSE].visible ||
  actionsMap[LineItemActionKind.CLOSE].visible ||
  actionsMap[LineItemActionKind.DUPLICATE].visible ||
  actionsMap[LineItemActionKind.EDIT].visible ||
  actionsMap[LineItemActionKind.SPLIT_SOURCE].visible;

export const getScheduleTimeValueFromDisplayedTime = (timeString: string) => {
  const apiTime = moment(timeString, FIELD_SCHEDULE_UI_TIME_FORMAT).format(
    FIELD_SCHEDULE_API_TIME_FORMAT
  );
  return apiTime.includes("Invalid") ? "00:00:00" : apiTime;
};
