import { useMutation } from "react-apollo-hooks";
import { switchCompany } from "../lib/queries/companySwitch";
import { Company } from "../types";

type Data = {
  switchCompany: boolean;
};

const useSwitchCompany = () => {
  const [mutate, mutationStatus] = useMutation<Data>(switchCompany);

  const switching = (c: Company) => {
    return mutate({
      variables: { companyID: c.id }
    });
  };
  return { switchCompany: switching, mutationStatus };
};

export default useSwitchCompany;
