import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import HEADER, { DASHBOARD, INVOICES } from "../../idHelpers";

const StyledNav = styled.nav`
  align-items: center;
  display: flex;
`;

const StyledButton = styled.button`
  background: none;
  text-decoration: none;
  border: 0 none;
  cursor: pointer;
  display: flex;
  position: relative;
  margin: auto;
  padding: 1.6rem 1.6rem 1.3rem;
  font: normal 400 1.4rem/2.2rem ${props => props.theme.fontFamily};
  color: #85858b;
  &.active {
    color: #3ea8dd;
    font-weight: 600;

    span {
      left: 0.6rem;
      right: 0.6rem;
      position: absolute;
      display: block;
      bottom: 0;
      border: 0.2rem solid;
      border-radius: 0.4rem;
    }
  }
`;

const StyledNavLink = StyledButton.withComponent(NavLink);

const Nav = () => {
  return (
    <StyledNav aria-label="Navigation">
      <StyledNavLink id={HEADER + "-" + DASHBOARD} to="/" exact>
        Dashboard <span />
      </StyledNavLink>
      <StyledNavLink id={HEADER + "-" + INVOICES} to="/invoices" exact>
        Invoices <span />
      </StyledNavLink>
    </StyledNav>
  );
};

export default Nav;
