import { useQuery } from "react-apollo-hooks";
import metaQuery from "../lib/queries/metaData";
import { StudyMetadata, Country } from "../types";

export type Data = {
  studyMetadata: StudyMetadata;
  countries: Array<Country>;
};

const useStudyMetaData = () => useQuery<Data>(metaQuery);

export default useStudyMetaData;
