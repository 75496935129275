export default {
  CREATE_PROJECT: "CREATE_PROJECT",
  LAUNCH_PROJECT: "LAUNCH_PROJECT",
  CREATE_REPORT: "CREATE_REPORT",
  CREATE_FILTER: "CREATE_FILTER",
  CREATE_CROSSTAB: "CREATE_CROSSTAB",
  EXPORT_DATA: "EXPORT_DATA",
  DECLINE_TOS: "DECLINE_TOS",
  INVOICE_REPORT: "INVOICE_REPORT",
  SPLIT_SOURCE: "SPLIT_SOURCE",
  CONFIRM_CLOSE_LINEITEM: "CONFIRM_CLOSE_LINEITEM",
  DUPLICATE_LINEITEM_WITH_DEPRECATED_ATTRIBUTES:
    "DUPLICATE_LINEITEM_WITH_DEPRECATED_ATTRIBUTES",
  CONFIRM_CLOSE_PROJECT: "CONFIRM_CLOSE_PROJECT",
  UPDATE_FOUND: "UPDATE_FOUND",
  CONFIRM_DELETE_SURVEY: "CONFIRM_DELETE_SURVEY",
  CONFIRM_NAVIGATION: "CONFIRM_NAVIGATION",
  ATTRIBUTE_EDITOR: "ATTRIBUTE_EDITOR",
  PROJECT_TOPIC_SELECTOR: "PROJECT_TOPIC_SELECTOR",
  INVALID_INPUT: "INVALID_INPUT",
  CONNECT_CMIX_SURVEY: "CONNECT_CMIX_SURVEY",
  CONFIRM_DELETE_TARGETING_TEMPLATE: "CONFIRM_DELETE_TARGETING_TEMPLATE",
  SAVE_TARGETING_TEMPLATE: "SAVE_TARGETING_TEMPLATE",
  PROJECT_RESTRICTIONS: "PROJECT_RESTRICTIONS",
  CONFIRM_LOAD_TARGETING_TEMPLATE: "CONFIRM_LOAD_TARGETING_TEMPLATE",
  DUPLICATE_PROJECT_WITH_INTERNAL_SURVEYS:
    "DUPLICATE_PROJECT_WITH_INTERNAL_SURVEYS"
};
