import React from "react";
import styled from "styled-components";
import { Button, icons } from "samplify-design";

const { Download } = icons;

const DownloadButton = styled(Button)`
  background-color: ${({ theme }) => theme.neutralLight["7"]};
  color: ${({ theme }) => theme.neutralDark["1"]};
  > svg {
    height: 1.4rem;
    width: auto;
    margin-right: 0.5rem;
  }
  &:hover {
    background-color: ${({ theme }) => theme.neutralLight["6"]};
  }
  &:active {
    background-color: ${({ theme }) => theme.neutralLight["5"]};
  }
`;

const DownloadPdf = ({ downloadLink }) => {
  return (
    <a href={downloadLink} target="_blank" rel="noopener noreferrer">
      <DownloadButton>
        <Download />
        Download
      </DownloadButton>
    </a>
  );
};

export default DownloadPdf;
