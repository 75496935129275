import { useState, useCallback, ChangeEvent } from "react";
import { SampleSource, LineItem } from "../../types";

type Props = {
  sampleSources: Array<SampleSource>;
  lineItem: LineItem;
};

export type SourceState = {
  source: SampleSource;
  checked: boolean;
  requiredCompletes: string;
};

export type IdToStateMap = {
  [id: string]: SourceState;
};

const coerceVal = (n: number): string => {
  if (Number.isNaN(n)) return "";
  return Math.abs(n).toString();
};

const parseVal = (val: string): number => {
  const n = parseInt(val, 10);
  if (Number.isNaN(n)) return 0;
  return n;
};

const valueFromEvent = (e: ChangeEvent<HTMLInputElement>) =>
  coerceVal(parseInt(e.currentTarget.value, 10));

const getInitialSourceState = (
  lineItem: LineItem,
  source: SampleSource
): SourceState => {
  const n = source.ID === lineItem.source ? lineItem.requiredCompletes : 0;
  const checked = source.ID === lineItem.source;
  const requiredCompletes = n.toString();
  return { source, checked, requiredCompletes };
};

const getInitialState = ({ sampleSources, lineItem }: Props): IdToStateMap => {
  const getInitial = (s: SampleSource) => getInitialSourceState(lineItem, s);
  const states = sampleSources.map(getInitial);
  const idToStateMap = {} as IdToStateMap;
  states.forEach(s => {
    idToStateMap[s.source.ID] = s;
  });
  return idToStateMap;
};

const sourceIsValid = (s: SourceState) => parseVal(s.requiredCompletes) > 0;

const sourcesAreValid = (sources: Array<SourceState>) => {
  const activeSources = sources.filter(src => src.checked);
  const countValid = activeSources.length > 1;
  const completesValid = activeSources.every(sourceIsValid);
  return countValid && completesValid;
};

const totalRequiredCompletes = (sources: Array<SourceState>) =>
  sources
    .filter(s => s.checked)
    .map(src => parseVal(src.requiredCompletes))
    .reduce((sum, req) => sum + req, 0);

const useSplitSourceState = ({ sampleSources, lineItem }: Props) => {
  const initialState = getInitialState({ sampleSources, lineItem });
  const [state, setState] = useState<IdToStateMap>(initialState);
  const sourceStates = Object.values(state);
  const makeTextChangeHandler = useCallback(
    (sourceId: string) => (e: ChangeEvent<HTMLInputElement>) => {
      const requiredCompletes = valueFromEvent(e);
      return setState(prevState => ({
        ...prevState,
        [sourceId]: { ...prevState[sourceId], requiredCompletes }
      }));
    },
    []
  );
  const makeCheckedChangeHandler = useCallback(
    (sourceId: string) => () =>
      setState(prevState => {
        const prevSource = prevState[sourceId];
        const checked = !prevSource.checked;
        const requiredCompletes = prevSource.checked
          ? "0"
          : prevSource.requiredCompletes;
        return {
          ...prevState,
          [sourceId]: { ...prevSource, checked, requiredCompletes }
        };
      }),
    []
  );
  // Formatted inputs for use with the SplitSource mutation.
  const inputSources = Object.keys(state)
    .map(key => state[key])
    .filter(
      srcState => srcState.source.visibility === "VISIBLE" && srcState.checked
    )
    .map(srcState => ({
      id: srcState.source.ID.toString(),
      requiredCompletes: parseVal(srcState.requiredCompletes)
    }))
    .filter(input => input.requiredCompletes > 0);
  return {
    state,
    makeTextChangeHandler,
    makeCheckedChangeHandler,
    inputSources,
    totalRequired: totalRequiredCompletes(sourceStates),
    sourcesValid: sourcesAreValid(sourceStates)
  };
};

export default useSplitSourceState;
