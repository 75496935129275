import { AllocationValue, getPerc } from "../../quotaHelpers";

type Props = {
  allocationMap: Map<string | number, AllocationValue>;
  requiredCompletes: number;
};

const useAllocationReview = ({ allocationMap, requiredCompletes }: Props) => {
  const allocationMapArr = Array.from(allocationMap.values());

  const counts = allocationMapArr.map(v =>
    typeof v.count === "number" ? v.count : 0
  );
  const totalCount = counts.reduce((acc, val) => {
    return acc + val;
  }, 0);

  const totalPerc = getPerc(totalCount, requiredCompletes);
  const remainingCount = requiredCompletes - totalCount;
  const imperative = remainingCount > 0 ? "Allocate" : "Remove";

  return {
    totalPerc,
    totalCount,
    remainingCount,
    imperative
  };
};

export default useAllocationReview;
