import { LineItemSettingsValidationError } from "./lineItemHelpers";
import {
  ActionType,
  ActionMetadata,
  LineItemActionKind,
  UserActionKind,
  SurveyActionKind,
  ProjectActionKind,
  ResourceType,
  Company
} from "./types";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { GraphQLError } from "graphql";
import { Error, ErrorCode } from "./types";

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const commaSeparatedString = (
  arr: Array<string>,
  quotesRequired: boolean
): string => {
  const quoteOrNoQuote = quotesRequired ? '"' : "";

  return arr.reduce((acc, opt, idx) => {
    const val = quoteOrNoQuote + opt + quoteOrNoQuote;
    switch (idx) {
      case 0:
        return `${val}`;
      case arr.length - 1:
        return `${acc} and ${val}`;
      default:
        return `${acc}, ${val}`;
    }
  }, "");
};

export const getTimeBasedExclusionDate = (s: string): Date => {
  return new Date(
    new Date(s).getTime() + Math.abs(new Date().getTimezoneOffset() * 60000)
  );
};

export const getDateWithTimezoneOffset = (s: string): Date =>
  new Date(new Date(s).getTime() - new Date().getTimezoneOffset() * 60000);

export const getDateStringInSpecFormat = (
  date: string,
  dateFormat: string,
  timezoneOffset = false
): string =>
  timezoneOffset
    ? moment(new Date(getDateWithTimezoneOffset(date))).format(dateFormat)
    : moment(new Date(date)).format(dateFormat);

export const getUTCDateInSpecFormat = (date: string, format: string): string =>
  moment(new Date(date))
    .utc()
    .format(format);

export const getHourOrMinuteString = (val: number): string =>
  val < 10 ? `0${val}` : `${val}`;

export const parseGraphQLError = (
  errorMessage: string
): Array<LineItemSettingsValidationError> => {
  if (!errorMessage.includes("[")) {
    return [];
  }
  try {
    const err = JSON.parse(errorMessage.substring(errorMessage.indexOf("[")));
    return err;
  } catch (e) {
    return [];
  }
};

type CalcShowAllPosAndWidthProps = {
  listElementId: string;
  showAllElementId: string;
  showAll: boolean;
  displayShowAll: boolean;
  listItemDistance: number;
  defaultDisplayedRows: number;
  setDisplay: (display: boolean) => void;
  setShowAllPos: (pos: number) => void;
  setShowAllWidth: (width: number) => void;
  setUndisplayedItems?: (undisplayed: number) => void;
};

export const calcShowAllPosAndWidth = ({
  listElementId,
  showAllElementId,
  showAll,
  displayShowAll,
  listItemDistance,
  defaultDisplayedRows,
  setDisplay,
  setShowAllPos,
  setShowAllWidth,
  setUndisplayedItems
}: CalcShowAllPosAndWidthProps) => {
  const listElem = document.getElementById(listElementId);
  if (listElem) {
    const showAllElem = document.getElementById(showAllElementId);
    const list = listElem.children;
    const rowWidth = listElem.offsetWidth;
    const showAllElemWidth = showAllElem ? showAllElem.offsetWidth : 0;
    const listLength = displayShowAll ? list.length - 1 : list.length;
    let listRowWidth = 0;
    let displayedItems = 0;
    let displayedRows = 1;
    for (let i = 0; i < listLength; i++) {
      //@ts-ignore
      const itemFullWidth = list[i].offsetWidth + listItemDistance;
      listRowWidth += itemFullWidth;
      if (listRowWidth > rowWidth) {
        const firstElemInNextRow = list[i];
        const actualRowWidth =
          //@ts-ignore;
          listRowWidth - firstElemInNextRow.offsetWidth - listItemDistance;
        setDisplay(true);
        if (!showAll && displayedRows === defaultDisplayedRows) {
          displayedItems = i;
          if (actualRowWidth + showAllElemWidth > rowWidth) {
            const lastDisplayed = list[i - 1];
            //@ts-ignore
            const lastDisplayedWidth = lastDisplayed.offsetWidth;
            displayedItems -= 1;
            setShowAllPos(
              actualRowWidth - lastDisplayedWidth - listItemDistance
            );
            setShowAllWidth(lastDisplayedWidth);
          } else {
            setShowAllPos(actualRowWidth);
          }
          if (setUndisplayedItems) {
            setUndisplayedItems(listLength - displayedItems);
          }
          break;
        }
        displayedRows += 1;
        listRowWidth = 0;
        i -= 1;
      }
      if (
        listRowWidth + showAllElemWidth < rowWidth &&
        defaultDisplayedRows >= displayedRows
      ) {
        setDisplay(false);
      }
    }
  }
};

export type ActionsMap = {
  [key: string]: ActionMetadata;
};

const defaultMetadata: ActionMetadata = {
  label: "",
  visible: false,
  enabled: false,
  description: ""
};

export const getActionsMap = (res: ResourceType, actions: ActionType[]) => {
  let actionMap: ActionsMap = {},
    actionKeys: string[] = [];
  switch (res) {
    case ResourceType.USER:
      actionKeys = Object.keys(UserActionKind);
      break;
    case ResourceType.SURVEY:
      actionKeys = Object.keys(SurveyActionKind);
      break;
    case ResourceType.PROJECT:
      actionKeys = Object.keys(ProjectActionKind);
      break;
    case ResourceType.LINEITEM:
      actionKeys = Object.keys(LineItemActionKind);
      break;
    default:
      actionKeys = [];
  }

  actionKeys.forEach(key => (actionMap[key] = defaultMetadata));
  actions.forEach(a => actionMap[a.kind] && (actionMap[a.kind] = a.metadata));
  return actionMap;
};

const getErrorCode = (code: string): ErrorCode => {
  switch (code) {
    case "INVALID_URL":
      return ErrorCode.INVALID_URL;

    case "INVALID_IDENTIFIERS":
      return ErrorCode.INVALID_IDENTIFIERS;

    case "INVALID_ATTRIBUTE_OPTIONS":
      return ErrorCode.INVALID_ATTRIBUTE_OPTIONS;

    case "CUSTOM_MESSAGE":
      return ErrorCode.CUSTOM_MESSAGE;

    default:
      return ErrorCode.UNKNOWN;
  }
};

export const graphQlErrorTitle = "System Error";

const toErrorType = (e: GraphQLError): Array<Error> => {
  const extensions = e.extensions;
  if (
    extensions &&
    extensions.code !== undefined &&
    extensions.message !== undefined
  )
    return [
      {
        id: "",
        title: extensions.title ? extensions.title : graphQlErrorTitle,
        code: getErrorCode(extensions.code),
        message: extensions.message
      }
    ];

  const message = e.message;
  const validationErr = parseGraphQLError(message);
  const errors = new Array<Error>();
  validationErr.forEach(e => {
    errors.push({
      id: e.ID,
      title: e.Title,
      code: getErrorCode(e.Code),
      message: e.Message
    });
  });
  if (errors.length) return errors;

  console.log("object is of not custom Error type");
  return [];
};

export const filterErrors = (errors: Array<GraphQLError>): Array<Error> => {
  const customErrors = new Array<Error>();
  try {
    errors.forEach(e => {
      const resultType = toErrorType(e);
      resultType.forEach(r => customErrors.push(r));
    });
  } catch (e) {
    console.error(e);
    return new Array<Error>();
  }
  return customErrors;
};

export const floorCurrentTime = () => {
  const currentTime = new Date();
  return new Date(
    new Date().setMinutes(
      currentTime.getMinutes() - (currentTime.getMinutes() % 30)
    )
  );
};

export const getTimezoneAbbreviation = () =>
  momentTimezone.tz(momentTimezone.tz.guess()).format("z");

const specialChars: string[] = [
  "\\\\",
  "\\[",
  "\\(",
  "\\)",
  "\\|",
  "\\^",
  "\\$",
  "\\.",
  "\\?",
  "\\*",
  "\\+",
  "\\{",
  "\\}"
];
export const getSafeTextToMatch = (text: string): string => {
  let safeTextToMatch = text;
  specialChars.forEach(sc => {
    const exp = new RegExp(sc, "g");
    safeTextToMatch = safeTextToMatch.replace(exp, sc);
  });

  return safeTextToMatch;
};

const companyComparison = (a: Company, b: Company): number => {
  if (a.isEffective) return -1;
  if (b.isEffective) return 1;

  if (a.name > b.name) return 1;
  if (a.name < b.name) return -1;

  return 0;
};
export const sortCompanies = (companies: Array<Company>): Array<Company> => {
  return companies.sort(companyComparison);
};

export const getEffectiveCompany = (
  companies: Array<Company>
): Company | undefined => {
  return companies.find(c => c.isEffective);
};

export const localEffCompIDKey = "effectiveCompanyID";
export const setLocalEffCompID = (value: number) => {
  localStorage.setItem(localEffCompIDKey, value.toString());
};
export const getLocalEffCompID = (): number | null => {
  const localEffCompID = localStorage.getItem(localEffCompIDKey);
  if (localEffCompID) {
    return Number(localEffCompID);
  }
  return null;
};

export const hasNoAccess = (err: Array<GraphQLError>): boolean => {
  const noAccess = err.find(e =>
    e.extensions ? e.extensions.title === "No Permission" : false
  );
  if (noAccess) return true;

  return false;
};

const specialCharsForPathSegment: string[] = ["\\:"];
export const getSafePathSegment = (pathSegment: string): string => {
  let safePathSegment = pathSegment;
  specialCharsForPathSegment.forEach(sc => {
    const exp = new RegExp(sc, "g");
    safePathSegment = safePathSegment.replace(exp, sc);
  });

  return safePathSegment;
};
