import React from "react";
import styled from "styled-components";
import accessKey from "../art/accessKey.svg";
import { SAMPLIFY } from "../constants/titles";

const Outer = styled.div`
  margin: 16rem auto;
  background: #ffffff;
  padding: 2.5rem 3rem;
  color: #696e7b;
`;

const Heading = styled.div`
  font: normal 600 1.6rem/2.2rem ${props => props.theme.fontFamily};
`;

const Message = styled.div`
  font: normal 400 1.4rem/1.9rem ${props => props.theme.fontFamily};
  margin-top: 1.5rem;
`;

const Icon = styled.img`
  height: 2rem;
  width: auto;
  margin: auto 1rem auto 0;
`;

const Unauthorized = () => (
  <Outer>
    <Heading>
      <Icon src={accessKey} />
      Get Access to {SAMPLIFY}
    </Heading>
    <Message>Please reach out to your account manager.</Message>
  </Outer>
);

export default Unauthorized;
