import React from "react";
import styled from "styled-components";
import { Button, Checkbox, ModalCancel } from "samplify-design";
import Arrow from "../../art/arrowWhite.svg";
import { MetaDataItem } from "../../types";
import useProjectTopicsEdit from "../../hooks/project/useProjectTopicsEdit";
import useUpdateProjectTopics from "../../hooks/project/useUpdateProjectTopics";

const ArrowIcon = styled.img`
  height: 9px;
  margin: 2px 0 0 8px;
  width: 12px;
`;

const Footer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`;

const ButtonSection = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 36px;
  > button {
    align-items: center;
    display: flex;
  }
`;

const StyledCancel = styled(ModalCancel)`
  font-size: 12px;
  margin-right: 22px;
`;

const StyledH1 = styled.h1`
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 20px;
`;

const StyledLabel = styled.label`
  align-items: center;
  display: flex;
  > span {
    margin-left: 6px;
  }
`;

const StyledP = styled.p`
  font-size: 14px;
  margin: 0;
`;

const Topics = styled.div`
  column-count: 2;
  font-size: 13px;
  margin-top: 24px;
  padding: 0;
`;

const Topic = styled.div`
  margin: 0.6rem 0;
  &:first-child {
    margin-top: 0;
  }
`;

type Props = {
  edit?: boolean;
  extProjectId: string;
  surveyTopics: Array<MetaDataItem>;
  selectedTopics: Array<string>;
  onCancel?: () => void;
  resetFeasibility?: () => void;
};

const ProjectTopicsSelector = ({
  edit = false,
  extProjectId,
  surveyTopics,
  selectedTopics,
  onCancel = () => {},
  resetFeasibility = () => {}
}: Props) => {
  const { state, toggleTopic } = useProjectTopicsEdit({
    surveyTopics,
    selectedTopics
  });
  const { allocatedTopics } = state;
  const input = {
    extProjectId,
    category: { surveyTopic: allocatedTopics },
    title: null,
    notificationEmails: null,
    devices: null,
    exclusions: null,
    jobNumber: null
  };
  const [updateProjectTopics] = useUpdateProjectTopics({ input });
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    toggleTopic(e.target.value);
  const onSave = () => {
    resetFeasibility();
    updateProjectTopics();
    if (edit) {
      onCancel();
    }
  };

  const buttonSection = (
    <ButtonSection>
      <Button disabled={!allocatedTopics.length} onClick={updateProjectTopics}>
        Continue to Targets
        <ArrowIcon src={Arrow} alt="" />
      </Button>
    </ButtonSection>
  );
  const footerSection = (
    <Footer>
      <StyledCancel onClick={onCancel}>Cancel</StyledCancel>
      <Button disabled={!allocatedTopics.length} onClick={onSave}>
        Save
      </Button>
    </Footer>
  );

  const allocatedTopicsSet = new Set(allocatedTopics);

  return (
    <div>
      {!edit ? <StyledH1>Choose Your Topics</StyledH1> : null}
      <StyledP>
        Get accurate feasibilities for your target respondents by selecting the
        topics that apply to this project.
      </StyledP>
      <Topics>
        {surveyTopics.map(t => (
          <Topic key={t.id}>
            <StyledLabel>
              <Checkbox
                value={t.id}
                checked={allocatedTopicsSet.has(t.id)}
                onChange={onChange}
              />
              <span>{t.name}</span>
            </StyledLabel>
          </Topic>
        ))}
      </Topics>
      {!edit ? buttonSection : footerSection}
    </div>
  );
};

export default ProjectTopicsSelector;
