import React, { useContext } from "react";
import styled from "styled-components";
import { ModalBody } from "samplify-design";
import ModalPropsContext from "../../containers/ModalPropsContext";
import { MetaDataItem } from "../../types";
import ProjectTopicsSelector from "../respondents/ProjectTopicsSelector";

const StyledModalBody = styled(ModalBody)`
  align-items: center;
  border: 1px solid #d5d7db;
  border-radius: 10px;
  box-sizing: border-box;
  max-height: fit-content;
  max-width: 656px;
  padding: 24px;
  text-align: left;
  flex-grow: unset;
`;

type Props = {
  clearModal: () => void;
};

type ProjectTopicSelectorModalProps = {
  extProjectId: string;
  surveyTopics: Array<MetaDataItem>;
  selectedTopics: Array<string>;
  resetFeasibility: () => void;
};

type ModalPropsContextArg = {
  modalProps: ProjectTopicSelectorModalProps;
};

const ProjectTopicSelectorModal = ({ clearModal }: Props) => {
  const context: ModalPropsContextArg = useContext(ModalPropsContext);
  const {
    extProjectId,
    surveyTopics,
    selectedTopics,
    resetFeasibility
  } = context.modalProps;

  return (
    <StyledModalBody>
      <ProjectTopicsSelector
        edit={true}
        extProjectId={extProjectId}
        selectedTopics={selectedTopics}
        surveyTopics={surveyTopics}
        onCancel={clearModal}
        resetFeasibility={resetFeasibility}
      />
    </StyledModalBody>
  );
};

export default ProjectTopicSelectorModal;
