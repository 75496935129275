import React, { useContext, useState } from "react";
import ModalPropsContext from "../../containers/ModalPropsContext";
import styled from "styled-components";
import { ModalBody, Button, Checkbox } from "samplify-design";
import { MetaDataItem } from "../../types";

const StyledAcceptSection = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 3.2rem;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 8px;
`;

const StyledModalBody = styled(ModalBody)`
  border-radius: 1rem;
  flex-grow: unset;
  padding: 4rem 4rem 2.5rem;
  text-align: left;
  > p {
    margin: 0;
    margin-bottom: 2.4rem;
  }
`;

const StyledLabel = styled.label`
  align-items: center;
  display: inline-flex;
  font-size: 12px;
`;

const StyledRestriction = styled.div`
  margin: 8px 0;
  > span:first-child {
    color: #f76e5c;
    margin-right: 11px;
  }
`;

type ProjectRestrictionsModalProps = {
  restrictions: Array<MetaDataItem>;
  openCreateProjectModal: () => void;
};

type ModalPropsContextArg = {
  modalProps: ProjectRestrictionsModalProps;
};

const ProjectRestrictionsModal = () => {
  const [checked, setChecked] = useState(false);
  const context: ModalPropsContextArg = useContext(ModalPropsContext);
  const { restrictions, openCreateProjectModal } = context.modalProps;
  const onAccept = () => {
    if (checked) {
      localStorage.setItem("showProjectRestrictionModal", "false");
    }
    openCreateProjectModal();
  };
  const onChange = () => setChecked(!checked);

  return (
    <StyledModalBody>
      <p>
        Before you begin, note that the following features are not allowed in
        your surveys:
      </p>
      {restrictions.map(r => (
        <StyledRestriction key={r.id}>
          <span>&#x2715;</span>
          <span>{r.name}</span>
        </StyledRestriction>
      ))}
      <StyledAcceptSection>
        <StyledLabel>
          <StyledCheckbox checked={checked} onChange={onChange} />
          <span>Don’t show this again</span>
        </StyledLabel>
        <Button onClick={onAccept}>Continue</Button>
      </StyledAcceptSection>
    </StyledModalBody>
  );
};

export default ProjectRestrictionsModal;
