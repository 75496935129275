import { useState, useEffect, useCallback } from "react";
import { getHealthy, logout, getSessionId } from "../networkHelpers";
import AppStatus from "../types/AppStatus";
import { BasicGraphQLError, Error } from "../types";

type Props = {
  gqlError: Array<Error | BasicGraphQLError>;
  children: (healthy: AppStatus) => JSX.Element;
};

const HealthyContainer = ({ children, gqlError }: Props) => {
  const [healthy, setHealthy] = useState<AppStatus>(AppStatus.Blank);

  const logoutIfRequired = () => {
    const sessionId = getSessionId();
    if (sessionId) {
      logout();
    }
  };

  const healthyCheck = useCallback(() => {
    getHealthy()
      .then((response: Response) => {
        if (response.status === 200) {
          setHealthy(AppStatus.OK);
        } else {
          setHealthy(AppStatus.NotOK);
          logoutIfRequired();
        }
      })
      .catch(() => {
        setHealthy(AppStatus.Blank);
        logoutIfRequired();
      });
  }, []);

  useEffect(() => {
    healthyCheck();
  }, [healthyCheck]);

  useEffect(() => {
    if (gqlError && gqlError.length) healthyCheck();
  }, [healthyCheck, gqlError]);

  return children(healthy);
};

export default HealthyContainer;
