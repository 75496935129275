import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { ModalBody, Button } from "samplify-design";
import ModalPropsContext from "../../containers/ModalPropsContext";

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2.5em;
`;

const Cancel = styled(Button)`
  background-color: #ffffff;
  color: ${({ theme }) => theme.neutralDark["4"]};
  text-decoration: underline;
  &:hover {
    background-color: #ffffff;
  }
  &:focus {
    box-shadow: none;
  }
`;

const StyledModal = styled(ModalBody)`
  border-radius: 1rem;
  max-height: 100px;
  text-align: left;
  flex-grow: unset;
`;

const ConfirmButton = ({
  nextLocation,
  history,
  clearModal,
  setBlockNavigateAway
}: any) => {
  return (
    <Button
      onClick={() => {
        history.push(nextLocation.pathname, []);
        setBlockNavigateAway(true);
        clearModal();
      }}
    >
      Yes
    </Button>
  );
};

const RoutingButton = withRouter(ConfirmButton);

type ModalProps = {
  nextLocation: any;
  setBlockNavigateAway: any;
};

type ModalPropsContextArg = {
  modalProps: ModalProps;
};

interface Props {
  clearModal: any;
}

const ConfirmClose = ({ clearModal }: Props) => {
  const context: ModalPropsContextArg = useContext(ModalPropsContext);
  const { nextLocation, setBlockNavigateAway } = context.modalProps;

  return (
    <StyledModal>
      <p>
        Are you sure you want to leave this page? You may lose any unsaved
        changes.
      </p>
      <Buttons>
        <Cancel
          onClick={() => {
            setBlockNavigateAway(true);
            clearModal();
          }}
        >
          Cancel
        </Cancel>
        <RoutingButton
          nextLocation={nextLocation}
          clearModal={clearModal}
          setBlockNavigateAway={setBlockNavigateAway}
        >
          Yes
        </RoutingButton>
      </Buttons>
    </StyledModal>
  );
};

export default ConfirmClose;
