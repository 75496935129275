import React from "react";
import Outer from "../components/AppOuter";
import Header from "../components/header/Header";
import Unauthorized from "../components/Unauthorized";
import {
  getLoginURL,
  appendLoginParams,
  login,
  getSessionId
} from "../networkHelpers";

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { renderChildren: false, unauthorized: false };
  }

  componentDidMount() {
    if (getSessionId()) {
      this.setState({ renderChildren: true });
      return;
    }
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    if (code) {
      login(code).catch(e => {
        if (e.message === "NOT AUTHORIZED") {
          this.setState({ unauthorized: true });
        }
      });
      return;
    }
    const redirect = appendLoginParams(getLoginURL());
    window.location.replace(redirect);
  }

  render() {
    const { children } = this.props;
    const { renderChildren, unauthorized } = this.state;

    if (unauthorized) {
      return (
        <Outer>
          <Header termsAccepted={false} doesUserHaveAccess={false} />
          <Unauthorized />
        </Outer>
      );
    }

    if (renderChildren) {
      return children;
    }

    return null;
  }
}

export default LoginContainer;
