import React, { useContext } from "react";
import styled from "styled-components";
import { ModalBody } from "samplify-design";
import ModalPropsContext from "../../containers/ModalPropsContext";
import ModalCloseButton from "../design/ModalCloseButton";
import CmixSurveyModalBody from "../survey/CmixSurveyModalBody";
import { Application } from "../../types";
import { CMIX } from "../../constants/titles";
import svgLink from "../../art/openLinkBlue.svg";
import { CMIX_DEFAULT_URL } from "../survey/Constants";
import LinkAsButton from "../LinkAsButton";

const StyledModal = styled(ModalBody)`
  border-radius: 0.4rem;
  text-align: left;
  background-color: var(--light-color-6);
  padding: 2.4rem 2rem 2.4rem 3.6rem;
  width: 60rem;
  max-height: 52rem;
  margin: 0 auto;
  flex-grow: unset;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTitle = styled.span`
  display: flex;
  font: normal 600 1.6rem/2.4rem ${props => props.theme.fontFamily};
  color: var(--dark-color-3);
`;

const GoToCmixLinkAsBtn = styled(LinkAsButton)`
  width: fit-content;
  color: var(--primary-color-2);
  background: var(--light-color-6);
  font: normal 600 1.4rem/2.2rem ${props => props.theme.fontFamily};
  margin: 0 1rem;
  padding: 0 1rem;
  align-items: center;
  &:hover {
    background: var(--light-color-6);
  }
  &:active {
    background: var(--light-color-6);
  }
`;
const StyledLinkIcon = styled.img`
  width: auto;
  height: 1.4rem;
  margin: 0 0.8rem 0 0;
`;

type ConnectCmixSurveyModalProps = {
  cmixApp?: Application;
  createSurvey: (surveyName: string, surveyId: string) => void;
  samplifyIconURL: string;
};

type ModalPropsContextArg = {
  modalProps: ConnectCmixSurveyModalProps;
};

type Props = {
  clearModal: () => void;
};

const ConnectCmixSurveyModal = ({ clearModal }: Props) => {
  const context: ModalPropsContextArg = useContext(ModalPropsContext);
  const { cmixApp, createSurvey, samplifyIconURL } = context.modalProps;

  const cmixIconURL = cmixApp ? cmixApp.icon : "";
  const cmixURL = cmixApp ? cmixApp.url : CMIX_DEFAULT_URL;

  return (
    <StyledModal>
      <StyledHeader>
        <StyledTitle>Connect a {CMIX} Survey</StyledTitle>
        <GoToCmixLinkAsBtn
          href={cmixURL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledLinkIcon src={svgLink} alt="" /> Go To {CMIX}
        </GoToCmixLinkAsBtn>
        <ModalCloseButton clearModal={clearModal} />
      </StyledHeader>

      <CmixSurveyModalBody
        closeModal={clearModal}
        cmixURL={cmixURL}
        cmixIconURL={cmixIconURL}
        samplifyIconURL={samplifyIconURL}
        createSurvey={createSurvey}
      />
    </StyledModal>
  );
};

export default ConnectCmixSurveyModal;
