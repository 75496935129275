import React from "react";
import { Route } from "react-router-dom";
import Modal from "../components/modal/Modal";
import { Modal as ModalType } from "./ModalPropsContext";

type Props = {
  modalData: ModalType;
  clearModalType: () => void;
};

const ModalContainer = ({ modalData, clearModalType }: Props) => {
  return (
    <Route>
      <Modal clearModalType={clearModalType} modalType={modalData.modalType} />
    </Route>
  );
};

export default ModalContainer;
