import { useState, useEffect } from "react";
import { getAppStatus } from "../networkHelpers";
import AppStatus from "../types/AppStatus";
import { BasicGraphQLError, Error } from "../types";

type Props = {
  gqlError: Array<Error | BasicGraphQLError> | null;
  children: (appStatus: AppStatus) => JSX.Element;
};

const AppStatusContainer = ({ children, gqlError }: Props) => {
  const [appStatus, setStatus] = useState<AppStatus>(AppStatus.Blank);

  const appStatusCheck = () =>
    getAppStatus()
      .then((response: Response) => {
        if (response.status === 200) {
          setStatus(AppStatus.OK);
        } else {
          setStatus(AppStatus.NotOK);
        }
      })
      .catch(() => setStatus(AppStatus.Blank));

  useEffect(() => {
    appStatusCheck();
  }, []);

  useEffect(() => {
    if (gqlError && gqlError.length) appStatusCheck();
  }, [gqlError]);

  return children(appStatus);
};

export default AppStatusContainer;
