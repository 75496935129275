import { Spinner } from "samplify-design";
import styled from "styled-components";

const StyledSpinner = styled(Spinner)`
  color: #fff;
  stroke: #fff;
  fill: #fff;
`;

export default StyledSpinner;
