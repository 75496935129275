import React, { useContext } from "react";
import ModalPropsContext from "../../containers/ModalPropsContext";
import ConfirmationModal from "./ConfirmationModal";

type Props = {
  clearModal: () => void;
};

const ConfirmCloseLineItem = ({ clearModal }: Props) => {
  const { modalProps } = useContext(ModalPropsContext);
  const closeLineItem = () => {
    modalProps.closeLineItem();
    clearModal();
  };

  return (
    <ConfirmationModal
      confirmation={<p>Are you sure you want to close {modalProps.title}?</p>}
      onCancel={clearModal}
      onConfirm={closeLineItem}
      confirmLabel={"Close"}
    />
  );
};

export default ConfirmCloseLineItem;
