import gql from "graphql-tag";

export const getGraphQLError = gql`
  {
    graphQLErrors @client
  }
`;

export const getNetworkError = gql`
  {
    networkError @client
  }
`;
