import {
  LineItemState,
  ProjectState,
  LineItem,
  Device as DeviceEnum,
  RespondentFilterDispositions,
  ExclusionType
} from "./types";
import { ProjectFilters, FormattedFilters } from "./types/ProjectFilters";

export const bubbleStateLabelMap = {
  PROVISIONED: "Draft",
  CLOSED: "Closed",
  INVOICED: "Invoiced",
  LAUNCHED: "Launched",
  COMPLETED: "Completed",
  AWAITING_APPROVAL: "Launched",
  CANCELLED: "Closed",
  DRAFT: "Draft"
};

export const isDraft = (state: ProjectState): boolean =>
  state === ProjectState.PROVISIONED ||
  state === ProjectState.REJECTED ||
  state === ProjectState.REJECTED_PAUSED;

export const isCloseable = (state: ProjectState): boolean =>
  state !== ProjectState.DRAFT && !isClosed(state);

export const isClosed = (state: ProjectState): boolean =>
  state === ProjectState.CLOSED ||
  state === ProjectState.CANCELLED ||
  state === ProjectState.INVOICED;

// no new surveys in these states
export const isLocked = (state: ProjectState): boolean =>
  state === ProjectState.CLOSED ||
  state === ProjectState.INVOICED ||
  state === ProjectState.CANCELLED;

export const isLaunched = (state: ProjectState): boolean =>
  isLive(state) ||
  state === ProjectState.CLOSED ||
  state === ProjectState.INVOICED ||
  state === ProjectState.CANCELLED;

export const isLive = (state: ProjectState): boolean =>
  state === ProjectState.LAUNCHED ||
  state === ProjectState.QA_APPROVED ||
  state === ProjectState.AWAITING_APPROVAL ||
  isPaused(state);

export const isPaused = (state: ProjectState): boolean =>
  state === ProjectState.PAUSED ||
  state === ProjectState.AWAITING_APPROVAL_PAUSED ||
  state === ProjectState.AWAITING_CLIENT_APPROVAL;

const isEditable = (
  projectState: ProjectState,
  lineitems: Array<LineItem>
): boolean => {
  if (
    projectState === ProjectState.PROVISIONED ||
    projectState === ProjectState.DRAFT
  ) {
    return !lineitems.some(
      li =>
        li.state === LineItemState.AWAITING_APPROVAL ||
        li.state === LineItemState.QA_APPROVED
    );
  }
  return false;
};

export const isTitleUpdatable = (state: ProjectState): boolean => {
  if (state === ProjectState.CANCELLED || state === ProjectState.INVOICED) {
    return false;
  }
  return true;
};

export const areProjectSettingsUpdatable = (
  projectState: ProjectState,
  lineitems: Array<LineItem>
): boolean => {
  if (projectState === ProjectState.LAUNCHED) {
    return true;
  }
  return isEditable(projectState, lineitems);
};

export const areProjectCategoriesUpdatable = (
  projectState: ProjectState,
  lineitems: Array<LineItem>
): boolean => isEditable(projectState, lineitems);

// Date format: "2006-01-02"
export const explicitDateString = (d: Date) => {
  const dateNumber = (num: number) => {
    if (num < 10) return `0${num}`;
    return num;
  };

  return `${d.getFullYear()}-${dateNumber(d.getMonth() + 1)}-${dateNumber(
    d.getDate()
  )}`;
};

export const multiSelectValuesStyle = (
  background: string,
  textColor: string,
  canChangeTopics = false
) => {
  const padding = canChangeTopics ? "2px" : "8px";
  const display = canChangeTopics ? "flex" : "none";
  return {
    indicatorsContainer: (styles: any) => ({
      ...styles,
      display: `${display}`
    }),
    multiValue: (styles: any) => ({
      ...styles,
      backgroundColor: `${background}`,
      borderRadius: "12px",
      color: `${textColor}`
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: `${textColor}`,
      padding: "4px",
      paddingLeft: "8px",
      paddingRight: `${padding}`
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      color: `${textColor}`,
      paddingLeft: 0,
      display: `${display}`,
      ":hover": {
        backgroundColor: `${background}`,
        borderRadius: "12px",
        color: `${textColor}`,
        cursor: "pointer"
      },
      "> svg": {
        height: "12px"
      }
    })
  };
};

export const formatFilters = (filters: ProjectFilters): FormattedFilters => {
  const startDate = filters.startDate
    ? explicitDateString(new Date(filters.startDate))
    : "";
  const endDate = filters.endDate
    ? explicitDateString(new Date(filters.endDate))
    : "";
  const searchQuery = filters.searchQuery || null;
  const statuses = filters.statuses || [];
  const scope = filters.scope || "";

  return {
    startDate,
    endDate,
    searchQuery,
    statuses,
    scope
  };
};

export enum ReasonCode {
  DEVICE = "DEVICE",
  CATEGORY = "CATEGORY"
}
export const foundDeviceOrCategoryError = (
  lineItems: Array<LineItem>,
  type: ReasonCode
) =>
  lineItems.some(lineItem =>
    lineItem.events.some(
      event =>
        event.eventType === "LineItem:StateChange:Rejected" &&
        event.details.reasons.some(reason => reason.code.includes(type))
    )
  );

export const route = (extProjectId: string, extLineItemId?: string) =>
  `/project/${extProjectId}/respondents#${extLineItemId ? extLineItemId : ""}`;

export const getDeviceType = (s: string): DeviceEnum => {
  switch (s) {
    case DeviceEnum.mobile:
      return DeviceEnum.mobile;

    case DeviceEnum.tablet:
      return DeviceEnum.tablet;

    default:
      return DeviceEnum.desktop;
  }
};

export const respondentStatuses: RespondentFilterDispositions = [
  "COMPLETED",
  "SCREENOUT",
  "INCOMPLETE",
  "OVERQUOTA"
];

export const respondentStatusLabelMap: { [key: string]: string } = {
  COMPLETED: "Completed",
  SCREENOUT: "Screen Out",
  INCOMPLETE: "Incomplete",
  OVERQUOTA: "Over Quota"
};

export const respondentEntryDateTypeLabelMap: { [key: string]: string } = {
  ALL_DATES: "All Dates",
  LAST_DAYS: "Last <x> day(s)",
  LAST_MONTHS: "Last <x> month(s)",
  CUSTOM: "Custom"
};

export const timeBasedProjectExclusionsTypes: Array<ExclusionType> = [
  "THIS_MONTH",
  "LAST_MONTH",
  "LAST_THREE_MONTHS"
];

export const timeBasedProjectExclusionsTypeLabelMap: {
  [key: string]: string;
} = {
  THIS_MONTH: "This month",
  LAST_MONTH: "Last month",
  LAST_THREE_MONTHS: "Last 3 months"
};
