import React from "react";
import styled from "styled-components";
import Select from "react-select";
import caret from "../../art/Caret.svg";

type CustomSpanProps = {
  menuIsOpen: boolean;
} & React.HTMLProps<HTMLSpanElement>;

const CustomSpan = ({ menuIsOpen, ...rest }: CustomSpanProps) => (
  <span {...rest} />
);

const StyledCaret = styled(CustomSpan)`
  margin-right: 0.5em;
  margin-bottom: 0.5rem;
`;

const StyledSelect = styled(Select)``;

const StyledCaretIcon = styled.img`
  width: 0.7em;
  height: auto;
`;

const DropdownIndicator = (menuIsOpen: boolean) => (
  <StyledCaret menuIsOpen={menuIsOpen}>
    <StyledCaretIcon src={caret} />
  </StyledCaret>
);

export default (props: any) => {
  const { components = {}, styles = {}, invalid, ...rest } = props;
  const customStyles = {
    indicatorSeparator: () => ({ display: "none" }),
    control: (base: any, state: any) =>
      invalid
        ? {
            ...base,
            boxShadow: "0 0 0 2px #f76e5c",
            borderColor: "transparent"
          }
        : state.isFocused
        ? {
            ...base,
            outline: "none",
            boxShadow: "0 0 0 2px #3ea8dd",
            borderColor: "transparent",
            "&:hover": {
              borderColor: "transparent",
              outline: "none"
            }
          }
        : {
            ...base,
            borderRadius: "0.5em",
            border: "1px solid #d5d7db",
            "&:hover": {
              outline: "none"
            }
          }
  };

  return (
    <StyledSelect
      styles={{ ...styles, ...customStyles }}
      components={{ DropdownIndicator, ...components }}
      {...rest}
    />
  );
};
