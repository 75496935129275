import React from "react";
import styled from "styled-components";
import { Button, Spinner } from "samplify-design";

const Accept = styled(Button)`
  background-color: ${props =>
    props.agree
      ? ({ theme }) => theme.primary["2"]
      : ({ theme }) => theme.neutralLight["2"]};
  &:hover {
    background-color: ${props =>
      props.agree
        ? ({ theme }) => theme.primary["1"]
        : ({ theme }) => theme.neutralLight["2"]};
  }
  &:active {
    background-color: ${props =>
      props.agree
        ? ({ theme }) => theme.primary["3"]
        : ({ theme }) => theme.neutralLight["2"]};
  }
`;

const StyledSpnner = styled(Spinner)`
  stroke: white;
`;

const TosAccept = ({ agree, showRedText, loading, acceptTos }) => {
  const buttonContent = loading ? <StyledSpnner /> : "I Accept";
  return (
    <div>
      <Accept
        agree={agree}
        disabled={loading}
        onClick={() => {
          if (agree) {
            acceptTos();
          } else {
            showRedText();
          }
        }}
      >
        {buttonContent}
      </Accept>
    </div>
  );
};

export default TosAccept;
