import React from "react";
import { ModalHeader, ModalBody, ModalFooter, Button } from "samplify-design";
import styled from "styled-components";

export const StyledModal = styled(ModalBody)`
  flex-grow: unset;
`;

const UpdateFound = () => (
  <div>
    <ModalHeader>Update Found</ModalHeader>
    <StyledModal>
      <p>
        Samplify has been updated. To ensure you're using the latest version,
        please refresh the page.
      </p>
    </StyledModal>
    <ModalFooter>
      <Button role="link" onClick={() => window.location.reload()}>
        Ok
      </Button>
    </ModalFooter>
  </div>
);

export default UpdateFound;
