import gql from "graphql-tag";

const metaQuery = gql`
  query Metadata {
    studyMetadata {
      category {
        studyTypes {
          id
          name
          description
          allowed
        }
        studyRequirements {
          id
          name
          description
          allowed
        }
        surveyTopics {
          id
          name
          description
          allowed
        }
      }
      deliveryTypes {
        id
        name
        description
        allowed
      }
    }
    countries {
      ID
      isoCode
      name
      supportedLanguages {
        ID
        isoCode
        name
      }
    }
  }
`;

export default metaQuery;
