import styled from "styled-components";

const IconButton = styled.button`
  border: 0 none;
  background: none;
  display: inline-block;
  padding: 0;
  cursor: pointer;
  > svg {
    fill: ${({ theme }) => theme.neutralLight["1"]};
  }
  &:focus {
    outline: none;
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => props.theme.primary["2"]};
    border-radius: 0.4rem;
  }
`;

export default IconButton;
