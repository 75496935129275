import gql from "graphql-tag";

export const statFragment = gql`
  fragment StatFragment on Stat {
    intValue
    floatValue
    percentageValue
    percentageValueRounded
    status
    statusMessage
  }
`;

const attributeFragment = gql`
  fragment AttributeFragment on Attribute {
    id
    countryISOCode
    languageISOCode
    name
    text
    type
    isAllowedInFilters
    isAllowedInQuotas
    options
    format
    state
    tier
  }
`;

const quoteFragment = gql`
  fragment QuoteFragment on LineItem {
    quote {
      costPerUnit
      estimatedCost
      detailedQuote {
        costPerUnit
        estimatedCost
        title
        type
        units
      }
    }
  }
`;

const actionsFragment = gql`
  fragment ActionsFragment on LineItem {
    actions {
      kind
      metadata {
        label
        enabled
        visible
        description
      }
    }
  }
`;

const projectActionsFragment = gql`
  fragment ProjectActionsFragment on Project {
    actions {
      kind
      metadata {
        label
        enabled
        visible
        description
      }
    }
  }
`;

const costFragment = gql`
  fragment CostFragment on Cost {
    costPerUnit
    estimatedCost
    incurredCost
    detailedCost {
      costPerUnit
      estimatedCost
      incurredCost
      title
      type
      deliveredUnits
      requestedUnits
    }
    currency
  }
`;

const reportFragment = gql`
  fragment ReportFragment on Report {
    attempts {
      ...StatFragment
    }
    completes {
      ...StatFragment
    }
    screenouts {
      ...StatFragment
    }
    overquotas {
      ...StatFragment
    }
    incompletes {
      ...StatFragment
    }
    completesRefused {
      ...StatFragment
    }
    conversion {
      ...StatFragment
    }
    incidence {
      ...StatFragment
    }
    progress {
      ...StatFragment
    }
    remainingCompletes {
      ...StatFragment
    }
    actualMedianLOI {
      ...StatFragment
    }
  }
  ${statFragment}
`;

const quotaCellFragment = gql`
  fragment QuotaCellFragment on QuotaCell {
    extProjectId
    extLineItemId
    quotaCellID
    quotaNodes {
      attributeId
      options
    }
    count
    perc
    status
    quotaCellReport {
      ...ReportFragment
    }
  }
  ${reportFragment}
`;

const quotaPlanFragment = gql`
  fragment QuotaPlanFragment on LineItem {
    quotaPlan {
      filters {
        attributeId
        options
        operator
      }
      quotaGroups {
        name
        quotaCells {
          ...QuotaCellFragment
        }
        quotaGroupReport {
          ...ReportFragment
        }
      }
    }
  }
  ${quotaCellFragment}
  ${reportFragment}
`;

const eventsFragment = gql`
  fragment EventsFragment on LineItem {
    events {
      eventId
      eventType
      extProjectId
      extLineItemId
      createdAt
      details {
        comments
        reasons {
          code
          description
        }
      }
      resource {
        currency
        reason
        indicativeIncidence {
          newValue
          previousValue
        }
        costPerInterview {
          newValue
          previousValue
        }
        estimatedCost {
          newValue
          previousValue
        }
        lengthOfInterview {
          newValue
          previousValue
        }
        incurredCost {
          newValue
          previousValue
        }
        status {
          newValue
          previousValue
        }
      }
    }
  }
`;

const lineItemReportFragment = gql`
  fragment LineItemReportFragment on LineItem {
    report {
      extLineItemId
      sources {
        ID
        name
      }
      title
      stats {
        ...ReportFragment
      }
      cost {
        ...CostFragment
      }
      state
      actualIncidence {
        ...StatFragment
      }
    }
  }
  ${statFragment}
  ${costFragment}
  ${reportFragment}
`;

export const fragments = {
  stat: statFragment,
  quote: quoteFragment,
  cost: costFragment,
  quotaPlan: quotaPlanFragment,
  events: eventsFragment,
  actions: actionsFragment,
  projectActions: projectActionsFragment,
  report: lineItemReportFragment,
  lineItem: gql`
    fragment LineItemFragment on LineItem {
      title
      extLineItemId
      extProjectId
      lengthOfInterview
      countryISOCode
      languageISOCode
      indicativeIncidence
      daysInField
      fieldSchedule {
        startTime
        endTime
      }
      requiredCompletes
      state
      cpi
      survey {
        surveyId
        countryISOCode
        languageISOCode
      }
      source
      deliveryType
      popularAttributes
      attributes
      ...ActionsFragment
      ...QuotaPlanFragment
      ...EventsFragment
      ...LineItemReportFragment
      ...QuoteFragment
    }
    ${actionsFragment}
    ${quotaPlanFragment}
    ${eventsFragment}
    ${quoteFragment}
    ${lineItemReportFragment}
  `
};

export const splitSource = gql`
  mutation splitSource($input: SplitBySampleSource!) {
    splitLineItem(input: $input) {
      extProjectId
      lineItems {
        ...LineItemFragment
      }
    }
  }
  ${fragments.lineItem}
`;

export const state = gql`
  query State($extProjectId: ID!, $extLineItemId: ID!) {
    project(extProjectId: $extProjectId) {
      extProjectId
      lineItem(extLineItemId: $extLineItemId) {
        state
      }
    }
  }
`;

export const feasibility = gql`
  query Feasibility($extProjectId: ID!, $extLineItemId: ID!) {
    project(extProjectId: $extProjectId) {
      extProjectId
      lineItem(extLineItemId: $extLineItemId) {
        extLineItemId
        extProjectId
        feasibility {
          extLineItemId
          status
          costPerInterview
          currency
          feasible
          errors {
            message
          }
          totalCount
          level
          valueCounts {
            quotaCells {
              quotaNodes {
                attributeId
                options
              }
              feasibilityCount
              level
            }
          }
        }
        ...ActionsFragment
        ...QuoteFragment
      }
    }
  }
  ${actionsFragment}
  ${quoteFragment}
`;

export const addLineItem = gql`
  mutation AddLineItem($input: AddLineItemInput!) {
    addLineItem(input: $input) {
      ...LineItemFragment
    }
  }
  ${fragments.lineItem}
`;

export const isDuplicatable = gql`
  query IsDuplicatable($extProjectId: ID!, $extLineItemId: ID!) {
    isDuplicatable(extProjectId: $extProjectId, extLineItemId: $extLineItemId) {
      isDuplicatable
      deprecatedAttributes {
        ...AttributeFragment
      }
    }
  }
  ${attributeFragment}
`;

export const duplicateLineItem = gql`
  mutation DuplicateLineItem($input: DuplicateLineItemInput!) {
    duplicateLineItem(input: $input) {
      ...LineItemFragment
    }
  }
  ${fragments.lineItem}
`;

export const updateLineItemSettings = gql`
  mutation UpdateLineItemSettings($input: UpdateLineItemInput!) {
    updateLineItem(input: $input) {
      extLineItemId
      extProjectId
      survey {
        surveyId
        countryISOCode
        languageISOCode
      }
      countryISOCode
      languageISOCode
      indicativeIncidence
      daysInField
      fieldSchedule {
        startTime
        endTime
      }
      lengthOfInterview
      source
      requiredCompletes
      deliveryType
      title
      popularAttributes
      attributes
      ...QuotaPlanFragment
      ...QuoteFragment
      ...ActionsFragment
    }
  }
  ${actionsFragment}
  ${quotaPlanFragment}
  ${quoteFragment}
`;

export const updateQuotaPlan = gql`
  mutation UpdateQuotaPlan($input: UpdateLineItemInput!) {
    updateLineItem(input: $input) {
      extLineItemId
      extProjectId
      attributes
      ...QuotaPlanFragment
      ...QuoteFragment
      ...ActionsFragment
    }
  }
  ${actionsFragment}
  ${quotaPlanFragment}
  ${quoteFragment}
`;

export const pauseLineItem = gql`
  mutation PauseLineItem($extProjectId: ID!, $extLineItemId: ID!) {
    pauseLineItem(
      input: { extProjectId: $extProjectId, extLineItemId: $extLineItemId }
    ) {
      extLineItemId
      extProjectId
      state
      ...ActionsFragment
    }
  }
  ${actionsFragment}
`;

export const closeLineItem = gql`
  mutation CloseLineItem($extProjectId: ID!, $extLineItemId: ID!) {
    closeLineItem(
      input: { extProjectId: $extProjectId, extLineItemId: $extLineItemId }
    ) {
      extLineItemId
      extProjectId
      state
      ...ActionsFragment
    }
  }
  ${actionsFragment}
`;

export const acceptRepricing = gql`
  mutation AcceptRepricing($eventId: ID!) {
    eventAction(input: { action: ACCEPT, eventId: $eventId })
  }
`;

export const launchLineItem = gql`
  mutation launchLineItem($extProjectId: ID!, $extLineItemId: ID!) {
    launchLineItem(
      input: { extProjectId: $extProjectId, extLineItemId: $extLineItemId }
    ) {
      extProjectId
      extLineItemId
      state
      stateLastUpdatedAt
      updatedAt
      ...ActionsFragment
      ...LineItemReportFragment
      ...QuotaPlanFragment
    }
  }
  ${actionsFragment}
  ${lineItemReportFragment}
  ${quotaPlanFragment}
`;

export const lineItemReport = gql`
  query lineItemReport($extProjectId: ID!, $extLineItemId: ID!) {
    project(extProjectId: $extProjectId) {
      extProjectId
      lineItem(extLineItemId: $extLineItemId) {
        extProjectId
        extLineItemId
        ...LineItemReportFragment
        ...QuotaPlanFragment
      }
    }
  }
  ${quotaPlanFragment}
  ${lineItemReportFragment}
`;

export const lineItemStatus = gql`
  query LineItemStatus($extProjectId: ID!) {
    project(extProjectId: $extProjectId) {
      extProjectId
      state
      lineItems {
        title
        extLineItemId
        extProjectId
        state
        ...ActionsFragment
        ...QuotaPlanFragment
        ...QuoteFragment
        ...LineItemReportFragment
      }
    }
  }
  ${actionsFragment}
  ${quotaPlanFragment}
  ${quoteFragment}
  ${lineItemReportFragment}
`;

export const targetingTemplates = gql`
  query TargetingTemplates(
    $countryISOCode: String!
    $languageISOCode: String!
  ) {
    targetingTemplates(
      countryISOCode: $countryISOCode
      languageISOCode: $languageISOCode
    ) {
      countryISOCode
      createdAt
      description
      id
      languageISOCode
      name
      state
      tags
      updatedAt
      editable
      quotaPlan {
        filters {
          attributeId
          options
          operator
        }
        quotaGroups {
          name
          quotaCells {
            quotaNodes {
              attributeId
              options
            }
            perc
            count
          }
        }
      }
    }
  }
`;

export const createTargetingTemplate = gql`
  mutation createTargetingTemplate($input: CreateTargetingTemplateInput!) {
    createTargetingTemplate(input: $input) {
      id
    }
  }
`;

export const deleteTargetingTemplate = gql`
  mutation deleteTargetingTemplate($templateID: String!) {
    deleteTargetingTemplate(input: { templateID: $templateID })
  }
`;

export const updateQuotaCellStatus = gql`
  mutation UpdateQuotaCellStatus($input: UpdateQuotaCellStatusInput!) {
    updateQuotaCellStatus(input: $input) {
      ...QuotaCellFragment
    }
  }
  ${quotaCellFragment}
`;
