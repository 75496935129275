import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { matchPath, RouteProps } from "react-router";
import customWindow from "../custom-window";

// interface CustomWindow extends Window {
//     Beacon?: (cmd: string, args?: any) => void
// }
// const customWindow = window as CustomWindow;

const useBeaconRouting = (userInfo: any) => {
  const loc = useLocation();
  useEffect(() => {
    if (userInfo === null) {
      return;
    }

    let dashboard: RouteProps = { path: "/", exact: true };
    let surveyPage: RouteProps = { path: "*/surveys", exact: true };
    let respondentsPage: RouteProps = { path: "*/respondents", exact: true };
    let invoicesPage: RouteProps = { path: "/invoices", exact: true };

    showBeacon(loc.pathname, dashboard, userInfo.hsDashboardMessage);
    showBeacon(loc.pathname, surveyPage, userInfo.hsSurveysMessage);
    showBeacon(loc.pathname, respondentsPage, userInfo.hsRespondentsMessage);
    showBeacon(loc.pathname, invoicesPage, userInfo.hsInvoicesMessage);
  }, [loc, userInfo]);
};

const showBeacon = (path: string, page: RouteProps, msgID: string) => {
  const beacon = customWindow.Beacon;
  if (!beacon) return;
  if (matchPath(path, page) !== null && msgID !== "") {
    beacon("show-message", msgID);
  }
};

export default useBeaconRouting;
