import React from "react";
import styled from "styled-components";
import panda from "../art/Error.svg";

const Outer = styled.div`
  height: 100vh;
  width: 100%;
  background: linear-gradient(white 92%, #eeeeee);
`;
const Inner = styled.div`
  padding-top: 3.8rem 0 1rem;
  margin: 0 auto;
  text-align: center;
  background-color: white;
`;
const Blurb = styled.p`
  font-weight: 600;
  font-size: 1.8rem;
  margin: 0;
  padding: 2rem 0 1rem;
`;

const ErrorArt = styled.img`
  max-width: 100rem;
  margin: 0 auto;
`;

const Page404 = () => (
  <Outer>
    <Inner>
      <Blurb>Sorry! we couldn't find the page you're looking for.</Blurb>
      <ErrorArt src={panda} />
    </Inner>
  </Outer>
);

export default Page404;
