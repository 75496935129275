import React, { useContext } from "react";
import ModalPropsContext from "../../containers/ModalPropsContext";
import styled from "styled-components";
import { ModalBody, Tooltip } from "samplify-design";
import AttributeEditorSwitch from "../quota/AttributeEditorSwitch";
import {
  getOptionMapping,
  AttributeEditorModalProps
} from "../../quotaHelpers";
import { TIER_PREMIUM } from "../lineitem/Constants";
import premiumTagSmall from "../../art/premiumTagSmall.svg";

const PremiumIcon = styled.img`
  margin: auto 0.8rem;
  height: 1.8rem;
  width: auto;
`;

const StyledTooltip = styled(Tooltip)`
  z-index: 99999;
`;

const StyledModal = styled(ModalBody)`
  border-radius: 0.4rem;
  text-align: left;
  background-color: #f5f5f5;
  padding: 1.2rem 1.6rem 1.6rem 1.6rem;
  max-width: 57rem;
  max-height: fit-content;
  margin: 0 auto;
  flex-grow: unset;
`;

const StyledTitle = styled.div`
  display: flex;
  font: normal 600 1.4rem/1.9rem ${props => props.theme.fontFamily};
  margin-bottom: 0.6rem;
`;

type Props = {
  clearModal: () => void;
};

type ModalPropsContextArg = {
  modalProps: AttributeEditorModalProps;
};

const AttributeEditorModal = ({ clearModal }: Props) => {
  const context: ModalPropsContextArg = useContext(ModalPropsContext);
  const {
    draft,
    attribute,
    onCancel,
    onSave,
    initialSelectedOptions,
    initialOperatorType,
    initialQuotaGroupInput,
    requiredCompletes,
    lineItemState,
    attributeMap,
    editMode,
    invalidOptions
  } = context.modalProps;

  const optionMap = getOptionMapping(attribute.options);

  const cancelModel = () => {
    if (onCancel) {
      onCancel();
    }
    clearModal();
  };

  const maybePremiumIcon =
    attribute.tier === TIER_PREMIUM ? (
      <StyledTooltip
        overlay="Premium Attribute"
        placement="top"
        mouseLeaveDelay={0}
      >
        <PremiumIcon src={premiumTagSmall} alt="Premium" />
      </StyledTooltip>
    ) : null;

  const calculatedListHeight = window.innerHeight / 30 + "rem";
  const calculatedNestingListHeight = window.innerHeight / 35 + "rem";

  return (
    <StyledModal>
      <StyledTitle>
        {attribute.name}
        {maybePremiumIcon}
      </StyledTitle>

      <AttributeEditorSwitch
        draft={draft}
        editMode={editMode}
        attribute={attribute}
        onCancel={cancelModel}
        onSave={onSave}
        options={optionMap}
        initialSelectedOptions={initialSelectedOptions}
        initialOperatorType={initialOperatorType}
        initialQuotaGroupInput={initialQuotaGroupInput}
        requiredCompletes={requiredCompletes}
        lineItemState={lineItemState}
        attributeMap={attributeMap}
        calculatedListHeight={calculatedListHeight}
        calculatedNestingListHeight={calculatedNestingListHeight}
        invalidOptions={invalidOptions}
      />
    </StyledModal>
  );
};

export default AttributeEditorModal;
