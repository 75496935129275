import React, { useEffect } from "react";
import { useQuery, useApolloClient } from "react-apollo-hooks";
import { getGraphQLError, getNetworkError } from "../lib/queries/error";
import { useToasts } from "react-toast-notifications";
import ToastChild from "../components/ToastChild";
import { filterErrors, graphQlErrorTitle, capitalize } from "../generalHelpers";
import { ErrorResponse } from "apollo-link-error";
import { ErrorCode, Error } from "../types";

const graphQlErrorMessage = "Encountered internal system error";
const networkErrorTitle = "Network Error";
const networkErrorMessage = "Encountered network connectivity issue";
const defaultSystemErr: Error = {
  id: "",
  title: graphQlErrorTitle,
  code: ErrorCode.CUSTOM_MESSAGE,
  message: graphQlErrorMessage
};

const getErrorsToDisplay = (errors: Error[]): Error[] => {
  if (!errors.length) {
    return [defaultSystemErr];
  }

  const customErrors = errors.filter(e => e.code === ErrorCode.CUSTOM_MESSAGE);

  if (customErrors.length) {
    return customErrors;
  }

  return [];
};

type Props = {
  children: (gqlError: any) => JSX.Element;
};

const GraphQLErrorHandlerContainer = ({ children }: Props) => {
  const client = useApolloClient();
  const { data: gqlError } = useQuery<ErrorResponse>(getGraphQLError);
  const { data: networkError } = useQuery<ErrorResponse>(getNetworkError);
  const { addToast } = useToasts();

  useEffect(() => {
    if (gqlError && gqlError.graphQLErrors && gqlError.graphQLErrors.length) {
      const errors = filterErrors([...gqlError.graphQLErrors]);
      const errorsToDisplay = getErrorsToDisplay(errors);

      errorsToDisplay.map(err => {
        const message = capitalize(err.message);

        return addToast(
          <ToastChild title={err.title} message={message} icon={"error"} />,
          {
            appearance: "error",
            autoDismiss: false,
            onDismiss: () =>
              client.writeQuery({
                query: getGraphQLError,
                data: {
                  graphQLErrors: []
                }
              })
          }
        );
      });
    }
    if (networkError && networkError.networkError) {
      addToast(
        <ToastChild
          title={networkErrorTitle}
          message={networkErrorMessage}
          icon={"error"}
        />,
        {
          appearance: "error",
          autoDismiss: false,
          onDismiss: () =>
            client.writeQuery({
              query: getNetworkError,
              data: {
                networkError: []
              }
            })
        }
      );
    }
  }, [gqlError, networkError, addToast, client]);

  return children(
    gqlError && gqlError.graphQLErrors && gqlError.graphQLErrors.length
      ? gqlError.graphQLErrors
      : null
  );
};

export default GraphQLErrorHandlerContainer;
