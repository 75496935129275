import React from "react";
import styled from "styled-components";

import HELP_URL from "../../networkHelpers";
import HEADER, { HELP } from "../../idHelpers";

const StyledButton = styled.button`
  background: none;
  border: none;
  justify-content: center;
  cursor: pointer;
  height: fit-content;
  padding: 0;
  margin: auto;
  &:focus {
    outline: none;
    border-color: transparent;
    box-shadow: none;
  }
`;
const StyledHelpIcon = styled.i`
  color: #85858b;
  font-size: 2.4rem;
  ${StyledButton}:hover & {
    color: #3ea8dd;
  }
`;

const Apps = () => {
  const openHelp = () => {
    window.open(HELP_URL, "_blank");
  };

  return (
    <StyledButton id={HEADER + "-" + HELP} onClick={openHelp}>
      <StyledHelpIcon className="axiom-icon-icon icon-help-circle" />
    </StyledButton>
  );
};

export default Apps;
