import { useState } from "react";

const usePopover = () => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);
  return {
    popoverOpen: open,
    togglePopover: toggleOpen,
    setPopover: setOpen
  };
};

export default usePopover;
