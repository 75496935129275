import { useState } from "react";
import { ProjectFilters, PartialProjectFilters } from "../types/ProjectFilters";

const useFilters = () => {
  const startDate: Date = new Date();
  startDate.setDate(startDate.getDate() - 30);
  startDate.setHours(0, 0, 0, 0);
  const endDate: Date = new Date();
  endDate.setDate(endDate.getDate());
  endDate.setHours(23, 59, 59, 999);

  const initialState: ProjectFilters = {
    startDate: startDate,
    endDate: endDate,
    searchQuery: "",
    statuses: [],
    scope: "user"
  };
  const [filters, setFilters] = useState(initialState);
  const mergeFilters = (partial: PartialProjectFilters): ProjectFilters => ({
    ...filters,
    ...partial
  });
  const setPartialFilters = (partial: PartialProjectFilters) =>
    setFilters(mergeFilters(partial));

  return {
    initialFilters: initialState,
    filters,
    setFilters: setPartialFilters
  };
};

export default useFilters;
