import { useMutation } from "react-apollo-hooks";
import { UpdateProjectMutationArgs } from "../../types/graphql";
import { updateProjectTopics } from "../../lib/queries/project";

type Data = {
  updateProject: {
    extProjectId: string;
    category: {
      surveyTopic: Array<string>;
    };
  };
};

const useUpdateProjectTopics = ({ input }: UpdateProjectMutationArgs) => {
  const variables = { input };
  const options = { variables };
  return useMutation<Data>(updateProjectTopics, options);
};

export default useUpdateProjectTopics;
