import React from "react";
import styled from "styled-components";
import { icons } from "samplify-design";

const { Caret, Check } = icons;

const StyledBlockHeader = styled.div`
  padding: 2rem 2.5rem;
  position: relative;
  text-align: left;
  :hover {
    cursor: pointer;
  }
  > svg:first-child {
    transform: ${props => (props.isOpen ? "rotate(180deg)" : "rotate(360deg)")};
  }
  > svg {
    fill: ${({ theme }) => theme.neutralDark["3"]};
  }
`;

const StyledCaret = styled(Caret)`
  height: 0.5rem;
  margin-right: 1rem;
  position: relative;
  top: -2px;
`;

const StyledCheck = styled(Check)`
  height: 1.25em;
  position: absolute;
  right: 1.5em;
`;

const BlockHeader = ({ isOpen, label, setOpenBlock, data }) => {
  const toggleOpenBlock = () =>
    !isOpen ? setOpenBlock(label) : setOpenBlock("");

  return (
    <StyledBlockHeader isOpen={isOpen} onClick={() => toggleOpenBlock()}>
      <StyledCaret />
      {label}
      {data ? <StyledCheck /> : null}
    </StyledBlockHeader>
  );
};

export default BlockHeader;
