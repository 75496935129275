import { useMutation } from "react-apollo-hooks";
import { splitSource as mutation } from "../../lib/queries/lineitem";
import respondentsQuery from "../../lib/queries/respondentsQuery";
import { LineItem } from "../../types";
import { MutationUpdaterFn } from "apollo-client";
import { DataProxy } from "apollo-cache";

export type InputSource = {
  id: string;
  requiredCompletes: number;
};

type Result = {
  data?: {
    splitLineItem: {
      lineItems: Array<LineItem>;
    };
  };
};
type RespondentsData = {
  project: {
    lineItems: Array<LineItem>;
  };
};

const useSplitSource = (extProjectId: string, extLineItemId: string) => {
  const [mutate, mutationResult] = useMutation(mutation);
  const updateRespondents: MutationUpdaterFn<any> = (
    cache: DataProxy,
    mutationResult: Result
  ) => {
    const resultData = mutationResult.data;
    const data: RespondentsData | null = cache.readQuery({
      query: respondentsQuery,
      variables: { extProjectId }
    });
    if (data !== null && resultData)
      data.project.lineItems = resultData.splitLineItem.lineItems;
    cache.writeQuery({ query: respondentsQuery, data });
  };
  const splitSource = (inputSources: Array<InputSource>) =>
    mutate({
      variables: {
        input: {
          extProjectId,
          extLineItemId,
          sources: inputSources
        }
      },
      update: updateRespondents
    });
  return {
    splitSource,
    mutationResult
  };
};

export default useSplitSource;
