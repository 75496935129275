import React from "react";
import { Mutation } from "react-apollo";
import { Route } from "react-router";
import CreateProject from "../../components/modal/CreateProject";
import { createProject } from "../../lib/queries/project";

class CreateProjectContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { path: "surveys" };
    this.setPath = this.setPath.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  setPath(path) {
    if (path !== "surveys" && path !== "respondents")
      throw new Error("Invalid project redirect");
    this.setState({ path });
  }

  redirect(data) {
    const { extProjectId } = data.createProject;
    const { path } = this.state;
    const { history, clearModal } = this.props;
    const location = `/project/${extProjectId}/${path}`;
    clearModal();
    history.push(location);
  }

  render() {
    return (
      <Mutation mutation={createProject} onCompleted={this.redirect}>
        {mutateCreateProject => {
          const createProject = title =>
            mutateCreateProject({ variables: { title } });

          return (
            <CreateProject
              createProject={createProject}
              setPath={this.setPath}
              {...this.props}
            />
          );
        }}
      </Mutation>
    );
  }
}

const CreateProjectContainerWithLocation = props => (
  <Route
    render={({ history }) => (
      <CreateProjectContainer history={history} {...props} />
    )}
  />
);

export default CreateProjectContainerWithLocation;
