import styled from "styled-components";

const PopoverStyle = styled.div`
  background-color: ${({ theme }) => theme.neutralLight["7"]};
  border-radius: 0.4rem;
  border: 0.1rem solid ${({ theme }) => theme.neutralLight["3"]};
  padding: 1rem 1.4rem 1.4rem;
  font-size: 1.2rem;
  max-width: 300px;
`;

export default PopoverStyle;
