import React, { Fragment } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { tableHeaders, footnote } from "./Constants";
import { route } from "../../../projectHelpers";
import { Project, LineItem } from "../../../types";

const StyledTable = styled.table`
  border-radius: 5px;
  border-spacing: unset;
  margin-bottom: 25px;
  width: 100%;
`;

const StyledTableHeader = styled.th`
  background: #e7e8eb;
  color: #696e7b;
  font-size: 14px;
  padding: 10px 0;
  text-align: right;
  font-weight: normal;
  width: 80px;
  &:first-child {
    padding-left: 15px;
    border-radius: 5px 0 0 5px;
    text-align: left;
    width: 200px;
  }
  &:nth-child(2) {
    text-align: center;
  }
  &:last-child {
    border-radius: 0 5px 5px 0;
    padding-right: 10px;
    width: 100px;
  }
`;

const StyledTableRow = styled.tr`
  td {
    padding: 10px 0;
    text-align: right;
    &:first-child {
      padding-left: 15px;
      padding-right: 5px;
      text-align: left;
      width: 200px;
      word-break: break-word;
    }
    &:nth-child(2) {
      text-align: center;
    }
    &:last-child {
      padding-right: 10px;
      width: 100px;
    }
  }
`;

const StyledCost = styled.span`
  background-color: #fbe7ba;
  border: 1px solid #fbe7ba;
  border-radius: 5px;
  padding: 10px;
`;

const StyledTableFooter = styled.tfoot`
  &:before {
    color: white;
    content: "_";
    display: block;
    line-height: 10px;
  }
`;

const StyledTableFooterRow = styled.tr`
  border-radius: 5px;
  th {
    padding: 0 16px;
    width: 540px;
  }
  th:nth-last-child(1) {
    width: 70px;
    text-align: right;
    padding-right: 0;
  }
`;

const StyledLink = styled(Link)`
  color: #3ea8dd;
  text-decoration: underline;
`;

const Footnote = styled.p`
  font-size: 1.25rem;
  font-style: italic;
  color: #9599a4;
  font-weight: normal;
  text-align: left;
`;

interface PropTypes {
  project: Project;
  clearModal?: () => void;
}

const LineItemTable = ({ project, clearModal }: PropTypes) => {
  const cost = project.report.cost.incurredCost;
  const buildLineItem = (li: LineItem, i: number) => {
    const { extProjectId, extLineItemId, report, title } = li;
    if (!report) return <Fragment />;

    const incurredCost = report.cost.incurredCost;
    const cpi = report.cost.costPerUnit;
    const source = report.sources.map(s => s.name).join(",");
    const completes = report.stats.completes.intValue;
    return (
      <StyledTableRow key={i}>
        <td>
          <StyledLink
            key={extLineItemId}
            to={route(extProjectId, extLineItemId)}
            onClick={() => clearModal && clearModal()}
          >
            {title}
          </StyledLink>
        </td>
        <td>{source}</td>
        <td>{completes}</td>
        <td>{cpi}</td>
        <td>{incurredCost}</td>
      </StyledTableRow>
    );
  };
  return (
    <StyledTable>
      <thead>
        <tr>
          {tableHeaders.map(th => (
            <StyledTableHeader key={th}>{th}</StyledTableHeader>
          ))}
        </tr>
      </thead>
      <tbody>
        {project.lineItems.map((item: LineItem, i: number) =>
          buildLineItem(item, i)
        )}
      </tbody>
      <StyledTableFooter>
        <StyledTableFooterRow>
          <th colSpan={3}>
            <Footnote>{footnote}</Footnote>
          </th>
          <th colSpan={2}>
            <StyledCost>Total {cost}</StyledCost>
          </th>
        </StyledTableFooterRow>
      </StyledTableFooter>
    </StyledTable>
  );
};

export default LineItemTable;
