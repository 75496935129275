import React from "react";

export type Modal = {
  modalType: string | null;
  modalProps: any;
};

type Props = {
  setModalData: (m: Modal) => void;
} & Modal;

const defaultValue: Props = {
  modalType: null,
  modalProps: null,
  setModalData: (m: Modal) => {}
};

const ModalPropsContext = React.createContext({ ...defaultValue });

export default ModalPropsContext;
