import React from "react";
import styled from "styled-components";
import { Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import Page404 from "./Page404";
import PageLoading from "./PageLoading";
import GenericErrorBoundary from "./GenericErrorBoundary";
import LoginCallback from "./LoginCallback";
import ModalContainer from "../containers/ModalContainer";
import Outer from "./AppOuter";
import Tos from "./tos/Tos";
import Header from "./header/Header";
import useBeaconRouting from "../hooks/useBeaconRouting";
import Unauthorized from "./Unauthorized";
import DIPLauncher from "./DIPLauncher";
import useComponentVisible from "../hooks/useComponentVisible";
import {
  UserActionKind,
  ResourceType,
  UserInfo,
  Country,
  MetaDataItem,
  Project
} from "../types";
import { getActionsMap } from "../generalHelpers";
import { FormattedFilters, ProjectFilters } from "../types/ProjectFilters";
import { ApolloError } from "apollo-client";
import { Modal } from "../containers/ModalPropsContext";

const StyledMain = styled.main`
  height: 100%;
  padding-top: 5rem;
`;

const LoadableDashboard = Loadable({
  loader: () => import("./dashboard/Dashboard"),
  loading: PageLoading
});

const LoadableInvoices = Loadable({
  loader: () => import("./invoices/Invoices"),
  loading: PageLoading
});

const LoadableProject = Loadable({
  loader: () => import("../containers/ProjectContainer"),
  loading: PageLoading
});

const LoadableSurveytool = Loadable({
  loader: () => import("../containers/SurveytoolContainer"),
  loading: PageLoading
});

type Props = {
  userInfo: UserInfo;
  resetDashboard: () => void;
  resetInProgress: boolean;
  setResetInProgress: () => void;
  filters: ProjectFilters;
  setFilters: (partial: Partial<ProjectFilters>) => void;
  countries: Array<Country>;
  deliveryModes: Array<MetaDataItem>;
  studyRequirements: Array<MetaDataItem>;
  surveyTopics: Array<MetaDataItem>;
  projectsList: Array<Project>;
  dashboardError: ApolloError | undefined;
  hasMoreProjects: boolean;
  addMoreProjects: () => void;
  formattedFilters: FormattedFilters;
  dashboardLoading: boolean;
  modalData: Modal;
  clearModalType: () => void;
};

const AppLayout = ({
  userInfo,
  resetDashboard,
  resetInProgress,
  setResetInProgress,
  filters,
  setFilters,
  countries,
  deliveryModes,
  studyRequirements,
  surveyTopics,
  projectsList,
  dashboardError,
  hasMoreProjects,
  addMoreProjects,
  formattedFilters,
  dashboardLoading,
  modalData,
  clearModalType
}: Props) => {
  const { setIsVisible, isVisible, toggleIsVisible } = useComponentVisible(
    false
  );
  const { userTermsInfo, settings, actions } = userInfo;
  const termsAccepted = userTermsInfo.termsAccepted
    ? userTermsInfo.signedVersion === userTermsInfo.currentVersion
    : false;
  useBeaconRouting(userInfo);
  const doesUserHaveAccess = settings.applications.some(
    a => a.id === "SAMPLIFY"
  );

  const userActionsMap = getActionsMap(ResourceType.USER, actions);
  const actionCreateProject = userActionsMap[UserActionKind.CREATE_NEW_PROJECT];

  const header = (
    <Header
      termsAccepted={termsAccepted}
      userInfo={userInfo}
      apps={settings.applications}
      doesUserHaveAccess={doesUserHaveAccess}
      toggleLauncherOpen={toggleIsVisible}
      resetDashboard={resetDashboard}
      resetInProgress={resetInProgress}
      setResetInProgress={setResetInProgress}
    />
  );

  if (!doesUserHaveAccess) {
    return (
      <Outer>
        {header}
        <Unauthorized />
      </Outer>
    );
  }

  return (
    <Switch>
      <Route
        path="/project/:extProjectId/surveytool/:initialSurveyHash"
        render={({ match }) => {
          const { extProjectId, initialSurveyHash } = match.params;
          return (
            <LoadableSurveytool
              extProjectId={extProjectId}
              initialSurveyHash={initialSurveyHash}
              basename={`/project/${extProjectId}/surveytool`}
              exitLink={`/project/${extProjectId}/surveys`}
            />
          );
        }}
      />
      <Route>
        <Outer>
          {header}
          <GenericErrorBoundary>
            <StyledMain>
              <DIPLauncher
                open={isVisible}
                setOpen={setIsVisible}
                apps={settings.applications}
              />
              <Switch>
                {!termsAccepted && (
                  <Route path="/">
                    <Tos />
                  </Route>
                )}
                <Route path="/" exact>
                  <LoadableDashboard
                    resetInProgress={resetInProgress}
                    studyRequirements={studyRequirements}
                    actionCreateProject={actionCreateProject}
                    filters={filters}
                    setFilters={setFilters}
                    projectsList={projectsList}
                    error={dashboardError}
                    hasNext={hasMoreProjects}
                    addMoreProjects={addMoreProjects}
                    formattedFilters={formattedFilters}
                    loading={dashboardLoading}
                  />
                </Route>
                <Route path="/invoices">
                  <LoadableInvoices />
                </Route>
                <Route path="/callback" exact>
                  <LoginCallback />
                </Route>
                <Route
                  path="/project/:extProjectId"
                  render={({ match, location }) => {
                    const { extProjectId } = match.params;
                    return (
                      <LoadableProject
                        location={location}
                        extProjectId={extProjectId}
                        allowSurveyBuilder={settings.allowSurveyBuilder}
                        userApps={settings.applications}
                        deliveryModes={deliveryModes}
                        surveyTopics={surveyTopics}
                        countries={countries}
                      />
                    );
                  }}
                />
                <Route>
                  <Page404 />
                </Route>
              </Switch>
            </StyledMain>
          </GenericErrorBoundary>

          <ModalContainer
            modalData={modalData}
            clearModalType={clearModalType}
          />
        </Outer>
      </Route>
    </Switch>
  );
};

export default AppLayout;
