import styled from "styled-components";

const LinkAsButton = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--light-color-6);
  height: 3.6rem;
  padding: 0 2rem;
  font: normal 600 1.2rem/1.8rem ${props => props.theme.fontFamily};
  background-color: var(--primary-color-2);
  border-radius: 0.54rem;
  &:hover {
    background-color: var(--primary-color-0);
    cursor: pointer;
  }
`;

export default LinkAsButton;
