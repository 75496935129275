import React, { useContext } from "react";
import styled from "styled-components";
import { ModalBody, Button } from "samplify-design";
import ModalPropsContext from "../../containers/ModalPropsContext";
import ModalCancelButton from "../ModalCancelButton";
import deleteIcon from "../../art/iconDeleteGray.svg";
import { TargetingTemplate } from "../../types";
import useTemplateDelete from "../../hooks/quota/useTemplateDelete";

const Footer = styled.div`
  text-align: right;
  margin-top: 40px;
`;
const StyledButton = styled(Button)`
  width: 11rem;
`;

const StyledBody = styled(ModalBody)`
  padding: 30px 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  font-size: 13px;
  color: #696e7b;
  width: 370px;
  margin: 0 auto;
  flex-grow: unset;
`;

const StyledHeader = styled.p`
  font-size: 15px;
`;

const StyledIcon = styled.img`
  float: left;
  margin-right: 10px;
`;

type Props = {
  clearModal: () => void;
};

type ModalProps = {
  template: TargetingTemplate;
  resetTemplates: () => void;
};
type ModalPropsContextArg = {
  modalProps: ModalProps;
};

const Outer = styled.div``;

const ConfirmDeleteTemplate = ({ clearModal }: Props) => {
  const context: ModalPropsContextArg = useContext(ModalPropsContext);
  const { template, resetTemplates } = context.modalProps;
  const [deleteTargetingTemplate] = useTemplateDelete(template.id);
  const onClickDelete = () => {
    clearModal();
    deleteTargetingTemplate().then(
      ({ data }) => data && data.deleteTargetingTemplate && resetTemplates()
    );
  };
  return (
    <Outer>
      <StyledBody>
        <StyledHeader>
          <StyledIcon src={deleteIcon} width="20" alt="delete" />
          Delete Template
        </StyledHeader>
        <p>Are you sure you want to delete "{template.name}"?</p>
        <Footer>
          <ModalCancelButton onClick={clearModal}>Cancel</ModalCancelButton>
          <StyledButton swatch="danger" onClick={onClickDelete}>
            Delete
          </StyledButton>
        </Footer>
      </StyledBody>
    </Outer>
  );
};

export default ConfirmDeleteTemplate;
