import React, { Fragment } from "react";
import styled from "styled-components";
import { ModalHeader, ModalBody, ModalFooter, Button } from "samplify-design";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { logout } from "../../networkHelpers";

const DECLINE_TOS_MUTATION = gql`
  mutation DECLINE_TOS_MUTATION {
    declineTermsAndConditions
  }
`;

const StyledBody = styled(ModalBody)`
  height: auto;
  flex-grow: unset;
  height: 110px;
  padding: 20px 30px 40px;
  flex-grow: unset;
`;

const StyledFooter = styled(ModalFooter)`
  height: auto;
`;

const ButtonsFlex = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 15px;
`;

const StyledDecline = styled(Button)`
  width: 15rem;
  height: 4rem;
  padding: 0;
  background-color: var(--danger-color-2);
  &:hover {
    background-color: var(--danger-color-1);
  }
  &:active {
    background-color: var(--danger-color-0);
  }
`;

const StyledReturn = styled(Button)`
  width: 15rem;
  height: 4rem;
  padding: 0;
  margin: 0 0 0 4rem;
`;

const DeclineTosConfirm = ({ clearModal }) => (
  <Mutation mutation={DECLINE_TOS_MUTATION}>
    {declineTermsAndConditions => (
      <Fragment>
        <ModalHeader>Decline Terms and Conditions</ModalHeader>
        <StyledBody>
          <p>
            You are about to decline the Terms and Conditions. You cannot access
            the platform if you do not accept. Are you sure you would like to
            decline?
          </p>
          <p>
            If you have any questions or concerns regarding these terms, please
            contact us at samplify-support@researchnow.com
          </p>
        </StyledBody>
        <StyledFooter>
          <ButtonsFlex>
            <StyledDecline
              onClick={() => {
                declineTermsAndConditions();
                logout();
              }}
            >
              I Decline
            </StyledDecline>
            <StyledReturn
              onClick={() => {
                clearModal();
              }}
            >
              Return to Agreement
            </StyledReturn>
          </ButtonsFlex>
        </StyledFooter>
      </Fragment>
    )}
  </Mutation>
);

export default DeclineTosConfirm;
