import React, { useState, useEffect } from "react";
import isEqual from "lodash/isEqual";
import styled, { StyledComponentProps } from "styled-components";
import { TextInput, Tooltip } from "samplify-design";
import Popover from "react-popover";
import FocusTrap from "focus-trap-react";
import usePopover from "../../hooks/usePopover";
import AllocationGroupPopover from "./AllocationGroupPopover";
import { QuotaCellInput, QuotaGroupInput, AttributeMap } from "../../types";
import NumberUnit from "../NumberUnit";
import {
  StyledInterlockButton,
  StyledGroupButton,
  StyledIcon
} from "./AttributeEditStyles";
import {
  getPerc,
  getCount,
  AllocVal,
  AllocationValue
} from "../../quotaHelpers";

const disabledTooltip = "Add another option to enable grouping!";

const StyledPopover = styled(Popover)`
  z-index: 99999;
`;

const StyledTooltip = styled(Tooltip)`
  z-index: 99999;
`;

const StyledNumberUnit = styled(NumberUnit)`
  padding-right: 0.2rem;
  right: 9px;
`;

const InputWrap = styled.div`
  position: relative;
`;

const Outer = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin: auto -0.3em;
  transform: translateY(0.3em);
  > * {
    margin: auto 0.3em;
  }
`;

const BaseStyledInput = styled(TextInput).attrs({ type: "number" })`
  text-align: center;
  height: 2.4rem;
  width: 4.5rem;
  padding: 0.3rem 1.5rem 0.3rem 0.5rem;
  font: normal 400 1.2rem/1.7rem ${props => props.theme.fontFamily};
  color: #696e7b;
  margin-right: 0;
  box-sizing: border-box;
  &:focus {
    border-width: 0.1rem;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: #9599a4;
  }
`;
type CustomTextInputProps = {
  showError: boolean;
} & StyledComponentProps<
  any,
  any,
  object & {
    type: string;
  },
  string | number | symbol
>;

const CustomTextInput = ({ showError, ...rest }: CustomTextInputProps) => (
  <BaseStyledInput {...rest} />
);

const PercInput = styled(CustomTextInput)`
  margin: 0 0.3em;
  border-color: ${props => (props.showError ? "#f76e5c" : "#dddddd")};
`;

const CountInput = styled(CustomTextInput)`
  padding: 0.3rem 0.5rem;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  border-color: ${props => (props.showError ? "#f76e5c" : "#dddddd")};
`;

const BracketSpan = styled.span`
  color: #cbcbcb;
  font: normal 400 1.2rem/1.7rem ${props => props.theme.fontFamily};
`;

const Count = (props: CustomTextInputProps) => (
  <div>
    <CountInput {...props} />
  </div>
);

const Perc = (props: CustomTextInputProps) => (
  <div>
    <BracketSpan>(</BracketSpan>
    <PercInput {...props} />
    <BracketSpan>)</BracketSpan>
  </div>
);

type Props = {
  count: AllocVal;
  updateCell: (v: AllocationValue) => void;
  requiredCompletes: number;
  quotaCell: QuotaCellInput;
  quotaGroup: QuotaGroupInput;
  updateTempQuotaGroupInput: (g: QuotaGroupInput) => void;
  attributeCount: number;
  isLineItemLaunched: boolean;
  attributes: AttributeMap;
  showAllocationError?: boolean;
  disableGrouping: boolean;
  calculatedNestingListHeight: string;
  completes?: number;
  toggleRangeGroupingChange?: () => void;
  changeShowCompletesError?: (b: boolean) => void;
  toggleUpdateAllocRowVal?: (b: boolean) => void;
  shouldUpdateAllocRowVal?: boolean;
};

const AllocationRow = ({
  count,
  updateCell,
  requiredCompletes,
  quotaCell,
  quotaGroup,
  updateTempQuotaGroupInput,
  attributeCount,
  isLineItemLaunched,
  attributes,
  showAllocationError = false,
  disableGrouping,
  calculatedNestingListHeight,
  toggleRangeGroupingChange,
  completes,
  changeShowCompletesError,
  shouldUpdateAllocRowVal = false,
  toggleUpdateAllocRowVal
}: Props) => {
  const numCnt = count ? count : 0;
  const { popoverOpen, togglePopover, setPopover } = usePopover();
  const [stateCount, setStateCount] = useState(numCnt);
  const numPerc = getPerc(numCnt, requiredCompletes);
  const [statePerc, setStatePerc] = useState(numPerc);

  useEffect(() => {
    if (shouldUpdateAllocRowVal) {
      if (toggleUpdateAllocRowVal) {
        toggleUpdateAllocRowVal(false);
      }
      setStateCount(numCnt);
      setStatePerc(numPerc);
    }
  }, [
    shouldUpdateAllocRowVal,
    numCnt,
    requiredCompletes,
    toggleUpdateAllocRowVal,
    numPerc
  ]);

  const updateWithPerc = (e: React.FormEvent<HTMLInputElement>) => {
    const perc = isNaN(e.currentTarget.valueAsNumber)
      ? 0
      : e.currentTarget.valueAsNumber;
    setStatePerc(perc);
    const count = getCount(perc, requiredCompletes);
    setStateCount(count);

    return updateCell({ perc, count });
  };

  const updateWithCount = (e: React.FormEvent<HTMLInputElement>) => {
    const count = isNaN(e.currentTarget.valueAsNumber)
      ? 0
      : e.currentTarget.valueAsNumber;
    setStateCount(count);
    const perc = getPerc(count, requiredCompletes);
    setStatePerc(perc);

    if (completes && changeShowCompletesError) {
      const val = count < completes;
      changeShowCompletesError(val);
    }

    return updateCell({ perc, count });
  };

  const groupEnabled =
    !disableGrouping && attributeCount === 1 && !isLineItemLaunched;
  const groupedOptionsCount = quotaCell.quotaNodes[0].options.length;
  const groupButtonText =
    groupedOptionsCount > 1 ? groupedOptionsCount + " Grouped" : "Group";
  const preSelectedOption =
    groupedOptionsCount === 1 ? quotaCell.quotaNodes[0].options[0] : "";

  const interlockingDisabled =
    groupedOptionsCount <= 1 &&
    quotaGroup.quotaCells.filter(
      c => !isEqual(c.quotaNodes, quotaCell.quotaNodes)
    ).length <= 0;

  const maybeGroup = groupEnabled ? (
    interlockingDisabled ? (
      <StyledTooltip
        overlay={disabledTooltip}
        placement="top"
        mouseLeaveDelay={0}
      >
        <StyledGroupButton noOfOptions={groupedOptionsCount}>
          <span>{groupButtonText}</span>
          <StyledIcon />
        </StyledGroupButton>
      </StyledTooltip>
    ) : (
      <StyledPopover
        isOpen={popoverOpen}
        onOuterAction={togglePopover}
        enterExitTransitionDurationMs={0}
        tipSize={0.1}
        place="below"
        body={
          <FocusTrap
            focusTrapOptions={{
              onDeactivate: () => {
                setPopover(false);
              }
            }}
          >
            <AllocationGroupPopover
              quotaCell={quotaCell}
              quotaGroup={quotaGroup}
              updateTempQuotaGroupInput={updateTempQuotaGroupInput}
              attributes={attributes}
              togglePopover={togglePopover}
              preSelectedOption={preSelectedOption}
              calculatedNestingListHeight={calculatedNestingListHeight}
              toggleRangeGroupingChange={toggleRangeGroupingChange}
            />
          </FocusTrap>
        }
      >
        <StyledInterlockButton
          togglePopover={togglePopover}
          buttonText={groupButtonText}
          interlockedCount={groupedOptionsCount}
        />
      </StyledPopover>
    )
  ) : null;

  return (
    <Outer>
      <Count
        step={1}
        max={requiredCompletes}
        min={0}
        value={stateCount || ""}
        onChange={updateWithCount}
        placeholder="0"
        showError={showAllocationError}
      />
      <InputWrap>
        <Perc
          step={0.01}
          max={100}
          min={0}
          value={statePerc || ""}
          onChange={updateWithPerc}
          placeholder="0"
          showError={showAllocationError}
        />
        <StyledNumberUnit>%</StyledNumberUnit>
      </InputWrap>
      {maybeGroup}
    </Outer>
  );
};

export default AllocationRow;
