import React, { useEffect } from "react";
import styled from "styled-components";
import { keyframes } from "styled-components";
import { Company } from "../../types";

const animation = keyframes`
  from {
    transform: translate(-50%, -100%);
  }
  to {
    transform: translate(-50%, 0);
  }
`;

const Message = styled.div`
  animation-duration: 0.25s;
  animation-name: ${animation};
  border-radius: 4px;
  box-shadow: 0px 5px 10px rgba(19, 21, 46, 0.16);
  left: 50%;
  padding: 1.2rem 1.6rem;
  position: fixed;
  top: 5;
  transform: translateX(-50%);
`;

const SuccessMessage = styled(Message)`
  background-color: var(--accent-color-4);
  color: var(--accent-color-1);
`;

const ErrorMessage = styled(Message)`
  background-color: var(--danger-color-4);
  color: var(--danger-color-1);
`;

type Props = {
  effectiveCompany: Company;
  showCompanySwitchErrMsg: boolean;
  removeCompanySwitchMsgs: () => void;
};

const successMsg = "Your company is now switched to";
const errorMsg = "Error while switching company";

const CompanySwitchSuccess = ({
  effectiveCompany,
  showCompanySwitchErrMsg,
  removeCompanySwitchMsgs
}: Props) => {
  useEffect(() => {
    const interval = setInterval(() => {
      removeCompanySwitchMsgs();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  });

  if (showCompanySwitchErrMsg) {
    return <ErrorMessage>{`${errorMsg}.`}</ErrorMessage>;
  }

  return (
    <SuccessMessage>{`${successMsg} ${effectiveCompany.name}.`}</SuccessMessage>
  );
};

export default CompanySwitchSuccess;
