import React from "react";
import { Wrapper } from "./Wrapper";
import { TextInput } from "samplify-design";
import styled from "styled-components";
import NumberUnit from "../../NumberUnit";
import ClearFilter from "./ClearFilter";

const InputWrap = styled.div`
  position: relative;
  margin-right: 2em;
`;

const StyledWrapper = styled(Wrapper)`
  > svg {
    margin-left: -1em;
    position: relative;
    top: 0.75em;
  }
`;

const StyledInput = styled(TextInput)`
  padding-right: 3em;
  width: 120px;
  &:focus {
    box-shadow: 0 0 0 2px
      ${({ invalid, theme }) =>
        invalid ? theme.danger["2"] : theme.primary["2"]};
  }
`;

const StyledNumberUnit = styled(NumberUnit)`
  right: 6px;
  top: 70%;
`;

const StyledLabel = styled.p`
  margin: 0 0 0.5em;
`;

const SurveyDuration = ({
  duration,
  setDuration,
  moreThanError,
  setMoreThanError,
  lessThanError,
  setLessThanError
}) => {
  const moreThan = duration && duration.moreThan ? duration.moreThan : "";
  const lessThan = duration && duration.lessThan ? duration.lessThan : "";

  const setMoreThan = e => {
    const value = e.target.value;
    setMoreThanError(Number(value) < 0);
    setLessThanError(Number(lessThan) && Number(lessThan) < Number(value));
    setDuration({
      moreThan: value ? Number(value) : null,
      lessThan: lessThan ? lessThan : null
    });
  };

  const setLessThan = e => {
    const value = e.target.value;
    setLessThanError(
      Number(value) < 0 || (value && Number(value) < Number(moreThan))
    );
    setDuration({
      moreThan: moreThan ? moreThan : null,
      lessThan: value ? Number(value) : null
    });
  };
  const clearFilter = () => setDuration(null);
  return (
    <StyledWrapper>
      <InputWrap>
        <StyledLabel>Greater than</StyledLabel>
        <StyledInput
          min={0}
          onChange={setMoreThan}
          type="number"
          value={moreThan}
          invalid={moreThanError}
        />
        <StyledNumberUnit>sec</StyledNumberUnit>
      </InputWrap>
      <InputWrap>
        <StyledLabel>Less than</StyledLabel>
        <StyledInput
          min={0}
          onChange={setLessThan}
          type="number"
          value={lessThan}
          invalid={lessThanError}
        />
        <StyledNumberUnit>sec</StyledNumberUnit>
      </InputWrap>
      <ClearFilter clearFilter={clearFilter} />
    </StyledWrapper>
  );
};

export default SurveyDuration;
