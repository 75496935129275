import { useState } from "react";
import { MetaDataItem } from "../../types";

type TopicCheck = {
  [id: string]: boolean;
};

type ProjectTopicsEditState = {
  topicsCheckList: TopicCheck;
  allocatedTopics: Array<string>;
};

type Props = {
  surveyTopics: Array<MetaDataItem>;
  selectedTopics: Array<string>;
};

const initialState = (
  surveyTopics: Array<MetaDataItem>,
  selectedTopics: Array<string>
): ProjectTopicsEditState => {
  const topics = surveyTopics.map(topic => topic.id);
  const selectedTopicsSet = new Set(selectedTopics);

  const topicsCheckList = {} as TopicCheck;
  topics.forEach(t => {
    topicsCheckList[t] = selectedTopicsSet.has(t);
  });

  return {
    topicsCheckList,
    allocatedTopics: selectedTopics.slice()
  };
};

const useProjectTopicsEdit = ({ surveyTopics, selectedTopics }: Props) => {
  const [state, setState] = useState<ProjectTopicsEditState>(
    initialState(surveyTopics, selectedTopics)
  );

  const toggleTopic = (topic: string) => {
    const { topicsCheckList, allocatedTopics } = state;
    const topicCheck = !topicsCheckList[topic];

    const newAllocatedTopics = topicCheck
      ? [...allocatedTopics, topic]
      : allocatedTopics.filter(t => t !== topic);

    setState({
      topicsCheckList: {
        ...topicsCheckList,
        [topic]: topicCheck
      },
      allocatedTopics: newAllocatedTopics
    });
  };

  return {
    state,
    toggleTopic
  };
};

export default useProjectTopicsEdit;
