import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { TextInput, Button } from "samplify-design";
import { DebounceInput } from "react-debounce-input";
import svgSearch from "../../art/search.svg";
import svgRefresh from "../../art/iconRefresh.svg";
import { AuthoringPlatform } from "../../types";
import CmixSurveyList from "./CmixSurveyList";
import Section from "../Section";
import useExternalSurveysPaging from "../../hooks/survey/useExternalSurveysPaging";

const SectionHeaderHeight = "3rem";

const StyledBody = styled.div`
  margin-top: 1.2rem;
`;

const StyledTop = styled.div`
  display: inline-block;
  width: 100%;
  align-items: center;
  margin-bottom: 2.2rem;
`;

const StyledSearchBox = styled.div`
  display: flex;
  align-items: center;
  float: left;
`;
const StyledSearchIcon = styled.img`
  width: auto;
  height: 1.4rem;
  position: relative;
  right: 3rem;
`;
const StyledDebounce = TextInput.withComponent(DebounceInput);
const SearchInput = styled(StyledDebounce)`
  width: 30rem;
  ::placeholder {
    color: #b9bcc4;
  }
`;

const StyledRefreshBox = styled.div`
  display: flex;
  align-items: center;
  float: right;
`;
const StyledRefreshBtn = styled(Button)`
  display: flex;
  align-items: center;
  width: fit-content;
  color: var(--dark-color-3);
  background: var(--light-color-6);
  font: normal 400 1.4rem/2.2rem ${props => props.theme.fontFamily};
  margin: 0 1rem;
  padding: 0 1rem;
  align-items: center;
  border: 1px solid var(--light-color-3);
  &:hover {
    background: var(--light-color-6);
  }
  &:active {
    background: var(--light-color-6);
  }
`;
const StyledRefreshIcon = styled.img`
  width: auto;
  height: 1.4rem;
  margin: 0 0.8rem 0 0;
`;

const SectionTitle = styled.span`
  color: #4e525c;
  font: normal 600 1.4rem/2.1rem ${props => props.theme.fontFamily};
  align-items: center;
`;
const RedSpan = styled.span`
  color: var(--danger-color-1);
  font: normal 400 1.4rem/2.1rem ${props => props.theme.fontFamily};
  align-items: center;
`;

const StyledSections = styled.div`
  display: inline-block;
  width: 100%;
  height: 40rem;
  position: relative;
`;
type CustomSectionOuterProps = {
  activeSectionOpen: boolean;
} & React.HTMLProps<HTMLDivElement>;
const CustomSectionOuterDiv = ({
  activeSectionOpen,
  ...rest
}: CustomSectionOuterProps) => <div {...rest} />;
const SectionOuter = styled(CustomSectionOuterDiv)`
  display: inline-block;
  width: 100%;
`;
const SectionActiveOuter = styled(SectionOuter)`
  position: absolute;
  top: 0;
`;
const SectionInActiveOuter = styled(SectionOuter)`
  position: absolute;
  bottom: ${props => (props.activeSectionOpen ? "0" : "unset")};
  top: ${props => (props.activeSectionOpen ? "unset" : "3rem")};
`;

type Props = {
  closeModal: () => void;
  cmixURL: string;
  cmixIconURL: string;
  samplifyIconURL: string;
  createSurvey: (surveyName: string, surveyId: string) => void;
};

const CmixSurveyModalBody = ({
  closeModal,
  cmixURL,
  cmixIconURL,
  samplifyIconURL,
  createSurvey
}: Props) => {
  const [stateSearchQuery, setStateSearchQuery] = useState<string>();

  const [activeSectionOpen, setActiveSectionOpen] = useState(true);
  const toggleSectionOpen = () => setActiveSectionOpen(!activeSectionOpen);

  const [totalActive, setTotalActive] = useState(0);
  const [totalInActive, setTotalInActive] = useState(0);
  const updateTotal = useCallback(
    (n: number) => {
      if (activeSectionOpen) {
        setTotalActive(n);
      } else {
        setTotalInActive(n);
      }
    },
    [activeSectionOpen, setTotalActive, setTotalInActive]
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setStateSearchQuery(e.target.value);
      setTotalActive(0);
      setTotalInActive(0);
    },
    [setStateSearchQuery]
  );

  const {
    surveys,
    error,
    hasNext,
    addMoreSurveys,
    fetching,
    refetch,
    refetching
  } = useExternalSurveysPaging(
    AuthoringPlatform.CMIX,
    activeSectionOpen,
    stateSearchQuery
  );

  useEffect(() => {
    updateTotal(surveys.length);
  }, [updateTotal, surveys]);

  const onRefresh = () => {
    if (!fetching && !refetching) {
      refetch();
    }
  };

  const surveysList = (
    <CmixSurveyList
      closeModal={closeModal}
      cmixURL={cmixURL}
      cmixIconURL={cmixIconURL}
      samplifyIconURL={samplifyIconURL}
      createSurvey={createSurvey}
      activeSectionOpen={activeSectionOpen}
      surveys={surveys}
      error={error ? true : false}
      hasNext={hasNext}
      addMoreSurveys={addMoreSurveys}
      fetching={fetching}
      refetching={refetching}
    />
  );

  const activeSurveysHeader = (
    <SectionTitle>Available Surveys ({totalActive})</SectionTitle>
  );

  const inActiveSurveysHeader = (
    <SectionTitle>
      Surveys in Use ({totalInActive}){" "}
      <RedSpan>Disconnect from other projects to reuse in this project</RedSpan>
    </SectionTitle>
  );

  return (
    <StyledBody>
      <StyledTop>
        <StyledSearchBox>
          <SearchInput
            placeholder="Search"
            value={stateSearchQuery ? stateSearchQuery : ""}
            onChange={onChange}
            debounceTimeout={1000}
          />
          <StyledSearchIcon src={svgSearch} alt="" />
        </StyledSearchBox>
        <StyledRefreshBox>
          <StyledRefreshBtn onClick={() => onRefresh()}>
            <StyledRefreshIcon src={svgRefresh} alt="" /> Refresh
          </StyledRefreshBtn>
        </StyledRefreshBox>
      </StyledTop>

      <StyledSections>
        <SectionActiveOuter activeSectionOpen={activeSectionOpen}>
          <Section
            header={activeSurveysHeader}
            isOpen={activeSectionOpen}
            toggleOpen={toggleSectionOpen}
            showTopBorder={false}
            minHeight={SectionHeaderHeight}
          >
            {surveysList}
          </Section>
        </SectionActiveOuter>

        <SectionInActiveOuter activeSectionOpen={activeSectionOpen}>
          <Section
            header={inActiveSurveysHeader}
            isOpen={!activeSectionOpen}
            toggleOpen={toggleSectionOpen}
            minHeight={SectionHeaderHeight}
          >
            {surveysList}
          </Section>
        </SectionInActiveOuter>
      </StyledSections>
    </StyledBody>
  );
};

export default CmixSurveyModalBody;
