import React from "react";
import styled from "styled-components";
import { Button, Tooltip, ModalCancel } from "samplify-design";
import iconError from "../../art/iconError.svg";

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 2.5rem;
`;

const StyledErrorCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const StyledErrorSpan = styled.span`
  font: normal 400 1.2rem/1.7rem ${props => props.theme.fontFamily};
  color: #f76e5c;
`;
const StyledErrorIcon = styled.img`
  width: 1rem;
  height: auto;
  margin-right: 0.8rem;
`;

const StyledButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledCancelButton = styled(ModalCancel)`
  font: normal 400 1.2rem/1.7rem ${props => props.theme.fontFamily};
  color: #696e7b;
  margin-right: 1.5rem;
`;

const StyledSaveButton = styled(Button)`
  font: normal 400 1.2rem/2rem ${props => props.theme.fontFamily};
  color: #ffffff;
  max-height: 3rem;
  width: 8.5rem;
`;

const StyledTooltip = styled(Tooltip)`
  z-index: 99999;
`;

const StyledDisabledButton = styled(StyledSaveButton)`
  background-color: #b0b3bc;
  cursor: default;
  :hover {
    cursor: default;
    background-color: #b0b3bc;
  }
`;

type Props = {
  onSave: () => void;
  onCancel: () => void;
  saveDisabled: boolean;
  tooltipOverlay: React.ReactNode;
  saveButtonName: string;
  showError: boolean;
  errorMsg?: string;
  toggleErrorHighlight: (b: boolean) => void;
};

const AttributeEditButtonGroup = ({
  onSave,
  onCancel,
  saveDisabled,
  tooltipOverlay,
  saveButtonName,
  showError,
  errorMsg,
  toggleErrorHighlight
}: Props) => {
  const saveButton = saveDisabled ? (
    <StyledTooltip overlay={tooltipOverlay} placement="top" mouseLeaveDelay={0}>
      <StyledDisabledButton onMouseEnter={() => toggleErrorHighlight(true)}>
        {saveButtonName}
      </StyledDisabledButton>
    </StyledTooltip>
  ) : (
    <StyledSaveButton
      onMouseEnter={() => toggleErrorHighlight(false)}
      onClick={onSave}
    >
      {saveButtonName}
    </StyledSaveButton>
  );

  const maybeError = showError ? (
    <StyledErrorSpan>
      <StyledErrorIcon src={iconError} />
      {errorMsg}
    </StyledErrorSpan>
  ) : null;

  return (
    <StyledGrid>
      <StyledErrorCell>{maybeError}</StyledErrorCell>
      <StyledButtons>
        <StyledCancelButton onClick={onCancel}>Cancel</StyledCancelButton>
        {saveButton}
      </StyledButtons>
    </StyledGrid>
  );
};

export default AttributeEditButtonGroup;
