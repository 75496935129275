import { useState } from "react";
import hash from "object-hash";
import {
  EditMode,
  AllocationValue,
  EDIT_MODE_FILTER,
  FILTER_TYPE_INCLUDE,
  groupFromFilter,
  groupFromOptionsAndGroup,
  flattenAllocation,
  parseAllocations,
  getPerc
} from "../../quotaHelpers";
import {
  QuotaGroupInput,
  AttributeOptionMap,
  Attribute,
  QuotaCellInput,
  OperatorType
} from "../../types";

export const getSelectedOptions = (oList: OptionCheck) =>
  Object.keys(oList).filter(k => oList[k]);

export const getQuotaGroupInputWithAllocations = (
  map: Map<string, AllocationValue>,
  quotaCells: Array<QuotaCellInput>,
  name: string
) => {
  const parsedMap = parseAllocations(map);
  const nextCells = quotaCells.map<QuotaCellInput>(c => {
    const allocationValue = parsedMap.get(hash(c.quotaNodes[0].options));
    return {
      ...c,
      count: (allocationValue && allocationValue.count) || 0,
      perc: (allocationValue && allocationValue.perc) || 0
    };
  });
  const nextGroup: QuotaGroupInput = {
    quotaCells: nextCells,
    name
  };
  return nextGroup;
};

const mapAllocations = (
  draft: boolean,
  cells: Array<QuotaCellInput>,
  evenly: boolean,
  requiredCompletes: number
): Map<string, AllocationValue> => {
  let cellsToMap = cells;
  if (evenly) {
    cellsToMap = flattenAllocation(cells, requiredCompletes);
  }

  return new Map(
    cellsToMap.map(c => [
      hash(c.quotaNodes[0].options),
      {
        count: draft ? "" : c.count,
        perc: draft ? "" : getPerc(c.count, requiredCompletes)
      }
    ])
  );
};

export const getCurrQuotaGroup = (
  attribute: Attribute,
  quotaGroupInput: QuotaGroupInput,
  selectedOptions: string[],
  requiredCompletes: number
) =>
  quotaGroupInput.quotaCells.length
    ? groupFromOptionsAndGroup(
        attribute.id,
        selectedOptions,
        quotaGroupInput,
        requiredCompletes
      )
    : groupFromFilter(
        {
          attributeId: attribute.id,
          options: selectedOptions
        },
        requiredCompletes,
        quotaGroupInput.name
      );

const unGroupOptions = (
  attribute: Attribute,
  quotaGroupInput: QuotaGroupInput,
  nodeOptions: string[],
  requiredCompletes: number
): QuotaGroupInput => {
  const quotaCellsWithoutOptions = quotaGroupInput.quotaCells.filter(
    c => c.quotaNodes[0].options !== nodeOptions
  );
  const groupWithoutOptions = {
    name: quotaGroupInput.name,
    quotaCells: quotaCellsWithoutOptions
  };

  const groupWithSegregateOptions = groupFromFilter(
    {
      attributeId: attribute.id,
      options: nodeOptions
    },
    requiredCompletes,
    quotaGroupInput.name
  );

  return { ...groupWithoutOptions, ...groupWithSegregateOptions };
};

const updateQuotaGroupInput = (
  prevGroup: QuotaGroupInput,
  allocationMap: Map<string, AllocationValue>
): QuotaGroupInput => {
  const newQuotaCells = prevGroup.quotaCells.map(c => {
    const allocationMapKey = hash(c.quotaNodes[0].options);
    const allocationValue = allocationMap.get(allocationMapKey);
    const perc = (allocationValue && allocationValue.perc) || 0;
    const count = (allocationValue && allocationValue.count) || 0;
    return { ...c, perc, count };
  });
  return { ...prevGroup, quotaCells: newQuotaCells };
};

type OptionCheck = {
  [id: string]: boolean;
};

type AttributeEditState = {
  optionsCheckList: OptionCheck;
  search: string;
  showSelectedOptions: boolean;
  editMode: EditMode;
  allocationMap: Map<string, AllocationValue>;
  filterType: OperatorType;
  quotaGroupInput: QuotaGroupInput;
};

const initialState = (
  draft: boolean,
  options: AttributeOptionMap,
  initialSelectedOptions: string[],
  initialQuotaGroupInput: QuotaGroupInput,
  editMode: EditMode,
  filterType: OperatorType,
  requiredCompletes: number
): AttributeEditState => {
  const nonNullOptionKeys = Object.keys(options) || [];

  let initialOptionsMap: { [key: string]: boolean } = {};
  initialSelectedOptions.forEach(option => (initialOptionsMap[option] = true));

  let optionsCheckList: { [key: string]: boolean } = {};
  for (let i = 0; i < nonNullOptionKeys.length; i++) {
    const key = nonNullOptionKeys[i].toString();
    optionsCheckList[key] = initialOptionsMap[key];
  }

  const allocationMap = mapAllocations(
    draft,
    initialQuotaGroupInput.quotaCells,
    false,
    requiredCompletes
  );

  return {
    optionsCheckList: optionsCheckList,
    search: "",
    showSelectedOptions: false,
    editMode: editMode || EDIT_MODE_FILTER,
    allocationMap: allocationMap,
    filterType: filterType || FILTER_TYPE_INCLUDE,
    quotaGroupInput: initialQuotaGroupInput
  };
};

type Props = {
  attribute: Attribute;
  draft: boolean;
  options: AttributeOptionMap;
  initialSelectedOptions: string[];
  initialQuotaGroupInput: QuotaGroupInput;
  initialEditMode: EditMode;
  initialOperatorType: OperatorType;
  requiredCompletes: number;
};

const useAttributeEdit = ({
  attribute,
  draft,
  options,
  initialSelectedOptions,
  initialQuotaGroupInput,
  initialEditMode,
  initialOperatorType,
  requiredCompletes
}: Props) => {
  const [state, setState] = useState<AttributeEditState>(
    initialState(
      draft,
      options,
      initialSelectedOptions,
      initialQuotaGroupInput,
      initialEditMode,
      initialOperatorType,
      requiredCompletes
    )
  );
  const [showAllocationErrors, setShowAllocationErrors] = useState<boolean>(
    false
  );
  const [shouldUpdateAllocRowVal, setShouldUpdateAllocRowVal] = useState(false);

  const toggleUpdateAllocRowVal = (b: boolean) => setShouldUpdateAllocRowVal(b);

  const toggleEditMode = (editMode: EditMode) => {
    setState(prevState => {
      return {
        ...prevState,
        editMode: editMode
      };
    });
  };

  const toggleOption = (
    optionId: string,
    allocationMapKey: string,
    nodeOptions: string[]
  ) => {
    const { optionsCheckList, quotaGroupInput, allocationMap } = state;
    const optionCheck = !optionsCheckList[optionId];

    const newQuotaGroupInput =
      !optionCheck && nodeOptions.length > 1
        ? unGroupOptions(
            attribute,
            quotaGroupInput,
            nodeOptions,
            requiredCompletes
          )
        : quotaGroupInput;

    const newAllocationMap = new Map(allocationMap);
    if (optionCheck) {
      newAllocationMap.set(allocationMapKey, { count: "", perc: "" });
    } else {
      newAllocationMap.delete(allocationMapKey);
    }

    setState(prevState => {
      return {
        ...prevState,
        optionsCheckList: {
          ...optionsCheckList,
          [optionId]: optionCheck
        },
        quotaGroupInput: newQuotaGroupInput,
        allocationMap: newAllocationMap
      };
    });
  };

  const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setState(prevState => {
      return { ...prevState, search: value };
    });
  };

  const clearSearch = () => {
    setState(prevState => {
      return { ...prevState, search: "" };
    });
  };

  const toggleAll = (filteredOptions: string[]) => {
    // filteredOptions is an array of ids matching filtered state.optionsCheckList
    const { optionsCheckList, quotaGroupInput } = state;
    const allSelected = filteredOptions.every(key => optionsCheckList[key]);

    const newOptions = {} as OptionCheck;
    filteredOptions.forEach(val => {
      newOptions[val] = !allSelected;
    });

    const newQuotaGroupInput = getCurrQuotaGroup(
      attribute,
      quotaGroupInput,
      filteredOptions,
      requiredCompletes
    );

    const allocationMap = mapAllocations(
      true,
      newQuotaGroupInput.quotaCells,
      false,
      requiredCompletes
    );

    setState(prevState => {
      return {
        ...prevState,
        optionsCheckList: {
          ...state.optionsCheckList,
          ...newOptions
        },
        allocationMap: allocationMap
      };
    });
  };

  const toggleViewSelectedOptions = () => {
    setState(prevState => {
      return {
        ...prevState,
        showSelectedOptions: !state.showSelectedOptions
      };
    });
  };

  const handleSplitEvenly = (
    selectedQuotaGroupInput: QuotaGroupInput,
    isRange = false
  ) => {
    const newAllocationMap = mapAllocations(
      false,
      selectedQuotaGroupInput.quotaCells,
      true,
      requiredCompletes
    );

    setState(prevState => {
      const { quotaGroupInput } = prevState;
      return {
        ...prevState,
        allocationMap: newAllocationMap,
        quotaGroupInput: isRange
          ? updateQuotaGroupInput(quotaGroupInput, newAllocationMap)
          : quotaGroupInput
      };
    });
    setShouldUpdateAllocRowVal(true);
  };

  const toggleFilterType = (filterType: OperatorType) => {
    setState(prevState => {
      return {
        ...prevState,
        filterType: filterType
      };
    });
  };

  const updateAllocationCell = (index: string, value: AllocationValue) => {
    setState(prevState => {
      const { allocationMap } = prevState;
      const newAllocationMap = new Map(allocationMap);
      newAllocationMap.set(index, { count: value.count, perc: value.perc });

      return {
        ...prevState,
        allocationMap: newAllocationMap
      };
    });
  };

  const deleteAllocationCell = (index: string) => {
    setState(prevState => {
      const newAllocationMap = new Map(prevState.allocationMap);
      newAllocationMap.delete(index);

      return {
        ...prevState,
        allocationMap: newAllocationMap
      };
    });
  };

  const updateGroup = (newQuotaGroupInput: QuotaGroupInput) => {
    setState(prevState => {
      return {
        ...prevState,
        quotaGroupInput: newQuotaGroupInput
      };
    });
  };

  const updateGroupWithAllocations = (newQuotaGroupInput: QuotaGroupInput) => {
    const newAllocationMap = mapAllocations(
      draft,
      newQuotaGroupInput.quotaCells,
      false,
      requiredCompletes
    );

    setState(prevState => {
      return {
        ...prevState,
        quotaGroupInput: newQuotaGroupInput,
        allocationMap: newAllocationMap
      };
    });
  };

  return {
    state,
    toggleEditMode,
    toggleOption,
    handleSearch,
    clearSearch,
    toggleAll,
    toggleViewSelectedOptions,
    handleSplitEvenly,
    toggleFilterType,
    updateAllocationCell,
    updateGroup,
    updateGroupWithAllocations,
    setShowAllocationErrors,
    showAllocationErrors,
    deleteAllocationCell,
    shouldUpdateAllocRowVal,
    toggleUpdateAllocRowVal
  };
};

export default useAttributeEdit;
