import gql from "graphql-tag";
import { fragments as lineItemFragments } from "./lineitem";

const actionsFragment = gql`
  fragment SurveyActionsFragment on Survey {
    actions {
      kind
      metadata {
        label
        visible
        enabled
        description
      }
    }
  }
`;

const localeLinksFragment = gql`
  fragment SurveyLocaleLinksFragment on Survey {
    localeLinks {
      localeName
      countryISOCode
      languageISOCode
      liveLink
      testLink
      entryLink
    }
  }
`;

export const fragments = {
  actions: actionsFragment,
  localeLinks: localeLinksFragment,
  survey: gql`
    fragment SurveyFragment on Survey {
      name
      id
      liveLink
      liveLinkErrors {
        error {
          code
          message
        }
        paramList
      }
      testLink
      testLinkErrors {
        error {
          code
          message
        }
        paramList
      }
      authoringPlatform
      entryLink {
        baseLink
        countryEntryLinks {
          countryISOCode
          entryLink
        }
      }
      surveyKey
      hasCustomParams
      customParams {
        psid
        pid
        securityKey
      }
      testingNotes
      dataAppends {
        countryISOCode
        appends {
          attributeId
          attributeName
          customKey
        }
      }
      formattedTestLink
      formattedLiveLink
      tests {
        ID
        status
        latest
        disposition
        testedAt
      }
      exitLinks {
        complete
        disqualified
        overQuota
      }
      editLink
      ...SurveyLocaleLinksFragment
      ...SurveyActionsFragment
    }
    ${localeLinksFragment}
    ${actionsFragment}
  `,
  surveyLineItem: gql`
    fragment SurveyLineItem on LineItem {
      state
      extLineItemId
      extProjectId
      title
      survey {
        surveyId
        countryISOCode
        languageISOCode
      }
      countryISOCode
    }
  `
};

export const refreshLocaleLinks = gql`
  query SurveyLocaleLinks($extProjectId: ID!, $surveyID: ID!) {
    project(extProjectId: $extProjectId) {
      extProjectId
      survey(id: $surveyID) {
        id
        ...SurveyLocaleLinksFragment
      }
    }
  }
  ${localeLinksFragment}
`;

export const surveysQuery = gql`
  query SurveysData($extProjectId: ID!) {
    project(extProjectId: $extProjectId) {
      extProjectId
      title
      state
      surveys {
        ...SurveyFragment
      }
      lineItems {
        ...SurveyLineItem
      }
      securitykey1
      securitykey2
      securityLevel
      ...ProjectActionsFragment
    }
  }
  ${fragments.survey}
  ${fragments.surveyLineItem}
  ${lineItemFragments.projectActions}
`;

export const dataAppendAttributes = gql`
  query DataAppendVariables {
    dataAppendAttributes {
      country {
        ID
        isoCode
        name
        supportedLanguages {
          ID
          isoCode
          name
        }
      }
      attributes {
        id
        countryISOCode
        name
      }
    }
  }
`;

export const createSurvey = gql`
  mutation createSurvey($input: CreateSurveyInput!) {
    createSurvey(input: $input) {
      extProjectId
      securitykey1
      securitykey2
      securityLevel
      state
      title
      lineItems {
        ...SurveyLineItem
      }
      surveys {
        ...SurveyFragment
      }
      ...ProjectActionsFragment
    }
  }
  ${fragments.survey}
  ${fragments.surveyLineItem}
  ${lineItemFragments.projectActions}
`;

export const updateSurvey = gql`
  mutation updateSurvey($input: UpdateSurveyInput!) {
    updateSurvey(input: $input) {
      extProjectId
      surveys {
        ...SurveyFragment
      }
    }
  }
  ${fragments.survey}
`;

export const deleteSurvey = gql`
  mutation deleteSurvey($input: DeleteSurveyInput!) {
    deleteSurvey(input: $input) {
      extProjectId
      title
      surveys {
        ...SurveyFragment
      }
    }
  }
  ${fragments.survey}
`;

export const externalSurveysQuery = gql`
  query externalSurveys(
    $authoringPlatform: AuthoringPlatform!
    $pageNumber: Int!
    $active: Boolean!
    $searchQuery: String
  ) {
    externalSurveys(
      authoringPlatform: $authoringPlatform
      pageNumber: $pageNumber
      active: $active
      searchQuery: $searchQuery
    ) {
      authoringPlatform
      totalSurveys
      hasNext
      nextPage
      surveys {
        id
        name
        createdAt
        modifiedAt
        status
        mappedProject {
          projectID
          projectName
          surveyID
        }
      }
    }
  }
`;
