import { useState, useEffect } from "react";
import useGetExternalSurveys, { Data } from "./useGetExternalSurveys";
import { AuthoringPlatform } from "../../types";
import { NetworkStatus } from "apollo-client";

const useExternalSurveysPaging = (
  authoringPlatform: AuthoringPlatform,
  active: boolean,
  searchQuery?: string
) => {
  const [pageNumber, setPageNumber] = useState(2);
  useEffect(() => {
    setPageNumber(2);
  }, [searchQuery]);

  const {
    data,
    fetchMore,
    error,
    loading,
    refetch,
    networkStatus
  } = useGetExternalSurveys(authoringPlatform, active, searchQuery);

  const hasNext =
    data && data.externalSurveys ? data.externalSurveys.hasNext : false;
  const surveys =
    data && data.externalSurveys ? data.externalSurveys.surveys : [];
  const totalSurveys =
    data && data.externalSurveys ? data.externalSurveys.totalSurveys : 0;

  const refetching = networkStatus === NetworkStatus.refetch;

  const addMoreSurveys = () => {
    fetchMore({
      variables: { pageNumber },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!prev || !fetchMoreResult) {
          return prev;
        }

        const newData = fetchMoreResult.externalSurveys;
        const previousSurveys = prev.externalSurveys.surveys;
        const allSurveys = [...previousSurveys, ...newData.surveys];

        const newSurveyList: Data = {
          ...prev,
          externalSurveys: {
            authoringPlatform: newData.authoringPlatform,
            totalSurveys: newData.totalSurveys,
            hasNext: newData.hasNext,
            nextPage: newData.nextPage,
            surveys: allSurveys,
            __typename: newData.__typename
          }
        };

        setPageNumber(newData.nextPage);
        return newSurveyList;
      }
    });
  };

  return {
    surveys,
    totalSurveys,
    hasNext,
    addMoreSurveys,
    pageNumber,
    error,
    fetching: loading,
    refetching,
    refetch
  };
};

export default useExternalSurveysPaging;
