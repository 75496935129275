import React from "react";
import Godzilla from "./PageError";

class GenericErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }
  render() {
    const errorRender =
      this.props.errorRender === undefined ? (
        <Godzilla />
      ) : (
        this.props.errorRender
      );
    if (this.state.error) return errorRender;
    return React.Children.only(this.props.children);
  }
}

export default GenericErrorBoundary;
