import React from "react";
import styled from "styled-components";
import Padlock from "../art/padlock.svg";
import { STATUS_PAGE_URL } from "../networkHelpers";
import { SAMPLIFY } from "../constants/titles";

const Outer = styled.div`
  box-sizing: border-box;
  position: absolute;
  width: 433px;
  left: 50%;
  top: 50%;
  background: #ffffff;
  border-radius: 10px;
  margin: auto;
  transform: translate(-50%, -50%);
  padding: 3.2rem 5.4rem 6.2rem 3.6rem;
  color: #4e525c;
`;

const StyledIcon = styled.img`
  margin-right: 1.4rem;
`;

const StyledMessage = styled.p`
  margin: 0;
`;

const StyledHeader = styled.p`
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 3rem;
`;

const StyledAnchor = styled.a`
  color: var(--primary-color-2);
  font-weight: 600;
`;

const MaintenanceMessage = () => (
  <Outer>
    <StyledHeader>
      <StyledIcon src={Padlock} alt="" />
      {SAMPLIFY} under maintenance
    </StyledHeader>
    <StyledMessage>
      {SAMPLIFY} is experiencing a service interruption. Please see{" "}
      <StyledAnchor
        href={STATUS_PAGE_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        {STATUS_PAGE_URL}
      </StyledAnchor>{" "}
      for more information.
    </StyledMessage>
  </Outer>
);

export default MaintenanceMessage;
