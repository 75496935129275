import gql from "graphql-tag";
import { fragments as lineItemFragments } from "./lineitem";
import { fragments as surveyFragments } from "./surveys";

export const fragments = {
  tableRow: gql`
    fragment TableRow on Project {
      extProjectId
      title
      jobNumber
      state
      stateLastUpdatedAt
      createdAt
      updatedAt
      report {
        avgCpi
        stats {
          completes {
            intValue
          }
          remainingCompletes {
            intValue
          }
        }
        cost {
          incurredCost
          estimatedCost
          currency
        }
      }
      lineItems {
        extProjectId
        extLineItemId
        title
        indicativeIncidence
        countryISOCode
        state
        report {
          sources {
            ID
            name
          }
          stats {
            conversion {
              ...StatFragment
            }
            completes {
              ...StatFragment
            }
            remainingCompletes {
              ...StatFragment
            }
            attempts {
              ...StatFragment
            }
          }
          cost {
            estimatedCost
            costPerUnit
            incurredCost
          }
        }
        ...ActionsFragment
      }
      surveys {
        id
        authoringPlatform
      }
      billing {
        ID
        type
        billingDate
      }
      ...ProjectActionsFragment
      viewOnly
    }
    ${lineItemFragments.stat}
    ${lineItemFragments.actions}
    ${lineItemFragments.projectActions}
  `
};

export const dashboardTableQuery = gql`
  query projects(
    $startDate: String
    $endDate: String
    $searchQuery: String
    $pageNumber: Int
    $statuses: [ProjectState!]
    $scope: String
  ) {
    projects(
      startDate: $startDate
      endDate: $endDate
      searchQuery: $searchQuery
      pageNumber: $pageNumber
      statuses: $statuses
      scope: $scope
    )
      @connection(
        key: "dashboardProjects"
        filter: ["searchQuery", "startDate", "endDate", "statuses", "scope"]
      ) {
      totalProjects
      hasNext
      projectsList {
        ...TableRow
      }
    }
  }
  ${fragments.tableRow}
`;

export const invoicesTableQuery = gql`
  query invoicingProjects(
    $startDate: String
    $endDate: String
    $searchQuery: String
    $pageNumber: Int
    $statuses: [ProjectState!]
    $scope: String
  ) {
    invoicingProjects(
      startDate: $startDate
      endDate: $endDate
      searchQuery: $searchQuery
      pageNumber: $pageNumber
      statuses: $statuses
      scope: $scope
    )
      @connection(
        key: "invoiceProjects"
        filter: ["searchQuery", "startDate", "endDate", "statuses", "scope"]
      ) {
      totalInvoices
      hasNext
      invoices {
        name
        type
        projects {
          extProjectId
          title
          jobNumber
          state
          stateLastUpdatedAt
          createdAt
          updatedAt
          report {
            avgCpi
            stats {
              completes {
                intValue
              }
              remainingCompletes {
                intValue
              }
            }
            cost {
              incurredCost
              estimatedCost
              currency
            }
          }
          lineItems {
            extProjectId
            extLineItemId
            title
            indicativeIncidence
            countryISOCode
            state
            report {
              sources {
                ID
                name
              }
              stats {
                conversion {
                  ...StatFragment
                }
                completes {
                  ...StatFragment
                }
                remainingCompletes {
                  ...StatFragment
                }
                attempts {
                  ...StatFragment
                }
              }
              cost {
                estimatedCost
                costPerUnit
                incurredCost
              }
            }
          }
          surveys {
            id
            authoringPlatform
          }
          billing {
            ID
            type
            billingDate
          }
          ...ProjectActionsFragment
          viewOnly
        }
        status
        cost
      }
    }
  }
  ${lineItemFragments.stat}
  ${lineItemFragments.projectActions}
`;

export const projectReport = gql`
  query projectReport($extProjectId: ID!) {
    project(extProjectId: $extProjectId) {
      extProjectId
      report {
        estimatedCost
        completes
        remainingCompletes
        avgCpi
      }
    }
  }
`;

export const exclusions = gql`
  query Exclusions {
    projectExclusionList {
      extProjectId
      title
    }
  }
`;

export const updateRespondentFilters = gql`
  mutation UpdateRespondentFilters($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      extProjectId
      respondentFilters {
        projects {
          list {
            extProjectId
            title
          }
          type
          startDate
          endDate
        }
        dispositions
        schedule {
          startDate
          endDate
          type
          value
        }
      }
    }
  }
`;

export const updateDevices = gql`
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      extProjectId
      devices
    }
  }
`;

export const createProject = gql`
  mutation createProject($title: String!) {
    createProject(input: { title: $title }) {
      extProjectId
    }
  }
`;

export const buyProject = gql`
  mutation buyProject($extProjectId: ID!) {
    buyProject(input: { extProjectId: $extProjectId }) {
      extProjectId
      state
      updatedAt
      lineItems {
        extProjectId
        extLineItemId
        state
        ...ActionsFragment
      }
    }
  }
  ${lineItemFragments.actions}
`;

export const closeProject = gql`
  mutation closeProject($extProjectId: ID!) {
    closeProject(input: { extProjectId: $extProjectId }) {
      extProjectId
      state
      updatedAt
      lineItems {
        extProjectId
        extLineItemId
        state
        ...ActionsFragment
      }
      ...ProjectActionsFragment
    }
  }
  ${lineItemFragments.actions}
  ${lineItemFragments.projectActions}
`;

export const sampleSources = gql`
  query sampleSources($surveyTopics: [String!]!) {
    sampleSources(surveyTopics: $surveyTopics)
  }
`;

export const duplicateProject = gql`
  mutation duplicateProject($input: DuplicateProjectInput!) {
    duplicateProject(input: $input) {
      ...TableRow
    }
  }
  ${fragments.tableRow}
`;

export const updateProjectTopics = gql`
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      extProjectId
      category {
        surveyTopic
      }
      ...ProjectActionsFragment
    }
  }
  ${lineItemFragments.projectActions}
`;

export const projectActions = gql`
  query projectActions($extProjectId: ID!) {
    project(extProjectId: $extProjectId) {
      extProjectId
      state
      ...ProjectActionsFragment
      lineItems {
        extProjectId
        extLineItemId
        state
        ...ActionsFragment
        ...QuoteFragment
        ...LineItemReportFragment
      }
      surveys {
        id
        ...SurveyActionsFragment
      }
    }
  }
  ${lineItemFragments.projectActions}
  ${lineItemFragments.actions}
  ${lineItemFragments.quote}
  ${lineItemFragments.report}
  ${surveyFragments.actions}
`;
