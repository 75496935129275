import React, { useCallback } from "react";
import styled from "styled-components";
import { Button } from "samplify-design";
import {
  correctionDescriptionPlaceholder,
  sampleFileLink,
  sampleFileLinkText
} from "./Constants";
import Dropzone from "./Dropzone";

const StyledTitle = styled.div`
  font-size: 14px;
  color: #696e7b;
  margin-bottom: 8px;
  font-weight: bold;
`;

const StyledDesc = styled.div`
  font-size: 13px;
  color: #696e7b;
`;

const FileBrowser = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0.5em 0;
`;

const FileName = styled.span`
  text-decoration: underline;
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.primary["2"]};
  &:visited {
    color: ${({ theme }) => theme.primary["2"]};
  }
`;

const StyledTextArea = styled.textarea`
  border: 1px solid #ddd;
  border-radius: 3px;
  color: ${({ theme }) => theme.neutralDark["4"]};
  display: block;
  font-size: 13px;
  height: 35px;
  margin: 5px 0 10px;
  padding: 7px 10px;
  resize: none;
  width: 96.5%;
  &:focus {
    outline: none;
    border-color: transparent;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.primary["2"]};
  }
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #9599a4;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #9599a4;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: #9599a4;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: #9599a4;
  }
`;

const StyledUpload = styled(Button)`
  margin: 0;
  background-color: #696e7b;
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.neutralLight["7"]};
  padding: 0 0 15px;
`;

const StyledDeleteFile = styled.span`
  color: #f76e5c;
  text-decoration: none;
  padding: 0 10px;
  cursor: pointer;
`;

const FileContainer = styled.div`
  font-size: 13px;
  color: #696e7b;
  margin: 15px 0;
`;

const checkLongFileName = (fileName: string) => {
  const length = fileName.length;
  return length > 53
    ? `${fileName.substring(0, 25)}...${fileName.substring(length - 25)}`
    : fileName;
};

interface PropTypes {
  description: string;
  fileName: string;
  deleteFile: () => void;
  onDescriptionChange: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  onFileChange: (file: File) => void;
}

const RequestCorrection = ({
  onDescriptionChange,
  onFileChange,
  description,
  fileName,
  deleteFile
}: PropTypes) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => onFileChange(acceptedFiles[0]),
    [onFileChange]
  );
  const uploadBtn = (
    <FileBrowser>
      <StyledUpload>Upload File</StyledUpload>
    </FileBrowser>
  );
  const maybeFileName = fileName && (
    <FileContainer>
      <FileName>{checkLongFileName(fileName)}</FileName>
      <StyledDeleteFile onClick={deleteFile}>Delete</StyledDeleteFile>
    </FileContainer>
  );
  const maybeDropzone = !fileName && (
    <Dropzone onDrop={onDrop} accept={".xls, .xlsx"} uploadBtn={uploadBtn} />
  );
  return (
    <Wrapper>
      <StyledTitle>Request Correction</StyledTitle>
      <StyledDesc>
        If changes in completes are needed, upload the PIDs in question.{" "}
        <StyledLink href={sampleFileLink}>{sampleFileLinkText}</StyledLink>
      </StyledDesc>
      {maybeFileName}
      {maybeDropzone}
      <StyledTextArea
        placeholder={correctionDescriptionPlaceholder}
        value={description}
        onChange={onDescriptionChange}
      />
    </Wrapper>
  );
};

export default RequestCorrection;
