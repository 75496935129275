import styled from "styled-components";

const NumberUnit = styled.span`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding-right: 0.8rem;
  color: #9b9b9b;
  pointer-events: none;
`;

export default NumberUnit;
