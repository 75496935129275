import { useState, useEffect } from "react";

const useServiceWorkerUpdated = () => {
  const [installed, setInstalled] = useState(false);
  useEffect(() => {
    // Terminate the effect early if service workers are unavailable.
    if (process.env.NODE_ENV !== "production") return;
    if (!("serviceWorker" in navigator)) return;
    const publicUrl = new URL(
      process.env.PUBLIC_URL,
      window.location.toString()
    );
    if (publicUrl.origin !== window.location.origin) return;

    // Get the service worker and its registration.
    const sw = navigator.serviceWorker;

    sw.getRegistration().then(reg => {
      if (reg === undefined) return;
      const installingWorker = reg.installing;
      if (installingWorker === null) return;
      installingWorker.onstatechange = () => {
        if (installingWorker.state === "installed") {
          if (navigator.serviceWorker.controller) {
            // At this point, the old content will have been purged and
            // the fresh content will have been added to the cache.
            setInstalled(true);
          }
        }
      };
    });
  }, []);

  return installed;
};

export default useServiceWorkerUpdated;
