import gql from "graphql-tag";
import { fragments as lineItemFragments } from "./lineitem";
import { fragments as surveyFragments } from "./surveys";

const respondentsQuery = gql`
  query RespondentsData($extProjectId: ID!) {
    project(extProjectId: $extProjectId) {
      extProjectId
      title
      devices
      state
      jobNumber
      category {
        surveyTopic
        studyType
        studyRequirements
      }
      exclusions {
        type
        list {
          extProjectId
          title
        }
        startDate
        endDate
      }
      respondentFilters {
        projects {
          list {
            extProjectId
            title
          }
          type
          startDate
          endDate
        }
        dispositions
        schedule {
          startDate
          endDate
          type
          value
        }
      }
      report {
        state
        avgCpi
        stats {
          completes {
            ...StatFragment
          }
          conversion {
            ...StatFragment
          }
          remainingCompletes {
            ...StatFragment
          }
        }
        cost {
          incurredCost
          estimatedCost
          currency
        }
      }
      surveys {
        name
        id
        authoringPlatform
        testLink
        liveLink
        tests {
          ID
          status
          latest
          disposition
          testedAt
        }
        ...SurveyLocaleLinksFragment
        ...SurveyActionsFragment
      }
      lineItems {
        ...LineItemFragment
      }
      ...ProjectActionsFragment
      dynataManaged
    }
  }
  ${lineItemFragments.lineItem}
  ${lineItemFragments.stat}
  ${surveyFragments.actions}
  ${surveyFragments.localeLinks}
  ${lineItemFragments.projectActions}
`;

export default respondentsQuery;
