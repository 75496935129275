const HELP_URL = "https://help.dynata.com/s/product-pages/samplify";

export const SAMPLIFY_LOGO_LINK =
  "https://platform-resources.dev.pe.dynata.com/samplify.svg";

export const STATUS_PAGE_URL = "https://status.dynata.com/";

export const appendLoginParams = url => {
  const params = {
    scope: "openid",
    response_type: "code",
    client_id: "dynataplatform",
    redirect_uri: `${window.origin}/callback`
  };
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
  return url;
};

export const getHost = () =>
  window.__SERVER_VARS__.attributes.hostURL || process.env.REACT_APP_HOST_URL;

const getKeycloak = () =>
  window.__SERVER_VARS__.attributes.keycloakURL ||
  process.env.REACT_APP_KEYCLOAK_URL;

export const getEnv = () => window.__SERVER_VARS__.attributes.nodeENV || "dev";

const LOGIN_ROUTE = "/auth/realms/pe/protocol/openid-connect/auth";
const ACCOUNT_ROUTE = "/auth/realms/pe/account/";
const PASSWORD_ROUTE = "/auth/realms/pe/account/password";

export const getLoginURL = () => new URL(`${getKeycloak()}${LOGIN_ROUTE}`);
export const getLogoutURL = () => new URL(`${getHost()}/logout`);
export const getAccountURL = () => new URL(`${getKeycloak()}${ACCOUNT_ROUTE}`);
export const getPasswordURL = () =>
  new URL(`${getKeycloak()}${PASSWORD_ROUTE}`);
export const getCodeURL = () => new URL(`${getHost()}/code`);
export const getGQLURL = () => new URL(`${getHost()}/graphql`);
export const getDownloadURL = () => new URL(`${getHost()}/downloadreport`);
export const getReconcileURL = () => new URL(`${getHost()}/reconcile`);
export const getAppStatusURL = () => new URL(`${getHost()}/status`);
export const getDownloadMappingFile = () => new URL(`${getHost()}/surveys`);
export const getHealthyURL = () => new URL(`${getHost()}/healthy`);

/**
 * Builds a body string for a login request.
 */
export const getLoginBody = code => {
  const body = { code, redirectURI: `${window.origin}/callback` };
  return JSON.stringify(body);
};

/**
 * Sends a POST request to log the user in.
 * @param {string} body The body string of the login request. Should contain code and redirectURI parameters.
 * @returns {Promise}
 */
export const login = code => {
  return fetch(getCodeURL(), {
    method: "POST",
    body: getLoginBody(code)
  }).then(response => {
    if (response.status === 400) {
      window.location.replace(appendLoginParams(getLoginURL()));
    }
    if (response.status === 401) {
      throw new Error("NOT AUTHORIZED");
    }
    if (response.ok) {
      const sessionId = response.headers.get("Authorization");
      if (!sessionId) throw new Error("No sessionId from login response");
      setSessionId(sessionId);
      localStorage.setItem("sessionId", sessionId);
      if (!localStorage.getItem("showProjectRestrictionModal")) {
        localStorage.setItem("showProjectRestrictionModal", "true");
      }
      window.location.replace(window.origin);
    }
  });
};

export const reconcile = (message, file, extProjectId) => {
  const sessionId = getSessionId();
  const headers = new Headers({
    Authorization: sessionId || ""
  });
  const url = getReconcileURL();
  url.searchParams.append("extprojectid", extProjectId);
  const data = new FormData();
  data.set("message", message);
  data.append("file", file);
  return fetch(url, {
    mode: "cors",
    method: "POST",
    headers: headers,
    body: data
  });
};

export const logout = () => {
  const sessionId = getSessionId();
  const headers = new Headers({ Authorization: sessionId || "" });
  return fetch(getLogoutURL(), {
    mode: "cors",
    method: "POST",
    headers: headers
  }).then(logoutResponse => {
    clearSessionId();
    window.location.replace(window.location.origin);
  });
};

export const getAppStatus = () => fetch(getAppStatusURL(), { method: "GET" });
export const getHealthy = () => {
  const sessionId = getSessionId();
  const headers = new Headers({ Authorization: sessionId || "" });
  return fetch(getHealthyURL(), {
    mode: "cors",
    method: "GET",
    headers: headers
  });
};

export const setSessionId = id => localStorage.setItem("sessionId", id);
export const clearSessionId = () => localStorage.removeItem("sessionId");
export const getSessionId = () => localStorage.getItem("sessionId");

export const surveytoolURL = hash => {
  var hashRoute = !!hash ? hash : "new";
  return new URL(`${window.location.origin}/surveytool/${hashRoute}/build`);
};

export default HELP_URL;
