import React, { Fragment } from "react";
import styled from "styled-components";
import { Toggle } from "samplify-design";
import { StyledGrid } from "./AttributeEditStyles";

const StyledSelectView = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
`;

const StyledSpan = styled.span`
  font: normal 600 1.2rem/1.7rem ${props => props.theme.fontFamily};
  color: #696e7b;
`;

const StyledToggle = styled(Toggle)`
  padding-left: 0.8rem;
  color: white;
  &.react-toggle--checked .react-toggle-track {
    background-color: #3ea8dd;
  }
  .react-toggle-track {
    font-size: 0.9rem;
    font-weight: 400;
    width: 40px;
    height: 20px;
  }
  .react-toggle-thumb {
    left: 10px;
    width: 16px;
    height: 16px;
  }
  &.react-toggle--checked .react-toggle-thumb {
    left: 30px;
  }
  .react-toggle-track-x {
    top: 10px;
  }
  .react-toggle-track-check {
    opacity: 1;
    left: 12px;
    top: 10px;
  }
`;

type CustomSpanProps = {
  showAllocationError: boolean;
} & React.HTMLProps<HTMLSpanElement>;

const CustomSpan = ({ showAllocationError, ...rest }: CustomSpanProps) => (
  <span {...rest} />
);

const StyledAllocSpan = styled(CustomSpan)`
  font-weight: 600;
  color: ${props => (props.showAllocationError ? "#f76e5c" : "#696E7B")};
`;

const StyledCompletesSpan = styled(CustomSpan)`
  color: ${props => (props.showAllocationError ? "#f76e5c" : "#696E7B")};
`;

type Props = {
  showSelectedOnlyToggle: boolean;
  allocationOn: boolean;
  showAllocationError: boolean;
  totalPerc: number;
  totalCount: number;
  requiredCompletes: number;
  toggleViewSelectedOptions?: () => void;
};

const AttributeEditFooter = ({
  showSelectedOnlyToggle,
  allocationOn,
  showAllocationError,
  totalPerc,
  totalCount,
  requiredCompletes,
  toggleViewSelectedOptions
}: Props) => {
  return (
    <StyledSelectView>
      <StyledGrid showAllocationView={allocationOn}>
        <Flex>
          {showSelectedOnlyToggle ? (
            <Fragment>
              <StyledSpan>View Selected Only</StyledSpan>
              <StyledToggle
                onChange={toggleViewSelectedOptions}
                icons={{ checked: "On", unchecked: "Off" }}
              />
            </Fragment>
          ) : null}
        </Flex>
        {allocationOn ? (
          <Flex>
            <StyledAllocSpan showAllocationError={showAllocationError}>
              {totalPerc}%
            </StyledAllocSpan>
            <span style={{ marginLeft: "0.5rem" }}>
              (
              <StyledCompletesSpan showAllocationError={showAllocationError}>
                {totalCount}
              </StyledCompletesSpan>{" "}
              out of {requiredCompletes})
            </span>
          </Flex>
        ) : null}
      </StyledGrid>
    </StyledSelectView>
  );
};

export default AttributeEditFooter;
