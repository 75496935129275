import { useQuery } from "react-apollo-hooks";
import userQuery from "../lib/queries/user";
import { UserInfo } from "../types";

export type UserData = {
  userInfo: UserInfo;
};

const useFetchUserInfo = () => {
  return useQuery<UserData>(userQuery);
};

export default useFetchUserInfo;
