import React from "react";
import styled from "styled-components";
import { Spinner } from "samplify-design";

const Outer = styled.div`
	text-align: center;
	font-size: 5rem;
	margin-top: 2rem;
`;

interface Props {
	error?: Error;
}

const PageLoading = ({ error }: Props) => {
	if (error) throw error;
	return (
		<Outer>
			<Spinner />
		</Outer>
	);
};

export default PageLoading;