import React from "react";
import styled from "styled-components";

const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  margin: 0;
`;

const BaseCheckbox = (props: any) => {
  const { children, className, ...rest } = props;
  return (
    <label className={className}>
      <HiddenInput type="checkbox" {...rest} />
      <svg viewBox="0 0 2 2" xmlns="http://www.w3.org/2000/svg">
        <rect
          className="sd-check-focus-rect"
          x="0.025"
          y="0.025"
          width="1.95"
          height="1.95"
          rx="0.45"
          ry="0.45"
        />
        <rect
          className="sd-check-rect"
          x="0.25"
          y="0.25"
          width="1.5"
          height="1.5"
          rx="0.3"
          ry="0.3"
          strokeWidth="0.1"
        />
        <polyline
          points="1.3,1.3 0.7,0.7 1,1 1.3,0.7 0.7,1.3"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {children}
    </label>
  );
};

export const RedCheckbox = styled(BaseCheckbox)`
  > ${HiddenInput}:first-child {
    &:active + svg rect.sd-check-rect {
      fill: ${props => props.theme.neutralLight["5"]};
    }
    &:focus + svg rect.sd-check-focus-rect {
      transform: scale(1);
    }
  }
  > ${HiddenInput}:checked + svg polyline {
    transform: scale(1);
  }
  > ${HiddenInput}:checked + svg rect.sd-check-rect {
    fill: #f76e5c;
  }
  > svg {
    font-size: 1em;
    height: 1.5em;
    width: 1.5em;
    vertical-align: middle;
    &:not(:last-child) {
      margin-right: 0.5em;
    }
    rect.sd-check-rect {
      fill: ${props => props.theme.neutralLight["7"]};
      stroke: #dddddd;
    }
    rect.sd-check-focus-rect {
      fill: ${props => props.theme.focusA};
      transform: scale(0.8);
      transform-origin: center;
      transition: transform 0.125s;
    }
    polyline {
      stroke: #ffffff;
      fill: none;
      transform: scale(0);
      transform-origin: center;
      transition: transform 0.125s;
    }
  }
`;
