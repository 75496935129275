import React, { Fragment } from "react";
import { getOptionText } from "../../quotaHelpers";
import { AttributeType, AttributeOptionMap } from "../../types";

type Props = {
  optionString: string;
  optionMap: AttributeOptionMap;
  attributeType: AttributeType;
};

const Option = (props: Props) => (
  <Fragment>{getOptionText(props) || null}</Fragment>
);

export default Option;
