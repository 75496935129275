import React from "react";

const CellSelect = ({ cells, update }) => (
  <React.Fragment>
    <h1>Cells</h1>
    <label>
      <input
        type="radio"
        value="text"
        checked={cells === "text"}
        onChange={update("cells")}
      />
      Actual Text
    </label>
    <label>
      <input
        type="radio"
        value="numeric"
        checked={cells === "numeric"}
        onChange={update("cells")}
      />
      Numeric Value
    </label>
  </React.Fragment>
);

export default CellSelect;
