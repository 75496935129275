import axios from "axios";
import { getHost } from "./networkHelpers";

export const getInvoicePDF = (
  extprojectid: string,
  changeLoading: (b: boolean) => void,
  toastManager?: any
) => {
  const headers = {
    Authorization: localStorage.getItem("sessionId")
  };
  const params = {
    extprojectid: extprojectid
  };
  changeLoading(true);
  axios
    .get(`${getHost()}/invoice`, {
      responseType: "blob",
      headers,
      params
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${extprojectid}.pdf`);
      link.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(error => {
      if (toastManager) {
        toastManager.add(`Something went wrong: ${error.message}`, {
          appearance: "error"
        });
      } else {
        alert(`Something went wrong: ${error.message}`);
      }
    })
    .finally(() => changeLoading(false));
};

export const getInvoiceSummaryPDF = (
  extprojectid: string,
  changeLoading: (b: boolean) => void
) => {
  const headers = {
    Authorization: localStorage.getItem("sessionId")
  };
  const params = {
    extprojectid: extprojectid
  };
  changeLoading(true);
  axios
    .get(`${getHost()}/invoice/summary`, {
      responseType: "blob",
      headers,
      params
    })
    .then(response => {
      const header = response.headers["content-disposition"];
      const headerArr = header.split("filename=");

      if (headerArr.lenght < 2) return alert(`Couldn't find file name`);
      const filename = headerArr[1];
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      link.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(err => alert(`Something went wrong: ${err.message}`))
    .finally(() => changeLoading(false));
};
