import * as FullStory from "@fullstory/browser";
import { useEffect } from "react";

const FullStoryIdentifier = ({ user }) => {
  useEffect(() => {
    if (user && user.userInfo && process.env.NODE_ENV === "production") {
      const userId = user.userInfo.email;
      FullStory.identify(userId, {
        displayName: user.userInfo.fullName,
        companyName: user.userInfo.companyName
      });
    }
  }, [user]);

  return null;
};

export default FullStoryIdentifier;
