import React from "react";
import styled from "styled-components";
import { getPasswordURL, logout } from "../../networkHelpers";
import HEADER, { ACCOUNT, CHANGE_PWD, LOGOUT } from "../../idHelpers";
import useComponentVisible from "../../hooks/useComponentVisible";
import { UserInfo } from "../../types";
import {
  AccountMenuCommonStyles,
  StyledMenuHeader,
  StyledMenuHeaderLabel
} from "./AccountMenuStyles";

const StyledAccount = styled.div`
  display: flex;
  margin-left: 2.7rem;
`;

const StyledAccountButton = styled.button`
  background: none;
  border: none;
  justify-content: center;
  cursor: pointer;
  height: fit-content;
  margin: auto;
  padding: 0;
  &:focus {
    outline: none;
    border-color: transparent;
    box-shadow: none;
  }
`;

const StyledAccountMenu = styled.div`
  ${AccountMenuCommonStyles}
  position: absolute;
  top: 4.2rem !important;
  right: 0 !important;
`;

const StyledSpan = styled.span`
  background: #85858b;
  border-radius: 0.4rem;
  height: 2.4rem;
  width: 2.4rem;
  color: #ffffff;
  font: normal 600 1.2rem/2.4rem ${props => props.theme.fontFamily};
  text-align: center;
  display: inline-block;
  ${StyledAccountButton}:hover & {
    background: #3ea8dd;
  }
`;

const StyledMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: inherit;
`;

const StyledSectionHeader = styled.div`
  font: normal 400 1.1rem/1.4rem ${props => props.theme.fontFamily};
  padding: 1.6rem 1.6rem 1.8rem;
  color: #85858b;
`;

const StyledLink = styled.a`
  color: var(--dark-color-3);
  text-align: left;
  text-decoration: none;
  font: normal 400 1.4rem/2.2rem ${props => props.theme.fontFamily};
  padding: 0 1.6rem 0.7rem;
  :hover {
    color: #3ea8dd;
  }
`;
const StyledButton = styled.button`
  font: normal 400 1.4rem/2.2rem ${props => props.theme.fontFamily};
  text-decoration: none;
  border: 0 none;
  text-align: left;
  background: none;
  color: var(--dark-color-3);
  cursor: pointer;
  outline: none;
  padding: 0.7rem 1.6rem;
  &:focus {
    outline: none;
    border-color: transparent;
    box-shadow: none;
  }
  :hover {
    color: #3ea8dd;
  }
`;

type Props = {
  userInfo?: UserInfo;
};

const Account = ({ userInfo }: Props) => {
  const { ref, isVisible, toggleIsVisible } = useComponentVisible(false);
  const firstName = userInfo ? userInfo.firstName : "?";
  const initials = userInfo ? userInfo.initials : "?";

  const maybeAccountMenu = isVisible && (
    <StyledAccountMenu>
      <StyledMenuWrapper>
        <StyledMenuHeader>
          <StyledMenuHeaderLabel>Welcome, {firstName}</StyledMenuHeaderLabel>
        </StyledMenuHeader>
        <StyledSectionHeader>User</StyledSectionHeader>
        <StyledLink
          id={HEADER + "-" + ACCOUNT + "-" + CHANGE_PWD}
          href={getPasswordURL().toString()}
          target="_blank"
          rel="noopener noreferrer"
        >
          Change Password
        </StyledLink>
        <StyledButton
          id={HEADER + "-" + ACCOUNT + "-" + LOGOUT}
          onClick={logout}
        >
          Logout
        </StyledButton>
      </StyledMenuWrapper>
    </StyledAccountMenu>
  );

  return (
    <StyledAccount ref={ref}>
      <StyledAccountButton
        id={HEADER + "-" + ACCOUNT}
        onClick={toggleIsVisible}
      >
        <StyledSpan>{initials}</StyledSpan>
      </StyledAccountButton>
      {maybeAccountMenu}
    </StyledAccount>
  );
};

export default Account;
