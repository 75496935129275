import React from "react";
import styled from "styled-components";
import { ErrorSplash } from "samplify-design";

const Godzilla = styled(ErrorSplash)`
  width: 100vw;
  height: auto;
  margin: 0 auto;
  max-width: 120rem;
`;

const Outer = styled.div`
  height: 100vh;
  width: 100%;
  background-color: white;
`;
const Inner = styled.div`
  padding-top: 3.8rem;
  margin: 0 auto;
  text-align: center;
  background-color: white;
`;
const Blurb = styled.p`
  font-weight: 600;
  font-size: 1.8rem;
`;

const PageError = () => (
  <Outer>
    <Inner>
      <Blurb>
        Uh oh! It appears we're experiencing issues. Please try again in a few
        moments.
      </Blurb>
      <Godzilla />
    </Inner>
  </Outer>
);

export default PageError;
