import { useEffect } from "react";
import modalTypes from "../constants/modalTypes";
import useServiceWorkerUpdated from "../hooks/useServiceWorkerUpdated";
import useSetModal from "../hooks/useSetModal";

const UpdateFoundContainer = () => {
  const updateFound = useServiceWorkerUpdated();
  const setModal = useSetModal(modalTypes.UPDATE_FOUND);
  useEffect(() => {
    if (updateFound) setModal();
  }, [updateFound, setModal]);
  return null;
};

export default UpdateFoundContainer;
