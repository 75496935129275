import React from "react";
import styled from "styled-components";
import svgLive from "../../art/cmixStatus/live.svg";
import svgClosed from "../../art/cmixStatus/closed.svg";
import svgTesting from "../../art/cmixStatus/testing.svg";
import svgDesign from "../../art/cmixStatus/design.svg";
import { ExternalSurveyStatus } from "../../types";

const getStatusText = (s: ExternalSurveyStatus) => {
  const l = s.toLowerCase();
  return l.charAt(0).toUpperCase() + l.slice(1);
};

const getStatusIcon = (s: ExternalSurveyStatus): string => {
  switch (s) {
    case ExternalSurveyStatus.DESIGN:
      return svgDesign;

    case ExternalSurveyStatus.LIVE:
      return svgLive;

    case ExternalSurveyStatus.CLOSED:
      return svgClosed;

    case ExternalSurveyStatus.TEST:
      return svgTesting;

    default:
      return "";
  }
};

type StatusData = {
  statusText: string;
  statusIcon: string;
};

const getStatusData = (s: ExternalSurveyStatus): StatusData => {
  return {
    statusText: getStatusText(s),
    statusIcon: getStatusIcon(s)
  };
};

type CustomDivProps = {
  isLive: boolean;
} & React.HTMLProps<HTMLDivElement>;

const CustomDiv = ({ isLive, ...rest }: CustomDivProps) => <div {...rest} />;

const Box = styled(CustomDiv)`
  display: flex;
  width: fit-content;
  height: 2.1rem;
  background: ${props => (props.isLive ? "#DCF2EC" : "#f1f2f3")};
  border-radius: 5rem;
  align-items: center;
  padding: 0.3rem 1.5rem;
`;

const StyledStatusIcon = styled.img`
  width: 1.4rem;
  height: auto;
  margin: 0 1rem 0 0;
`;

const StyledSurveyName = styled.span`
  font: normal 400 1.2rem/1.8rem ${props => props.theme.fontFamily};
  color: #373941;
`;

type Props = {
  status: ExternalSurveyStatus;
};

const CmixSurveyStatus = ({ status }: Props) => {
  const { statusText, statusIcon } = getStatusData(status);

  return (
    <Box isLive={status === ExternalSurveyStatus.LIVE}>
      <StyledStatusIcon src={statusIcon} alt="" />
      <StyledSurveyName>{statusText}</StyledSurveyName>
    </Box>
  );
};

export default CmixSurveyStatus;
