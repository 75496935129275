import React from "react";
import styled from "styled-components";

type CustomDivProps = {
  open: boolean;
  disabled: boolean;
} & React.HTMLProps<HTMLDivElement>;
const CustomDiv = ({ open, disabled, ...rest }: CustomDivProps) => {
  return <div {...rest} />;
};
const StyledTriangle = styled(CustomDiv)`
  width: 0;
  height: 0;
  padding: 0 0 0 0.3rem;
  border-left: 8px solid ${props => (props.disabled ? "#b9bcc4" : "#696e7b")};
  border-top: 6px solid #fff;
  border-bottom: 6px solid #fff;
  border-right: none;
  margin-right: 4px;
  transform: ${props => (props.open ? "rotate(90deg)" : "rotate(0deg)")};
  -webkit-transition: -webkit-transform 2s;
  transition: transform 0.4s ease;
`;

type CustomTitleProps = {
  disabled: boolean;
} & React.HTMLProps<HTMLDivElement>;
const CustomTitle = ({ disabled, ...rest }: CustomTitleProps) => {
  return <div {...rest} />;
};
export const StyledTitle = styled(CustomTitle)`
  margin: 0 0 0 1rem;
  color: ${props => (props.disabled ? "#b9bcc4" : "#696e7b")};
  width: 100%;
  text-align: left;
  font: normal 600 1.4rem/1.9rem ${props => props.theme.fontFamily};
  display: flex;
  align-items: center;
`;

type CustomButtonProps = {
  minHeight: string;
} & React.HTMLProps<HTMLButtonElement>;
const CustomButton = ({ minHeight, ...rest }: CustomButtonProps) => {
  return <button {...rest} />;
};
const SectionButton = styled(CustomButton)`
  width: 100%;
  min-height: ${props => props.minHeight};
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  cursor: ${props => (props.disabled ? "auto" : "pointer")};
  background: none;
  &:focus {
    outline: none;
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => props.theme.primary["2"]};
    border-radius: 0.4rem;
  }
`;

type CustomSectionProps = {
  showTopBorder: boolean;
  handleMargins: boolean;
  handlePadding: boolean;
} & React.HTMLProps<HTMLDivElement>;
const CustomSection = ({
  showTopBorder,
  handleMargins,
  handlePadding,
  ...rest
}: CustomSectionProps) => {
  return <div {...rest} />;
};
const SectionContainer = styled(CustomSection)`
  border-top: ${props => (props.showTopBorder ? "1px solid #f4f4f4" : "none")};
  margin: ${props => (props.handleMargins ? "0 -2.4rem" : "auto")};
  padding: ${props => (props.handlePadding ? "0 2.4rem" : "auto")};
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
`;

interface PropTypes {
  header: React.ReactNode;
  children: React.ReactNode;
  disabled?: boolean;
  isOpen: boolean;
  toggleOpen: () => void;
  showTopBorder?: boolean;
  minHeight?: string;
  handleMargins?: boolean;
  handlePadding?: boolean;
  additionalHeaderComponent?: React.ReactNode;
}

const Section = ({
  header,
  children,
  disabled = false,
  isOpen,
  toggleOpen,
  showTopBorder = true,
  minHeight = "4.9rem",
  handleMargins = false,
  handlePadding = false,
  additionalHeaderComponent = null
}: PropTypes) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    toggleOpen();
    e.currentTarget.blur();
  };
  const maybeChildren = isOpen && !disabled ? children : null;

  return (
    <SectionContainer
      showTopBorder={showTopBorder}
      handleMargins={handleMargins}
      handlePadding={handlePadding}
    >
      <SectionHeader>
        <SectionButton
          onClick={handleClick}
          disabled={disabled}
          minHeight={minHeight}
        >
          <StyledTriangle open={isOpen && !disabled} disabled={disabled} />
          <StyledTitle disabled={disabled}>{header}</StyledTitle>
        </SectionButton>
        {additionalHeaderComponent}
      </SectionHeader>
      {maybeChildren}
    </SectionContainer>
  );
};

export default Section;
