import React from "react";
import styled from "styled-components";
import { Button } from "samplify-design";
import svgClear from "../../art/iconClear.svg";

const StyledButton = styled(Button)`
  position: absolute;
  right: 3.5rem;
  top: 2rem;
  width: fit-content;
  height: fit-content;
  border: none;
  background: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  &:hover {
    border: none;
    background: none;
  }
  &:active {
    border: none;
    background: none;
  }
`;

const ClearIcon = styled.img`
  height: 1rem;
  width: auto;
`;

type Props = {
  clearModal: () => void;
};

export default ({ clearModal }: Props) => {
  return (
    <StyledButton onClick={clearModal}>
      <ClearIcon src={svgClear} />
    </StyledButton>
  );
};
