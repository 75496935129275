import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Button, ModalBody, TextInput } from "samplify-design";
import Allocation from "../../art/attribute_edit_modal/allocation.svg";
import Filter from "../../art/attribute_edit_modal/filter.svg";
import ModalCancelButton from "../ModalCancelButton";
import {
  QuotaPlan,
  AttributeMap,
  QuotaGroup,
  CreateTargetingTemplateInput
} from "../../types";
import ModalPropsContext from "../../containers/ModalPropsContext";
import { FetchResult } from "apollo-link";
import { TemplateSaveInput } from "../../hooks/quota/useTemplateCreate";

const Buttons = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 2em;
`;

const InputWrapper = styled.div`
  display: flex;
  margin-bottom: 1.5em;
  > input,
  textarea {
    width: 100%;
    font-family: "Open Sans", sans-serif;
    ::placeholder {
      color: #b0b3bc;
      font-weight: 300;
      font-family: "Open Sans", sans-serif;
    }
  }
`;

const StyledTextArea = styled.textarea`
  font-size: 1em;
  border: 1px solid #d5d7db;
  border-radius: 0.5em;
  color: #565656;
  margin-right: 0.25em;
  padding: 0.6em 0.9em;
  resize: none;
  font-family: "Open Sans", sans-serif;
  &:focus {
    outline: none;
    border-color: transparent;
    box-shadow: 0 0 0 2px #3ea8dd;
  }
`;

const StyledH2 = styled.h2`
  font-size: 1.1em;
  font-weight: 600;
  margin: 0;
  margin-bottom: 2em;
`;

const StyledImg = styled.img`
  margin-right: 0.5em;
  width: 1.5em;
`;

const StyledModalBody = styled(ModalBody)`
  flex-grow: unset;
  text-align: left;
`;

const StyledSection = styled.div`
  margin-bottom: 1.2em;
  font-weight: 600;
  > ul {
    list-style: none;
    font-size: 0.8em;
    margin-top: 5px;
  }
  > ul li {
    padding: 5px 0;
  }
`;

const StyledSectionHeader = styled.div`
  align-items: center;
  display: flex;
`;

const StyledSpan = styled.span`
  font-weight: normal;
`;

type Data = {
  createTargetingTemplate: { id: string };
};

type Props = {
  clearModal: () => void;
  createTemplate: (
    input: CreateTargetingTemplateInput
  ) => Promise<void | FetchResult<Data>>;
};
type ModalProps = {
  quotaPlan: QuotaPlan;
  attributes: AttributeMap;
  saveTemplate: (input: TemplateSaveInput) => void;
};
type ModalPropsContextArg = {
  modalProps: ModalProps;
};

const SaveTargetingTemplate = ({ clearModal, createTemplate }: Props) => {
  const [name, setName] = useState({ value: "", error: false });
  const [desc, setDesc] = useState("");
  const context: ModalPropsContextArg = useContext(ModalPropsContext);
  const { quotaPlan, attributes, saveTemplate } = context.modalProps;
  const { filters, quotaGroups } = quotaPlan;

  const saveName = (e: React.FormEvent<HTMLInputElement>) =>
    setName({ value: e.currentTarget.value, error: false });
  const saveDesc = (e: React.FormEvent<HTMLTextAreaElement>) =>
    setDesc(e.currentTarget.value);

  const handleClick = () => {
    const nameVal = name.value;
    if (nameVal.length === 0) return setName({ value: nameVal, error: true });
    const input: TemplateSaveInput = {
      name: nameVal,
      desc,
      quotaPlan,
      tags: [],
      createTemplate
    };
    saveTemplate(input);
  };

  const allocations = quotaGroups.map((item: QuotaGroup, i: number) => {
    const quotaNodes = item.quotaCells[0].quotaNodes;
    const attrId = quotaNodes[0].attributeId;
    const attrName = attributes[attrId].name;
    const getNested = () => {
      const nestedAttrs = quotaNodes.slice(1, quotaNodes.length);
      const nestedAttrsList = nestedAttrs.map(
        i => `${attributes[i.attributeId].name}; `
      );
      return (
        <span>
          <StyledSpan>Nested with </StyledSpan>
          {nestedAttrsList}
        </span>
      );
    };
    const maybeNested = quotaNodes.length > 1 && getNested();
    return (
      <li key={i}>
        {attrName} {maybeNested}
      </li>
    );
  });
  const maybeAllocation = quotaGroups.length > 0 && (
    <StyledSection>
      <StyledSectionHeader>
        <StyledImg src={Allocation} alt="" />
        <span>Allocation</span>
      </StyledSectionHeader>
      <ul>{allocations}</ul>
    </StyledSection>
  );

  const filterItems = filters.map((item, i) => {
    const { attributeId } = item;
    const attrName = attributes[attributeId].name;
    return <li key={i}>{attrName}</li>;
  });
  const maybeFilters = filters.length > 0 && (
    <StyledSection>
      <StyledSectionHeader>
        <StyledImg src={Filter} alt="" />
        <span>Filter</span>
      </StyledSectionHeader>
      <ul>{filterItems}</ul>
    </StyledSection>
  );
  return (
    <StyledModalBody>
      <StyledH2>Save as New Template</StyledH2>
      <InputWrapper>
        <TextInput
          placeholder="Template Name"
          value={name.value}
          onChange={saveName}
          invalid={name.error}
        />
      </InputWrapper>
      <InputWrapper>
        <StyledTextArea
          placeholder="Description"
          value={desc}
          onChange={saveDesc}
        />
      </InputWrapper>
      {maybeAllocation}
      {maybeFilters}
      <Buttons>
        <ModalCancelButton onClick={clearModal}>Cancel</ModalCancelButton>
        <Button onClick={handleClick}>Save</Button>
      </Buttons>
    </StyledModalBody>
  );
};

export default SaveTargetingTemplate;
