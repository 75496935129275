import styled from "styled-components";
import { ModalBody, ModalFooter, Button } from "samplify-design";

export const StyledFooter = styled(ModalFooter)`
  height: auto;
`;

export const Section = styled.div`
  text-align: left;
  padding: 6px 0;
  > label {
    padding: 0 10px 0 0;
  }
`;

export const StyledButton = styled(Button)`
  width: 15rem;
  height: 4rem;
  padding: 0;
`;

export const StyledCancelButton = styled(Button)`
  color: ${({ theme }) => theme.neutralDark["2"]};
  background-color: transparent;
  text-decoration: underline;
  &:hover,
  &:active,
  &:focus {
    background: transparent;
    box-shadow: none;
  }
`;

export const StyledModal = styled(ModalBody)`
  padding: 20px 30px;
  flex-grow: unset;
  h1 {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.25;
    margin-right: 0.5em;
    text-align: left;
    height: auto;
  }
  flex-grow: unset;
`;
