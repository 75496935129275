import React from "react";
import styled from "styled-components";

type CustomProps = {
  attributeColumnCount: number;
} & React.HTMLProps<HTMLDivElement>;

const CustomDiv = ({
  attributeColumnCount: columnCount,
  ...rest
}: CustomProps) => {
  return <div {...rest} />;
};

export const OuterGrid = styled(CustomDiv)`
  background-color: var(--light-color-6);
  border-radius: 0.8rem;
  margin: 2.4rem 0;
  display: grid;
  grid-template-columns:
    repeat(${props => props.attributeColumnCount}, 1fr)
    minmax(10rem, max-content)
    minmax(12rem, max-content);
  font-size: 1.2rem;
  border: 1px solid #f1f1f1;
`;

export const LiveOuterGrid = styled(OuterGrid)`
  grid-template-columns:
    1fr
    5rem
    minmax(8rem, max-content)
    minmax(10rem, max-content)
    5rem;
`;

export const DraftOuterGrid = styled(OuterGrid)`
  background-color: #f9f9f9;
`;

export const InnerGrid = styled.div`
  border-radius: 0.8rem;
  display: grid;
  grid-column-end: -1;
  grid-column-start: 1;
  grid-template-columns: inherit;
  grid-row-gap: 2px;
  overflow: hidden;
  background-color: #f1f1f4;
`;

export const GridCell = styled.div`
  font-size: 1.2rem;
  padding: 1.7rem 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--light-color-6);
  overflow-x: auto;
`;

export const GridCellChart = styled(GridCell)`
  padding: 1.3rem 1rem;
`;

export const AllocationEditingCell = styled(GridCell)`
  padding: 0 1.2rem;
  background-color: #f1f9fd;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
  height: 100%;
`;

export const AllocationEditableCell = styled(GridCell)`
  padding: 0;
  height: 100%;
`;

type CustomAllocButtonProps = {
  allocationEditHover: boolean;
} & React.HTMLProps<HTMLButtonElement>;

const CustomAllocButton = ({
  allocationEditHover,
  ...rest
}: CustomAllocButtonProps) => {
  return <button {...rest} />;
};

export const AllocationEditableButton = styled(CustomAllocButton)`
  font: normal 400 1.2rem/1.7rem ${props => props.theme.fontFamily};
  color: #696e7b;
  height: 100%;
  padding: 1.7rem 1.2rem;
  text-align: left;
  border: none;
  background-color: ${props =>
    props.allocationEditHover ? "#f1f9fd !important" : "#ffffff"};
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => props.theme.primary["2"]};
    border-radius: 0.4rem;
  }
  display: flex;
`;

type CustomAllocHeaderProps = {
  deprecated: boolean;
  headerWidth?: string;
} & React.HTMLProps<HTMLHeadingElement>;

const CustomAllocHeader = ({
  headerWidth,
  deprecated,
  ...rest
}: CustomAllocHeaderProps) => {
  return <header {...rest} />;
};

export const StyledGridHeader = styled(CustomAllocHeader)`
  color: var(--dark-color-3);
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1.2rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-color: ${props =>
    props.deprecated ? "var(--danger-color-4)" : "#f9f9f9"};
  :first-child {
    border-top-left-radius: 0.8rem;
  }
`;

export const StyledGridMidHeader = styled(StyledGridHeader)`
  border-radius: 0;
  width: ${props =>
    props.headerWidth && `${parseInt(props.headerWidth) - 20}px`};
`;

export const StyledGridControlHeader = styled(StyledGridHeader)`
  border-top-left-radius: 0;
  border-top-right-radius: 0.8rem;
`;

export const FeasibilityCell = styled(GridCell)`
  text-align: right;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
`;

export const getIncompleteAllocMsg = (
  imperative: string,
  remainingCount: number
) => (
  <span>
    {imperative} the remaining <b>{Math.abs(remainingCount)}</b> complete(s) to
    continue.
  </span>
);
