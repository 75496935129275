import React, { useContext } from "react";
import styled from "styled-components";
import { ModalBody, Button } from "samplify-design";
import ModalPropsContext from "../../containers/ModalPropsContext";
import ModalCancelButton from "../ModalCancelButton";
import iconWarning from "../../art/iconWarning.svg";

const Footer = styled.div`
  text-align: right;
  margin-top: 40px;
`;

const StyledBody = styled(ModalBody)`
  padding: 30px 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  font-size: 13px;
  color: #696e7b;
  width: 370px;
  margin: 0 auto;
  flex-grow: unset;
`;

const StyledHeader = styled.p`
  font-size: 16px;
`;

const StyledIcon = styled.img`
  float: left;
  margin-right: 10px;
  width: 25px;
`;

const StyledP = styled.p`
  line-height: 20px;
`;

type Props = {
  clearModal: () => void;
};

type ModalProps = {
  loadTemplate: () => void;
};
type ModalPropsContextArg = {
  modalProps: ModalProps;
};

const Outer = styled.div``;

const ConfirmLoadTemplate = ({ clearModal }: Props) => {
  const context: ModalPropsContextArg = useContext(ModalPropsContext);
  const { loadTemplate } = context.modalProps;
  const useTemplate = () => {
    clearModal();
    loadTemplate();
  };
  return (
    <Outer>
      <StyledBody>
        <StyledHeader>
          <StyledIcon src={iconWarning} width="20" alt="warning" />
          Template vs. Existing Attributes
        </StyledHeader>
        <StyledP>
          When adding a template, existing attributes will be removed
          automatically. Are you sure you want to add the template?
        </StyledP>
        <Footer>
          <ModalCancelButton onClick={clearModal}>Cancel</ModalCancelButton>
          <Button onClick={useTemplate}>Use Template</Button>
        </Footer>
      </StyledBody>
    </Outer>
  );
};

export default ConfirmLoadTemplate;
