import { InMemoryCache, defaultDataIdFromObject } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { setContext } from "apollo-link-context";
import { withClientState } from "apollo-link-state";
import { ApolloLink } from "apollo-link";
import {
  getLoginURL,
  getGQLURL,
  appendLoginParams,
  getSessionId,
  clearSessionId
} from "./networkHelpers";
import { getGraphQLError, getNetworkError } from "./lib/queries/error";
import { hasNoAccess } from "./generalHelpers";

// omitTypenameLink strips out the __typename field from GraphQL operations.
// Apollo client adds __typename on queries, but does not expect it in mutations.
// See https://github.com/apollographql/apollo-client/issues/3616
const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      (key, value) => (key === "__typename" ? undefined : value)
    );
  }
  return forward(operation);
});

const hasUnauthError = ({ networkError, graphQLErrors }) =>
  (networkError &&
    (networkError.statusCode === 401 || networkError.statusCode === 402)) ||
  (graphQLErrors && graphQLErrors.some(e => e.message === "401 Unauthorized"));

// errorLink logs graphQL errors and network errors to the console.
const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path, extensions }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Extensions: ${extensions}`
      )
    );
    if (!hasNoAccess(graphQLErrors)) {
      cache.writeQuery({
        query: getGraphQLError,
        data: {
          graphQLErrors
        }
      });
    }
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    cache.writeQuery({
      query: getNetworkError,
      data: {
        networkError
      }
    });
  }

  if (hasUnauthError({ graphQLErrors, networkError }) && response) {
    response.errors = null;
  }
});

const authHeaderLink = setContext((_, { headers }) => {
  const sessionId = getSessionId();
  return {
    headers: {
      ...headers,
      authorization: sessionId ? sessionId : ""
    }
  };
});

const clientHttpLink = new HttpLink({ uri: getGQLURL() });

const dataIdFromObject = object => {
  switch (object.__typename) {
    case "Project":
      return object.extProjectId; // use `extProjectId` as the primary key
    case "LineItem":
      // ! extProjectId is undefined and could mess up caching
      return `LineItem:${object.extProjectId}_${object.extLineItemId}`; // use `extLineItemId` as the primary key
    case "Attribute":
      return `Attribute:${object.id}_${object.countryISOCode}_${
        object.languageISOCode
      }`; // use combo of lineitemID, countryISOCode and languageISOCode as the primary key
    default:
      return defaultDataIdFromObject(object); // fall back to default handling
  }
};

const cache = new InMemoryCache({ dataIdFromObject });

// keeping this to avoid undefined Network errors
const resolvers = {
  Mutation: {}
};

const clientStateLink = withClientState({
  resolvers,
  cache
});

export const stateLinkWriteDefault = async () => {
  clientStateLink.writeDefaults();
};

const unauthorizedLink = onError(
  ({ operation, networkError, graphQLErrors, forward }) => {
    if (hasUnauthError({ networkError, graphQLErrors })) {
      clearSessionId();
      const redirect = appendLoginParams(getLoginURL());
      window.location.replace(redirect);
    }
    //if (forward) return forward(operation);
  }
);

function getClient() {
  return new ApolloClient({
    link: ApolloLink.from([
      unauthorizedLink,
      authHeaderLink,
      omitTypenameLink,
      errorLink,
      clientStateLink,
      clientHttpLink
    ]),
    cache
  });
}

export default getClient;
