import React from "react";
import partition from "lodash/partition";
import isEqual from "lodash/isEqual";
import { onlyGroupAttributeId } from "../../quotaHelpers";
import { QuotaCellInput, QuotaGroupInput } from "../../types";

const getRowMap = (
  quotaGroup: QuotaGroupInput,
  quotaCell: QuotaCellInput
): Map<string[], boolean> => {
  const stateMap = new Map<string[], boolean>();

  const nonNullNodes = quotaCell.quotaNodes || [];
  nonNullNodes.forEach(node => {
    const nonNullOptions = node.options || [];
    nonNullOptions.forEach(o => {
      stateMap.set([o], true);
    });
  });

  const nonNullCells = quotaGroup.quotaCells || [];
  const otherCells = nonNullCells.filter(
    c => !isEqual(c.quotaNodes, nonNullNodes)
  );
  otherCells.forEach(cell => {
    const cellOpts = new Array<string>();
    const nonNullNodes = cell.quotaNodes || [];
    nonNullNodes.forEach(node => {
      const nonNullOptions = node.options || [];
      cellOpts.push(...nonNullOptions);
    });
    stateMap.set(cellOpts, false);
  });

  return stateMap;
};

const getNextCells = (
  attributeId: string,
  rowMap: Map<string[], boolean>
): Array<QuotaCellInput> => {
  const mapKeys = Array.from(rowMap.keys());

  const [checkedRows, uncheckedRows] = partition<string[]>(mapKeys, row =>
    rowMap.get(row)
  );

  const checkedOptions = new Array<string>();
  checkedRows.forEach(row => {
    checkedOptions.push(...row);
  });

  const checkedCell: QuotaCellInput = {
    quotaNodes: [{ attributeId, options: checkedOptions }],
    count: 0,
    perc: 0
  };

  const uncheckedCells = uncheckedRows.map<QuotaCellInput>(row => ({
    quotaNodes: [{ attributeId, options: row }],
    count: 0,
    perc: 0
  }));

  return [checkedCell, ...uncheckedCells];
};

type StateProps = {
  rowMap: Map<string[], boolean>;
};

export type RenderProps = {
  toggleRow: (row: string[]) => void;
  updateGroupings: () => void;
} & StateProps;

type ContainerProps = {
  quotaCell: QuotaCellInput;
  quotaGroup: QuotaGroupInput;
  updateTempQuotaGroupInput: (g: QuotaGroupInput) => void;
  togglePopover: () => void;
  toggleRangeGroupingChange?: () => void;
  children: (result: RenderProps) => React.ReactNode;
};

class AllocationGroupContainer extends React.Component<
  ContainerProps,
  StateProps
> {
  constructor(props: ContainerProps) {
    super(props);
    const { quotaGroup, quotaCell } = this.props;
    this.state = { rowMap: getRowMap(quotaGroup, quotaCell) };
    this.toggleRow = this.toggleRow.bind(this);
    this.updateGroupings = this.updateGroupings.bind(this);
  }

  toggleRow(row: string[]) {
    this.setState(prevState => {
      const nextMap = new Map(prevState.rowMap);
      nextMap.set(row, !prevState.rowMap.get(row));
      return { rowMap: nextMap };
    });
  }

  updateGroupings() {
    const {
      quotaGroup,
      updateTempQuotaGroupInput,
      togglePopover,
      toggleRangeGroupingChange
    } = this.props;
    const { rowMap } = this.state;
    const attributeId = onlyGroupAttributeId(quotaGroup);
    const quotaCells = getNextCells(attributeId, rowMap);
    updateTempQuotaGroupInput({ ...quotaGroup, quotaCells });
    if (toggleRangeGroupingChange) {
      toggleRangeGroupingChange();
    }
    togglePopover();
  }

  render() {
    const { children } = this.props;
    const { rowMap } = this.state;
    const { toggleRow, updateGroupings } = this;
    return children({ rowMap, toggleRow, updateGroupings });
  }
}

export default AllocationGroupContainer;
