import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import Header from "./AttributeEditHeader";
import Footer from "./AttributeEditFooter";
import {
  AttributeOptionMap,
  QuotaNodeInput,
  QuotaGroupInput,
  QuotaCellInput,
  Attribute
} from "../../types";
import {
  AttributeEditorProps,
  EDIT_MODE_ALLOCATION,
  groupAttributeIds,
  sortOptionMapKeys,
  getGroupedOptionMap,
  FILTER_TYPE_INCLUDE,
  FILTER_TYPE_EXCLUDE,
  EDIT_MODE_FILTER,
  allocExceededText,
  needMoreAllocText,
  finishFilterText,
  finishAllocText,
  selectOneFilterText
} from "../../quotaHelpers";
import AttributeOptionList from "./AttributeOptionList";
import AttributeEditButtonGroup from "./AttributeEditButtonGroup";
import useAllocationReview from "../../hooks/quota/useAllocationReview";
import hash from "object-hash";
import Option from "./Option";
import useAttributeEdit, {
  getSelectedOptions,
  getCurrQuotaGroup,
  getQuotaGroupInputWithAllocations
} from "../../hooks/quota/useAttributeEdit";
import AttributeEditorOuter, { StyledSeparator } from "./AttributeEditStyles";
import { MAX_COMPLETES } from "../lineitem/Constants";
import { getIncompleteAllocMsg } from "./GridStyles";
import { getSafeTextToMatch } from "../../generalHelpers";

const INCLUDE_HIGHLIGHT_COLOR = "#dcf2ec";
const INCLUDE_FILL_COLOR = "#52c1a3";
const EXCLUDE_HIGHLIGHT_COLOR = "#fef0ee";
const EXCLUDE_FILL_COLOR = "#f76e5c";
const ALLOC_HIGHLIGHT_COLOR = "#ebf6fb";
const ALLOC_FILL_COLOR = "#3ea8dd";

type CustomProps = {
  maxHeight: string;
} & React.HTMLProps<HTMLDivElement>;

const CustomDiv = ({ maxHeight, ...rest }: CustomProps) => {
  return <div {...rest} />;
};

const OptionsList = styled(CustomDiv)`
  height: 100%;
  min-height: 25rem;
  max-height: ${props => props.maxHeight};
  min-width: 54rem;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const findMatches = (wordToMatch: string, options: AttributeOptionMap) => {
  const optionKeys = Object.keys(options) || [];
  const safeWordToMatch = getSafeTextToMatch(wordToMatch);

  return optionKeys.filter(key => {
    const regex = new RegExp(safeWordToMatch, "gi");
    return options[key].text.match(regex);
  });
};

const findSelectedMatches = (
  wordToMatch: string,
  options: AttributeOptionMap,
  selectedOptions: string[]
) => {
  const safeWordToMatch = getSafeTextToMatch(wordToMatch);

  return selectedOptions.filter(key => {
    const regex = new RegExp(safeWordToMatch, "gi");
    return options[key].text.match(regex);
  });
};

const getCurrentSortedList = (
  maybeGroupedOptions: Map<string, string[]>,
  currSortedOptionList: string[]
): string[] => {
  let sortedAllOptionsMap = new Map<string, boolean>();
  currSortedOptionList.forEach(o => sortedAllOptionsMap.set(o, true));

  maybeGroupedOptions.forEach(k => {
    k.forEach((o, i) => {
      if (i !== 0) {
        sortedAllOptionsMap.set(o, false);
      }
    });
  });

  let sortedList = new Array<string>(0);
  currSortedOptionList.forEach(o => {
    if (sortedAllOptionsMap.get(o)) {
      sortedList.push(o);
    }
  });

  return sortedList;
};

const Or = styled.span.attrs({ children: " or " })`
  font-weight: 600;
  color: #696e7b;
  margin: 0 0.4rem;
`;

const getGroupedOptionText = (
  optionMap: AttributeOptionMap,
  optionKeys: string[],
  attribute: Attribute
) => {
  const sortedOptionKeys = sortOptionMapKeys(optionMap, optionKeys);
  return (
    <Fragment>
      {sortedOptionKeys.map((o, idx) => (
        <Fragment key={o}>
          <Option
            optionString={o}
            optionMap={optionMap}
            attributeType={attribute.type}
          />
          {idx === sortedOptionKeys.length - 1 ? null : <Or />}
        </Fragment>
      ))}
    </Fragment>
  );
};

const AttributeOptionEditor = ({
  draft,
  attribute,
  onCancel,
  onSave,
  options,
  initialSelectedOptions = [],
  initialOperatorType,
  initialQuotaGroupInput,
  requiredCompletes,
  lineItemState,
  attributeMap,
  editMode,
  calculatedListHeight,
  calculatedNestingListHeight
}: AttributeEditorProps) => {
  const allocationAllowed = attribute.isAllowedInQuotas;
  const filterAllowed = attribute.isAllowedInFilters;

  if (editMode === EDIT_MODE_FILTER && !filterAllowed && !allocationAllowed) {
    throw new Error("attribute does not allow edit as filter OR allocation!");
  }

  const {
    state,
    toggleEditMode,
    toggleOption,
    handleSearch,
    clearSearch,
    toggleAll,
    toggleViewSelectedOptions,
    handleSplitEvenly,
    toggleFilterType,
    updateAllocationCell,
    updateGroupWithAllocations,
    setShowAllocationErrors,
    showAllocationErrors,
    shouldUpdateAllocRowVal,
    toggleUpdateAllocRowVal
  } = useAttributeEdit({
    attribute,
    draft,
    options,
    initialSelectedOptions,
    initialQuotaGroupInput,
    initialEditMode: editMode,
    initialOperatorType,
    requiredCompletes
  });

  const toggleVals = state.optionsCheckList;
  const allocationOn = state.editMode === EDIT_MODE_ALLOCATION;
  const filterOn = state.editMode === EDIT_MODE_FILTER;
  const filterIsInclude = state.filterType === FILTER_TYPE_INCLUDE;
  const filterIsExclude = state.filterType === FILTER_TYPE_EXCLUDE;
  const filteredOptions = findMatches(state.search, options);
  const toggleAllValue = filteredOptions.every(key => toggleVals[key]);
  const selectedOptions = getSelectedOptions(state.optionsCheckList);
  const selectedFilteredOptions = findSelectedMatches(
    state.search,
    options,
    selectedOptions
  );
  const currOptionList = state.showSelectedOptions
    ? state.search !== ""
      ? selectedFilteredOptions
      : selectedOptions
    : filteredOptions;

  const currSortedUnGroupedOptionList = sortOptionMapKeys(
    options,
    currOptionList
  );

  const selectedQuotaGroupInput = getCurrQuotaGroup(
    attribute,
    state.quotaGroupInput,
    selectedOptions,
    requiredCompletes
  );

  const groupAttrIds = groupAttributeIds(selectedQuotaGroupInput);

  const nonNullGroupedOptionMap: Map<string, string[]> =
    allocationOn && selectedQuotaGroupInput
      ? getGroupedOptionMap(selectedQuotaGroupInput.quotaCells)
      : new Map();

  const currList =
    allocationOn && nonNullGroupedOptionMap
      ? getCurrentSortedList(
          nonNullGroupedOptionMap,
          currSortedUnGroupedOptionList
        )
      : currSortedUnGroupedOptionList;

  const hoverColor = allocationOn
    ? ALLOC_HIGHLIGHT_COLOR
    : filterIsInclude
    ? INCLUDE_HIGHLIGHT_COLOR
    : EXCLUDE_HIGHLIGHT_COLOR;

  const fillColor = allocationOn
    ? ALLOC_FILL_COLOR
    : filterIsInclude
    ? INCLUDE_FILL_COLOR
    : EXCLUDE_FILL_COLOR;

  const optionList = currList.map((key, i) => {
    const o = options[key];
    const quotaNodeOptions = nonNullGroupedOptionMap.get(key) || new Array(key);
    const text =
      allocationOn && nonNullGroupedOptionMap.has(key) ? (
        getGroupedOptionText(options, quotaNodeOptions, attribute)
      ) : (
        <Fragment>{o.text}</Fragment>
      );
    const quotaNodeInput: QuotaNodeInput = {
      attributeId: attribute.id,
      options: quotaNodeOptions
    };
    const allocationMapKey = hash(quotaNodeOptions);
    const allocationValue = state.allocationMap.get(allocationMapKey);
    const quotaCellCount = (allocationValue && allocationValue.count) || "";
    const quotaCellPerc = (allocationValue && allocationValue.perc) || "";
    const quotaCell: QuotaCellInput = {
      count: quotaCellCount === "" ? 0 : quotaCellCount,
      quotaNodes: [quotaNodeInput],
      perc: quotaCellPerc === "" ? 0 : quotaCellPerc
    };

    return (
      <AttributeOptionList
        key={i}
        optionChecked={!!state.optionsCheckList[o.id]}
        onChange={() => toggleOption(o.id, allocationMapKey, quotaNodeOptions)}
        text={text}
        allocationOn={allocationOn}
        quotaCell={quotaCell}
        quotaGroupInput={selectedQuotaGroupInput}
        count={quotaCellCount}
        updateCell={v => updateAllocationCell(allocationMapKey, v)}
        updateGroup={updateGroupWithAllocations}
        requiredCompletes={requiredCompletes}
        attributeCount={groupAttrIds.length}
        lineItemState={lineItemState}
        attributes={attributeMap}
        hoverColor={hoverColor}
        fillColor={fillColor}
        filterIsExclude={filterIsExclude}
        showAllocationError={showAllocationErrors}
        calculatedNestingListHeight={calculatedNestingListHeight}
        shouldUpdateAllocRowVal={shouldUpdateAllocRowVal}
        toggleUpdateAllocRowVal={toggleUpdateAllocRowVal}
      />
    );
  });

  const {
    totalPerc,
    totalCount,
    remainingCount,
    imperative
  } = useAllocationReview({
    allocationMap: state.allocationMap,
    requiredCompletes
  });

  useEffect(() => {
    if (remainingCount === 0) {
      setShowAllocationErrors(false);
    }
  }, [remainingCount, setShowAllocationErrors]);

  const selectedQuotaGroupWithAlloc: QuotaGroupInput = allocationOn
    ? getQuotaGroupInputWithAllocations(
        state.allocationMap,
        selectedQuotaGroupInput.quotaCells,
        selectedQuotaGroupInput.name
      )
    : {
        name: "",
        quotaCells: []
      };

  const isAllocationIncomplete = remainingCount !== 0;
  const isSelectedAllocationOptionsLess =
    allocationOn && selectedQuotaGroupWithAlloc.quotaCells.length <= 1;
  const isSelectedFilterOptionsLess =
    filterOn && selectedFilteredOptions.length < 1;
  const saveDisabled =
    (allocationOn &&
      (isAllocationIncomplete || isSelectedAllocationOptionsLess)) ||
    (filterOn && isSelectedFilterOptionsLess);

  const onSaveClick = () => {
    onSave({
      asAllocation: allocationOn,
      quotaFilterInput: {
        attributeId: attribute.id,
        operator: state.filterType,
        options: selectedOptions
      },
      quotaGroupInput: selectedQuotaGroupWithAlloc
    });
    onCancel();
  };

  const disabledMessage =
    isSelectedAllocationOptionsLess || isSelectedFilterOptionsLess
      ? (allocationOn && needMoreAllocText) || (filterOn && finishFilterText)
      : totalCount > MAX_COMPLETES
      ? allocExceededText
      : isAllocationIncomplete
      ? getIncompleteAllocMsg(imperative, remainingCount)
      : "";

  const showAllocationError =
    (allocationOn && isAllocationIncomplete && showAllocationErrors) ||
    (filterOn && isSelectedFilterOptionsLess);

  const errorMsg = allocationOn ? finishAllocText : selectOneFilterText;

  return (
    <AttributeEditorOuter>
      <Header
        toggleAll={() => toggleAll(filteredOptions)}
        toggleAllValue={toggleAllValue}
        handleSearch={handleSearch}
        value={state.search}
        clearSearch={clearSearch}
        allocationAllowed={allocationAllowed}
        filterAllowed={filterAllowed}
        editMode={state.editMode}
        toggleEditMode={toggleEditMode}
        showSplitEvenly={!isSelectedAllocationOptionsLess}
        onSplitEvenlyClick={() => handleSplitEvenly(selectedQuotaGroupInput)}
        filterType={state.filterType}
        toggleFilterType={toggleFilterType}
        allowSearch={true}
        allowSelectAll={true}
      />

      <OptionsList maxHeight={calculatedListHeight}>{optionList}</OptionsList>

      <StyledSeparator />

      <Footer
        toggleViewSelectedOptions={toggleViewSelectedOptions}
        showAllocationError={showAllocationError}
        totalPerc={totalPerc}
        requiredCompletes={requiredCompletes}
        allocationOn={allocationOn}
        totalCount={totalCount}
        showSelectedOnlyToggle={true}
      />

      <AttributeEditButtonGroup
        saveDisabled={saveDisabled}
        onSave={onSaveClick}
        onCancel={onCancel}
        tooltipOverlay={disabledMessage}
        saveButtonName="Save"
        showError={showAllocationError}
        errorMsg={errorMsg}
        toggleErrorHighlight={setShowAllocationErrors}
      />
    </AttributeEditorOuter>
  );
};

export default AttributeOptionEditor;
