import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  AttributeEditorProps,
  EDIT_MODE_ALLOCATION,
  AllocationValue,
  finishAllocText,
  selectOneFilterText,
  AllocVal
} from "../../quotaHelpers";
import AttributeEditButtonGroup from "./AttributeEditButtonGroup";
import AttributeEditorOuter, { StyledSeparator } from "./AttributeEditStyles";
import Header from "./AttributeEditHeader";
import Footer from "./AttributeEditFooter";
import useAttributeEdit, {
  getQuotaGroupInputWithAllocations
} from "../../hooks/quota/useAttributeEdit";
import useAttributeRange, {
  Range,
  getOptionFromRangeArray
} from "../../hooks/quota/useAttributeRange";
import { StyledGrid, Flex } from "./AttributeEditStyles";
import AllocationRow from "./AllocationRow";
import { QuotaCellInput, QuotaNodeInput } from "../../types";
import hash from "object-hash";
import useAllocationReview from "../../hooks/quota/useAllocationReview";
import RangeRow from "./AttributeRangeRow";

const MIN_VAL = 13;
const MAX_VAL = 99;

const AGE_ALLOCATION_WARNING =
  "To turn off Allocation, you need to have only one age range. Delete the remaining age ranges to set as Filter";

const Index = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 0.8rem;
  > span {
    color: #9599a4;
  }
`;

type CustomProps = {
  maxHeight: string;
} & React.HTMLProps<HTMLDivElement>;

const CustomDiv = ({ maxHeight, ...rest }: CustomProps) => {
  return <div {...rest} />;
};

const StyledRangeList = styled(CustomDiv)`
  min-height: 25rem;
  max-height: ${props => props.maxHeight};
  overflow-y: scroll;
`;

const StyledGroupGrid = styled(StyledGrid)`
  display: grid;
  max-height: inherit;
  margin-top: 1rem;
  &:first-child {
    margin-top: 0;
  }
`;

const StyledRangeGrid = styled.div`
  display: grid;
  grid-template-columns: 2rem auto;
`;

const StyledFlex = styled(Flex)`
  flex-wrap: wrap;
  height: fit-content;
`;

const getMinRangeInput = (regex: RegExp): number => {
  let min = MIN_VAL;
  let rnToTest = min.toString() + "-" + (min + 1).toString();
  while (!regex.test(rnToTest)) {
    min = min + 1;
    if (min > MAX_VAL) {
      return 0;
    }
    rnToTest = min.toString() + "-" + (min + 1).toString();
  }
  return min;
};

const getMaxRangeInput = (regex: RegExp): number => {
  let max = MAX_VAL;
  let rnToTest = (max - 1).toString() + "-" + max.toString();
  while (!regex.test(rnToTest)) {
    max = max - 1;
    if (max < MIN_VAL) {
      return 0;
    }
    rnToTest = (max - 1).toString() + "-" + max.toString();
  }
  return max;
};

const getCountFromMapViaIndex = (
  allocationMap: Map<string, AllocationValue>,
  index: number
): AllocVal => {
  const key = Array.from(allocationMap.keys())[index];
  const allocationValue = allocationMap.get(key);
  return (allocationValue && allocationValue.count) || "";
};

const getSingleArray = (arr: string[][]): string[] =>
  arr.reduce((acc, val) => acc.concat(val), []);

const AttributeRangeEditor = ({
  draft,
  options,
  initialSelectedOptions,
  initialQuotaGroupInput,
  editMode,
  onCancel,
  onSave,
  attribute,
  initialOperatorType,
  attributeMap,
  lineItemState,
  requiredCompletes,
  calculatedListHeight,
  calculatedNestingListHeight
}: AttributeEditorProps) => {
  const allocationAllowed = attribute.isAllowedInQuotas;
  const filterAllowed = attribute.isAllowedInFilters;
  const regex = RegExp(attribute.format);

  const minInput = getMinRangeInput(regex);
  const maxInput = getMaxRangeInput(regex);

  const {
    state: attributeEditState,
    toggleEditMode,
    handleSplitEvenly,
    toggleFilterType,
    updateAllocationCell,
    updateGroup,
    updateGroupWithAllocations,
    setShowAllocationErrors,
    showAllocationErrors,
    deleteAllocationCell,
    toggleUpdateAllocRowVal,
    shouldUpdateAllocRowVal
  } = useAttributeEdit({
    attribute,
    draft,
    options,
    initialSelectedOptions,
    initialQuotaGroupInput,
    initialEditMode: editMode,
    initialOperatorType,
    requiredCompletes
  });

  const allocationOn = attributeEditState.editMode === EDIT_MODE_ALLOCATION;

  const {
    ranges,
    setValue,
    removeRange,
    addRange,
    getUpdatedRanges,
    toggleRangeGroupingChange,
    rangesValid,
    disabledMessage: rangesErrorMessage
  } = useAttributeRange({
    allocationMap: attributeEditState.allocationMap,
    attribute,
    initialOptions: initialSelectedOptions,
    quotaGroupInput: attributeEditState.quotaGroupInput,
    updateGroup,
    toggleEditMode,
    updateAllocationCell,
    deleteAllocationCell,
    toggleUpdateAllocRowVal
  });

  const onSaveClick = () => {
    const rangeStrings = getUpdatedRanges();
    const selectedQuotaGroup = allocationOn
      ? getQuotaGroupInputWithAllocations(
          attributeEditState.allocationMap,
          attributeEditState.quotaGroupInput.quotaCells,
          attributeEditState.quotaGroupInput.name
        )
      : {
          name: "",
          quotaCells: []
        };

    onSave({
      asAllocation: allocationOn,
      quotaFilterInput: {
        attributeId: attribute.id,
        operator: attributeEditState.filterType,
        options: getSingleArray(rangeStrings)
      },
      quotaGroupInput: selectedQuotaGroup
    });

    onCancel();
  };

  const {
    totalPerc,
    totalCount,
    remainingCount,
    imperative
  } = useAllocationReview({
    allocationMap: attributeEditState.allocationMap,
    requiredCompletes
  });

  useEffect(() => {
    if (remainingCount === 0) {
      setShowAllocationErrors(false);
    }
  }, [remainingCount, setShowAllocationErrors]);

  const isAllocationIncomplete = remainingCount !== 0;
  const isSelectedOptionsLess = ranges.length <= 1;

  const saveDisabled =
    !rangesValid ||
    (allocationOn && (isAllocationIncomplete || isSelectedOptionsLess));

  const saveDisabledMessage = !rangesValid ? (
    rangesErrorMessage
  ) : allocationOn ? (
    isSelectedOptionsLess ? (
      <span>Allocation needs two or more ranges.</span>
    ) : isAllocationIncomplete ? (
      <span>
        {imperative} the remaining <b>{Math.abs(remainingCount)}</b> complete(s)
        to continue.
      </span>
    ) : (
      ""
    )
  ) : null;

  const isRangeGroupEmpty = (range: string[]) => range.join("") === "-";

  const hasEmptyRangeGroup = () => getUpdatedRanges().some(isRangeGroupEmpty);

  const showAllocationError =
    allocationOn && isAllocationIncomplete && showAllocationErrors;

  const [rangeError, setRangeError] = useState(false);

  const toggleErrorHighlight = (b: boolean) => {
    setRangeError(b);
    setShowAllocationErrors(b);
  };

  const errorMsg = allocationOn ? finishAllocText : selectOneFilterText;

  return (
    <AttributeEditorOuter>
      <Header
        allocationAllowed={allocationAllowed}
        filterAllowed={filterAllowed && isSelectedOptionsLess}
        filterDisableMessage={AGE_ALLOCATION_WARNING}
        editMode={attributeEditState.editMode}
        toggleEditMode={toggleEditMode}
        showSplitEvenly={ranges.length >= 2}
        onSplitEvenlyClick={() =>
          handleSplitEvenly(attributeEditState.quotaGroupInput, true)
        }
        filterType={attributeEditState.filterType}
        toggleFilterType={toggleFilterType}
        allowSearch={false}
        allowSelectAll={false}
      />

      <StyledRangeList maxHeight={calculatedListHeight}>
        {ranges.map((rangeArray: Range[], arrayIndex: number) => {
          const quotaNodeOptions = getOptionFromRangeArray(rangeArray);
          const quotaNodeInput: QuotaNodeInput = {
            attributeId: attribute.id,
            options: quotaNodeOptions
          };
          const allocationMapKey = hash(quotaNodeOptions);
          const allocationValue = attributeEditState.allocationMap.get(
            allocationMapKey
          );
          const quotaCellCount =
            (allocationValue && allocationValue.count) ||
            getCountFromMapViaIndex(
              attributeEditState.allocationMap,
              arrayIndex
            );
          const quotaCellPerc = (allocationValue && allocationValue.perc) || 0;

          const quotaCell: QuotaCellInput = {
            count: quotaCellCount === "" ? 0 : quotaCellCount,
            quotaNodes: [quotaNodeInput],
            perc: quotaCellPerc
          };

          return (
            <StyledGroupGrid key={arrayIndex} showAllocationView={allocationOn}>
              <StyledFlex key={arrayIndex}>
                {rangeArray.map((r, i) => (
                  <StyledRangeGrid key={i}>
                    <Index>
                      {i === 0 ? `${arrayIndex + 1}.` : <span>or</span>}
                    </Index>
                    <RangeRow
                      arrayIndex={arrayIndex}
                      key={i}
                      range={r}
                      index={i}
                      setValue={setValue}
                      removeRange={removeRange}
                      addRange={addRange}
                      addDisabled={hasEmptyRangeGroup()}
                      deleteDisabled={ranges.length < 2}
                      highlightErrorRanges={rangeError}
                      minInput={minInput}
                      maxInput={maxInput}
                    />
                  </StyledRangeGrid>
                ))}
              </StyledFlex>
              {allocationOn ? (
                <StyledFlex>
                  <AllocationRow
                    quotaCell={quotaCell}
                    quotaGroup={attributeEditState.quotaGroupInput}
                    count={quotaCellCount}
                    updateCell={v => updateAllocationCell(allocationMapKey, v)}
                    updateTempQuotaGroupInput={updateGroupWithAllocations}
                    requiredCompletes={requiredCompletes}
                    attributeCount={1}
                    isLineItemLaunched={false}
                    attributes={attributeMap}
                    showAllocationError={showAllocationErrors}
                    disableGrouping={false}
                    calculatedNestingListHeight={calculatedNestingListHeight}
                    toggleRangeGroupingChange={toggleRangeGroupingChange}
                    shouldUpdateAllocRowVal={shouldUpdateAllocRowVal}
                    toggleUpdateAllocRowVal={toggleUpdateAllocRowVal}
                  />
                </StyledFlex>
              ) : (
                <div />
              )}
            </StyledGroupGrid>
          );
        })}
      </StyledRangeList>

      <StyledSeparator />

      <Footer
        showAllocationError={showAllocationError}
        totalPerc={totalPerc}
        requiredCompletes={requiredCompletes}
        allocationOn={allocationOn}
        totalCount={totalCount}
        showSelectedOnlyToggle={false}
      />

      <AttributeEditButtonGroup
        saveDisabled={saveDisabled}
        onSave={onSaveClick}
        onCancel={onCancel}
        tooltipOverlay={saveDisabledMessage}
        saveButtonName="Save"
        errorMsg={errorMsg}
        showError={showAllocationError}
        toggleErrorHighlight={toggleErrorHighlight}
      />
    </AttributeEditorOuter>
  );
};

export default AttributeRangeEditor;
