import React from "react";
import styled from "styled-components";
import { icons } from "samplify-design";

const { Delete } = icons;

const StyledDelete = styled(Delete)`
  fill: ${({ theme }) => theme.neutralDark["4"]};
  height: 1.25em;
  &:hover {
    cursor: pointer;
  }
`;

const ClearFilter = ({ clearFilter, setPopover }) => {
  const handleOnClick = e => {
    clearFilter();
    if (setPopover) setPopover(false);
    e.stopPropagation();
  };

  return <StyledDelete onClick={handleOnClick} />;
};

export default ClearFilter;
