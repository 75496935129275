import React, { useState } from "react";
import styled from "styled-components";
import { Wrapper } from "./Wrapper";
import ClearFilter from "./ClearFilter";

const StyledTextArea = styled.textarea`
  border: 1px solid ${({ theme }) => theme.neutralLight["3"]};
  border-radius: 0.5em;
  color: inherit;
  font-size: 14px;
  line-height: 1.5;
  overflow: auto;
  padding: 0.5rem;
  resize: none;
  width: 100%;
  &:focus {
    outline: none;
    border-color: transparent;
    box-shadow: 0 0 0 2px #3ea8dd;
  }
`;

const StyledP = styled.p`
  margin: 0 0 1em;
  position: relative;
  text-align: left;
  width: 100%;
  > svg {
    position: absolute;
    right: 0;
    top: -2px;
  }
`;

const RespondentId = ({ respondentID, setRespondentID }) => {
  const [ids, setIds] = useState(respondentID ? respondentID.join("\n") : "");
  const clearFilter = () => {
    setRespondentID(null);
    setIds("");
  };

  const handleOnChange = e => {
    const idString = e.target.value;
    const idArray = idString.length ? idString.trim().split(/\s+/) : null;
    setIds(idString);
    setRespondentID(idArray);
  };

  return (
    <Wrapper>
      <StyledP>
        Enter IDs here in new line format
        <ClearFilter clearFilter={clearFilter} />
      </StyledP>
      <StyledTextArea onChange={handleOnChange} rows="5" value={ids} />
    </Wrapper>
  );
};

export default RespondentId;
