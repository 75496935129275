import React from "react";
import styled, { StyledComponentProps } from "styled-components";
import { icons, TextInput } from "samplify-design";
import DeleteIcon from "../../art/iconDelete.svg";
import IconButton from "../IconButton";
import { Range } from "../../hooks/quota/useAttributeRange";

const { Add } = icons;

type CustomTextInputProps = {
  highlightBorder: boolean;
  currValue: string;
} & StyledComponentProps<
  any,
  any,
  object & {
    type: string;
  },
  string | number | symbol
>;

const CustomTextInput = ({
  highlightBorder,
  currValue,
  ...rest
}: CustomTextInputProps) => <TextInput {...rest} />;

const StyledTextInput = styled(CustomTextInput)`
  box-sizing: border-box;
  height: 2.4rem;
  width: 6rem;
  text-align: center;
  margin: 0;
  border-color: ${props =>
    props.highlightBorder && props.currValue === "" ? "#f76e5c" : "#dddddd"};
`;

const RangeRowOuter = styled.div`
  display: flex;
  align-items: center;
  :not(:last-child) {
    margin: 0 -0.5rem 1rem;
  }
  > * {
    margin: 0.4rem 0.5rem;
  }
`;

const StyledIconButton = styled(IconButton)`
  height: auto;
  width: 2rem;
`;

const StyledAddIconButton = styled(StyledIconButton)`
  > svg {
    fill: #3ea8dd;
  }
  :disabled {
    cursor: not-allowed;
    > svg {
      fill: #b0b3bc;
    }
  }
`;

const StyledDeleteIcon = styled.img`
  height: 20px;
`;

type AttributeRangeRowProps = {
  addDisabled: boolean;
  arrayIndex: number;
  range: Range;
  index: number;
  deleteDisabled: boolean;
  setValue: (
    arrayIndex: number,
    i: number,
    type: string,
    value: string
  ) => void;
  addRange: (i: number) => void;
  removeRange: (arrayIndex: number, i: number) => void;
  highlightErrorRanges: boolean;
  minInput: number;
  maxInput: number;
};

const AttributeRangeRow = ({
  addDisabled,
  arrayIndex,
  range,
  setValue,
  index,
  addRange,
  removeRange,
  deleteDisabled,
  highlightErrorRanges,
  minInput,
  maxInput
}: AttributeRangeRowProps) => {
  const handleMinBlur = (e: React.FormEvent<HTMLInputElement>) => {
    const min = parseFloat(e.currentTarget.value);
    if (min < minInput || min > maxInput) {
      setValue(arrayIndex, index, "min", minInput.toString());
    }
  };
  const handleMaxBlur = (e: React.FormEvent<HTMLInputElement>) => {
    const max = parseFloat(e.currentTarget.value);
    if (max < minInput || max > maxInput) {
      setValue(arrayIndex, index, "max", maxInput.toString());
    }
  };

  return (
    <RangeRowOuter>
      <StyledTextInput
        type="number"
        step="1"
        value={range.min}
        currValue={range.min.toString()}
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          setValue(arrayIndex, index, "min", e.currentTarget.value)
        }
        min={minInput.toString()}
        max={maxInput.toString()}
        onBlur={handleMinBlur}
        highlightBorder={highlightErrorRanges}
      />
      {" - "}
      <StyledTextInput
        type="number"
        step="1"
        value={range.max}
        currValue={range.max.toString()}
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          setValue(arrayIndex, index, "max", e.currentTarget.value)
        }
        min={minInput.toString()}
        max={maxInput.toString()}
        onBlur={handleMaxBlur}
        highlightBorder={highlightErrorRanges}
      />
      <StyledAddIconButton
        disabled={addDisabled}
        onClick={() => addRange(arrayIndex)}
      >
        <Add />
      </StyledAddIconButton>
      <StyledIconButton
        disabled={deleteDisabled}
        onClick={() => removeRange(arrayIndex, index)}
      >
        <StyledDeleteIcon src={DeleteIcon} alt="" />
      </StyledIconButton>
    </RangeRowOuter>
  );
};

export default AttributeRangeRow;
