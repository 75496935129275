import React from "react";
import styled from "styled-components";
import { Checkbox } from "samplify-design";
import { CustomGridProps, StyledGrid, Flex } from "./AttributeEditStyles";
import {
  QuotaGroupInput,
  QuotaCellInput,
  AttributeMap,
  LineItemState
} from "../../types";
import AllocationRow from "./AllocationRow";
import { RedCheckbox } from "../design/Checkbox";
import { AllocationValue, AllocVal } from "../../quotaHelpers";

type CustomStyledGridProps = {
  hoverColor: string;
} & CustomGridProps;

const optionHighlight = ({ hoverColor, ...rest }: CustomStyledGridProps) => {
  //@ts-ignore: not able to recognize `ref` for <div/>
  return <StyledGrid {...rest} />;
};

type CustomCheckboxProps = {
  fillColor: string;
} & React.HTMLProps<HTMLInputElement>;

const optionCheckbox = ({ fillColor, ...rest }: CustomCheckboxProps) => {
  return <Checkbox {...rest} />;
};

const StyledCheckbox = styled(optionCheckbox)`
  > svg rect.sd-check-rect {
    fill: ${props => (props.checked ? props.fillColor : "#ffffff")};
    stroke: #dddddd;
  }
  > svg polyline {
    stroke: #ffffff;
  }
  cursor: pointer;
`;

const StyledOptionGrid = styled(optionHighlight)`
  &:hover {
    background-color: ${props => props.hoverColor};
  }
`;

const StyledOptionLabel = styled.label`
  display: inline-flex;
  align-items: center;
  font: normal 400 1.2rem/1.7rem ${props => props.theme.fontFamily};
  color: #696e7b;
  margin: 0.6rem 0;
  > * {
    margin: 0 1.2rem 0 0;
  }
  cursor: pointer;
`;

type Props = {
  text: React.ReactNode;
  optionChecked: boolean;
  onChange: () => void;
  allocationOn: boolean;
  count: AllocVal;
  updateCell: (v: AllocationValue) => void;
  requiredCompletes: number;
  quotaCell: QuotaCellInput;
  quotaGroupInput: QuotaGroupInput;
  updateGroup: (g: QuotaGroupInput) => void;
  attributeCount: number;
  lineItemState: LineItemState;
  attributes: AttributeMap;
  hoverColor: string;
  fillColor: string;
  filterIsExclude: boolean;
  showAllocationError: boolean;
  calculatedNestingListHeight: string;
  shouldUpdateAllocRowVal: boolean;
  toggleUpdateAllocRowVal: (b: boolean) => void;
};

const AttributeOptionList = ({
  text,
  optionChecked,
  onChange,
  allocationOn,
  count,
  updateCell,
  requiredCompletes,
  quotaCell,
  quotaGroupInput,
  updateGroup,
  attributeCount,
  lineItemState,
  attributes,
  hoverColor,
  fillColor,
  filterIsExclude,
  showAllocationError,
  calculatedNestingListHeight,
  shouldUpdateAllocRowVal,
  toggleUpdateAllocRowVal
}: Props) => {
  const checkbox =
    !allocationOn && filterIsExclude ? (
      <RedCheckbox checked={optionChecked} onChange={onChange} />
    ) : (
      <StyledCheckbox
        checked={optionChecked}
        onChange={onChange}
        fillColor={fillColor}
      />
    );

  return (
    <StyledOptionGrid showAllocationView={allocationOn} hoverColor={hoverColor}>
      <Flex>
        <StyledOptionLabel>
          {checkbox}
          <span style={{ margin: "0" }}>{text}</span>
        </StyledOptionLabel>
      </Flex>
      {allocationOn && optionChecked ? (
        <Flex>
          <AllocationRow
            quotaCell={quotaCell}
            quotaGroup={quotaGroupInput}
            count={count}
            updateCell={updateCell}
            updateTempQuotaGroupInput={updateGroup}
            requiredCompletes={requiredCompletes}
            attributeCount={attributeCount}
            isLineItemLaunched={false}
            attributes={attributes}
            showAllocationError={showAllocationError}
            disableGrouping={false}
            calculatedNestingListHeight={calculatedNestingListHeight}
            shouldUpdateAllocRowVal={shouldUpdateAllocRowVal}
            toggleUpdateAllocRowVal={toggleUpdateAllocRowVal}
          />
        </Flex>
      ) : null}
    </StyledOptionGrid>
  );
};

export default AttributeOptionList;
