import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Title from "./Title";
import Nav from "./Nav";
import Help from "./Help";
import Account from "./Account";
import CompanySwitcher from "./CompanySwitcher";
import { Application, UserInfo, Company } from "../../types";
import HEADER from "../../idHelpers";
import CompanySwitchSuccess from "./CompanySwitchSuccess";
import usePopover from "../../hooks/usePopover";
import { getEffectiveCompany } from "../../generalHelpers";

const StyledHeader = styled.header`
  position: fixed;
  left: 0;
  right: 0;
  z-index: 50;
  justify-content: space-between;
  height: 5rem;
  border-bottom: 1px solid #dde1e7;
  padding: 0 1.3em;
  background-color: ${({ theme }) => theme.neutralLight["7"]};
  display: flex;
  align-items: center;
`;

const StyledLeftDiv = styled.div`
  display: flex;
  height: 100%;
`;

const StyledAppsButton = styled.button`
  background: none;
  border: none;
  justify-content: center;
  cursor: pointer;
  height: fit-content;
  padding: 0;
  margin: auto;
  &:focus {
    outline: none;
    border-color: transparent;
    box-shadow: none;
  }
`;
const StyledGridIcon = styled.i`
  color: #85858b;
  font-size: 2.4rem;
  ${StyledAppsButton}:hover & {
    color: #3ea8dd;
  }
`;

const StyledRightDiv = styled.div`
  align-items: center;
  position: relative;
  display: flex;
  height: 100%;
  border-left: 1px solid #f1f2f3;
  padding-left: 2.3rem;
`;

type Props = {
  termsAccepted: boolean;
  userInfo?: UserInfo;
  apps?: Array<Application>;
  doesUserHaveAccess: boolean;
  toggleLauncherOpen?: () => void;
  resetDashboard?: () => void;
  resetInProgress?: boolean;
  setResetInProgress?: () => void;
};

const Header = ({
  termsAccepted,
  userInfo,
  apps,
  doesUserHaveAccess,
  toggleLauncherOpen,
  resetDashboard,
  resetInProgress,
  setResetInProgress
}: Props) => {
  const companies = userInfo ? userInfo.companies : [];
  const nav = termsAccepted && doesUserHaveAccess ? <Nav /> : null;

  const {
    popoverOpen: companySwitcherOpen,
    togglePopover: toggleCompanySwitcher
  } = usePopover();

  const [
    showCompanySwitchSuccessMsg,
    setShowCompanySwitchSuccessMsg
  ] = useState(false);
  const [showCompanySwitchErrMsg, setShowCompanySwitchErrMsg] = useState(false);
  const removeCompanySwitchMsgs = () => {
    setShowCompanySwitchSuccessMsg(false);
    setShowCompanySwitchErrMsg(false);
  };

  // use custom `effectiveCompany` to avoid stale data while displaying company switch banner notification
  const [effectiveCompany, setEffectiveCompany] = useState(
    getEffectiveCompany(companies)
  );
  useEffect(() => {
    setEffectiveCompany(getEffectiveCompany(companies));
  }, [companies]);

  const onSwitchSuccess = (c: Company) => {
    if (!resetInProgress) {
      if (setResetInProgress) setResetInProgress();
      setEffectiveCompany(c);
      setShowCompanySwitchSuccessMsg(true);
      if (resetDashboard) resetDashboard();
    }
  };

  const onSwitchFailure = () => {
    setShowCompanySwitchErrMsg(true);
  };

  const maybeCompanySwitcher =
    doesUserHaveAccess && companies.length > 1 && effectiveCompany ? (
      <CompanySwitcher
        effectiveCompany={effectiveCompany}
        companies={companies}
        companySwitcherOpen={companySwitcherOpen}
        toggleCompanySwitcher={toggleCompanySwitcher}
        onSwitchSuccess={onSwitchSuccess}
        onSwitchFailure={onSwitchFailure}
      />
    ) : null;

  const maybeCompanySwitchSuccess =
    (showCompanySwitchSuccessMsg || showCompanySwitchErrMsg) &&
    effectiveCompany ? (
      <CompanySwitchSuccess
        effectiveCompany={effectiveCompany}
        showCompanySwitchErrMsg={showCompanySwitchErrMsg}
        removeCompanySwitchMsgs={removeCompanySwitchMsgs}
      />
    ) : null;

  return (
    <StyledHeader id={HEADER}>
      <StyledLeftDiv>
        <StyledAppsButton onClick={toggleLauncherOpen}>
          <StyledGridIcon className="axiom-icon-icon icon-grid" />
        </StyledAppsButton>
        <Title />
        {nav}
      </StyledLeftDiv>

      <StyledRightDiv>
        {doesUserHaveAccess ? <Help /> : null}
        <Account userInfo={userInfo} />
        {maybeCompanySwitcher}
        {maybeCompanySwitchSuccess}
      </StyledRightDiv>
    </StyledHeader>
  );
};

export default Header;
