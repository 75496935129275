import React, { useContext } from "react";
import ModalPropsContext from "../../containers/ModalPropsContext";
import ConfirmationModal from "./ConfirmationModal";
import { Attribute } from "../../types";
import { commaSeparatedString } from "../../generalHelpers";

type Props = {
  clearModal: () => void;
};

type ModalProps = {
  deprecatedAttributes: Attribute[];
  duplicateLineItem: () => void;
};

type ModalPropsContextArg = {
  modalProps: ModalProps;
};

const ConfirmDuplicateLineItemDeprecatedAttribute = ({ clearModal }: Props) => {
  const context: ModalPropsContextArg = useContext(ModalPropsContext);
  const { deprecatedAttributes, duplicateLineItem } = context.modalProps;
  const deprecatedAttributeList = commaSeparatedString(
    deprecatedAttributes.map(a => a.name),
    true
  );
  const confirmModal = () => {
    duplicateLineItem();
    clearModal();
  };

  return (
    <ConfirmationModal
      confirmation={
        <>
          <p>
            The targeting attributes <b>{deprecatedAttributeList}</b> have been
            retired.
          </p>
          <p>
            When duplicating the group, all targeting attributes will be
            removed. Only the settings will be duplicated.
          </p>
        </>
      }
      onCancel={clearModal}
      onConfirm={confirmModal}
      confirmLabel={"Accept & Duplicate"}
    />
  );
};

export default ConfirmDuplicateLineItemDeprecatedAttribute;
