import { useState, useEffect } from "react";
import useGetDashboardProjects from "./useGetDashboardProjects";
import { formatFilters } from "../projectHelpers";
import { ProjectFilters } from "../types/ProjectFilters";
import { NetworkStatus } from "apollo-client";

const useDashboardPaging = (filters: ProjectFilters) => {
  const formattedFilters = formatFilters(filters);
  const [pageNumber, setPageNumber] = useState(1);
  useEffect(() => {
    setPageNumber(1);
  }, [filters]);
  const {
    data,
    fetchMore,
    error,
    loading,
    refetch,
    networkStatus
  } = useGetDashboardProjects(filters);

  const hasNext = data && data.projects ? data.projects.hasNext : false;
  const projectsList = data && data.projects ? data.projects.projectsList : [];
  const addMoreProjects = () => {
    fetchMore({
      variables: { pageNumber },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        if (!prev) return prev;
        if (
          fetchMoreResult.projects.projectsList.every(np =>
            prev.projects.projectsList.find(
              pp => np.extProjectId === pp.extProjectId
            )
              ? true
              : false
          )
        )
          return prev;

        const newProjects = fetchMoreResult.projects.projectsList;
        const previousProjects = prev.projects.projectsList;
        const projects = [...previousProjects, ...newProjects];

        const newProjectList = {
          ...prev,
          projects: {
            totalProjects: fetchMoreResult.projects.totalProjects,
            hasNext: fetchMoreResult.projects.hasNext,
            projectsList: projects,
            __typename: "Projects"
          }
        };
        setPageNumber(pageNumber + 1);
        return newProjectList;
      }
    });
  };
  const refetchProjects = () => {
    setPageNumber(1);
    return refetch();
  };

  return {
    projectsList,
    error,
    hasNext,
    addMoreProjects,
    formattedFilters,
    pageNumber,
    loading: loading || networkStatus === NetworkStatus.refetch,
    refetchProjects
  };
};

export default useDashboardPaging;
