import React from "react";
import styled from "styled-components";
import { ModalBody, TextInputLine, Button, icons } from "samplify-design";
import art from "../../art/CreateProject.svg";
const { Alert } = icons;

const StyledInput = styled(TextInputLine)`
  text-align: center;
  width: 20rem;
  &:focus {
    outline: none;
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => props.theme.primary["2"]};
    border-radius: 0.4rem;
  }
`;

const StyledBody = styled(ModalBody)`
  border-radius: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: unset;
  justify-content: space-between;
  text-align: center;
  max-width: min-content;
  max-height: fit-content;
  ${TextInputLine} {
    text-align: center;
    font-size: 1.4rem;
  }
  padding: 3rem 4rem;
  flex-grow: unset;
`;

const StyledButton = styled(Button)`
  width: 15rem;
  height: 4rem;
  padding: 0;
`;

const ButtonsFlex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
`;

const ErrorText = styled.div`
  color: ${({ theme }) => theme.danger["2"]};
  margin-top: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  > svg {
    fill: ${({ theme }) => theme.danger["2"]};
    height: 1em;
    width: auto;
    margin-right: 0.5em;
  }
`;

const StyledImage = styled.img`
  width: 32rem;
  height: auto;
  margin-bottom: 5rem;
`;

const StyledHeader = styled.h1`
  font: normal 400 1.8rem/2.4rem ${props => props.theme.fontFamily};
  text-align: center;
  margin-bottom: 4.3rem;
  color: #696e7b;
`;

const TextLine = styled.p`
  font: normal 400 1.2rem/1.7rem ${props => props.theme.fontFamily};
  color: #696e7b;
  text-align: left;
  margin-top: 2.4rem;
  display: none;
`;

const LinkButton = styled.button`
  text-decoration: underline;
  color: #50a5e0;
  border: 0 none;
  background: none;
  cursor: pointer;
  padding: 0;
  &:focus {
    outline: none;
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => props.theme.primary["2"]};
    border-radius: 0.4rem;
  }
`;

const StyledAnchor = LinkButton.withComponent("a");

class CreateProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = { title: "", error: false };
    this.createProject = this.createProject.bind(this);
  }

  setTitle = title => {
    this.setState({ title, error: false });
  };

  onSubmit = path => {
    if (this.state.title.length === 0) {
      return this.setState({ error: true });
    }
    this.createProject(path);
  };

  createProject = path => {
    const { setPath, createProject } = this.props;
    const { title } = this.state;
    if (title === "") return;
    setPath(path);
    createProject(title);
  };

  render() {
    const error = this.state.error ? (
      <ErrorText>
        <Alert />
        Please give your project a name
      </ErrorText>
    ) : null;
    return (
      <StyledBody>
        <StyledHeader>Begin Your Journey</StyledHeader>
        <StyledImage src={art} alt="" />
        <StyledInput
          onChange={e => this.setTitle(e.target.value)}
          placeholder="Give your project a name"
          autoFocus="autofocus"
        />
        {error}
        <ButtonsFlex>
          <StyledButton
            onClick={() => this.onSubmit("surveys")}
            swatch="neutralDark"
          >
            Set Up Surveys
          </StyledButton>
          <StyledButton onClick={() => this.onSubmit("respondents")}>
            Set Up Respondents
          </StyledButton>
        </ButtonsFlex>
        <TextLine>
          This project's respondents will be automatically deduped using{" "}
          <StyledAnchor
            href={"http://www.imperium.com/services/relevantid/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            RelevantID
          </StyledAnchor>
          .
        </TextLine>
      </StyledBody>
    );
  }
}

export default CreateProject;
