import { CustomParams } from "../../types";
import { CMIX, SAMPLIFY } from "../../constants/titles";

export const NewSurveyName = "New Survey";

export const CMIX_OPTION_TEXT = "Link your Survey to this sample seamlessly.";
export const CMIX_LEARN_URL =
  "https://kb.platform.dynata.com/article/725-link-a-survey-from-cmix";
export const CMIX_DEFAULT_URL = "https://cmix.com";

export const EXTERNAL_LINK_WARNING_TEXT =
  "It seems that you’re using a " +
  CMIX +
  " survey. You can connect it to " +
  SAMPLIFY +
  " instead of setting it up as an external link.";

export const EntryLinkDescription =
  "This is where we send panelists into your survey.";

export const IdentifiersDescription1 =
  "To identify each panelist sent to your Survey Entry Link, we add unique values to that link.";
export const IdentifiersDescription2 =
  "Following are the variable names we’ll use for those values.";

export const DataAppendsDescription =
  "To obtain demographics information on the survey respondents, attach variables to the entry link from the list below.";

export const ExitLinksDescription1 =
  "Exit links are used to redirect panelists back to " +
  SAMPLIFY +
  " after they take your survey.";
export const ExitLinksDescription2 =
  "Insert the links into your survey platform; the highlighted placeholder might need to change depending on the platform.";

export const PID_TooltipText =
  "Panelist ID is an unique and static identifier for our panelists across all surveys. In the survey URL, “{{PID}}” is replaced by our panelist ID.";
export const PSID_TooltipText =
  "Session ID is an unique identifier for a panelist within a survey. In the survey URL, “{{PSID}}” is replaced by our session ID.";

export const DataAppendsDescriptionURL =
  "https://kb.platform.dynata.com/article/465-setting-up-survey-entry-links";

export enum STEP_STATES {
  INCOMPLETE = "INCOMPLETE",
  DISABLED = "DISABLED",
  COMPLETE = "COMPLETE",
  ERROR = "ERROR"
}

export const statusColors: { [key: string]: string } = {
  SUCCESS: "#52C1A3",
  FAILURE: "#F76E5C",
  IN_PROGRESS: "#696C7A"
};

export const SECURITY_KEY = "securityKey";
export const K2 = "k2";
export const PID = "pid";
export const PSID = "psid";

export const DEFAULT_PARAMS: CustomParams = {
  psid: PSID,
  pid: PID,
  securityKey: ""
};

type Colors = {
  text: string;
  background: string;
};
const pidColors: Colors = { text: "#9084DE", background: "#ECEAFB" };
const psidColors: Colors = { text: "#FCA360", background: "#FFF0E5" };
const k2Colors: Colors = { text: "#BA9544", background: "#F4EDE0" };
const securityColors: Colors = { text: "#CE32B4", background: "#FAECFA" };
interface ParamColorMap {
  [key: string]: Colors;
}
export const PARAMS_COLORS: ParamColorMap = {
  default: { text: "#9599A4", background: "inherit" },
  restricted: { text: "#696e7b", background: "inherit" },
  pid: pidColors,
  "{{PID}}": pidColors,
  "{pid}": pidColors,
  psid: psidColors,
  "{{PSID}}": psidColors,
  "{psid}": psidColors,
  k2: k2Colors,
  "{{K2}}": k2Colors,
  med: securityColors,
  high: securityColors,
  "{calculatedSecurityCode}": securityColors,
  _s: securityColors,
  _k: { text: "#91C6A6", background: "inherit" },
  _o: { text: "#D17E78", background: "inherit" },
  rst: { text: "#6AD2EA", background: "#E3F3F7" },
  secret: { text: "#B78F6A", background: "#EEE9E4" },
  toSign: { text: "#5E92B5", background: "inherit" }
};

export const DEFAULT_PLACEHOLDERS = ["{{PID}}", "{{PSID}}", "{{K2}}"];

export const dataAppendsColors = [
  "#CA99C5",
  "#F794C1",
  "#50A37D",
  "#EF738B",
  "#8F9FD8",
  "#7ACECE",
  "#B78F6A",
  "#F7BC52",
  "#DD6BA9",
  "#50698E"
];
