import styled from "styled-components";
import { SITE_MAX_WIDTH } from "../constants/styles";

const Wrap = styled.div`
  max-width: ${SITE_MAX_WIDTH};
  margin: 0 auto;
  padding: 0 1em;
  height: 100%;
`;

export default Wrap;
