import React from "react";

const ColumnSelect = ({ columns, update }) => (
  <React.Fragment>
    <h1>Columns</h1>
    <label>
      <input
        type="radio"
        value="condensed"
        checked={columns === "condensed"}
        onChange={update("columns")}
      />
      Condensed
    </label>
    <label>
      <input
        type="radio"
        value="expanded"
        checked={columns === "expanded"}
        onChange={update("columns")}
      />
      Expanded
    </label>
  </React.Fragment>
);

export default ColumnSelect;
