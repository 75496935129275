import { useEffect, useState } from "react";
import { ApolloError, ApolloQueryResult } from "apollo-client";
import { isEqual } from "lodash";
import useFetchUserInfo, { UserData } from "./useFetchUserInfo";
import {
  localEffCompIDKey,
  getEffectiveCompany,
  setLocalEffCompID
} from "../generalHelpers";

type Data = UserData | undefined | null;

type ReturnProps = {
  data: Data;
  error?: ApolloError;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<UserData>>;
};

type Props = {
  resetDashboard: () => void;
  resetInProgress: boolean;
  setResetInProgress: () => void;
};

const useUserInfo = ({
  resetDashboard,
  resetInProgress,
  setResetInProgress
}: Props): ReturnProps => {
  // use custom userData state to avoid re-render of whole samplify
  const [userData, setUserData] = useState<Data>(null);
  const { data, error, loading, refetch } = useFetchUserInfo();

  const effComp =
    data && data.userInfo && getEffectiveCompany(data.userInfo.companies);

  useEffect(() => {
    if (!loading && !isEqual(userData, data)) {
      setUserData(data);
      if (effComp) {
        setLocalEffCompID(effComp.id);
      }
    }
  }, [loading, data, userData, effComp]);

  window.onstorage = (storageEvent: StorageEvent) => {
    if (!resetInProgress && !loading && !error) {
      if (
        storageEvent.key === localEffCompIDKey &&
        storageEvent.oldValue !== storageEvent.newValue
      ) {
        setResetInProgress();
        resetDashboard();
      }
    }
  };

  return {
    data: loading || !data ? userData : data,
    error,
    loading: loading || userData === null,
    refetch
  };
};

export default useUserInfo;
