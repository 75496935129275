import { useQuery, QueryHookOptions } from "react-apollo-hooks";
import { externalSurveysQuery } from "../../lib/queries/surveys";
import { ExternalSurveys, AuthoringPlatform } from "../../types";

type Variables = {
  authoringPlatform: AuthoringPlatform;
  pageNumber: number;
  active: boolean;
  searchQuery?: string;
};

type Options = QueryHookOptions<Variables>;

export type Data = {
  externalSurveys: ExternalSurveys;
};

const useGetExternalSurveys = (
  authoringPlatform: AuthoringPlatform,
  active: boolean,
  searchQuery?: string
) => {
  const variables: Variables = {
    pageNumber: 1,
    authoringPlatform,
    active,
    searchQuery
  };
  const options: Options = {
    variables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  };

  return useQuery<Data, Variables>(externalSurveysQuery, options);
};

export default useGetExternalSurveys;
