export type Partial<T> = { [P in keyof T]?: T[P] };

export type Country = {
  ID: string;
  isoCode: string;
  name: string;
  supportedLanguages: Array<Language>;
};

export type Language = {
  ID: string;
  isoCode: string;
  name: string;
};

export type SourcesForCountry = {
  [languageISOCode: string]: Array<SampleSource>;
};

export type SampleSourceMap = {
  [countryISOCode: string]: SourcesForCountry;
};

export type Attributes = {
  countryISOCode: string;
  languageISOCode: string;
  list: string;
};

export enum Device {
  mobile = "mobile",
  desktop = "desktop",
  tablet = "tablet"
}

export interface Category {
  surveyTopic: Array<string>;
  studyType: Array<string>;
  studyRequirements: Array<string>;
}

export type ProjectHeader = {
  extProjectId: string;
  title: string;
  state: ProjectState;
  stateLastUpdatedAt: string;
  createdAt: string;
  updatedAt: string;
};

export enum ProjectState {
  PROVISIONED = "PROVISIONED",
  AWAITING_APPROVAL = "AWAITING_APPROVAL",
  LAUNCHED = "LAUNCHED",
  PAUSED = "PAUSED",
  CLOSED = "CLOSED",
  INVOICED = "INVOICED",
  REJECTED = "REJECTED",
  QA_APPROVED = "QA_APPROVED",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  AWAITING_APPROVAL_PAUSED = "AWAITING_APPROVAL_PAUSED",
  AWAITING_CLIENT_APPROVAL = "AWAITING_CLIENT_APPROVAL",
  REJECTED_PAUSED = "REJECTED_PAUSED",
  DRAFT = "DRAFT"
}

export type SampleSource = {
  ID: string;
  name: string;
  default: boolean;
  visibility: "VISIBLE" | "DISABLED";
};

export enum AuthoringPlatform {
  MACADAMIA = "macadamia",
  EXTERNAL = "external",
  CMIX = "cmix"
}

export type Survey = {
  id: string;
  name: string;
  liveLink: string;
  testLink: string;
  exitLinks: SurveyExitLinks;
  tests: Array<SurveyTest>;
  entryLink: EntryLink;
  surveyKey: string;
  hasCustomParams: boolean;
  customParams: CustomParams | null;
  formattedTestLink: string;
  formattedLiveLink: string;
  liveLinkErrors: Array<LinkError>;
  testLinkErrors: Array<LinkError>;
  authoringPlatform: AuthoringPlatform;
  dataAppends: DataAppends[];
  testingNotes: string;
  editLink: string | null;
  actions: SurveyAction[];
  localeLinks: Array<LocaleLink> | null;
};

export type LocaleLink = {
  localeName: string;
  countryISOCode: string;
  languageISOCode: string;
  liveLink: string;
  testLink: string;
  entryLink: string;
};

export type LinkError = {
  error: Error;
  paramList: Array<string>;
};

export type SurveyExitLinks = {
  complete: string;
  disqualified: string;
  overQuota: string;
};

export type CustomParams = {
  psid: string;
  pid: string;
  securityKey: string;
};

export type SurveyTest = {
  ID: string;
  status: TestStatus;
  latest: boolean;
  disposition: Disposition;
  testedAt: string;
};

export type Security = {
  securityLevel: SecurityLevel;
  securitykey1: string;
  securitykey2: string;
};

export enum SecurityLevel {
  NONE = "NONE",
  BASIC = "BASIC",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  SIGNED_URL = "SIGNED_URL"
}

export type Append = {
  attributeId: string;
  attributeName: string;
  customKey: string;
};

export type DataAppends = {
  countryISOCode: string;
  appends: Append[];
  __typename?: string;
};

export type DataAppendAttributes = {
  country: Country;
  attributes: Attribute[];
};

export type CountryEntryLink = {
  countryISOCode: string;
  entryLink: string;
};

export type EntryLink = {
  baseLink: string;
  countryEntryLinks: CountryEntryLink[];
};

export type Schedule = {
  startTime: string;
  endTime: string;
  __typename?: string;
};

export enum LineItemState {
  PROVISIONED = "PROVISIONED",
  AWAITING_APPROVAL = "AWAITING_APPROVAL",
  LAUNCHED = "LAUNCHED",
  PAUSED = "PAUSED",
  CLOSED = "CLOSED",
  INVOICED = "INVOICED",
  REJECTED = "REJECTED",
  REJECTED_PAUSED = "REJECTED_PAUSED",
  QA_APPROVED = "QA_APPROVED",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  AWAITING_APPROVAL_PAUSED = "AWAITING_APPROVAL_PAUSED",
  AWAITING_CLIENT_APPROVAL = "AWAITING_CLIENT_APPROVAL",
  AWAITING_AUTO_APPROVAL = "AWAITING_AUTO_APPROVAL"
}

export type SurveyLocale = {
  surveyId: string | null;
  countryISOCode: string | null;
  languageISOCode: string | null;
  __typename?: string;
};

export type LineItem = {
  extProjectId: string;
  countryISOCode: string;
  createdAt: string;
  daysInField: number | null;
  fieldSchedule: Schedule | null;
  deliveryType: DeliveryType;
  events: [LineItemEvent];
  extLineItemId: string;
  indicativeIncidence: number;
  languageISOCode: string;
  launchedAt: string;
  lengthOfInterview: number;
  quotaPlan: QuotaPlan;
  report: LineItemReport | null;
  requiredCompletes: number;
  cpi: string;
  state: LineItemState;
  stateLastUpdatedAt: string;
  stateReason: string;
  survey: SurveyLocale | null;
  title: string;
  feasibility: Feasibility;
  updatedAt: string;
  source: string;
  actions: [LineItemAction];
  quote: Quote;
  rejectionResolution: RejectionResolution | null;
  popularAttributes: [string];
  attributes: string;
};

export type Quote = {
  costPerUnit: string;
  detailedQuote: [DetailedQuote];
  estimatedCost: string;
};

export type DetailedQuote = {
  costPerUnit: string;
  estimatedCost: string;
  title: string;
  type: QuoteType;
  units: number;
};

export type QuoteType = "BASE" | "PREMIUM";

export type Attribute = {
  id: string;
  countryISOCode: string;
  languageISOCode: string;
  name: string;
  text: string;
  type: AttributeType;
  isAllowedInFilters: boolean;
  isAllowedInQuotas: boolean;
  options: string;
  format: string;
  state: AttributeState;
  category: AttributeCategory;
  tier: AttributeTier;
};

export type AttributeTier = "STANDARD" | "PREMIUM";

export type AttributeCategory = {
  mainCategory: AttrCategory;
  subCategory: AttrCategory;
};

type AttrCategory = {
  id: string;
  text: string;
  localizedText: string;
};

export type AttributeOption = {
  id: string;
  text: string;
  localizedText: string;
};

export type AttributeState = "ACTIVE" | "DEPRECATED" | "INACTIVE";

export type AttributeType = "LIST" | "INTEGER_RANGE" | "STRING" | "INTEGER";

export type AttributeMap = {
  [id: string]: Attribute;
};

export type AttributeOptionMap = {
  [id: string]: AttributeOption;
};

export type QuotaGroup = {
  __typename?: string;
  name: string;
  quotaCells: Array<QuotaCell>;
  quotaGroupReport: Report;
};

export type QuotaGroupInput = {
  __typename?: string;
  name: string;
  quotaCells: Array<QuotaCellInput>;
};

export type QuotaCell = {
  __typename?: string;
  quotaCellID: string;
  count: number;
  perc: number;
  status: string;
  quotaNodes: Array<QuotaNode>;
  quotaCellReport: Report;
  extProjectId: string;
  extLineItemId: string;
};

export type UpdateQuotaCellStatusData = {
  updateQuotaCellStatus: QuotaCell;
};

export type QuotaNode = {
  __typename?: string;
  attributeId: string;
  options: Array<string>;
};

export type QuotaCellInput = {
  __typename?: string;
  quotaCellID?: string;
  count: number;
  perc: number;
  status?: string;
  quotaNodes: Array<QuotaNodeInput>;
};

export type QuotaFilter = {
  __typename?: string;
  attributeId: string;
  options: Array<string>;
  operator: OperatorType;
};

export type QuotaNodeInput = {
  __typename?: string;
  attributeId: string;
  options: Array<string>;
};

export type OperatorType = "EXCLUDE" | "INCLUDE";

export type QuotaFilterInput = {
  __typename?: string;
  attributeId: string;
  options: Array<string>;
  operator: OperatorType;
};

export type QuotaPlan = {
  __typename?: string;
  filters: Array<QuotaFilter>;
  quotaGroups: Array<QuotaGroup>;
};

export type QuotaPlanInput = {
  __typename?: string;
  filters: Array<QuotaFilterInput>;
  quotaGroups: Array<QuotaGroupInput>;
};

type EventValues = {
  newValue: string;
  previousValue: string;
};

type EventStatusValues = {
  newValue: string;
  previousValue: string;
};

type EventResource = {
  costPerInterview: EventValues;
  estimatedCost: EventValues;
  lengthOfInterview: EventValues;
  indicativeIncidence: EventValues;
  incurredCost: EventValues;
  currency: string;
  status: EventStatusValues;
  reason: string;
};

export type LineItemEvent = {
  eventId: string;
  eventType: string;
  extProjectId: string;
  extLineItemId: string;
  resource: EventResource;
  createdAt: string;
  details: EventDetails;
};

export type EventDetails = {
  comments: string;
  reasons: Array<EventReasons>;
};

export type EventReasons = {
  code: string;
  description: string;
};

export type ActionMetadata = {
  label: string;
  enabled: boolean;
  visible: boolean;
  description: string;
};

export type ActionType = {
  kind:
    | LineItemActionKind
    | UserActionKind
    | ProjectActionKind
    | SurveyActionKind;
  metadata: ActionMetadata;
};

export type LineItemAction = {
  kind: LineItemActionKind;
  metadata: ActionMetadata;
};

export type UserAction = {
  kind: UserActionKind;
  metadata: ActionMetadata;
};

export type ProjectAction = {
  kind: ProjectActionKind;
  metadata: ActionMetadata;
};

export type SurveyAction = {
  kind: SurveyActionKind;
  metadata: ActionMetadata;
};

export type ActionStateMap = { [key in LineItemActionKind]: LineItemAction };

export enum LineItemActionKind {
  PAUSE = "PAUSE",
  LAUNCH = "LAUNCH",
  CLOSE = "CLOSE",
  APPROVE_REPRICE = "APPROVE_REPRICE",
  DECLINE_REPRICE = "DECLINE_REPRICE",
  DUPLICATE = "DUPLICATE",
  EDIT = "EDIT",
  SPLIT_SOURCE = "SPLIT_SOURCE",
  ESTIMATES = "ESTIMATES",
  EDIT_TITLE = "EDIT_TITLE",
  EDIT_REQUIRED_COMPLETES = "EDIT_REQUIRED_COMPLETES",
  EDIT_FIELDING_DAYS = "EDIT_FIELDING_DAYS",
  EDIT_DELIVERY_TYPE = "EDIT_DELIVERY_TYPE",
  EDIT_SURVEY_ID = "EDIT_SURVEY_ID",
  EDIT_LENGTH_OF_INTERVIEW = "EDIT_LENGTH_OF_INTERVIEW",
  EDIT_COUNTRY_ISO = "EDIT_COUNTRY_ISO",
  EDIT_LANGUAGE_ISO = "EDIT_LANGUAGE_ISO",
  EDIT_SOURCE_ID = "EDIT_SOURCE_ID",
  EDIT_INDICTIVE_INCIDENCE = "EDIT_INDICTIVE_INCIDENCE",
  EDIT_ATTRIBUTES = "EDIT_ATTRIBUTES",
  EDIT_QUOTA_PLAN = "EDIT_QUOTA_PLAN",
  EDIT_QUOTA_CELL = "EDIT_QUOTA_CELL"
}

export enum UserActionKind {
  CREATE_NEW_PROJECT = "CREATE_NEW_PROJECT"
}

export enum ProjectActionKind {
  CREATE_NEW_LINE_ITEM = "CREATE_NEW_LINE_ITEM",
  CREATE_NEW_SURVEY = "CREATE_NEW_SURVEY",
  DOWNLOAD_INVOICE = "DOWNLOAD_INVOICE",
  REQUEST_RECONCILIATION = "REQUEST_RECONCILIATION",
  CLOSE_OR_SUMMARY = "CLOSE_OR_SUMMARY",
  EDIT_TOPICS = "EDIT_TOPICS",
  EDIT_DEVICES = "EDIT_DEVICES",
  EDIT_EXCLUSIONS = "EDIT_EXCLUSIONS",
  EDIT_RESTRICTIONS = "EDIT_RESTRICTIONS",
  EDIT_PO_NUMBER = "EDIT_PO_NUMBER",
  EDIT_TITLE = "EDIT_TITLE",
  DUPLICATE = "DUPLICATE",
  EDIT = "EDIT"
}

export enum SurveyActionKind {
  EDIT_TITLE = "EDIT_TITLE",
  EDIT_LIVE_LINK = "EDIT_LIVE_LINK",
  EDIT_IDENTIFIERS = "EDIT_IDENTIFIERS",
  EDIT_DATA_APPENDS = "EDIT_DATA_APPENDS",
  EDIT_TEST_LINK = "EDIT_TEST_LINK",
  EDIT_NOTES = "EDIT_NOTES",
  EDIT = "EDIT",
  DELETE = "DELETE",
  TEST = "TEST"
}

export enum ResourceType {
  USER = "User",
  SURVEY = "Survey",
  PROJECT = "Project",
  LINEITEM = "LineItem"
}

export type Disposition =
  | "COMPLETE"
  | "DISQUALIFICATION"
  | "OVERQUOTA"
  | "IN_PROGRESS"
  | "FAILED";

export type TestStatus = "SUCCESS" | "FAILURE" | "IN_PROGRESS";

export enum DeliveryType {
  SLOW = "SLOW",
  BALANCED = "BALANCED",
  FAST = "FAST",
  BURST = "BURST",
  UNLIMITED = "UNLIMITED"
}

type StatStatus = "OK" | "Info" | "Warning" | "Error" | "Critical";

export type Stat = {
  intValue: number;
  floatValue: number;
  percentageValue: number;
  percentageValueRounded: number;
  status: StatStatus;
  statusMessage: string;
};

export type Source = {
  ID: string;
  name: string;
};

export interface LineItemReport {
  extLineItemId: string;
  state: LineItemState;
  title: string;
  stats: Report;
  cost: Cost;
  sources: Source[];
  actualIncidence: Stat;
}

export type Report = {
  attempts: Stat;
  completes: Stat;
  screenouts: Stat;
  overquotas: Stat;
  incompletes: Stat;
  completesRefused: Stat;
  conversion: Stat;
  incidence: Stat;
  progress: Stat;
  remainingCompletes: Stat;
  actualMedianLOI: Stat;
};

export type Cost = {
  costPerUnit: string;
  estimatedCost: string;
  incurredCost: string;
  detailedCost: Array<DetailedCost>;
};

export type DetailedCost = {
  costPerUnit: string;
  estimatedCost: string;
  incurredCost: string;
  title: string;
  type: CostType;
  deliveredUnits: number;
  requestedUnits: number;
};

export type CostType = "BASE" | "PREMIUM";

export type LineItemSettingsInput = {
  title: string;
  survey: SurveyLocale | null;
  lengthOfInterview: number;
  countryISOCode: string;
  languageISOCode: string;
  source: string;
  indicativeIncidence: number;
  daysInField: number | null;
  requiredCompletes: number;
  deliveryType: string;
  fieldSchedule: Schedule | null;
};

export type AddLineItemInput = {
  extProjectId: string;
  quotaPlan?: QuotaPlanInput;
} & LineItemSettingsInput;

export type UpdateLineItemInput = {
  extProjectId: string;
  extLineItemId: string;
  quotaPlan?: QuotaPlanInput;
  templateID: number | null;
} & LineItemSettingsInput;

export type DuplicateLineItemInput = {
  extProjectId: string;
  extLineItemId: string;
};

export type FeasibilityStatus =
  | "READY"
  | "PROCESSING"
  | "NOT_SUPPORTED"
  | "FAILED";

export type FeasibilityQuotaNode = {
  __typename?: string;
  attributeId: string;
  options: Array<string>;
};

export type FeasibilityQuotaCell = {
  quotaNodes: Array<FeasibilityQuotaNode>;
  feasibilityCount: number;
  level: FeasibilityLevel;
};

export type FeasibilityQuotaGroup = {
  quotaCells: Array<FeasibilityQuotaCell>;
};

export type FeasibilityState = {
  feasibility: Feasibility | null;
  isPolling: boolean;
  timedOut: boolean;
};

export type Feasibility = {
  extLineItemId: string;
  status: FeasibilityStatus;
  costPerInterview: string;
  currency: string;
  feasible: boolean;
  errors: Array<Error>;
  totalCount: number;
  level: FeasibilityLevel;
  valueCounts: Array<FeasibilityQuotaGroup>;
};

export type FeasibilityLevel = "OK" | "LOW" | "CRITICAL";

export enum ErrorCode {
  UNKNOWN = "UNKNOWN",
  CUSTOM_MESSAGE = "CUSTOM_MESSAGE",
  INVALID_URL = "INVALID_URL",
  INVALID_IDENTIFIERS = "INVALID_IDENTIFIERS",
  INVALID_ATTRIBUTE_OPTIONS = "INVALID_ATTRIBUTE_OPTIONS"
}

export type Error = {
  id: string;
  code: ErrorCode;
  title: string;
  message: string;
};

export type BasicGraphQLError = {
  message: string;
};

export type RejectionResolution = {
  comments: string;
  reasons: Reason[];
  rejectionType: string;
};

export type Reason = {
  code: string;
};

export enum AppID {
  CMIX = "CMIX",
  SAMPLIFY = "SAMPLIFY",
  MARKET_SIGHT = "MARKET_SIGHT",
  INSTANT_INSIGHTS = "INSTANT_INSIGHTS"
}

export type Application = {
  id: AppID;
  title: string;
  description: string;
  icon: string;
  url: string;
};

export type UserTermsInfo = {
  termsAccepted: boolean;
  signedVersion: number;
  currentVersion: number;
};

export type UserSettings = {
  allowSurveyBuilder: boolean;
  applications: Array<Application>;
};

export type UserInfo = {
  id: string | null;
  userName: string | null;
  userTermsInfo: UserTermsInfo;
  roles: Array<string>;
  firstName: string;
  fullName: string;
  initials: string;
  settings: UserSettings;
  email: string;
  companies: Array<Company>;
  helpScoutSignature: string;
  hsDashboardMessage: string;
  hsRespondentsMessage: string;
  hsSurveysMessage: string;
  hsInvoicesMessage: string;
  actions: Array<UserAction>;
};

export type Company = {
  isDefault: boolean;
  isEffective: boolean;
  id: number;
  name: string;
};

export type ProjectReport = {
  extProjectId: string;
  state: string;
  avgCpi: string;
  stats: Report;
  cost: Cost;
  lineitemReport: LineItemReport[];
};

export enum BillingType {
  AGGREGATED_MONTHLY = "AGGREGATED_MONTHLY",
  PER_PROJECT = "PER_PROJECT"
}

export type Billing = {
  ID: string;
  type: BillingType;
  billingDate: string;
};

export type Project = {
  extProjectId: string;
  title: string;
  jobNumber: string;
  userName: string;
  notificationEmails: string[];
  devices: Device[];
  category: Category;
  respondentFilters: RespondentFilters;
  report: ProjectReport;
  state: ProjectState;
  stateLastUpdatedAt: string;
  createdAt: string;
  updatedAt: string;
  launchedAt: string;
  closedAt: string;
  securitykey1: string;
  securitykey2: string;
  securityLevel: string;
  lineItems: LineItem[];
  lineItem: LineItem;
  surveys: Survey[];
  billing: Billing;
  viewOnly: boolean;
  dynataManaged: boolean;
  actions: ProjectAction[];
};

export type Invoice = {
  name: string;
  type: BillingType;
  projects: Project[];
  status: string;
  cost: string;
};
export type ExclusionType =
  | "PROJECT"
  | "TAG"
  | "THIS_MONTH"
  | "LAST_MONTH"
  | "LAST_THREE_MONTHS"
  | "CUSTOM_TIME_PERIOD";

export type ExcludableProject = {
  extProjectId: string;
  title: string;
};

export type ExcludableProjects = Array<ExcludableProject>;

export type Duplicatable = {
  isDuplicatable: {
    isDuplicatable: boolean;
    deprecatedAttributes: Attribute[];
  };
};

export type TargetingTemplate = {
  id: string;
  name: string;
  description: string;
  state: string;
  countryISOCode: string;
  languageISOCode: string;
  tags: string[];
  createdAt: string;
  updatedAt: string;
  editable: boolean;
  quotaPlan: QuotaPlan;
};

export type CreateTargetingTemplateInput = {
  name: string;
  description: string;
  countryISOCode: string;
  languageISOCode: string;
  quotaPlan: QuotaPlanInput;
  tags: string[];
};
export type MetaDataItem = {
  name: string;
  id: string;
  description: string;
  allowed: boolean;
};

export type CategoryMetadata = {
  studyTypes: Array<MetaDataItem>;
  studyRequirements: Array<MetaDataItem>;
  surveyTopics: Array<MetaDataItem>;
};

export type StudyMetadata = {
  category: CategoryMetadata;
  deliveryTypes: Array<MetaDataItem>;
};

export type CreateSurveyInput = {
  input: {
    extProjectId: string;
    name: string;
    authoringPlatform: AuthoringPlatform;
    extSurveyId?: string;
  };
};

export type UpdateSurveyInput = {
  input: {
    id: string;
    extProjectId: string;
    liveLink?: string;
    testLink?: string;
    customParams?: CustomParamsInput;
    dataAppends?: DataAppends[];
    testingNotes?: string;
  };
};

type CustomParamsInput = {
  psid: string;
  pid: string;
  securityKey?: string;
};

export type ExternalSurveys = {
  authoringPlatform: AuthoringPlatform;
  totalSurveys: number;
  nextPage: number;
  hasNext: boolean;
  surveys: Array<ExternalSurvey>;
  __typename?: string;
};

export enum ExternalSurveyStatus {
  LIVE = "LIVE",
  DESIGN = "DESIGN",
  TEST = "TEST",
  CLOSED = "CLOSED"
}

export type ExternalSurvey = {
  id: string;
  name: string;
  createdAt: string;
  modifiedAt: string;
  status: ExternalSurveyStatus;
  mappedProject: MappedProject | null;
};

export type MappedProject = {
  projectID: string;
  projectName: string;
  surveyID: string;
};

export enum QuotaCellAction {
  PAUSE = "pause",
  LAUNCH = "launch"
}

export type UpdateQuotaCellStatusInput = {
  input: {
    extProjectID: string;
    extLineItemID: string;
    quotaCellID: string;
    action: QuotaCellAction;
  };
};

export type RespondentFilterDispositionType =
  | "COMPLETED"
  | "SCREENOUT"
  | "INCOMPLETE"
  | "OVERQUOTA";

export type RespondentFilterScheduleType =
  | "ALL_DATES"
  | "THIS_MONTH"
  | "LAST_DAYS"
  | "LAST_MONTHS"
  | "CUSTOM";

export type RespondentFilterDispositions = Array<
  RespondentFilterDispositionType
>;

export type RespondentFilterSchedule = {
  type: RespondentFilterScheduleType;
  value: number;
  startDate: string | null;
  endDate: string | null;
};

export type RespondentFilterProjects = {
  type: ExclusionType;
  list: Array<ExcludableProject>;
  startDate?: string | null | undefined;
  endDate?: string | null | undefined;
};

export type RespondentFilter = {
  projects: RespondentFilterProjects;
  dispositions: RespondentFilterDispositions;
  schedule: RespondentFilterSchedule;
};

export type UpdateRespondentFilterInput = {
  projects: {
    list: Array<string>;
    type: ExclusionType;
    startDate?: string | null;
    endDate?: string | null;
  };
  dispositions: RespondentFilterDispositions;
  schedule: RespondentFilterSchedule;
};

export type RespondentFilters = Array<RespondentFilter>;
