import React, { ChangeEvent } from "react";
import styled from "styled-components";
import {
  ModalBody,
  ModalFooter,
  Checkbox,
  TextInput,
  Button,
  Tooltip
} from "samplify-design";
import FocusTrap from "focus-trap-react";
import ModalPropsContext from "../../containers/ModalPropsContext";
import { Country, SampleSource, SourcesForCountry } from "../../types";
import useSplitSource from "../../hooks/lineitem/useSplitSource";
import useSplitSourceState from "../../hooks/lineitem/useSplitSourceState";
import StyledSpinner from "../StyledSpinner";
import ModalCancelButton from "../ModalCancelButton";
import { disabledSourceTooltipText } from "../../lineItemHelpers";

const ErrorBody = styled.div`
  margin-top: 1em;
  text-align: left;
  color: ${({ theme }) => theme.danger["2"]};
`;

const StyledBody = styled(ModalBody)`
  border-radius: 1rem 1rem 0 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  font-size: 1.2rem;
  flex-grow: unset;
`;
const Outer = styled.div`
  max-width: 37rem;
`;
const H1 = styled.h1`
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 2rem;
  font-size: 1.6rem;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 1.3rem;
  input {
    width: 1px;
  }
`;
const StyledTextInput = styled(TextInput)`
  width: 6rem;
  font-size: 1.2rem;
`;

const StyledTooltip = styled(Tooltip)`
  z-index: 99999;
`;

type RowProps = {
  country: Country;
  language: string;
  sourceId: string;
  sourcesForCountry: SourcesForCountry | null;
  name: string;
  requiredCompletes: string;
  checked: boolean;
  checkDisabled: boolean;
  textDisabled: boolean;
  className?: string;
  onTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onCheckedChange: () => void;
};
const SourceRow = ({
  country,
  language,
  sourceId,
  sourcesForCountry,
  name,
  requiredCompletes,
  checked,
  checkDisabled,
  textDisabled,
  className,
  onTextChange,
  onCheckedChange
}: RowProps) => {
  const row = (
    <div className={className}>
      <label>
        <StyledCheckbox
          onChange={onCheckedChange}
          checked={checked}
          disabled={checkDisabled}
          tabIndex={0}
        />
        <span>{name}</span>
      </label>
      <StyledTextInput
        type="number"
        step={1}
        min={0}
        value={requiredCompletes}
        onChange={onTextChange}
        disabled={textDisabled}
      />
    </div>
  );
  if (checkDisabled) {
    const tooltipText = disabledSourceTooltipText(
      country,
      language,
      sourceId,
      sourcesForCountry
    );
    return (
      <StyledTooltip overlay={tooltipText} placement="top" mouseLeaveDelay={0}>
        {row}
      </StyledTooltip>
    );
  }
  return row;
};

const StyledSourceRow = styled(SourceRow)`
  background-color: ${({ checked }: RowProps) =>
    checked ? "rgba(80, 165, 224, 0.1)" : "transparent"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  position: relative;
`;
const Strong = styled.strong`
  font-weight: 600;
`;
const Total = styled.span`
  margin-top: 2rem;
`;

const StyledDisabledSourceRow = styled(StyledSourceRow)`
  color: var(--light-color-3);
  cursor: not-allowed;
  * {
    cursor: not-allowed;
  }
`;

type Props = {
  country: Country;
  sampleSources: Array<SampleSource>;
  sourcesForCountry: SourcesForCountry | null;
  lineItem: any;
  clearModal: () => void;
};

type ModalPropsContextArg = {
  modalProps: {
    project: Props;
  };
};

const SplitSource = ({
  country,
  sampleSources,
  sourcesForCountry,
  lineItem,
  clearModal
}: Props) => {
  const { extProjectId, extLineItemId, languageISOCode } = lineItem;
  const { splitSource, mutationResult } = useSplitSource(
    extProjectId,
    extLineItemId
  );
  const { loading, error } = mutationResult;
  const {
    state,
    totalRequired,
    sourcesValid,
    inputSources,
    makeTextChangeHandler,
    makeCheckedChangeHandler
  } = useSplitSourceState({ sampleSources, lineItem });
  const rowStates = Object.values(state);
  const onConfirm = () => {
    splitSource(inputSources).then(result => {
      if (result.data !== undefined) clearModal();
    });
  };
  const maybeError =
    error !== undefined ? (
      <ErrorBody>Splitting source failed. Please try again.</ErrorBody>
    ) : null;
  const buttonChild = loading ? <StyledSpinner /> : "Split";
  return (
    <FocusTrap>
      <Outer>
        <StyledBody>
          <H1>Split Source</H1>
          {rowStates.map(srcState => {
            const checked = srcState.checked;
            const visible = srcState.source.visibility === "VISIBLE";
            return visible ? (
              <StyledSourceRow
                country={country}
                language={languageISOCode}
                sourceId={srcState.source.ID}
                sourcesForCountry={sourcesForCountry}
                key={srcState.source.ID}
                name={srcState.source.name}
                requiredCompletes={srcState.requiredCompletes}
                checked={srcState.checked}
                checkDisabled={!visible}
                textDisabled={!visible || !srcState.checked}
                onTextChange={makeTextChangeHandler(srcState.source.ID)}
                onCheckedChange={makeCheckedChangeHandler(srcState.source.ID)}
              />
            ) : (
              <StyledDisabledSourceRow
                country={country}
                language={languageISOCode}
                sourceId={srcState.source.ID}
                sourcesForCountry={sourcesForCountry}
                key={srcState.source.ID}
                name={srcState.source.name}
                requiredCompletes={srcState.requiredCompletes}
                checked={checked}
                checkDisabled={!visible}
                textDisabled={!visible || !checked}
                onTextChange={makeTextChangeHandler(srcState.source.ID)}
                onCheckedChange={makeCheckedChangeHandler(srcState.source.ID)}
              />
            );
          })}
          <Total>
            <Strong>{totalRequired}</Strong> total completes
          </Total>
          {maybeError}
        </StyledBody>
        <ModalFooter>
          <ModalCancelButton disabled={loading} onClick={clearModal}>
            Cancel
          </ModalCancelButton>
          <Button disabled={loading || !sourcesValid} onClick={onConfirm}>
            {buttonChild}
          </Button>
        </ModalFooter>
      </Outer>
    </FocusTrap>
  );
};

const SplitSourceOuter = ({ clearModal }: { clearModal: () => void }) => (
  <ModalPropsContext.Consumer>
    {({ modalProps }: ModalPropsContextArg) => {
      const {
        country,
        sampleSources,
        sourcesForCountry,
        lineItem
      } = modalProps.project;
      return (
        <SplitSource
          country={country}
          sampleSources={sampleSources}
          sourcesForCountry={sourcesForCountry}
          lineItem={lineItem}
          clearModal={clearModal}
        />
      );
    }}
  </ModalPropsContext.Consumer>
);

export default SplitSourceOuter;
