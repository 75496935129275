import React, { Fragment } from "react";
import styled from "styled-components";
import { Button, ModalCancel } from "samplify-design";
import { Spinner } from "samplify-design";
import calculatorIcon from "../../../art/calculator.svg";
import { isCloseable } from "../../../projectHelpers";
import { ProjectState, ProjectActionKind } from "../../../types";
import { ActionsMap } from "../../../generalHelpers";

const StyledButton = styled(Button)`
  margin-left: 1em;
  background-color: transparent;
  color: #3ea8dd;
  font-size: 14px;
  padding: 0;
  &:hover {
    background: transparent;
    text-decoration: underline;
  }
`;

const StyledSendButton = styled(Button)`
  margin-left: 1em;
  background-color: #3ea8dd;
  &:disabled {
    background-color: #95cfec;
  }
`;

const StyledButtonIcon = styled.img`
  float: left;
  margin-right: 8px;
`;

interface PropTypes {
  sendDisabled: boolean;
  showCorrection: boolean;
  state: ProjectState;
  loadingCorrection: boolean;
  modal?: boolean;
  fromRespondent?: boolean;
  projectActionsMap: ActionsMap;
  toggleShowCorrection: () => void;
  onApproveSummary: () => void;
  onCancel?: () => void;
  onSendCorrection: () => void;
}

const FooterContent = ({
  onApproveSummary,
  onCancel,
  onSendCorrection,
  sendDisabled,
  showCorrection,
  state,
  toggleShowCorrection,
  loadingCorrection,
  modal,
  fromRespondent,
  projectActionsMap
}: PropTypes) => {
  const maybeCancelBtn = modal && (
    <ModalCancel onClick={onCancel}>Cancel</ModalCancel>
  );
  const actionProjectRequestRecon =
    projectActionsMap[ProjectActionKind.REQUEST_RECONCILIATION];
  const {
    label: requestLabel,
    visible: requestVisible,
    enabled: requestEnabled
  } = actionProjectRequestRecon;

  const canRequestRecon = !fromRespondent && !showCorrection && requestVisible;
  const maybeRequestCorrectionBtn = canRequestRecon ? (
    <StyledButton onClick={toggleShowCorrection} disabled={!requestEnabled}>
      <StyledButtonIcon src={calculatorIcon} alt="Calculator" />
      {requestLabel}
    </StyledButton>
  ) : null;
  const maybeApproveBtn = !showCorrection && isCloseable(state) && (
    <StyledSendButton onClick={onApproveSummary} swatch="accent">
      Close & Invoice
    </StyledSendButton>
  );
  const maybeCorrection = showCorrection && (
    <StyledSendButton
      disabled={sendDisabled || loadingCorrection}
      onClick={onSendCorrection}
    >
      {loadingCorrection ? <Spinner /> : "Send Correction"}
    </StyledSendButton>
  );
  return (
    <Fragment>
      {maybeCancelBtn}
      {maybeRequestCorrectionBtn}
      {maybeApproveBtn}
      {maybeCorrection}
    </Fragment>
  );
};

export default FooterContent;
