import React from "react";
import styled from "styled-components";
import caret from "../../art/Caret.svg";
import CompanySwitcherMenu from "./CompanySwitcherMenu";
import Popover from "react-popover";
import { AccountMenuCommonStyles } from "./AccountMenuStyles";
import { Company } from "../../types";
import useSwitchCompany from "../../hooks/useCompanySwitch";
import HEADER, { SWITCH_COMPANY } from "../../idHelpers";

const CaretIcon = styled.img`
  width: 0.9rem;
  margin-left: 0.7rem;
`;

const StyledCompanySwitcher = styled.button`
  align-items: center;
  font: normal 400 1.2rem/2.4rem ${props => props.theme.fontFamily};
  color: var(--dark-color-3);
  background: none;
  border: none;
  justify-content: center;
  cursor: pointer;
  height: fit-content;
  padding: 0;
  margin: auto 0.9rem auto 2.1rem;
  &:focus {
    outline: none;
    border-color: transparent;
    box-shadow: none;
  }
`;

const StyledSpan = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 14rem;
`;

const StyledPopover = styled(Popover)`
  ${AccountMenuCommonStyles}
  transform: translate(-1.2rem, 0.5rem) !important;
`;

type Props = {
  effectiveCompany: Company;
  companies: Array<Company>;
  companySwitcherOpen: boolean;
  toggleCompanySwitcher: () => void;
  onSwitchSuccess: (c: Company) => void;
  onSwitchFailure: () => void;
};

const CompanySwitcher = ({
  effectiveCompany,
  companies,
  companySwitcherOpen,
  toggleCompanySwitcher,
  onSwitchSuccess,
  onSwitchFailure
}: Props) => {
  const { switchCompany } = useSwitchCompany();

  const onCompanySelect = (c: Company) => {
    switchCompany(c)
      .then(res => {
        if (res && res.data && res.data.switchCompany) {
          onSwitchSuccess(c);
        } else {
          onSwitchFailure();
        }
      })
      .finally(() => toggleCompanySwitcher());
  };

  return (
    <StyledPopover
      isOpen={companySwitcherOpen}
      body={
        <CompanySwitcherMenu
          companies={companies}
          onCompanySelect={onCompanySelect}
        />
      }
      enterExitTransitionDurationMs={0}
      tipSize={0.1}
      place="below"
      onOuterAction={toggleCompanySwitcher}
    >
      <StyledCompanySwitcher
        id={HEADER + "-" + SWITCH_COMPANY}
        onClick={toggleCompanySwitcher}
      >
        <StyledSpan>{effectiveCompany.name}</StyledSpan>
        <CaretIcon src={caret} alt="" />
      </StyledCompanySwitcher>
    </StyledPopover>
  );
};

export default CompanySwitcher;
