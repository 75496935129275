import React, { Fragment } from "react";
import styled from "styled-components";
import { ErrorSplash, Spinner } from "samplify-design";
import debounce from "lodash/debounce";
import InfiniteScroll from "react-infinite-scroll-component";
import CmixSurveyListItem from "./CmixSurveyListItem";
import { ExternalSurvey } from "../../types";
import svgFile from "../../art/iconFile.svg";
import { CMIX } from "../../constants/titles";
import LinkAsButton from "../LinkAsButton";

const Godzilla = styled(ErrorSplash)`
  width: auto;
  height: 30rem;
`;
const ErrorOuter = styled.div`
  text-align: center;
`;
const Blurb = styled.p`
  font-weight: 600;
  font-size: 1.4rem;
`;
const PageError = () => (
  <ErrorOuter>
    <Blurb>
      Uh oh! It appears we're experiencing issues. Please try again in a few
      moments.
    </Blurb>
    <Godzilla />
  </ErrorOuter>
);

const LoadingOuter = styled.div`
  text-align: center;
  font-size: 3rem;
  margin-top: 1rem;
`;
const PageLoading = () => (
  <LoadingOuter>
    <Spinner />
  </LoadingOuter>
);

const NoSurveysOuter = styled.div`
  display: block;
  text-align: center;
  align-items: center;
  margin-top: 5rem;
`;
const StyledFileIcon = styled.img`
  height: 7.4rem;
  width: auto;
`;
const StyledNoSurveysBlurb = styled.p`
  color: var(--dark-color-3);
  font: normal 400 1.4rem/2.1rem ${props => props.theme.fontFamily};
`;
const StyledLinkAsButton = styled(LinkAsButton)`
  display: inline-flex;
  font: normal 600 1.4rem/2.2rem ${props => props.theme.fontFamily};
  color: var(--light-color-6);
  background-color: var(--accent-color-2);
  &:hover {
    background-color: var(--accent-color-0);
  }
`;

interface PageNoSurveysProps {
  activeSectionOpen: boolean;
  cmixURL: string;
}
const PageNoSurveys = ({ activeSectionOpen, cmixURL }: PageNoSurveysProps) => {
  const imperative = activeSectionOpen ? "available" : "in use";

  return (
    <NoSurveysOuter>
      <StyledFileIcon src={svgFile} alt="" />
      <StyledNoSurveysBlurb>
        You don’t have any {CMIX} surveys {imperative} now.
      </StyledNoSurveysBlurb>
      <StyledLinkAsButton
        href={cmixURL}
        target="_blank"
        rel="noopener noreferrer"
      >
        Go to {CMIX}
      </StyledLinkAsButton>
    </NoSurveysOuter>
  );
};

interface Props {
  cmixURL: string;
  cmixIconURL: string;
  samplifyIconURL: string;
  closeModal: () => void;
  createSurvey: (surveyName: string, surveyId: string) => void;
  activeSectionOpen: boolean;
  surveys: ExternalSurvey[];
  error: boolean;
  hasNext: boolean;
  addMoreSurveys: () => void;
  fetching: boolean;
  refetching: boolean;
}

const CmixSurveyList = ({
  cmixURL,
  cmixIconURL,
  samplifyIconURL,
  closeModal,
  createSurvey,
  activeSectionOpen,
  surveys,
  error,
  hasNext,
  addMoreSurveys,
  fetching,
  refetching
}: Props) => {
  if (error) {
    return <PageError />;
  }
  if (fetching && surveys.length === 0) {
    return <PageLoading key={"loader-" + 0} />;
  }
  if (surveys.length === 0) {
    return (
      <PageNoSurveys activeSectionOpen={activeSectionOpen} cmixURL={cmixURL} />
    );
  }

  const maybeRefetching = refetching ? (
    <PageLoading key={"loader-" + 1} />
  ) : null;

  const debouncedLoadMore = debounce(addMoreSurveys, 500, {
    leading: true,
    trailing: false
  });

  const surveyRows = surveys.map(s => {
    return (
      <CmixSurveyListItem
        key={s.id}
        cmixIconURL={cmixIconURL}
        samplifyIconURL={samplifyIconURL}
        survey={s}
        closeModal={closeModal}
        createSurvey={createSurvey}
        activeSectionOpen={activeSectionOpen}
      />
    );
  });

  return (
    <Fragment>
      {maybeRefetching}
      <InfiniteScroll
        dataLength={surveys.length}
        next={debouncedLoadMore}
        hasMore={hasNext}
        loader={<PageLoading key={"loader-" + 2} />}
        height={refetching ? 288 : 340}
      >
        {surveyRows}
      </InfiniteScroll>
    </Fragment>
  );
};

export default CmixSurveyList;
