import React, { useContext } from "react";
import ModalPropsContext from "../../containers/ModalPropsContext";
import useCloseProject from "../../hooks/project/useCloseProject";
import ConfirmationModal from "./ConfirmationModal";

type Props = {
  clearModal: () => void;
};

const ConfirmCloseProject = ({ clearModal }: Props) => {
  const { modalProps } = useContext(ModalPropsContext);
  const [closeProject] = useCloseProject(modalProps.extProjectId);

  const closeProj = () => {
    closeProject();
    clearModal();
  };

  return (
    <ConfirmationModal
      confirmation={
        <p>Are you sure you want to close and bill this project?</p>
      }
      onCancel={clearModal}
      onConfirm={closeProj}
      confirmLabel={"Close"}
    />
  );
};

export default ConfirmCloseProject;
