import React from "react";
import styled from "styled-components";
import { Button } from "samplify-design";
import { ExternalSurvey } from "../../types";
import CmixSurveyStatus from "./CmixSurveyStatus";

const OuterDiv = styled.div`
  &:not(:first-child) {
    margin-top: 1.2rem;
  }
  &:last-child {
    margin-bottom: 1.2rem;
  }
`;

type CustomButtonProps = {
  activeSectionOpen: boolean;
} & React.HTMLProps<HTMLButtonElement>;

const CustomButton = ({ activeSectionOpen, ...rest }: CustomButtonProps) => {
  return <Button {...rest} />;
};
const Box = styled(CustomButton)`
  align-items: center;
  background: #ffffff;
  font: normal 400 1.4rem/2.1rem ${props => props.theme.fontFamily};
  border: 0.1rem solid #dedfe3;
  box-sizing: border-box;
  border-radius: 0.8rem;
  height: 6.5rem;
  width: 98%;
  padding: 1rem 1.8rem;
  cursor: ${props => (props.activeSectionOpen ? "pointer" : "default")};
  &:active {
    background: #ffffff;
  }
  &:hover {
    background-color: ${props =>
      props.activeSectionOpen ? "var(--primary-color-5)" : "#ffffff"};
  }
  &:focus {
    box-shadow: ${props => (props.activeSectionOpen ? "inherit" : "none")};
  }
`;

const StyledGrid = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns:
    minmax(2.5rem, max-content)
    minmax(42rem, max-content)
    minmax(10rem, max-content);
`;

const StyledGridCol = styled.div`
  display: flex;
`;

const StyledAppIcon = styled.img`
  width: auto;
  height: 2.4rem;
  margin: auto;
`;

const StyledSurveyNameSection = styled.div`
  text-align: left;
  display: block !important;
  margin-left: 1rem;
`;
const StyledSurveyName = styled.span`
  color: #4e525c;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  margin-right: 1rem;
`;
const StyledSurveyMappedDetails = styled.div`
  color: #696e7b;
  font: normal 400 1.2rem/1.8rem ${props => props.theme.fontFamily};
  display: flex;
  align-items: center;
`;
const StyledProjectLink = styled.a`
  color: var(--primary-color-2);
  font-weight: 600;
  white-space: nowrap;
  text-decoration: none;
  margin-left: 0.4rem;
`;
const StyledSurveyMappedIcon = styled.img`
  width: auto;
  height: 1.6rem;
`;

type Props = {
  cmixIconURL: string;
  samplifyIconURL: string;
  survey: ExternalSurvey;
  closeModal: () => void;
  createSurvey: (surveyName: string, surveyId: string) => void;
  activeSectionOpen: boolean;
};

const CmixSurveyListItem = ({
  cmixIconURL,
  samplifyIconURL,
  survey,
  closeModal,
  createSurvey,
  activeSectionOpen
}: Props) => {
  const onSelectSurvey = () => {
    //can select only active survey
    if (activeSectionOpen && survey.mappedProject === null) {
      createSurvey(survey.name, survey.id);
      closeModal();
    }
  };

  const maybeInactiveRowDetails =
    !activeSectionOpen && survey.mappedProject ? (
      <StyledSurveyMappedDetails>
        Used by{" "}
        <StyledProjectLink
          href={
            window.origin +
            "/project/" +
            survey.mappedProject.projectID +
            "/surveys#" +
            survey.mappedProject.surveyID
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          {survey.mappedProject.projectName}
        </StyledProjectLink>
        <StyledSurveyMappedIcon src={samplifyIconURL} alt="" />
      </StyledSurveyMappedDetails>
    ) : null;

  const row = (
    <StyledGrid>
      <StyledGridCol>
        <StyledAppIcon src={cmixIconURL} alt="" />
      </StyledGridCol>
      <StyledGridCol>
        <StyledSurveyNameSection>
          <StyledSurveyName>{survey.name}</StyledSurveyName>
          {maybeInactiveRowDetails}
        </StyledSurveyNameSection>
      </StyledGridCol>
      <StyledGridCol>
        <CmixSurveyStatus status={survey.status} />
      </StyledGridCol>
    </StyledGrid>
  );

  return (
    <OuterDiv>
      <Box onClick={onSelectSurvey} activeSectionOpen={activeSectionOpen}>
        {row}
      </Box>
    </OuterDiv>
  );
};

export default CmixSurveyListItem;
