import React, { ReactNode } from "react";
import styled from "styled-components";
import { ModalBody, Button } from "samplify-design";

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2.5em;
`;

const Cancel = styled(Button)`
  background-color: #ffffff;
  color: ${({ theme }) => theme.neutralDark["4"]};
  text-decoration: underline;
  &:hover {
    background-color: #ffffff;
  }
  &:focus {
    box-shadow: none;
  }
`;

const StyledModal = styled(ModalBody)`
  border-radius: 1rem;
  max-height: fit-content;
  text-align: left;
  flex-grow: unset;
`;

type Props = {
  confirmation: ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  cancelLabel?: ReactNode;
  confirmLabel?: ReactNode;
};

const ConfirmationModal = ({
  confirmation,
  onCancel,
  onConfirm,
  cancelLabel = "Cancel",
  confirmLabel = "Close"
}: Props) => {
  return (
    <StyledModal>
      {confirmation}
      <Buttons>
        <Cancel onClick={onCancel}>{cancelLabel}</Cancel>
        <Button onClick={onConfirm}>{confirmLabel}</Button>
      </Buttons>
    </StyledModal>
  );
};

export default ConfirmationModal;
