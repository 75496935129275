import React from "react";
import styled from "styled-components";
import {
  TextInput,
  Checkbox,
  Tooltip,
  Button,
  ButtonGroup,
  GroupedButton,
  Select
} from "samplify-design";
import search from "../../art/search.svg";
import iconInfo from "../../art/iconInfo.svg";
import iconClear from "../../art/iconClear.svg";
import filter from "../../art/attribute_edit_modal/filter.svg";
import filterActive from "../../art/attribute_edit_modal/filterActive.svg";
import filterDisabled from "../../art/attribute_edit_modal/filterDisabled.svg";
import allocation from "../../art/attribute_edit_modal/allocation.svg";
import allocationActive from "../../art/attribute_edit_modal/allocationActive.svg";
import allocationDisabled from "../../art/attribute_edit_modal/allocationDisabled.svg";
import splitEvenly from "../../art/attribute_edit_modal/splitEvenly.svg";
import {
  EditMode,
  EDIT_MODE_ALLOCATION,
  EDIT_MODE_FILTER,
  FILTER_TYPE_INCLUDE,
  FILTER_TYPE_EXCLUDE
} from "../../quotaHelpers";
import { OperatorType } from "../../types";
import { StyledGrid } from "./AttributeEditStyles";

const FILTER_TOOLTIP =
  "You can choose to include or exclude respondents that match that condition but not set an allocation for it.";

const ALLOC_TOOLTIP =
  "You can choose the percentage of respondents that match that condition.";

const StyledSelectAllLabel = styled.label`
  display: inline-flex;
  align-items: center;
  font-size: 1.2rem;
  > * {
    margin: 0 1.2rem 0 0;
  }
`;

const StyledFlex = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
`;

const StyledSearchIcon = styled.img`
  width: 1.5rem;
  height: auto;
  position: absolute;
  right: 0px;
  pointer-events: none;
  margin: 0.9rem 1rem;
`;

const StyledClearButton = styled(Button)`
  width: 6rem;
  height: 1.7rem;
  position: absolute;
  right: 28px;
  color: #696e7b;
  padding: 0;
  margin: 0.8rem 0;
  border: none;
  background: none;
  font: normal 400 1.2rem/1.7rem ${props => props.theme.fontFamily};
  &:hover {
    border: none;
    background: none;
  }
  &:active {
    border: none;
    background: none;
  }
`;

const StyledSplitEvenlyButton = styled(Button)`
  height: auto;
  padding: 0;
  margin: 0 0 0 1.6rem;
  color: #3ea8dd;
  border: none;
  background: none;
  font: normal 400 1.2rem/1.7rem ${props => props.theme.fontFamily};
  &:hover {
    border: none;
    background: none;
  }
  &:active {
    border: none;
    background: none;
  }
`;

const StyledSplitEvenlyIcon = styled.img`
  width: auto;
  height: auto;
  margin-right: 0.8rem;
`;

const StyledSplitEvenlySpan = styled.span`
  display: flex;
  align-items: center;
  width: fit-content;
  margin: auto;
`;

const StyledClearIcon = styled.img`
  width: 1rem;
  height: auto;
  margin: 0;
`;

const StyledSearchLabel = styled.label`
  position: relative;
  font-size: 1.2rem;
  flex-direction: column;
  justify-content: flex-start;
  width: -webkit-fill-available;
`;

const StyledTextInput = styled(TextInput)`
  border-radius: 0.4rem;
  width: -webkit-fill-available;
  margin: 0;
  padding-right: 9rem;
  &::placeholder {
    color: #9599a4;
  }
`;

const StyledCheckbox = styled(Checkbox)``;

const StyledHeaderSeparator = styled.div`
  margin-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.neutralLight["4"]};
`;

const StyledHeader = styled.div`
  justify-content: flex-start;
`;

const StyledTypeLabel = styled.label`
  display: inline-flex;
  align-items: center;
  color: #696e7b;
  font: normal 600 1.4rem/1.9rem ${props => props.theme.fontFamily};
`;

const StyledTooltip = styled(Tooltip)`
  z-index: 99999;
`;

const StyledInfoIcon = styled.img`
  width: 1.3rem;
  height: auto;
  fill: #5d6068;
  margin: auto 0.4rem;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  height: 3.4rem;
  border-radius: 0.4rem;
  display: flex;
  margin: auto 1.5rem;
`;

const StyledGroupedButton = styled(GroupedButton)`
  align-items: center;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  width: 11.5rem;
  height: inherit;
  line-height: inherit;
  font: normal 400 1.2rem/1.7rem ${props => props.theme.fontFamily};
  background-color: #ffffff;
  border: solid 0.2rem #3ea8dd;
  color: #3ea8dd;
  &.active {
    color: #ffffff;
    background-color: #3ea8dd;
    svg,
    path,
    g {
      stroke: #ffffff;
      color: #ffffff;
    }
  }
  &:not(:first-child) {
    border-left-width: 0;
  }
  &:disabled {
    pointer-events: none;
    cursor: default;
    color: #9599a4;
    background-color: #e7e8eb;
    border-color: #e7e8eb;
  }
  &:hover {
    background-color: ${({ theme }) => theme.neutralLight["7"]};
    color: #3ea8dd;
  }
`;

const StyledGroupButtonIcon = styled.img`
  width: auto;
  height: auto;
  margin-right: 0.8rem;
`;

const StyledGroupButtonSpan = styled.span`
  display: flex;
  align-items: center;
  width: fit-content;
  margin: auto;
`;

const StyledFilterTypeSelect = styled(Select)`
  > * {
    min-height: 3.4rem !important;
  }
  width: 9rem;
  padding: 0;
  margin: 0 0 0 2.4rem;
  background: none;
  font: normal 400 1.2rem/1.7rem ${props => props.theme.fontFamily};
`;
type FilterTypeOption = {
  label: React.ReactNode;
  value: OperatorType;
};

const filterTypeOptions = [
  {
    label: <span style={{ color: "#52C1A3" }}>Include</span>,
    value: FILTER_TYPE_INCLUDE
  },
  {
    label: <span style={{ color: "#F76E5C" }}>Exclude</span>,
    value: FILTER_TYPE_EXCLUDE
  }
];

const StyledAllocationSpan = styled.span`
  font: normal 600 1.3rem/1.8rem ${props => props.theme.fontFamily};
`;

type ButtonProps = {
  children: React.ReactNode;
  active: boolean;
  onClick: () => void;
  disabled?: boolean;
};

const EditTypeButton = ({
  children,
  onClick,
  active,
  disabled
}: ButtonProps) => {
  const className = active ? "active" : "";
  return (
    <StyledGroupedButton
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </StyledGroupedButton>
  );
};

type Props = {
  toggleAll?: () => void;
  handleSearch?: (e: React.FormEvent<HTMLInputElement>) => void;
  value?: string;
  toggleAllValue?: boolean;
  clearSearch?: () => void;
  allocationAllowed: boolean;
  filterAllowed: boolean;
  editMode: EditMode;
  toggleEditMode: (f: EditMode) => void;
  showSplitEvenly?: boolean;
  onSplitEvenlyClick?: () => void;
  filterType: OperatorType;
  toggleFilterType: (filterType: OperatorType) => void;
  allowSearch: boolean;
  allowSelectAll: boolean;
  filterDisableMessage?: string;
};

const AttributeEditHeader = ({
  toggleAll,
  handleSearch,
  value,
  toggleAllValue,
  clearSearch,
  allocationAllowed,
  filterAllowed,
  editMode,
  toggleEditMode,
  showSplitEvenly,
  onSplitEvenlyClick,
  filterType,
  toggleFilterType,
  allowSearch,
  allowSelectAll,
  filterDisableMessage
}: Props) => {
  const showAllocation = editMode === EDIT_MODE_ALLOCATION;
  const showFilter = editMode === EDIT_MODE_FILTER;

  const maybeClearSearchButton =
    value === "" ? null : (
      <StyledClearButton onClick={clearSearch}>
        <StyledClearIcon src={iconClear} alt="" /> Clear
      </StyledClearButton>
    );

  const tooltipInfo = showFilter ? FILTER_TOOLTIP : ALLOC_TOOLTIP;

  return (
    <StyledHeader>
      <StyledFlex>
        <StyledTypeLabel>
          <span style={{ margin: 0 }}>Use as </span>
          <StyledTooltip
            overlay={tooltipInfo}
            placement="top"
            mouseLeaveDelay={0}
          >
            <StyledInfoIcon src={iconInfo} alt="Info" />
          </StyledTooltip>
        </StyledTypeLabel>

        <StyledButtonGroup>
          <EditTypeButton
            disabled={!allocationAllowed}
            active={showAllocation}
            onClick={() => {
              toggleEditMode(EDIT_MODE_ALLOCATION);
            }}
          >
            <StyledGroupButtonSpan>
              <StyledGroupButtonIcon
                src={
                  showAllocation
                    ? allocationActive
                    : allocationAllowed
                    ? allocation
                    : allocationDisabled
                }
                alt=""
              />
              Allocation
            </StyledGroupButtonSpan>
          </EditTypeButton>

          <EditTypeButton
            disabled={!filterAllowed}
            active={showFilter}
            onClick={() => {
              toggleEditMode(EDIT_MODE_FILTER);
            }}
          >
            <StyledGroupButtonSpan>
              <StyledGroupButtonIcon
                src={
                  showFilter
                    ? filterActive
                    : filterAllowed
                    ? filter
                    : filterDisabled
                }
                alt=""
              />
              Filter
            </StyledGroupButtonSpan>
          </EditTypeButton>
        </StyledButtonGroup>

        {showFilter ? (
          <StyledFilterTypeSelect
            options={
              filterType === FILTER_TYPE_EXCLUDE
                ? [filterTypeOptions[0]]
                : [filterTypeOptions[1]]
            }
            onChange={(o: FilterTypeOption) => toggleFilterType(o.value)}
            value={
              filterType === FILTER_TYPE_EXCLUDE
                ? filterTypeOptions[1]
                : filterTypeOptions[0]
            }
          />
        ) : null}
      </StyledFlex>

      {allowSearch ? (
        <StyledFlex>
          <StyledSearchLabel>
            <StyledSearchIcon src={search} alt="" />
            {maybeClearSearchButton}
            <StyledTextInput
              type="text"
              name="search"
              onChange={handleSearch}
              value={value}
              placeholder="Search options"
            />
          </StyledSearchLabel>
        </StyledFlex>
      ) : null}

      <StyledFlex>
        <StyledGrid showAllocationView={showAllocation}>
          <div style={{ height: "18px" }}>
            {allowSelectAll ? (
              <StyledSelectAllLabel>
                <StyledCheckbox onChange={toggleAll} checked={toggleAllValue} />
                <span>Select All</span>
              </StyledSelectAllLabel>
            ) : null}
          </div>

          {showAllocation ? (
            <div style={{ display: "flex" }}>
              <StyledAllocationSpan>Allocation</StyledAllocationSpan>
              {showSplitEvenly ? (
                <StyledSplitEvenlyButton onClick={onSplitEvenlyClick}>
                  <StyledSplitEvenlySpan>
                    <StyledSplitEvenlyIcon src={splitEvenly} alt="" />
                    Split Evenly
                  </StyledSplitEvenlySpan>
                </StyledSplitEvenlyButton>
              ) : null}
            </div>
          ) : null}
        </StyledGrid>
      </StyledFlex>

      <StyledHeaderSeparator />
    </StyledHeader>
  );
};

export default AttributeEditHeader;
