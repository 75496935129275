import React from "react";
import styled from "styled-components";
import { Modal as BaseModal } from "samplify-design";
import InvoiceReportModal from "../invoices/InvoiceReportModal";
import modalTypes from "../../constants/modalTypes";
import CreateProjectContainer from "../../containers/project/CreateProjectContainer";
import CreateReport from "./CreateReport";
import CreateFilter from "./CreateFilter";
import CreateCrosstab from "./CreateCrosstab";
import ExportData from "./ExportData/ExportData";
import DeclineTosConfirm from "./DeclineTosConfirm";
import ViewReportOnModal from "./ViewReport/ViewReportOnModal";
import SplitSource from "./SplitSource";
import ConfirmCloseLineItem from "./ConfirmCloseLineItem";
import ConfirmCloseProject from "./ConfirmCloseProject";
import UpdateFound from "./UpdateFound";
import ConfirmDeleteSurvey from "./ConfirmDeleteSurvey";
import ConfirmNavigation from "./ConfirmNavigation";
import ConfirmDuplicateLineItemDeprecatedAttribute from "./ConfirmDuplicateLineItemDeprecatedAttribute";
import AttributeEditorModal from "./AttributeEditorModal";
import ProjectTopicSelectorModal from "./ProjectTopicSelectorModal";
import InvalidInputModal from "./InvalidInputModal";
import ConnectCmixSurveyModal from "./ConnectCmixSurveyModal";
import ConfirmDeleteTargetingTemplate from "./ConfirmDeleteTargetingTemplate";
import CreateTemplateContainer from "../../containers/lineitem/CreateTargetingTemplateContainer";
import ProjectRestrictionsModal from "./ProjectRestrictionsModal";
import ConfirmLoadTargetingTemplate from "./ConfirmLoadTargetingTemplate";
import ConfirmDuplicateProjectInternalSurveys from "./ConfirmDuplicateProjectInternalSurveys";

const closeable = (modalType: string | null) =>
  modalType !== modalTypes.UPDATE_FOUND;

const StyledBaseModal = styled(BaseModal)`
  max-height: initial;
`;

type Props = {
  clearModalType: () => void;
  modalType: string | null;
};
const Modal = ({ modalType, clearModalType }: Props) => {
  const clearModal = () => {
    if (!closeable(modalType)) return;
    clearModalType();
  };

  const Content = (function() {
    switch (modalType) {
      case modalTypes.CREATE_PROJECT:
        return CreateProjectContainer;
      case modalTypes.CREATE_REPORT:
        return CreateReport;
      case modalTypes.CREATE_FILTER:
        return CreateFilter;
      case modalTypes.CREATE_CROSSTAB:
        return CreateCrosstab;
      case modalTypes.EXPORT_DATA:
        return ExportData;
      case modalTypes.DECLINE_TOS:
        return DeclineTosConfirm;
      case modalTypes.INVOICE_REPORT:
        return ViewReportOnModal;
      case modalTypes.SPLIT_SOURCE:
        return SplitSource;
      case modalTypes.CONFIRM_CLOSE_LINEITEM:
        return ConfirmCloseLineItem;
      case modalTypes.CONFIRM_CLOSE_PROJECT:
        return ConfirmCloseProject;
      case modalTypes.UPDATE_FOUND:
        return UpdateFound;
      case modalTypes.CONFIRM_DELETE_SURVEY:
        return ConfirmDeleteSurvey;
      case modalTypes.CONFIRM_NAVIGATION:
        return ConfirmNavigation;
      case modalTypes.DUPLICATE_LINEITEM_WITH_DEPRECATED_ATTRIBUTES:
        return ConfirmDuplicateLineItemDeprecatedAttribute;
      case modalTypes.ATTRIBUTE_EDITOR:
        return AttributeEditorModal;
      case modalTypes.PROJECT_TOPIC_SELECTOR:
        return ProjectTopicSelectorModal;
      case modalTypes.INVALID_INPUT:
        return InvalidInputModal;
      case modalTypes.CONNECT_CMIX_SURVEY:
        return ConnectCmixSurveyModal;
      case modalTypes.CONFIRM_DELETE_TARGETING_TEMPLATE:
        return ConfirmDeleteTargetingTemplate;
      case modalTypes.SAVE_TARGETING_TEMPLATE:
        return CreateTemplateContainer;
      case modalTypes.PROJECT_RESTRICTIONS:
        return ProjectRestrictionsModal;
      case modalTypes.CONFIRM_LOAD_TARGETING_TEMPLATE:
        return ConfirmLoadTargetingTemplate;
      case modalTypes.DUPLICATE_PROJECT_WITH_INTERNAL_SURVEYS:
        return ConfirmDuplicateProjectInternalSurveys;
      default:
        return null;
    }
  })();
  const renderModalContent = () =>
    Content === null ? null : <Content clearModal={clearModal} />;
  const appElement = document.getElementById("root");

  let modalSize = "";
  if (modalType === modalTypes.CREATE_FILTER) {
    modalSize = "lg";
  }
  if (modalType === modalTypes.PROJECT_TOPIC_SELECTOR) {
    modalSize = "xl";
  }

  if (modalType === modalTypes.INVOICE_REPORT) {
    return (
      <InvoiceReportModal
        appElement={appElement ? appElement : undefined}
        closeTimeoutMS={150}
        isOpen={modalType !== null}
        contentLabel="modal"
        onRequestClose={clearModal}
      >
        {renderModalContent()}
      </InvoiceReportModal>
    );
  }
  if (
    modalType === modalTypes.ATTRIBUTE_EDITOR ||
    modalType === modalTypes.CONNECT_CMIX_SURVEY
  ) {
    return (
      <StyledBaseModal
        size="xl"
        onRequestClose={clearModal}
        appElement={appElement}
        isOpen={modalType !== null}
        shouldCloseOnOverlayClick={false}
      >
        {renderModalContent()}
      </StyledBaseModal>
    );
  }

  return (
    <StyledBaseModal
      size={modalSize}
      onRequestClose={clearModal}
      appElement={appElement}
      isOpen={modalType !== null}
    >
      {renderModalContent()}
    </StyledBaseModal>
  );
};

export default Modal;
