import React, { Fragment } from "react";
import styled from "styled-components";
import arrowLogo from "../../art/header/arrow.svg";
import samplifyTitle from "../../art/header/samplifyTitle.svg";
import { SAMPLIFY_LOGO_LINK, getEnv } from "../../networkHelpers";

const StyledLogo = styled.img`
  height: 3rem;
  width: auto;
  margin: auto 0.4rem auto 2rem;
`;

const StyledTitle = styled.img`
  height: 1.5rem;
  width: auto;
  margin: 2.1rem 0 auto 0.2rem;
`;

const StyledArrow = styled.img`
  height: 100%;
  width: auto;
  margin-right: 1.3rem;
`;

const StyledIdentifier = styled.div`
  display: flex;
  flex-direction: row;
  width: 2.1rem;
`;

const StyledEnv = styled.div`
  background: #e7eaed;
  border-radius: 1px;
  color: #696e7b;
  font-size: 8px;
  text-transform: uppercase;
  align-self: flex-start;
  padding: 2px 3px;
  margin-top: 7px;
`;

const Title = () => {
  const env = getEnv().toUpperCase();
  return (
    <Fragment>
      <StyledLogo src={SAMPLIFY_LOGO_LINK} alt="" />
      <StyledTitle src={samplifyTitle} alt="" />
      <StyledIdentifier>
        {env === "UAT" && <StyledEnv>{env}</StyledEnv>}
      </StyledIdentifier>
      <StyledArrow src={arrowLogo} alt="" />
    </Fragment>
  );
};

export default Title;
