import React, { Fragment } from "react";
import styled from "styled-components";
import Option from "./Option";
import { AttributeType, AttributeOptionMap } from "../../types";

const Or = styled.span.attrs({ children: " or " })`
  font-weight: 600;
  color: ${({ theme }) => theme.neutralLight["1"]};
`;

type Props = {
  options: Array<string>;
  optionMap: AttributeOptionMap;
  attributeType: AttributeType;
};

const OptionList = ({ options, optionMap, attributeType }: Props) => {
  return (
    <Fragment>
      <span>
        {options.map((o, idx) => (
          <Fragment key={o}>
            <Option
              optionString={o}
              optionMap={optionMap}
              attributeType={attributeType}
            />
            {idx === options.length - 1 ? null : <Or />}
          </Fragment>
        ))}
      </span>
    </Fragment>
  );
};

export default OptionList;
