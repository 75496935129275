export const intro = "Summary of Costs";

export const approveSummary =
  "If this looks accurate, please approve the summary below.";

export const mainText = `
  If you need any changes made, you can request a correction below, and we will follow up with you as soon as possible.
`;

export const footnote =
  "Taxes, if applicable, will appear on the final invoice.";

export const tableHeaders = [
  "Target Group",
  "Source",
  "Completes",
  "CPI",
  "Cost"
];

export const correctionDescriptionPlaceholder = "Describe the correction here";

export const uploadFileText =
  "Please upload the Excel file with the correction:";

export const sampleFileLink =
  "https://s3.amazonaws.com/rnssi-api-resources/Data+Quality+Request+Template.xlsx";

export const sampleFileLinkText = "Download Template";
