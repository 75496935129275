import { useMutation } from "react-apollo-hooks";
import { deleteTargetingTemplate } from "../../lib/queries/lineitem";

type Data = {
  deleteTargetingTemplate: boolean;
};

const useDeleteTargetingTemplate = (templateID: string) =>
  useMutation<Data>(deleteTargetingTemplate, {
    variables: { templateID }
  });

export default useDeleteTargetingTemplate;
