import styled from "styled-components";
import { ModalCancel } from "samplify-design";

const ModalCancelButton = styled(ModalCancel.withComponent("button"))`
  background: none;
  border: 0 none;
  font-family: inherit;
  margin-right: 2.5em;
  text-decoration: none;
  &:disabled {
    color: #999999;
    cursor: default;
  }
`;

export default ModalCancelButton;
