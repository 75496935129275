import React from "react";
import styled from "styled-components";
import {
  DefaultToast,
  AppearanceTypes,
  ToastProps
} from "react-toast-notifications";

const getBackgroundColor = (appearance: AppearanceTypes) => {
  if (appearance === "error") return "var(--danger-color-4)";
  if (appearance === "info") return "var(--primary-color-5)";
  if (appearance === "success") return "var(--accent-color-4)";
  if (appearance === "warning") return "#fef8f0";
};

const getBorderColor = (appearance: AppearanceTypes) => {
  if (appearance === "error") return "var(--danger-color-2)";
  if (appearance === "info") return "var(--primary-color-2)";
  if (appearance === "success") return "var(--accent-color-2)";
  if (appearance === "warning") return "#f7b161";
};

const StyledToast = styled(DefaultToast)`
  background-color: ${props => getBackgroundColor(props.appearance)} !important;
  color: var(--dark-color-3) !important;
  border: 1px solid ${props => getBorderColor(props.appearance)};
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem 0.75rem;
  justify-content: space-between;
  position: relative;
  .react-toast-notifications__toast__icon-wrapper {
    display: none;
  }
  .react-toast-notifications__toast__dismiss-button {
    padding: 0;
    position: absolute;
    right: 18px;
    top: 15px;
  }
`;

const Toast = ({ ...props }: ToastProps) => (
  <StyledToast {...props}>{props.children}</StyledToast>
);

export default Toast;
