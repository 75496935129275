import React, { Fragment, useState } from "react";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import { DefaultThemeProvider } from "samplify-design";
import { BrowserRouter, useHistory } from "react-router-dom";
import { withDragDropContext } from "react-surveytool";
import { ToastProvider } from "react-toast-notifications";
import GlobalStyle from "./components/GlobalStyle";
import AppLayout from "./components/AppLayout";
import LoginContainer from "./containers/LoginContainer";
import FullStoryIdentifier from "./components/FullStoryIdentifier";
import IdleLogoutContainer from "./containers/IdleLogoutContainer";
import AppStatusContainer from "./containers/AppStatusContainer";
import OutageNotice from "./components/OutageNotice";
import UpdateFoundContainer from "./containers/UpdateFoundContainer";
import HealthyContainer from "./containers/HealthyContainer";
import MaintenanceMessage from "./components/MaintenanceMessage";
import AppStatus from "./types/AppStatus";
import Toast from "./components/Toast";
import GraphQLErrorHandlerContainer from "./containers/GraphQLErrorHandlerContainer";
import useUserInfo from "./hooks/useUserInfo";
import PageError from "./components/PageError";
import PageLoading from "./components/PageLoading";
import ApolloClient from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import useMetaData from "./hooks/useMetaData";
import useProjectFilters from "./hooks/useProjectFilters";
import useDashboardPaging from "./hooks/useDashboardPaging";
import ModalPropsContext, { Modal } from "./containers/ModalPropsContext";
import getClient, { stateLinkWriteDefault } from "./apolloClient";

type AppStatusSwitchProps = {
  appStatus: AppStatus;
  healthy: AppStatus;
  client: ApolloClient<NormalizedCacheObject>;
};

const AppStatusSwitch = ({
  appStatus,
  healthy,
  client
}: AppStatusSwitchProps) => {
  const [modalData, setModalData] = useState<Modal>({
    modalType: null,
    modalProps: null
  });
  const clearModalType = () => {
    setModalData(s => ({ ...s, modalType: null }));
  };

  if (appStatus === AppStatus.Blank || healthy === AppStatus.Blank) {
    return null;
  }

  if (appStatus === AppStatus.NotOK) {
    return <OutageNotice />;
  }
  if (healthy === AppStatus.NotOK) {
    return <MaintenanceMessage />;
  }

  return (
    <LoginContainer>
      <ModalPropsContext.Provider
        value={{
          modalProps: modalData.modalProps,
          modalType: modalData.modalType,
          setModalData
        }}
      >
        <UpdateFoundContainer />
        <AppContainer
          client={client}
          modalData={modalData}
          clearModalType={clearModalType}
        />
      </ModalPropsContext.Provider>
      <IdleLogoutContainer />
    </LoginContainer>
  );
};

type AppContainerProps = {
  client: ApolloClient<NormalizedCacheObject>;
  modalData: Modal;
  clearModalType: () => void;
};

const AppContainer = ({
  client,
  modalData,
  clearModalType
}: AppContainerProps) => {
  const history = useHistory();

  // using `resetInProgress` to avoid multiple calls for `resetDashboard` from checking effectiveCompany
  const [resetInProgress, setStateResetInProgress] = useState(false);
  const setResetInProgress = () => setStateResetInProgress(true);
  const resetDashboard = () => {
    // reset store to avoid stale cache data
    history.push("/");
    client.clearStore().finally(() => {
      refetchUserData().then(() =>
        refetchMetaData().then(() =>
          refetchProjects().finally(() => setStateResetInProgress(false))
        )
      );
    });
  };
  // on reset of store write default cache and resolver to state-link
  client.onClearStore(stateLinkWriteDefault);

  const { data, error, loading, refetch: refetchUserData } = useUserInfo({
    resetDashboard,
    resetInProgress,
    setResetInProgress
  });

  const {
    countries,
    deliveryModes,
    studyRequirements,
    surveyTopics,
    refetch: refetchMetaData
  } = useMetaData();

  const { filters, setFilters } = useProjectFilters();
  const {
    projectsList,
    error: dashboardError,
    hasNext,
    addMoreProjects,
    formattedFilters,
    loading: dashboardLoading,
    refetchProjects
  } = useDashboardPaging(filters);

  if (loading) {
    return <PageLoading />;
  }
  if (error || !(data && data.userInfo)) {
    return <PageError />;
  }

  return (
    <Fragment>
      <FullStoryIdentifier user={data} />
      <AppLayout
        userInfo={data.userInfo}
        resetDashboard={resetDashboard}
        resetInProgress={resetInProgress}
        setResetInProgress={setResetInProgress}
        filters={filters}
        setFilters={setFilters}
        countries={countries}
        deliveryModes={deliveryModes}
        studyRequirements={studyRequirements}
        surveyTopics={surveyTopics}
        projectsList={projectsList}
        dashboardError={dashboardError}
        hasMoreProjects={hasNext}
        addMoreProjects={addMoreProjects}
        formattedFilters={formattedFilters}
        dashboardLoading={dashboardLoading}
        modalData={modalData}
        clearModalType={clearModalType}
      />
    </Fragment>
  );
};

const App = () => {
  const client = getClient();

  return (
    <BrowserRouter>
      <DefaultThemeProvider>
        <Fragment>
          <GlobalStyle />
          <ApolloProvider client={client}>
            <ApolloHooksProvider client={client}>
              <ToastProvider components={{ Toast }} placement="bottom-left">
                <GraphQLErrorHandlerContainer>
                  {gqlError => (
                    <AppStatusContainer gqlError={gqlError}>
                      {appStatus => (
                        <HealthyContainer gqlError={gqlError}>
                          {healthy => (
                            <AppStatusSwitch
                              appStatus={appStatus}
                              healthy={healthy}
                              client={client}
                            />
                          )}
                        </HealthyContainer>
                      )}
                    </AppStatusContainer>
                  )}
                </GraphQLErrorHandlerContainer>
              </ToastProvider>
            </ApolloHooksProvider>
          </ApolloProvider>
        </Fragment>
      </DefaultThemeProvider>
    </BrowserRouter>
  );
};

export default withDragDropContext(App);
