import useFetchMetaData, { Data } from "./useFetchMetaData";
import { Country, MetaDataItem } from "../types";
import { ApolloError, ApolloQueryResult } from "apollo-client";

type ReturnProps = {
  countries: Array<Country>;
  deliveryModes: Array<MetaDataItem>;
  studyRequirements: Array<MetaDataItem>;
  surveyTopics: Array<MetaDataItem>;
  refetch: () => Promise<ApolloQueryResult<Data>>;
};

const validateData = (
  loading: boolean,
  error: ApolloError | undefined,
  data: Data | undefined
): {
  countries: Array<Country>;
  deliveryModes: Array<MetaDataItem>;
  studyRequirements: Array<MetaDataItem>;
  surveyTopics: Array<MetaDataItem>;
} => {
  const metaData =
    !loading &&
    !error &&
    data &&
    data.countries &&
    data.studyMetadata &&
    data.studyMetadata.deliveryTypes &&
    data.studyMetadata.category &&
    data.studyMetadata.category.studyTypes &&
    data.studyMetadata.category.surveyTopics &&
    data.studyMetadata.category.studyRequirements
      ? data
      : undefined;

  return {
    countries: metaData ? metaData.countries : [],
    deliveryModes: metaData ? metaData.studyMetadata.deliveryTypes : [],
    studyRequirements: metaData
      ? metaData.studyMetadata.category.studyRequirements
      : [],
    surveyTopics: metaData ? metaData.studyMetadata.category.surveyTopics : []
  };
};

const useMetaData = (): ReturnProps => {
  const { loading, error, data, refetch } = useFetchMetaData();

  return { ...validateData(loading, error, data), refetch };
};

export default useMetaData;
