import React, { useContext } from "react";
import styled from "styled-components";
import { ModalBody, Button } from "samplify-design";
import Flag from "../Flag";
import Gong from "../Gong";
import ModalPropsContext from "../../containers/ModalPropsContext";
import ModalCancelButton from "../ModalCancelButton";

const Footer = styled.div`
  text-align: right;
`;
const StyledButton = styled(Button)`
  width: 15rem;
`;

const StyledBody = styled(ModalBody)`
  border-radius: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  flex-grow: unset;
`;

type SurveyLineItem = {
  extLineItemId: string;
  extProjectId: string;
  title: string;
  countryISOCode: string;
  surveyId: string;
};

type Props = {
  clearModal: () => void;
};

type ModalProps = {
  lineItems: SurveyLineItem[];
  deleteSurvey: () => void;
};
type ModalPropsContextArg = {
  modalProps: ModalProps;
};

const Outer = styled.div``;

const LineItemRow = (props: SurveyLineItem) => (
  <div>
    <Flag countryISOCode={props.countryISOCode} />
    <span>{props.title}</span>
  </div>
);

const ConfirmDeleteSurvey = ({ clearModal }: Props) => {
  const context: ModalPropsContextArg = useContext(ModalPropsContext);
  const { lineItems, deleteSurvey } = context.modalProps;
  const onClickDelete = () => {
    clearModal();
    deleteSurvey();
  };
  return (
    <Outer>
      <StyledBody>
        <Gong />
        <div>
          <p>
            You are about to delete a survey that is attached to the following
            target groups:
          </p>
          {lineItems.map(li => (
            <LineItemRow key={li.extLineItemId} {...li} />
          ))}
          <p>Are you sure you would like to delete this survey?</p>
          <p>
            You will need to assign a new survey to these target groups before
            launching.
          </p>
        </div>
        <Footer>
          <ModalCancelButton onClick={clearModal}>Cancel</ModalCancelButton>
          <StyledButton swatch="danger" onClick={onClickDelete}>
            Delete
          </StyledButton>
        </Footer>
      </StyledBody>
    </Outer>
  );
};

export default ConfirmDeleteSurvey;
