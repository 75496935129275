import moment from "moment";

export const stateLabelMap: { [key: string]: string } = {
  CLOSED: "Invoice Processing",
  COMPLETED: "Needs Approval",
  INVOICED: "Invoiced"
};

export const dropdownButtonStateLabelMap: { [key: string]: string } = {
  CLOSED: "Summary",
  COMPLETED: "Summary",
  INVOICED: "Invoice"
};

export const dropdownButtonOptions: { [key: string]: string[] } = {
  Summary: ["View Report"],
  Invoice: ["View Report", "Download"]
};

export const isZeroCost = (cost: string) => {
  const costNum = cost.substr(1, cost.length);
  return costNum === "0.00";
};

export const getStartDate = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth(), 1);
export const getEndDate = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth() + 1, 0);

export const formatFilterDate = (type: string, dateVal: moment.Moment) => {
  const formatteddate = new Date(dateVal.format("YYYY-MM-DD"));
  return type === "start"
    ? getStartDate(formatteddate)
    : getEndDate(formatteddate);
};
