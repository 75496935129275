import React from "react";
import styled from "styled-components";
import { ErrorSplash, icons } from "samplify-design";
import LightBackground from "./LightBackground";
import WidthWrap from "./WidthWrap";
import Subheader from "./Subheader";
import Outer from "./AppOuter";
import SROnly from "./SROnly";
import { SAMPLIFY } from "../constants/titles";

const { Alert } = icons;

const Grid = styled.main`
  display: grid;
  grid-template-columns: [art] 1.5fr [message] 1fr;
  align-items: start;
  margin-top: 2em;
`;

const H1 = styled.h1`
  font-weight: 600;
  margin-top: 0;
  display: flex;
  align-items: center;
`;

const Blurb = styled.p`
  font-size: 1.4rem;
`;

const MessageOuter = styled.div`
  align-self: center;
`;

const StyledSplash = styled(ErrorSplash)`
  width: 100%;
  height: auto;
`;

const StyledSub = styled.sub`
  display: block;
  text-align: center;
  font-style: italic;
`;
const Art = (props: React.HTMLAttributes<HTMLImageElement>) => (
  <div>
    <StyledSplash {...props} />
    <StyledSub>Oh no</StyledSub>
  </div>
);

const Icon = styled(Alert)`
  height: 2rem;
  fill: ${({ theme }) => theme.danger["2"]};
  margin-right: 1rem;
`;

const Message = () => (
  <MessageOuter>
    <H1>
      <Icon />
      {SAMPLIFY} is down
    </H1>
    <Blurb>
      We're currently experiencing connectivity issues. <br /> Please try again
      later.
    </Blurb>
  </MessageOuter>
);

const OutageNotice = () => (
  <Outer>
    <Subheader>
      <WidthWrap>
        <LightBackground />
        <Grid>
          <Art role="img" aria-labelledby="artdesc" />
          <Message />
        </Grid>
        <SROnly id="artdesc">It's Godzilla</SROnly>
      </WidthWrap>
    </Subheader>
  </Outer>
);

export default OutageNotice;
