import { useEffect, useState } from "react";
import sortBy from "lodash/sortBy";
import orderBy from "lodash/orderBy";
import {
  Attribute,
  QuotaCellInput,
  QuotaGroupInput,
  QuotaNodeInput
} from "../../types";
import {
  AllocationValue,
  EDIT_MODE_ALLOCATION,
  EditMode
} from "../../quotaHelpers";
import hash from "object-hash";

export type Range = {
  min: string;
  max: string;
};

const parseRangeStrings = (rangeStrings: string[]): Range[] =>
  rangeStrings.map(s => {
    const match = s.match(/(\d+)-(\d+)/);
    const min = match ? match[1] : "";
    const max = match ? match[2] : "";
    return { min, max };
  });

const stringifyRanges = (ranges: Range[][]) =>
  ranges.map(r => r.map(({ min, max }) => `${min}-${max}`));

const rangeProper = (value: Range, index: number, array: Range[]) => {
  const { min, max } = value;
  const minInt = parseInt(min, 10);
  const maxInt = parseInt(max, 10);
  if (isNaN(minInt)) return false;
  if (isNaN(maxInt)) return false;
  return minInt <= maxInt;
};

const rangesProper = (ranges: Range[]) => ranges.every(rangeProper);

const rangesOverlap = (ranges: Range[]) => {
  const sortedRanges = sortBy(ranges, r => parseInt(r.min, 10));
  return sortedRanges.some((r, i) =>
    i === 0
      ? false
      : parseInt(r.min, 10) <= parseInt(sortedRanges[i - 1].max, 10)
  );
};

const rangesMatch = (ranges: Range[], format: string) => {
  const regex = RegExp(format);
  return ranges.every(r => {
    const rn = r.min + "-" + r.max;
    return regex.test(rn);
  });
};

const findQuotaNode = (quotaNodes: QuotaNodeInput[], attributeId: string) =>
  quotaNodes.find(n => n.attributeId === attributeId);

const validateRanges = (singleRanges: Range[], attribute: Attribute): boolean =>
  rangesProper(singleRanges) &&
  !rangesOverlap(singleRanges) &&
  rangesMatch(singleRanges, attribute.format);

const getDisabledMessage = (singleRanges: Range[], format: string) =>
  !rangesProper(singleRanges) || !rangesMatch(singleRanges, format)
    ? "Invalid value(s)!"
    : "The ranges are overlapping!";

export const getOptionFromRangeArray = (rangeArray: Range[]) =>
  rangeArray.map(({ min, max }) => `${min}-${max}`);

const findPrevCellCount = (
  allocationMap: Map<string, AllocationValue>,
  quotaCells: Array<QuotaCellInput>,
  option: Array<string>,
  i: number
): number => {
  const allocationKey = hash(option);
  const allocatedCount = allocationMap.get(allocationKey);
  if (allocatedCount) {
    return allocatedCount.count || 0;
  }
  if (quotaCells[i]) {
    return quotaCells[i].count;
  }
  return 0;
};

const groupFromRangesAndGroup = (
  allocationMap: Map<string, AllocationValue>,
  attributeId: string,
  rangeArrays: Range[][],
  quotaGroupInput: QuotaGroupInput
): QuotaGroupInput => {
  const prevCells = quotaGroupInput.quotaCells;
  const updatedQuotaCells = rangeArrays.map<QuotaCellInput>((rangeArray, i) => {
    const options = getOptionFromRangeArray(rangeArray);
    return {
      quotaNodes: [
        {
          attributeId,
          options
        }
      ],
      count: findPrevCellCount(allocationMap, prevCells, options, i),
      perc: 0
    };
  });

  return {
    name: quotaGroupInput.name,
    quotaCells: updatedQuotaCells
  };
};

const sortQuotaCells = (attribute: Attribute, quotaCells: QuotaCellInput[]) => {
  const cellText = (cell: QuotaCellInput) => {
    if (!attribute) return "";
    const foundNode = findQuotaNode(cell.quotaNodes, attribute.id);
    if (!foundNode || !foundNode.options || !foundNode.options[0]) return "";
    return foundNode.options[0];
  };
  return orderBy(quotaCells, cellText, "asc");
};

const getSingleRangesArray = (ranges: Range[][]): Range[] =>
  ranges.reduce((acc, val) => acc.concat(val), []);

const getInitialRanges = (
  attribute: Attribute,
  initialOptions: string[],
  quotaGroupInput: QuotaGroupInput
) => {
  if (initialOptions.length && !quotaGroupInput.quotaCells.length) {
    return [parseRangeStrings(initialOptions)];
  }
  const sortedCells = sortQuotaCells(attribute, quotaGroupInput.quotaCells);
  const options = sortedCells.map(c => {
    const foundNode = findQuotaNode(c.quotaNodes, attribute.id);
    return foundNode ? foundNode.options : [];
  });
  return options.length
    ? options.map(o => parseRangeStrings(o))
    : [[{ min: "", max: "" }]];
};

type StateProps = {
  ranges: Range[][];
};

type Props = {
  allocationMap: Map<string, AllocationValue>;
  attribute: Attribute;
  initialOptions: string[];
  quotaGroupInput: QuotaGroupInput;
  updateGroup: (g: QuotaGroupInput) => void;
  toggleEditMode: (editMode: EditMode) => void;
  updateAllocationCell: (index: string, value: AllocationValue) => void;
  deleteAllocationCell: (index: string) => void;
  toggleUpdateAllocRowVal: (b: boolean) => void;
};

const useAttributeRange = ({
  allocationMap,
  attribute,
  initialOptions,
  quotaGroupInput,
  updateGroup,
  toggleEditMode,
  updateAllocationCell,
  deleteAllocationCell,
  toggleUpdateAllocRowVal
}: Props) => {
  const [state, setState] = useState<StateProps>({
    ranges: getInitialRanges(attribute, initialOptions, quotaGroupInput)
  });
  const [rangeGroupingChange, setRangeGroupingChange] = useState<boolean>(
    false
  );
  const { ranges } = state;
  const singleRangesArray = getSingleRangesArray(ranges);

  useEffect(() => {
    if (rangeGroupingChange) {
      setState({
        ranges: getInitialRanges(attribute, initialOptions, quotaGroupInput)
      });
      setRangeGroupingChange(false);
    }
  }, [attribute, initialOptions, quotaGroupInput, rangeGroupingChange]);

  const toggleRangeGroupingChange = () =>
    setRangeGroupingChange(!rangeGroupingChange);

  const setValue = (
    arrayIndex: number,
    index: number,
    type: string,
    value: string
  ) => {
    setState(prevState => {
      const { ranges } = prevState;
      const prevRangeArray = ranges[arrayIndex];
      const prevQuotaNodeOptions = getOptionFromRangeArray(prevRangeArray);
      const prevAllocationMapKey = hash(prevQuotaNodeOptions);
      const allocationValue = allocationMap.get(prevAllocationMapKey);
      const prevAllocationCount =
        (allocationValue && allocationValue.count) || "";
      const prevAllocationPerc =
        (allocationValue && allocationValue.perc) || "";
      deleteAllocationCell(prevAllocationMapKey);

      const newRange = { ...prevRangeArray[index], [type]: value };
      const newRangeArray = [
        ...prevRangeArray.slice(0, index),
        newRange,
        ...prevRangeArray.slice(index + 1, prevRangeArray.length)
      ];
      const newQuotaNodeOptions = newRangeArray.map(r => `${r.min}-${r.max}`);
      const newAllocationMapKey = hash(newQuotaNodeOptions);
      updateAllocationCell(newAllocationMapKey, {
        count: prevAllocationCount,
        perc: prevAllocationPerc
      });

      const newRanges = [
        ...ranges.slice(0, arrayIndex),
        newRangeArray,
        ...ranges.slice(arrayIndex + 1, ranges.length)
      ];

      const newGroupInput = groupFromRangesAndGroup(
        allocationMap,
        attribute.id,
        newRanges,
        quotaGroupInput
      );
      updateGroup(newGroupInput);
      toggleUpdateAllocRowVal(true);

      return {
        ranges: newRanges
      };
    });
  };

  const removeRange = (arrayIndex: number, index: number) => {
    return setState(prevState => {
      const { ranges } = prevState;
      const prevRangeArray = ranges[arrayIndex];
      const prevQuotaNodeOptions = prevRangeArray.map(r => `${r.min}-${r.max}`);
      const prevAllocationMapKey = hash(prevQuotaNodeOptions);

      const newRange = [
        ...prevRangeArray.slice(0, index),
        ...prevRangeArray.slice(index + 1, prevRangeArray.length)
      ];
      const newRanges = newRange.length
        ? [
            ...ranges.slice(0, arrayIndex),
            newRange,
            ...ranges.slice(arrayIndex + 1, ranges.length)
          ]
        : [
            ...ranges.slice(0, arrayIndex),
            ...ranges.slice(arrayIndex + 1, ranges.length)
          ];

      const newGroupInput = groupFromRangesAndGroup(
        allocationMap,
        attribute.id,
        newRanges,
        quotaGroupInput
      );
      updateGroup(newGroupInput);

      deleteAllocationCell(prevAllocationMapKey);
      toggleUpdateAllocRowVal(true);

      return {
        ranges: newRanges
      };
    });
  };

  const addRange = (after: number) => {
    return setState(prevState => {
      const { ranges } = prevState;
      const newRanges = [
        ...ranges.slice(0, after + 1),
        [{ min: "", max: "" }],
        ...ranges.slice(after + 1, ranges.length)
      ];

      if (newRanges.length >= 2) {
        toggleEditMode(EDIT_MODE_ALLOCATION);
      }

      const newGroupInput = groupFromRangesAndGroup(
        allocationMap,
        attribute.id,
        newRanges,
        quotaGroupInput
      );
      updateGroup(newGroupInput);
      toggleUpdateAllocRowVal(true);

      return {
        ranges: newRanges
      };
    });
  };

  const getUpdatedRanges = () => stringifyRanges(ranges);

  const rangesValid = validateRanges(singleRangesArray, attribute);

  const disabledMessage = getDisabledMessage(
    singleRangesArray,
    attribute.format
  );

  return {
    ranges,
    setValue,
    removeRange,
    addRange,
    getUpdatedRanges,
    toggleRangeGroupingChange,
    rangesValid,
    disabledMessage
  };
};

export default useAttributeRange;
