import React, { useContext } from "react";
import ModalPropsContext from "../../containers/ModalPropsContext";
import styled from "styled-components";
import { ModalBody, Button } from "samplify-design";
import iconOrangeInfo from "../../art/iconOrangeInfo.svg";

const StyledModal = styled(ModalBody)`
  border-radius: 1rem;
  max-height: fit-content;
  text-align: left;
  color: #696e7b;
  margin: auto;
  flex-grow: unset;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const ModalIcon = styled.img`
  height: 3.2rem;
  width: auto;
  margin-right: 1.6rem;
`;

const Title = styled.h3`
  font: normal 600 1.6rem/2.4rem ${props => props.theme.fontFamily};
  margin: auto 0;
`;

const Message = styled.p`
  margin: 3rem 0 2.5rem 0;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2.5em;
  font: normal 400 1.4rem/2.2rem ${props => props.theme.fontFamily};
`;

const EditButton = styled(Button)`
  background-color: ${({ theme }) => theme.neutralLight["7"]};
  color: ${({ theme }) => theme.primary["2"]};
  &:hover {
    background-color: ${({ theme }) => theme.neutralLight["7"]};
  }
  &:focus {
    box-shadow: none;
  }
`;

type Props = {
  clearModal: () => void;
};

type ModalProps = {
  invalidOpts: string;
  attributeName: string;
  onEdit: () => void;
  onProceed: () => void;
};

type ModalPropsContextArg = {
  modalProps: ModalProps;
};

const InvalidInputModal = ({ clearModal }: Props) => {
  const context: ModalPropsContextArg = useContext(ModalPropsContext);
  const { invalidOpts, attributeName, onEdit, onProceed } = context.modalProps;

  return (
    <StyledModal>
      <Header>
        <ModalIcon src={iconOrangeInfo} alt="" />
        <Title>Invalid Inputs Found</Title>
      </Header>

      <Message>
        {invalidOpts} are invalid inputs for {attributeName}, which would be
        omitted if you want to proceed with saving the attribute.
      </Message>

      <Buttons>
        <EditButton
          onClick={() => {
            onEdit();
          }}
        >
          Go Back to Editing
        </EditButton>

        <Button
          onClick={() => {
            onProceed();
            clearModal();
          }}
        >
          Proceed
        </Button>
      </Buttons>
    </StyledModal>
  );
};

export default InvalidInputModal;
