import React, { useContext, useState } from "react";
import ColumnSelect from "./ExportColumnSelect";
import CellSelect from "./ExportCellSelect";
import OpenEnd from "./ExportOpenEnd";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import {
  StyledFooter,
  Section,
  StyledButton,
  StyledCancelButton,
  StyledModal
} from "./ExportStyles";
import {
  ModalHeader,
  TextInputLine,
  ButtonGroup,
  GroupedButton
} from "samplify-design";
import Select from "../../design/Select";
import ModalPropsContext from "../../../containers/ModalPropsContext";

const CREATE_EXPORT = gql`
  mutation CREATE_EXPORT($input: CreateExportInput!) {
    createExport(input: $input) {
      token
    }
  }
`;
type Format = {
  value: string;
  label: string;
};
type State = {
  format: Format;
  aggregate: string;
  cells: string;
  includeOpen: boolean;
  columns: string;
  title: string;
};

type Props = {
  clearModal: () => void;
};

const ExportData = ({ clearModal }: Props) => {
  const context = useContext(ModalPropsContext);
  const { surveyKey } = context.modalProps;

  const [state, setState] = useState<State>({
    format: { value: "excel", label: "Excel" },
    aggregate: "individual",
    cells: "text",
    includeOpen: true,
    columns: "condensed",
    title: "Internal Survey Title"
  });

  const updateAggregate = (e: React.MouseEvent<HTMLButtonElement>) => {
    const individual = state.aggregate === "summary";
    const format = state.format.label;
    let newFormat = { value: "", label: "" };
    switch (format) {
      case "Excel":
        newFormat = individual
          ? { value: "excel", label: "Excel" }
          : { value: "aggregate_excel", label: "Excel" };
        break;
      case "CSV":
        newFormat = individual
          ? { value: "csv", label: "CSV" }
          : { value: "aggregate_csv", label: "CSV" };
        break;
      case "SPSS":
        newFormat = individual
          ? { value: "spss", label: "SPSS" }
          : { value: "aggregate_excel", label: "Excel" };
        break;
      default:
        break;
    }

    const target = e.currentTarget;
    setState(s => ({ ...s, aggregate: target.value, format: newFormat }));
  };

  const update = (field: any) => {
    return (e: React.FormEvent<HTMLInputElement>) => {
      const target = e.currentTarget;
      const value = target.type === "checkbox" ? target.checked : target.value;
      setState(s => ({ ...s, [field]: value }));
    };
  };

  const handleSelectChange = (selectedOption: Format) => {
    setState(s => ({ ...s, format: selectedOption }));
  };

  const aggregate = state.aggregate === "individual";
  const options: Array<Format> = aggregate
    ? [
        { value: "excel", label: "Excel" },
        { value: "csv", label: "CSV" },
        { value: "spss", label: "SPSS" }
      ]
    : [
        { value: "aggregate_excel", label: "Excel" },
        { value: "aggregate_csv", label: "CSV" }
      ];

  const input = {
    input: {
      surveyKey: surveyKey,
      format: state.format.value,
      options: {
        condensed: state.columns === "condensed",
        noOpened: !state.includeOpen,
        actualText: state.cells === "text",
        mapResponses: false // could implement
      }
    }
  };

  return (
    <Mutation mutation={CREATE_EXPORT} variables={input}>
      {createExport => (
        <React.Fragment>
          <ModalHeader>All Data Report (Unfiltered)</ModalHeader>
          <StyledModal>
            <ButtonGroup>
              <GroupedButton
                className={state.aggregate === "individual" ? "active" : ""}
                onClick={updateAggregate}
                value={"individual"}
              >
                Individual
              </GroupedButton>
              <GroupedButton
                className={state.aggregate === "summary" ? "active" : ""}
                onClick={updateAggregate}
                value={"summary"}
              >
                Summary
              </GroupedButton>
            </ButtonGroup>

            <Section>
              <h1>File Format</h1>
              <Select
                value={state.format}
                options={options}
                onChange={handleSelectChange}
                defaultValue={state.format}
              />
            </Section>

            <Section>
              {aggregate && (
                <ColumnSelect columns={state.columns} update={update} />
              )}
            </Section>

            <Section>
              {aggregate && <CellSelect cells={state.cells} update={update} />}
            </Section>

            <Section>
              {aggregate && (
                <OpenEnd includeOpen={state.includeOpen} update={update} />
              )}
            </Section>

            <Section>
              <TextInputLine value={state.title} onChange={update("title")} />
            </Section>
          </StyledModal>
          <StyledFooter>
            <StyledCancelButton
              onClick={() => {
                clearModal();
              }}
            >
              Cancel
            </StyledCancelButton>
            <StyledButton
              title="submit"
              onClick={() => {
                createExport();
                clearModal();
              }}
            >
              Export
            </StyledButton>
          </StyledFooter>
        </React.Fragment>
      )}
    </Mutation>
  );
};

export default ExportData;
