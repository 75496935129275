import React from "react";
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCancel,
  Button,
  TextInput
} from "samplify-design";
import Select from "../../components/design/Select";

const CreateCrosstab = ({ clearModal }) => (
  <div>
    <ModalHeader>New Crosstab</ModalHeader>
    <ModalBody>
      <TextInput />
      <Select />
      <Select />
    </ModalBody>
    <ModalFooter>
      <ModalCancel onClick={() => clearModal()}>Cancel</ModalCancel>
      <Button>Save</Button>
    </ModalFooter>
  </div>
);

export default CreateCrosstab;
