import React, { Component } from "react";
import { Checkbox } from "samplify-design";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import GenericErrorBoundary from "../GenericErrorBoundary";
import DashboardStyler from "../dashboard/DashboardStyler";
import styled from "styled-components";
import { userQuery } from "../../lib/queries/user";
import TosDecline from "./TosDecline";
import TosAccept from "./TosAccept";
import DownloadPdf from "./DownloadPdf";
import PageLoading from "../PageLoading";
import PageError from "../PageError";

const TosStyler = styled.div`
  background-color: ${({ theme }) => theme.neutralLight["5"]};
  color: ${({ theme }) => theme.neutralDark["1"]};
  height: 100%;
  font-size: 1.4rem;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
`;

const ToCText = styled.ol`
  padding: 2rem 3.5rem;
  background-color: #ffffff;
  max-height: 350px;
  overflow: scroll;
  list-style: decimal;
  .extrastrong {
    font-weight: 700;
    text-decoration: underline;
  }
  ul {
    list-style: decimal;
    line-height: 1.5;
  }

  ol {
    list-style: lower-roman;
    ol {
      list-style: lower-alpha;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 0.4rem;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  button {
    margin-left: 1rem;
    width: 18rem;
  }
`;

const SmallText = styled.p`
  font-size: 1.2rem;
`;

const RedText = styled(SmallText)`
  color: ${({ theme }) => theme.danger["1"]};
  text-align: right;
  display: ${props => props.display || "none"};
  flex-grow: 1;
  padding-top: 5px;
  margin-right: 0.4rem;
`;

const TOS_QUERY = gql`
  query TermsTemplate {
    termsTemplate {
      version
      template
      downloadLink
    }
  }
`;

const ACCEPT_TOS_MUTATION = gql`
  mutation ACCEPT_TOS_MUTATION {
    acceptTermsAndConditions
  }
`;

class Tos extends Component {
  state = {
    agree: false,
    redText: "none",
    errors: []
  };

  handleChange = () => {
    this.setState({ agree: !this.state.agree, redText: "none" });
  };

  showRedText = () => {
    this.setState({ redText: "block" });
  };

  hideRedText = () => {
    this.setState({ redText: "none" });
  };

  clearErrors = () => {
    this.setState({ errors: [] });
  };

  htmlEntities = str => {
    return String(str)
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;");
  };

  render() {
    return (
      <GenericErrorBoundary>
        <Query query={TOS_QUERY}>
          {({ data, error, loading }) => {
            if (loading) return <PageLoading />;
            if (error) return <PageError />;

            const { termsTemplate } = data;
            const { template, downloadLink } = termsTemplate;
            const parser = new DOMParser();
            const HTMLdoc = parser.parseFromString(template, "text/xml");
            const text = HTMLdoc.firstChild.innerHTML;

            return (
              <Mutation
                mutation={ACCEPT_TOS_MUTATION}
                refetchQueries={[{ query: userQuery }]}
              >
                {(acceptTermsAndConditions, { loading }) => {
                  const maybeErrors = this.state.errors.length
                    ? this.state.errors.map((message, i) => (
                        <RedText display={"block"} key={i}>
                          {message}
                        </RedText>
                      ))
                    : null;

                  const acceptTos = () => {
                    this.clearErrors();
                    acceptTermsAndConditions().catch(e => {
                      const errorMsgs = e.graphQLErrors.map(e => e.message);
                      this.setState({ errors: errorMsgs });
                    });
                  };
                  return (
                    <DashboardStyler data-testid="TestID">
                      <TosStyler>
                        <Header>
                          <h1>SAMPLIFY USAGE TERMS</h1>
                          <DownloadPdf downloadLink={downloadLink} />
                        </Header>
                        <p>
                          By clicking on the “I Accept” button below you hereby
                          agree to the following terms and conditions for your
                          use of the Samplify Service.
                        </p>
                        <ToCText dangerouslySetInnerHTML={{ __html: text }} />
                        <SmallText>
                          By clicking “I ACCEPT” below you represent that you
                          are an authorized representative of your company and
                          are empowered to commit your company to the terms
                          contained herein.
                        </SmallText>
                        <Flex>
                          <label>
                            <StyledCheckbox
                              checked={this.state.agree}
                              onChange={this.handleChange}
                            />
                            I have read and agree to the terms of services
                          </label>
                          {maybeErrors}
                          <RedText display={this.state.redText}>
                            Please check the box to indicate that you have read
                            and agree to the terms of service before accepting
                          </RedText>
                        </Flex>
                        <Buttons>
                          <TosDecline />
                          <TosAccept
                            agree={this.state.agree}
                            showRedText={this.showRedText}
                            loading={loading}
                            acceptTos={acceptTos}
                          />
                        </Buttons>
                      </TosStyler>
                    </DashboardStyler>
                  );
                }}
              </Mutation>
            );
          }}
        </Query>
      </GenericErrorBoundary>
    );
  }
}

export default Tos;
