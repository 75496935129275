import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle<any>`
    html {
        font-size: 10px; /* for em scaling */
        height: 100%;
    }

    #root {
        height: 100%;
        position: relative;
    }

    body {
        color: ${({ theme }) => theme.neutralDark["4"]};
        margin: 0;
        padding: 0;
        font-family: ${({ theme }) => theme.fontFamily};
        background-color: ${({ theme }) => theme.neutralLight["5"]};
        height: 100%;
        font-size: 1.2rem;
    }

    .__GA_RENDER__ {
        display: none;
    }
`;

export default GlobalStyle;
