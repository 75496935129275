import React from "react";
import IdleTimer from "react-idle-timer";
import { logout } from "../networkHelpers";

const IDLE_TIMEOUT = 1000 * 60 * 30;

const IdleLogoutContainer = () => (
  <IdleTimer debounce={250} timeout={IDLE_TIMEOUT} onIdle={logout} />
);

export default IdleLogoutContainer;
