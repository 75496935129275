import { LineItemAction, LineItemActionKind } from "../../types";

export const MIN_COMPLETES = 1;
export const MAX_COMPLETES = 999999;

export const MIN_DURATION = 1;
export const MAX_DURATION = 60;

export const MIN_DAYS_IN_FIELD = 1;
export const MAX_DAYS_IN_FIELD = 1000;

export const MIN_INCIDENCE = 1;
export const MAX_INCIDENCE = 100;

export const MAX_NUMBER_OF_ZIP_CODES_OR_RESPONDENT_IDS = 100000;

export const MAX_ATTRIBUTE_OPTIONS = 10000;
export const MAX_ATTRIBUTE_OPTIONS_ERROR_MESSAGE = (
  maxAttributeOptions = MAX_ATTRIBUTE_OPTIONS
) => "Maximum number of values allowed are " + maxAttributeOptions;

export const TIER_PREMIUM = "PREMIUM";
export const TIER_STANDARD = "STANDARD";

export const COST_TYPE_PREMIUM = "PREMIUM";
export const COST_TYPE_BASE = "BASE";

export const PERFORMANCE = "Performance";
export const AVAIL_AFTER_LAUNCH = " (Available after launch)";
export const TARGETING_ATTRIBUTES = "Targeting Attributes";
export const NONE_SELECTED = " (none selected)";

export const GET_ESTIMATES_MSG = "Get estimates to continue.";
export const GETTING_ESTIMATES_MSG = "Getting estimates...";
export const EDIT_OPEN_MSG = "Cancel or Save changes.";

const defaultActionWith = (kind: LineItemActionKind): LineItemAction => ({
  metadata: {
    label: "",
    enabled: false,
    visible: false,
    description: ""
  },
  kind
});

export const DEFAULT_LAUNCH_ACTION = defaultActionWith(
  LineItemActionKind.LAUNCH
);
export const DEFAULT_PAUSE_ACTION = defaultActionWith(LineItemActionKind.PAUSE);
export const DEFAULT_DUPLICATE_ACTION = defaultActionWith(
  LineItemActionKind.DUPLICATE
);
export const DEFAULT_CLOSE_ACTION = defaultActionWith(LineItemActionKind.CLOSE);
export const DEFAULT_EDIT_ACTION = defaultActionWith(LineItemActionKind.EDIT);
export const DEFAULT_SPLIT_SOURCE_ACTION = defaultActionWith(
  LineItemActionKind.SPLIT_SOURCE
);
export const DEFAULT_REJECT_REPRICE_ACTION = defaultActionWith(
  LineItemActionKind.DECLINE_REPRICE
);
export const DEFAULT_APPROVE_REPRICE_ACTION = defaultActionWith(
  LineItemActionKind.APPROVE_REPRICE
);
export const DEFAULT_ESTIMATES_ACTION = defaultActionWith(
  LineItemActionKind.ESTIMATES
);
