import React from "react";
import { Mutation } from "react-apollo";
import SaveTemplate from "../../components/modal/SaveTargetingTemplate";
import { createTargetingTemplate } from "../../lib/queries/lineitem";
import { CreateTargetingTemplateInput } from "../../types";

type Data = {
  createTargetingTemplate: { id: string };
};

type Props = {
  clearModal: () => void;
};

const CreateTargetingTemplateContainer = ({ clearModal }: Props) => {
  return (
    <Mutation mutation={createTargetingTemplate} onCompleted={clearModal}>
      {mutateCreateTargetingTemplate => {
        const createTemplate = (input: CreateTargetingTemplateInput) =>
          mutateCreateTargetingTemplate({ variables: { input } });

        return (
          <SaveTemplate
            createTemplate={createTemplate}
            clearModal={clearModal}
          />
        );
      }}
    </Mutation>
  );
};

export default CreateTargetingTemplateContainer;
