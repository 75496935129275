import React, { useContext } from "react";
import ModalPropsContext from "../../../containers/ModalPropsContext";
import ViewReport from "./ViewReport";

interface PropTypes {
  clearModal: () => void;
}

const ViewReportOnModal = ({ clearModal }: PropTypes) => {
  const { project, status, fromRespondent } = useContext<any>(
    ModalPropsContext
  ).modalProps;

  return (
    <ViewReport
      project={project}
      modal={true}
      clearModal={clearModal}
      status={status}
      fromRespondent={fromRespondent}
    />
  );
};

ViewReportOnModal.contextType = ModalPropsContext;

export default ViewReportOnModal;
