import gql from "graphql-tag";

export const userQuery = gql`
  query User {
    userInfo {
      id
      userName
      userTermsInfo {
        termsAccepted
        signedVersion
        currentVersion
      }
      fullName
      firstName
      initials
      email
      companies {
        isDefault
        isEffective
        id
        name
      }
      settings {
        allowSurveyBuilder
        applications {
          id
          title
          description
          icon
          url
        }
      }
      helpScoutSignature
      hsDashboardMessage
      hsRespondentsMessage
      hsSurveysMessage
      hsInvoicesMessage
      actions {
        kind
        metadata {
          label
          enabled
          visible
          description
        }
      }
    }
  }
`;

export default userQuery;
