import { useQuery, QueryHookOptions } from "react-apollo-hooks";
import { dashboardTableQuery } from "../lib/queries/project";
import { formatFilters } from "../projectHelpers";
import { ProjectFilters, FormattedFilters } from "../types/ProjectFilters";
import { Project } from "../types";

type Data = {
  projects: {
    projectsList: Array<Project>;
    hasNext: boolean;
    totalProjects: number;
  };
};
type Variables = { pageNumber: number } & FormattedFilters;
type Options = QueryHookOptions<Variables>;

const useGetDashboardProjects = (filters: ProjectFilters) => {
  const formattedFilters: FormattedFilters = formatFilters(filters);
  const variables: Variables = { pageNumber: 0, ...formattedFilters };
  const options: Options = {
    variables,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true
  };
  return useQuery<Data, Variables>(dashboardTableQuery, options);
};

export default useGetDashboardProjects;
