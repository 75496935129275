import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import "dip-launcher";
import { Application } from "../types";

type Props = {
  apps: Application[];
  open: boolean;
  setOpen: (val: boolean) => void;
  className?: string;
};

interface CustomWindow extends Window {
  DIPLauncherWidget?: {
    open: boolean;
    mount: Function;
    render: Function;
  };
  __SERVER_VARS__?: {
    attributes: {
      nodeENV: string | null;
    };
  };
}

const getEnvTag = () => {
  const vars = customWindow.__SERVER_VARS__;
  if (vars === undefined) return "PROD";
  const env = vars.attributes.nodeENV;
  if (env === null) return "PROD";
  return env.toUpperCase();
};

const customWindow = window as CustomWindow;
const widget = customWindow.DIPLauncherWidget;

const DIPLauncher = ({ apps, open, setOpen, className }: Props) => {
  const [mounted, setMounted] = useState(false);
  const appIds = apps.map(a => a.id.toUpperCase());
  const env = getEnvTag();
  const contentRef = (node: any) => {
    if (node !== null && !mounted && widget !== undefined) {
      widget.mount({ parentElement: node, open, apps: appIds, env });
      setMounted(true);
    }
  };

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const launcherContainer = document.getElementsByClassName(
        "launcher-container"
      )[0];
      if (launcherContainer) {
        const appsButton = document.getElementsByClassName("icon-grid")[0];
        if (
          // @ts-ignore
          !launcherContainer.contains(event.target) &&
          event.target !== appsButton
        ) {
          setOpen(false);
        }
      }
    },
    [setOpen]
  );

  useEffect(() => {
    if (open) {
      customWindow.addEventListener("click", handleClickOutside, true);
    }
    if (mounted && widget !== undefined) {
      widget.render({ open, apps: appIds, env });
    }
    return () => {
      customWindow.removeEventListener("click", handleClickOutside, true);
    };
  }, [mounted, open, appIds, env, handleClickOutside]);
  return <div ref={contentRef} className={className} />;
};

const StyledLauncher = styled(DIPLauncher)`
  position: fixed;
  display: ${({ open }) => (open ? "block" : "none")};
  height: 100%;
  width: 100%;
  z-index: 10000;
`;

export default StyledLauncher;
