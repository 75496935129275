import React from "react";
import styled from "styled-components";
import { flags } from "samplify-design";

const Outer = styled.div`
  display: inline-block;
  margin-right: 1rem;
  > svg {
    width: 1.6rem;
    height: auto;
  }
`;

const Flag = ({ countryISOCode, ...rest }) => {
  const F = flags[countryISOCode];
  if (!F) return null;
  return (
    <Outer {...rest}>
      <F />
    </Outer>
  );
};

export default Flag;
