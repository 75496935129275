import React from "react";
import styled from "styled-components";
import BlockHeader from "./BlockHeader";

const StyledFilterBlock = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.neutralLight["5"]};
`;

const FilterBlock = ({ content, data, label, openBlock, setOpenBlock }) => {
  const isOpen = label === openBlock;
  return (
    <StyledFilterBlock>
      <BlockHeader
        data={data}
        isOpen={isOpen}
        label={label}
        setOpenBlock={setOpenBlock}
      />
      {isOpen && content}
    </StyledFilterBlock>
  );
};

export default FilterBlock;
