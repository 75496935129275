import React from "react";
import styled from "styled-components";
import art from "../art/LaunchProject.svg";

const StyledImg = styled.img`
  max-width: 30rem;
  height: auto;
  padding: 2rem;
  margin: 0 auto;
`;
const Gong = (props: any) => <StyledImg src={art} {...props} />;

export default Gong;
