import React, { useState, useContext } from "react";
import styled from "styled-components";
import { ModalHeader, ModalBody, ModalFooter, Button } from "samplify-design";
import RespondentId from "./ReportFilter/RespondentId";
import DateRange from "./ReportFilter/DateRange";
import SurveyDuration from "./ReportFilter/SurveyDuration";
import ModalPropsContext from "../../containers/ModalPropsContext";
import FilterBlock from "./ReportFilter/FilterBlock";

const StyledButton = styled(Button)`
  width: 15rem;
  height: 4rem;
  padding: 0;
`;

const StyledCancelButton = styled(Button)`
  color: ${({ theme }) => theme.neutralDark["2"]};
  background-color: transparent;
  text-decoration: underline;
  &:hover,
  &:active,
  &:focus {
    background: transparent;
    box-shadow: none;
  }
`;

const StyledModalBody = styled(ModalBody)`
  padding: 0;
  flex-grow: unset;
`;

const CreateFilter = ({ clearModal }) => {
  const { modalProps } = useContext(ModalPropsContext);
  const { usedReportFilter } = modalProps;
  const { reportFilter, setReportFilter, block } = usedReportFilter;
  const [openBlock, setOpenBlock] = useState(block);
  const [respondentID, setRespondentID] = useState(reportFilter.respondentID);
  const [duration, setDuration] = useState(reportFilter.duration);
  const [filterDate, setFilterDate] = useState(reportFilter.filterDate);
  const [moreThanError, setMoreThanError] = useState(false);
  const [lessThanError, setLessThanError] = useState(false);

  const saveFilters = () => {
    setReportFilter({
      respondentID: respondentID ? [...new Set(respondentID)] : null,
      duration:
        duration && (!duration.lessThan && !duration.moreThan)
          ? null
          : duration,
      filterDate
    });
  };

  const onSave = () => {
    saveFilters();
    clearModal();
  };

  return (
    <div>
      <ModalHeader>Report Filters</ModalHeader>
      <StyledModalBody>
        <FilterBlock
          content={
            <RespondentId
              respondentID={respondentID}
              setRespondentID={setRespondentID}
            />
          }
          data={respondentID}
          label="Respondent ID"
          openBlock={openBlock}
          setOpenBlock={setOpenBlock}
        />
        <FilterBlock
          content={
            <SurveyDuration
              duration={duration}
              setDuration={setDuration}
              moreThanError={moreThanError}
              setMoreThanError={setMoreThanError}
              lessThanError={lessThanError}
              setLessThanError={setLessThanError}
            />
          }
          data={duration}
          label="Duration"
          openBlock={openBlock}
          setOpenBlock={setOpenBlock}
        />
        <FilterBlock
          content={
            <DateRange filterDate={filterDate} setFilterDate={setFilterDate} />
          }
          data={filterDate}
          label="Date Range"
          openBlock={openBlock}
          setOpenBlock={setOpenBlock}
        />
      </StyledModalBody>
      <ModalFooter>
        <StyledCancelButton onClick={() => clearModal()}>
          Cancel
        </StyledCancelButton>
        <StyledButton
          disabled={moreThanError || lessThanError}
          onClick={() => onSave()}
        >
          Save
        </StyledButton>
      </ModalFooter>
    </div>
  );
};

export default CreateFilter;
