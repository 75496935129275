import React, { useState } from "react";
import { TextInput } from "samplify-design";
import styled from "styled-components";
import search from "../../art/search.svg";
import pipe from "../../art/pipeDark.svg";
import { StyledMenuHeaderLabel, StyledMenuHeader } from "./AccountMenuStyles";
import { Company } from "../../types";
import { sortCompanies } from "../../generalHelpers";

type CustomDivProps = {
  isEffective: boolean;
} & React.HTMLProps<HTMLDivElement>;

const CustomDiv = ({ isEffective, ...rest }: CustomDivProps) => (
  <div {...rest} />
);

const StyledCompanyLabel = styled(CustomDiv)`
  align-items: center;
  background-color: ${props =>
    props.isEffective ? "#EDF8FC" : "var(--light-color-6)"};
  color: ${props => (props.isEffective ? "#373941" : "inherit")};
  display: flex;
  justify-content: space-between;
  padding: 0.7rem 1.6rem;
  :hover {
    background-color: #edf8fc;
    color: #373941;
    cursor: pointer;
  }
`;

const StyledCompanyList = styled.div`
  font: normal 400 1.4rem/2.2rem ${props => props.theme.fontFamily};
  max-height: 18rem;
  overflow-y: scroll;
`;

const StyledInput = styled(TextInput)`
  margin: 0;
  padding-left: 3.8rem;
  width: 100%;
  ::placeholder {
    color: #9599a4;
  }
`;

const StyledInputWrapper = styled.div`
  display: flex;
  font: normal 400 1.4rem/2.2rem ${props => props.theme.fontFamily};
  padding: 1.2rem 1.6rem;
  position: relative;
`;

const StyledSearchIcon = styled.img`
  left: 3.3rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

type Props = {
  companies: Array<Company>;
  onCompanySelect: (c: Company) => void;
};

const CompanySwitcherMenu = ({ companies, onCompanySelect }: Props) => {
  const [searchText, setSearchText] = useState("");

  const onInputChange = (e: React.FormEvent<HTMLInputElement>) =>
    setSearchText(e.currentTarget.value);

  const sortedCompanies = sortCompanies(companies);

  return (
    <React.Fragment>
      <StyledMenuHeader>
        <StyledMenuHeaderLabel>Switch Company</StyledMenuHeaderLabel>
      </StyledMenuHeader>
      <StyledInputWrapper>
        <StyledSearchIcon src={search} alt="" />
        <StyledInput
          value={searchText}
          placeholder="Search companies"
          onChange={onInputChange}
        />
      </StyledInputWrapper>
      <StyledCompanyList>
        {sortedCompanies
          .filter((c: Company) =>
            c.name.toLowerCase().includes(searchText.toLowerCase())
          )
          .map((c: Company) => {
            return (
              <StyledCompanyLabel
                key={c.id}
                isEffective={c.isEffective}
                onClick={() => onCompanySelect(c)}
              >
                {c.name}
                {c.isEffective && <img src={pipe} alt="" />}
              </StyledCompanyLabel>
            );
          })}
      </StyledCompanyList>
    </React.Fragment>
  );
};

export default CompanySwitcherMenu;
