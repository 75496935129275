import React from "react";
import styled from "styled-components";
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  TextInputLine,
  Button
} from "samplify-design";

const StyledFooter = styled(ModalFooter)`
  height: auto;
`;

const StyledButton = styled(Button)`
  width: 15rem;
  height: 4rem;
  padding: 0;
`;

const StyledCancelButton = styled(Button)`
  color: ${({ theme }) => theme.neutralDark["2"]};
  background-color: transparent;
  text-decoration: underline;
  &:hover,
  &:active,
  &:focus {
    background: transparent;
    box-shadow: none;
  }
`;

const ButtonsFlex = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
`;

const CreateReport = ({ clearModal }) => (
  <div>
    <ModalHeader>Create new report</ModalHeader>
    <ModalBody>
      <TextInputLine
        style={{ width: "16rem" }}
        placeholder="Give your report a name"
      />
    </ModalBody>
    <StyledFooter>
      <ButtonsFlex>
        <StyledCancelButton onClick={() => clearModal()}>
          Cancel
        </StyledCancelButton>
        <StyledButton>Create Report</StyledButton>
      </ButtonsFlex>
    </StyledFooter>
  </div>
);

export default CreateReport;
