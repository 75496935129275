import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { ModalBody, ModalFooter, Button, Spinner } from "samplify-design";
import { intro } from "./Constants";
import { reconcile } from "../../../networkHelpers";
import {
  Project,
  ProjectState,
  ProjectActionKind,
  ResourceType
} from "../../../types";
import { getInvoicePDF, getInvoiceSummaryPDF } from "../../../invoice-api";
import LineItemTable from "./LineItemTable";
import RequestCorrection from "./RequestCorrection";
import Footer from "./FooterContent";
import useCloseProject from "../../../hooks/project/useCloseProject";
import { isZeroCost } from "../../../invoiceHelpers";
import { isClosed } from "../../../projectHelpers";
import { getActionsMap } from "../../../generalHelpers";

const Intro = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`;

const InvoiceName = styled.p`
  font-size: 18px;
  margin: 0 0 5px 0;
  color: ${(props: StyleType) => (props.modal ? "inherit" : "#3EA8DD")};
`;

const RefNumber = styled.div`
  color: #9599a4;
`;

const StyledModalBody = styled(ModalBody)`
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  padding: 40px 40px 10px;
  text-align: left;
  flex-grow: unset;
`;

const StyledModalFooter = styled(ModalFooter)`
  padding: ${props => (props.modal ? "0 40px 30px" : "0")};
  background-color: #fff;
  height: auto;
  line-height: inherit;
`;

const StyledModalHeader = styled.div`
  margin: -40px -40px 20px -40px;
  background: #e7e8eb;
  padding: 17px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledInvoiceTitle = styled.div`
  margin-bottom: 20px;
`;

interface StyleType {
  modal?: boolean;
}

const StyledRequestCorrection = styled.div`
  padding: ${(props: StyleType) => (props.modal ? "0 40px" : "0")};
  background: #fff;
`;

const StyledButton = styled(Button)`
  border-radius: 4px;
  padding: 0 1.1em;
  margin: 0 7px;
  font-weight: normal;
  width: 140px;
`;

const StyledSummaryButton = styled(StyledButton)`
  background-color: #696e7b;
`;

const StyledSpinner = styled(Spinner)`
  color: #fff;
  stroke: #fff;
  fill: #fff;
`;

interface PropTypes {
  project: Project;
  modal?: boolean;
  status?: ProjectState;
  fromRespondent?: boolean;
  clearModal?: () => void;
}

const ViewReport = ({
  project,
  modal,
  clearModal,
  status,
  fromRespondent
}: PropTypes) => {
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [showCorrection, setShowCorrection] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(true);
  const [loadingCorrection, setLoadingCorrection] = useState(false);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const { extProjectId, state, title, jobNumber, actions } = project;
  const [closeProject] = useCloseProject(extProjectId);

  const projectActionsMap = getActionsMap(ResourceType.PROJECT, actions);
  const actionViewInvoice =
    projectActionsMap[ProjectActionKind.DOWNLOAD_INVOICE];
  const {
    label: invoicesLabel,
    visible: invoiceVisible,
    enabled: invoiceEnabled
  } = actionViewInvoice;

  const onApproveSummary = () => {
    closeProject();
    clearModal && clearModal();
  };

  const sendCorrection = async () => {
    const { extProjectId } = project;
    setLoadingCorrection(true);
    reconcile(description, file, extProjectId)
      .then(clearModal)
      .then(setLoadingCorrection(false))
      .catch((e: Error) => {
        clearModal && clearModal();
        setLoadingCorrection(false);
      });
  };

  const onDescriptionChange = (e: React.FormEvent<HTMLTextAreaElement>) =>
    setDescription(e.currentTarget.value);

  const onFileChange = (selectedFile: any) => {
    if (!selectedFile) return;
    setFile(selectedFile);
    setFileName(selectedFile.name);
    setSendDisabled(false);
  };

  const deleteFile = () => {
    setFile(null);
    setFileName("");
    setSendDisabled(true);
  };

  const toggleRequestCorrection = () => setShowCorrection(!showCorrection);

  const maybeJobNumber = jobNumber !== "" && (
    <RefNumber>Customer PO number: {jobNumber}</RefNumber>
  );
  const reportHeader = (
    <Fragment>
      <StyledInvoiceTitle>
        <InvoiceName modal={modal}>{title}</InvoiceName>
        {maybeJobNumber}
      </StyledInvoiceTitle>
      <LineItemTable project={project} clearModal={clearModal} />
    </Fragment>
  );

  const cost = project.report.cost.incurredCost;
  const changeInvoiceLoading = (b: boolean) => setInvoiceLoading(b);
  const changeSummaryLoading = (b: boolean) => setSummaryLoading(b);
  const handleInvoiceDownload = () =>
    getInvoicePDF(extProjectId, changeInvoiceLoading);
  const downloadSummary = () =>
    getInvoiceSummaryPDF(extProjectId, changeSummaryLoading);
  const maybeInvoiceLoader = invoiceLoading ? <StyledSpinner /> : invoicesLabel;
  const maybeSummaryLoader = summaryLoading ? (
    <StyledSpinner />
  ) : (
    "Download Summary"
  );
  const maybeInvoiceBtn = status &&
    status === "INVOICED" &&
    invoiceVisible &&
    !isZeroCost(cost) && (
      <StyledButton onClick={handleInvoiceDownload} disabled={!invoiceEnabled}>
        {maybeInvoiceLoader}
      </StyledButton>
    );
  const maybeSummaryBtn = status && isClosed(status) && (
    <StyledSummaryButton onClick={downloadSummary}>
      {maybeSummaryLoader}
    </StyledSummaryButton>
  );
  const modalHeader = (
    <StyledModalBody>
      <StyledModalHeader>
        <Intro>{intro}</Intro>
        <div>
          {maybeInvoiceBtn}
          {maybeSummaryBtn}
        </div>
      </StyledModalHeader>
      {reportHeader}
    </StyledModalBody>
  );
  const header = modal ? modalHeader : reportHeader;
  const maybeCorrection = showCorrection && (
    <StyledRequestCorrection modal={modal}>
      <RequestCorrection
        description={description}
        fileName={fileName}
        onDescriptionChange={onDescriptionChange}
        onFileChange={onFileChange}
        deleteFile={deleteFile}
      />
    </StyledRequestCorrection>
  );

  return (
    <Fragment>
      {header}
      {maybeCorrection}
      <StyledModalFooter modal={modal}>
        <Footer
          onApproveSummary={onApproveSummary}
          onCancel={clearModal}
          sendDisabled={sendDisabled}
          showCorrection={showCorrection}
          state={state}
          toggleShowCorrection={toggleRequestCorrection}
          onSendCorrection={sendCorrection}
          loadingCorrection={loadingCorrection}
          modal={modal}
          fromRespondent={fromRespondent}
          projectActionsMap={projectActionsMap}
        />
      </StyledModalFooter>
    </Fragment>
  );
};

export default ViewReport;
