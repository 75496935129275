import React from "react";
import AttributeOptionEditor from "./AttributeOptionEditor";
import AttributeRangeEditor from "./AttributeRangeEditor";
import AttributeStringEditor from "./AttributeStringEditor";
import AttributeIntegerEditor from "./AttributeIntegerEditor";
import { AttributeEditorProps } from "../../quotaHelpers";

const AttributeEditorSwitch = (props: AttributeEditorProps) => {
  const { attribute } = props;

  switch (attribute.type) {
    case "LIST":
      return <AttributeOptionEditor {...props} />;

    case "INTEGER_RANGE":
      return <AttributeRangeEditor {...props} />;

    case "STRING":
      return <AttributeStringEditor {...props} />;

    case "INTEGER":
      return <AttributeIntegerEditor {...props} />;

    default:
      return null;
  }
};

export default AttributeEditorSwitch;
