import React from "react";
import Modal from "react-modal";
import styled from "styled-components";

interface PropTypes {
  className?: string;
  children?: any;
  appElement: HTMLElement | undefined;
  closeTimeoutMS: number;
  isOpen: boolean;
  contentLabel: string;
  onRequestClose: () => void;
}

const ModalRenderer = ({ className, children, ...props }: PropTypes) => {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;
  return (
    <Modal
      portalClassName={className}
      overlayClassName={overlayClassName}
      className={contentClassName}
      {...props}
    >
      {children}
    </Modal>
  );
};

const StyledModal = styled(ModalRenderer)`
  &__overlay {
    overflow-y: auto;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 150ms linear;
    z-index: 999;
    &.ReactModal__Overlay--after-open {
      opacity: 1;
    }
    &.ReactModal__Overlay--before-close {
      opacity: 0;
    }
  }
  &__content {
    display: flex;
    font-size: 14px;
    flex-direction: column;
    max-width: 700px;
    margin: 40px auto;
    outline: none;
    transform: translate(0, -25%);
    transition: transform 150ms ease-out;
    &.ReactModal__Content--after-open {
      transform: translate(0, 0);
    }
    &.ReactModal__Content--before-close {
      transform: translate(0, -25%);
    }
  }
`;

export default StyledModal;
