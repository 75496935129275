import { useContext } from "react";
import ModalPropsContext from "../containers/ModalPropsContext";

const useSetModal = (modalType: string) => {
  const { setModalData } = useContext(ModalPropsContext);
  const setModal = (modalProps?: any) => {
    setModalData({ modalProps, modalType });
  };
  return setModal;
};

export default useSetModal;
