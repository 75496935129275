import React from "react";
import { Button } from "samplify-design";
import styled from "styled-components";
import modalTypes from "../../constants/modalTypes";
import useSetModal from "../../hooks/useSetModal";

const Decline = styled(Button)``;

const TosDecline = () => {
  const setDeclineToSModal = useSetModal(modalTypes.DECLINE_TOS);

  return (
    <Decline onClick={setDeclineToSModal} swatch="danger">
      I Decline
    </Decline>
  );
};

export default TosDecline;
